import React from 'react';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import DataGridLocalization from '../../../utils/DataGridLocalization';
import { Section } from '../../../components/Section';
import useCallHistory, { AssignedContact } from './gql/callHistory';
import useErrorHandler from '../../../utils/userErrorHandler';
import formatPhoneNumber from '../../../utils/formatPhoneNumber';

type CallHistoryProps = {
    id: number;
};

const CallHistory: React.FC<CallHistoryProps> = ({ id }: CallHistoryProps) => {
    const callHistory = useCallHistory({ customer: id });
    useErrorHandler(callHistory.error);

    const getName = (history: AssignedContact) => {
        if (history.list_contact !== null) return `${history.list_contact.customer.lastname} ${history.list_contact.customer.firstname}`;
        if (history.late_payment_list_contact !== null) return `${history.late_payment_list_contact.customer.lastname} ${history.late_payment_list_contact.customer.firstname}`;
    };

    const getPhone = (history: AssignedContact) => {
        if (history.list_contact !== null) return formatPhoneNumber(history.list_contact.customer.phone);
        if (history.late_payment_list_contact !== null) return formatPhoneNumber(history.late_payment_list_contact.customer.phone);
    };

    const getEmail = (history: AssignedContact) => {
        if (history.list_contact !== null) return history.list_contact.customer.customer.email;
        if (history.late_payment_list_contact !== null) return history.late_payment_list_contact.customer.customer.email;
    };

    const getComment = (history: AssignedContact) => {
        if (history.list_contact !== null) return history.list_contact.comment;
        if (history.late_payment_list_contact !== null) return history.late_payment_list_contact.comment;
    };

    const getDate = (history: AssignedContact) => {
        if (history.list_contact !== null) return history.list_contact.created_at;
        if (history.late_payment_list_contact !== null) return history.late_payment_list_contact.created_at;
    };

    const makeRows = (callHistory: AssignedContact[]) => {
        const rows: GridRowsProp = callHistory.map((history) => ({
            id: history.id,
            historyID: history.id,
            name: getName(history),
            phone: getPhone(history),
            email: getEmail(history),
            state: `${history.call_state.name} ${history.talk_state?.name ? ` / ${history.talk_state?.name}` : ''}`,
            ts_user: `${history.ts_user.lastname} ${history.ts_user.firstname}`,
            comment: getComment(history),
            date: getDate(history),
        }));

        return rows;
    };

    const makeCols = () => {
        const columns: GridColDef[] = [
            { field: 'historyID', headerName: 'ID', width: 100 },
            { field: 'name', headerName: 'Név', width: 150 },
            { field: 'phone', headerName: 'Telefonszám', width: 150 },
            { field: 'email', headerName: 'E-mail', width: 200 },
            { field: 'state', headerName: 'Állapot', width: 200 },
            { field: 'ts_user', headerName: 'Értékesítő', width: 200 },
            { field: 'comment', headerName: 'Megjegyzés', width: 250 },
            { field: 'date', headerName: 'Létrehozva', width: 200 },
        ];

        return columns;
    };

    return (
        <Section title="Hívás előzmények">
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                    componentsProps={{
                        pagination: {
                            labelRowsPerPage: 'Oldalanként',
                            labelDisplayedRows: ({ from, to, count }: { from: number; to: number; count: number }) => `${from}–${to} / ${count !== -1 ? count : `more than ${to}`}`,
                        },
                    }}
                    localeText={DataGridLocalization}
                    rows={makeRows(callHistory.data || [])}
                    columns={makeCols()}
                    style={{ borderRadius: 0, border: 'none' }}
                />
            </div>
        </Section>
    );
};

export default CallHistory;
