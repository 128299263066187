import { ApolloClient, ApolloError, gql, InMemoryCache, useQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import getMyLatePaymentListContacts from './getMyLatePaymentListContacts';
import getMyListContacts from './getMyListContacts';
import getMySalesPopupListContacts from './getMySalesPopupListContacts';
import getMyStuckOrderListContacts from './getMyStuckOrderListContacts';
import getMyUpsellListContacts from './getMyUpsellListContacts';

const myListsGql = gql`
    query getLists($lastNDays: Int!) {
        myLists(lastNDays: $lastNDays) {
            id
        }
    }
`;

const myLatePaymentListsGql = gql`
    query getLists($lastNDays: Int!) {
        myLatePaymentLists(lastNDays: $lastNDays) {
            id
        }
    }
`;

const mySalesPopupListsGql = gql`
    query getLists($lastNDays: Int!) {
        mySalesPopupLists(lastNDays: $lastNDays) {
            id
        }
    }
`;

const myUpsellListsGql = gql`
    query getLists($lastNDays: Int!) {
        myUpsellLists(lastNDays: $lastNDays) {
            id
        }
    }
`;

const myStuckOrderListsGql = gql`
    query getLists($lastNDays: Int!) {
        myStuckOrderLists(lastNDays: $lastNDays) {
            id
        }
    }
`;

const client = new ApolloClient({
    uri: 'https://api.maganpenzugyiakademia.hu',
    cache: new InMemoryCache(),
    credentials: 'include',
});

type List = { id: number };

const getMyLists = (lastNDays: number) => client.query<{ myLists: List[] }>({ query: myListsGql, variables: { lastNDays: lastNDays } });
const getMyLatePaymentLists = (lastNDays: number) => client.query<{ myLatePaymentLists: List[] }>({ query: myLatePaymentListsGql, variables: { lastNDays: lastNDays } });
const getMySalesPopupLists = (lastNDays: number) => client.query<{ mySalesPopupLists: List[] }>({ query: mySalesPopupListsGql, variables: { lastNDays: lastNDays } });
const getMyUpsellLists = (lastNDays: number) => client.query<{ myUpsellLists: List[] }>({ query: myUpsellListsGql, variables: { lastNDays: lastNDays } });
const getMyStuckOrderLists = (lastNDays: number) => client.query<{ myStuckOrderLists: List[] }>({ query: myStuckOrderListsGql, variables: { lastNDays: lastNDays } });

type GetElementType<T extends unknown[]> = T extends (infer U)[] ? U : never;
type GetPromiseType<T> = T extends Promise<infer U> ? U : never;

type ListContact = GetElementType<GetPromiseType<ReturnType<typeof getMyListContacts>>['data']['myListContacts']>;
type SalesPopupListContact = GetElementType<GetPromiseType<ReturnType<typeof getMySalesPopupListContacts>>['data']['mySalesPopupListContacts']>;
type LatePaymentListContact = GetElementType<GetPromiseType<ReturnType<typeof getMyLatePaymentListContacts>>['data']['myLatePaymentListContacts']>;
type UpsellListContact = GetElementType<GetPromiseType<ReturnType<typeof getMyUpsellListContacts>>['data']['myUpsellListContacts']>;
type StuckOrderListContact = GetElementType<GetPromiseType<ReturnType<typeof getMyStuckOrderListContacts>>['data']['myStuckOrderListContacts']>;

export interface MixedContact {
    listContact?: ListContact;
    salesPopupListContact?: SalesPopupListContact;
    latePaymentListContact?: LatePaymentListContact;
    upsellListContact?: UpsellListContact;
    stuckOrderListContact?: StuckOrderListContact;
}

const myMixedContacts = async (lastNDays: number): Promise<{ data?: MixedContact[]; error?: ApolloError }> => {
    const lists = await Promise.all([getMyLists(lastNDays), getMyLatePaymentLists(lastNDays), getMySalesPopupLists(lastNDays), getMyUpsellLists(lastNDays), getMyStuckOrderLists(lastNDays)]);

    const mContacts: MixedContact[] = [];
    let err: ApolloError | undefined = undefined;

    const queriedListContactProms = lists[0].data.myLists.map((list) => getMyListContacts(list.id));
    const queriedListContacts = await Promise.all(queriedListContactProms);

    queriedListContacts.forEach((listContactArray) => {
        if (listContactArray.error !== undefined) {
            err = listContactArray.error;
        }
        if (listContactArray.data !== undefined) {
            listContactArray.data.myListContacts.forEach((l) => mContacts.push({ listContact: l }));
        }
    });

    if (err) return { error: err };

    const queriedLatePaymentListContactProms = lists[1].data.myLatePaymentLists.map((list) => getMyLatePaymentListContacts(list.id));
    const queriedLatePaymentListContacts = await Promise.all(queriedLatePaymentListContactProms);

    queriedLatePaymentListContacts.forEach((latePaymnetListContactArray) => {
        if (latePaymnetListContactArray.error !== undefined) {
            err = latePaymnetListContactArray.error;
        }
        if (latePaymnetListContactArray.data !== undefined) {
            latePaymnetListContactArray.data.myLatePaymentListContacts.forEach((l) => mContacts.push({ latePaymentListContact: l }));
        }
    });

    if (err) return { error: err };

    const queriedSalesPopupListContactProms = lists[2].data.mySalesPopupLists.map((list) => getMySalesPopupListContacts(list.id));
    const queriedSalesPopupListContacts = await Promise.all(queriedSalesPopupListContactProms);

    queriedSalesPopupListContacts.forEach((salesPopupListContactArray) => {
        if (salesPopupListContactArray.error !== undefined) {
            err = salesPopupListContactArray.error;
        }
        if (salesPopupListContactArray.data !== undefined) {
            salesPopupListContactArray.data.mySalesPopupListContacts.forEach((l) => mContacts.push({ salesPopupListContact: l }));
        }
    });

    if (err) return { error: err };

    const queriedUpsellListContactProms = lists[3].data.myUpsellLists.map((list) => getMyUpsellListContacts(list.id));
    const queriedUpsellListContacts = await Promise.all(queriedUpsellListContactProms);

    queriedUpsellListContacts.forEach((UpsellListContactArray) => {
        if (UpsellListContactArray.error !== undefined) {
            err = UpsellListContactArray.error;
        }
        if (UpsellListContactArray.data !== undefined) {
            UpsellListContactArray.data.myUpsellListContacts.forEach((l) => mContacts.push({ upsellListContact: l }));
        }
    });

    if (err) return { error: err };

    const queriedStuckOrderListContactProms = lists[4].data.myStuckOrderLists.map((list) => getMyStuckOrderListContacts(list.id));
    const queriedStuckOrderListContacts = await Promise.all(queriedStuckOrderListContactProms);

    queriedStuckOrderListContacts.forEach((StuckOrderListContactArray) => {
        if (StuckOrderListContactArray.error !== undefined) {
            err = StuckOrderListContactArray.error;
        }
        if (StuckOrderListContactArray.data !== undefined) {
            StuckOrderListContactArray.data.myStuckOrderListContacts.forEach((l) => mContacts.push({ stuckOrderListContact: l }));
        }
    });

    if (err) return { error: err };

    return { data: mContacts };
};

export interface Contact extends ListContact {
    type: 'list' | 'late' | 'sales' | 'upsell' | 'stuck';
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useMyMixedContacts = (lastNDays: number) => {
    const [mixedContacts, setMixedContacts] = useState<MixedContact[]>([]);
    const [error, setError] = useState<ApolloError>();

    const refetch = async (lastNDays: number) => {
        const m = await myMixedContacts(lastNDays);
        if (m.data) setMixedContacts(m.data);
        if (m.error) setError(m.error);
    };

    useMemo(async () => {
        return refetch(lastNDays);
    }, [lastNDays]);

    return { data: mixedContacts, error: error, refetch: refetch };
};

export default useMyMixedContacts;
