import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const updateDayClosing = gql`
    mutation updateDayClosing($id: Int!, $data: DayClosingInput!) {
        updateDayClosing(id: $id, data: $data) {
            id
        }
    }
`;

const useUpdateDayClosing = mutationFactory<{ id: number }>(updateDayClosing, 'updateDayClosing');

export default useUpdateDayClosing;
