/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Paper, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { Section } from '../../components/Section';
import { v4 } from 'uuid';
import { CustomDateRangePicker } from '../../components/CustomDateRangePicker';
import useErrorHandler from '../../utils/userErrorHandler';
import useUsers from '../WorkingTimeListPage/gql/users';
import useMeQuery from '../AbsencesPage/gql/me';
import { addDays } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';
import useUpdateOffDayMutation from './gql/updateOffDay';
import useOffDayQuery from './gql/offDay';
import { useSnackbar } from 'notistack';
import useOffDayReasonsQuery from './gql/offDayReasons';

const AbsenceEditPage: React.FC = () => {
    const params = useParams();
    const id = params.id as string;
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const users = useUsers();
    const me = useMeQuery();
    const offDay = useOffDayQuery({ id: parseInt(id) });
    const offDayReasons = useOffDayReasonsQuery();
    const [updateOffDay, updateResponse] = useUpdateOffDayMutation();
    useErrorHandler(users.error, me.error, updateResponse.error, offDayReasons.error);

    const [reason, setReason] = useState('');
    const [worker, setWorker] = useState('');
    const [date, setDate] = useState<
        {
            startDate: Date;
            endDate: Date;
            key: string;
        }[]
    >([
        {
            startDate: new Date(new Date()),
            endDate: addDays(new Date(), 6),
            key: 'selection',
        },
    ]);

    useEffect(() => {
        if (offDay.data) {
            setReason(offDay.data.reason.id.toString());
            setWorker(offDay.data.user.id.toString());
            setDate([
                {
                    startDate: new Date(offDay.data.start),
                    endDate: new Date(offDay.data.end),
                    key: 'selection',
                },
            ]);
        }
    }, [offDay.data]);

    const handleDateChange = (item: { selection: { startDate: Date; endDate: Date; key: string } }) => {
        setDate([item.selection]);
    };

    const isAdmin = () => {
        return me.data?.permission.name.includes('admin');
    };

    const handeSave = async () => {
        if (reason && worker && date[0].startDate && date[0].endDate) {
            await updateOffDay({
                id: parseInt(id),
                data: {
                    user: parseInt(worker),
                    reason: parseInt(reason),
                    start: date[0].startDate,
                    end: date[0].endDate,
                },
            });
            navigate('/working-hours/absence');
        } else {
            enqueueSnackbar('Az összes mező kitöltése kötelező!', { variant: 'error' });
        }
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`#${id}: Távollét szerkesztése`}>
                <div css={sectionStyles}>
                    <CustomDateRangePicker ranges={date} onChange={handleDateChange} />
                    <div css={someInputContainerStyles}>
                        <div css={{ minWidth: 226 }} />
                        <FormControl sx={{ minWidth: 352 }} disabled={!isAdmin()} css={selectStyles}>
                            <InputLabel id="worker">Név</InputLabel>
                            <Select labelId="worker" id="worker-select" label="Név" value={worker} onChange={(e) => setWorker(e.target.value)}>
                                {users.data?.map((worker) => {
                                    return (
                                        <MenuItem key={v4()} value={worker.id}>
                                            {worker.lastname + ' ' + worker.firstname + ' ' + (worker.middlename ? worker.middlename : '') + ` - (${worker.username})`}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ minWidth: 352 }}>
                            <InputLabel id="reason">Indok</InputLabel>
                            <Select labelId="reason" id="reason-select" label="Indok" value={reason} onChange={(e) => setReason(e.target.value)}>
                                {offDayReasons.data?.map((reason) => {
                                    return (
                                        <MenuItem key={v4()} value={reason.id}>
                                            {reason.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div css={buttonContainerStyle}>
                    <Button variant="contained" color="success" onClick={handeSave}>
                        Módosít
                    </Button>
                </div>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    & > * + * {
        margin-top: 20px !important;
    }
`;

const someInputContainerStyles = css`
    width: 931px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1000px) {
        flex-direction: column;
        & > * + * {
            margin-top: 20px !important;
        }
    }
`;

const selectStyles = css`
    margin-right: 15px;
    @media (max-width: 1000px) {
        margin-right: 0px;
    }
`;

const buttonContainerStyle = css`
    text-decoration: none;
    margin-left: auto;
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
`;

export default AbsenceEditPage;
