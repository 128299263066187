import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const deleteProductCategoryMutation = gql`
    mutation deleteProductCategory($id: Int!) {
        deleteProductCategory(id: $id)
    }
`;

const useDeleteProductCategoryMutation = mutationFactory(deleteProductCategoryMutation, 'deleteProductCategory');

export default useDeleteProductCategoryMutation;
