/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { Requireable } from 'react';
import PropTypes from 'prop-types';

import SubSectionProps from './SubSectionProps';
import SubSectionState from './SubSectionState';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

class SubSection extends React.Component<SubSectionProps, SubSectionState> {
    static propTypes: {
        children: Requireable<unknown>;
        title: Requireable<string>;
        closedDefault: Requireable<boolean>;
        midTitle: Requireable<boolean>;
        fitContent: Requireable<boolean>;
    };

    constructor(props: SubSectionProps) {
        super(props);

        this.state = {
            collapsed: this.props.closedDefault ? true : false,
        };
    }

    render(): React.ReactNode {
        return (
            <Paper className={this.props.className} style={this.props.fitContent ? { width: 'fit-content' } : { width: '100%' }}>
                <div css={headStyles} onClick={this.toggleCollapse} style={this.props.midTitle ? { justifyContent: 'center' } : { justifyContent: 'start' }}>
                    <Typography variant="subtitle1">{this.props.title}</Typography>
                    {this.renderArrow()}
                </div>
                {this.renderBody()}
            </Paper>
        );
    }

    renderArrow(): React.ReactNode {
        if (this.props.collapsible) {
            if (this.state.collapsed) {
                return <KeyboardArrowDownIcon />;
            } else {
                return <KeyboardArrowUpIcon />;
            }
        } else {
            return null;
        }
    }

    renderBody(): React.ReactNode {
        if (!this.props.collapsible) {
            return <div css={bodyStyles}>{this.props.children}</div>;
        } else {
            if (!this.state.collapsed) {
                return <div css={bodyStyles}>{this.props.children}</div>;
            } else {
                return null;
            }
        }
    }

    // HANDLERS
    toggleCollapse = (): void => {
        this.setState(
            (prevState: SubSectionState): SubSectionState => {
                return {
                    collapsed: !prevState.collapsed,
                };
            },
        );
    };
}

const headStyles = css`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #ffffff;
    background-color: #29b6f6;
    padding: 10px;
    box-sizing: border-box;
`;

const bodyStyles = css`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
`;

SubSection.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    closedDefault: PropTypes.bool,
    midTitle: PropTypes.bool,
    fitContent: PropTypes.bool,
};

export default SubSection;
