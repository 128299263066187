/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Paper, Button } from '@mui/material';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import DataGridLocalization from '../../utils/DataGridLocalization';
import { Section } from '../../components/Section';
import useLists, { List } from './gql/lists';
import useErrorHandler from '../../utils/userErrorHandler';

const ListsPage: React.FC = () => {
    const navigate = useNavigate();
    const lists = useLists();
    useErrorHandler(lists.error);

    const makeRows = (lists: List[]) => {
        const rows: GridRowsProp = lists.map((list) => ({
            id: list.id,
            listID: list.id,
            name: list.name,
            description: list.description,
            dynamic: list.dynamic ? 'Igen' : 'Nem',
            frequency: list.frequency || '-',
            ttl: list.ttl || '-',
            date: list.created_at,
            creator: `${list.creator.lastname} ${list.creator.firstname}`,
        }));

        return rows;
    };

    const makeCols = () => {
        const columns: GridColDef[] = [
            { field: 'listID', headerName: 'ID', width: 100 },
            { field: 'name', headerName: 'Név', width: 150 },
            { field: 'description', headerName: 'Leírás', width: 300 },
            { field: 'dynamic', headerName: 'Dinamikus', width: 100 },
            { field: 'frequency', headerName: 'Gyakoriság (perc)', width: 150 },
            { field: 'ttl', headerName: 'Élettartam (óra)', width: 150 },
            { field: 'date', headerName: 'Létrehozva', width: 200 },
            { field: 'creator', headerName: 'Létrehozta', width: 150 },
            {
                field: 'actions',
                headerName: 'Műveletek',
                width: 180,
                renderCell: function renderActions(cellValues) {
                    return (
                        <Button variant="contained" color="primary" onClick={() => navigate(`/telesales/lists/${cellValues.row.id}`)}>
                            Megtekintés
                        </Button>
                    );
                },
            },
        ];

        return columns;
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title="Listák">
                <div style={{ height: 600, width: '100%' }}>
                    <DataGrid
                        componentsProps={{
                            pagination: {
                                labelRowsPerPage: 'Oldalanként',
                                labelDisplayedRows: ({ from, to, count }: { from: number; to: number; count: number }) => `${from}–${to} / ${count !== -1 ? count : `more than ${to}`}`,
                            },
                        }}
                        localeText={DataGridLocalization}
                        rows={makeRows(lists.data || [])}
                        columns={makeCols()}
                        style={{ borderRadius: 0, border: 'none' }}
                    />
                </div>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

export default ListsPage;
