/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Avatar, Button, Tooltip, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NavDropdown from '../NavDropdown/NavDropdown';
import routes from '../../utils/routes';
import IRoute from '../../utils/IRoute';
import useLogout from './gql/logout';

const Navbar: React.FC = () => {
    const [logout, loggedOut] = useLogout();
    const location = useLocation();
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = useState<HTMLElement | null>(null);
    const [anchorElUser, useAnchorElUser] = useState<HTMLElement | null>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        useAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        useAnchorElUser(null);
    };

    const handleLogout = () => {
        logout();
    };

    useEffect(() => {
        if (loggedOut.data) {
            localStorage.removeItem('erp3-session-expiration');
            handleCloseUserMenu();
            navigate('/login');
        }
    }, [loggedOut.data]);

    const version = () => {
        return `v${process.env.REACT_APP_MAJOR}.${process.env.REACT_APP_MINOR}.${process.env.REACT_APP_PATCH}`;
    };

    return (
        <AppBar position="static" style={{ display: location.pathname === '/login' ? 'none' : 'block' }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography variant="h6" noWrap component="div" sx={{ mr: 2, display: { xs: 'none', xl: 'flex' } }} onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
                        ERP {version()}
                    </Typography>

                    {/* Hamburger */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', xl: 'none' } }}>
                        <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit">
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', xl: 'none' },
                            }}
                        >
                            {routes.map((route: IRoute) => {
                                if (route.sub.length === 0) {
                                    return (
                                        <MenuItem key={v4()} onClick={handleCloseNavMenu}>
                                            {/* <Typography textAlign="center">{route.name}</Typography> */}
                                            <Link css={hamburgerLinkStyles} to={route.to}>
                                                {route.name}
                                            </Link>
                                        </MenuItem>
                                    );
                                } else {
                                    return <NavDropdown key={v4()} menu={route} insideDropdown handleCloseNavMenu={handleCloseNavMenu} />;
                                }
                            })}
                        </Menu>
                    </Box>

                    {/* Nav */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', xl: 'flex' }, gap: 2 }}>
                        {routes.map((route: IRoute) => {
                            if (route.sub.length === 0) {
                                return (
                                    <Button key={v4()} sx={{ my: 2, color: 'white', display: 'block' }}>
                                        <Link css={linkStyles} to={route.to}>
                                            {route.name}
                                        </Link>
                                    </Button>
                                );
                            } else {
                                return <NavDropdown key={v4()} menu={route} />;
                            }
                        })}
                    </Box>

                    {/* Profile dropdown */}
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <Link css={linkStyles} to="/profile">
                                <MenuItem onClick={handleCloseUserMenu}>
                                    <Typography textAlign="center">Profil</Typography>
                                </MenuItem>
                            </Link>
                            <MenuItem onClick={handleLogout}>
                                <Typography textAlign="center">Kijelentkezés</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

const linkStyles = css`
    color: inherit;
    text-decoration: none;
`;

const hamburgerLinkStyles = css`
    color: black;
    text-decoration: none;
`;

export default Navbar;
