/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useContext, useEffect, useState } from 'react';

import { ShippingAddressContext } from '../../../contexts/ShippingAddressContext';
import { NewOrderContext } from '../../../contexts/NewOrderContext';

import { TextField, FormGroup, FormControlLabel, Checkbox, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { SubSection } from '../../../components/SubSection';
import { countries } from '../../../utils/countryData';
import useErrorHandler from '../../../utils/userErrorHandler';
import useSelectedCustomerDetailsQuery from '../gql/selectedCustomerDetails';
import { v4 } from 'uuid';
import { NewOrderArguments } from '../NewOrderPage';

type ShippingAddressProps = {
    sendData: (data: NewOrderArguments) => void;
};

const BillingAddress: React.FC<ShippingAddressProps> = (props) => {
    const [getExistingAddresses, existingAddressesResp] = useSelectedCustomerDetailsQuery();

    useErrorHandler(existingAddressesResp.error);

    const shippingContext = useContext(ShippingAddressContext);
    const customerContext = useContext(NewOrderContext);

    const [name, setName] = useState<string>('');
    const [tax_number, setTax_number] = useState<string>('');
    const [country, setCountry] = useState<string>('Magyarország');
    const [zip_code, setZip_code] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [isCompany, setIsCompany] = useState<boolean>(false);
    const [matchingAddresses, setMatchingAddresses] = useState<boolean>(false);
    const [prevAddress, setPrevAddress] = useState<string>('');

    useEffect(() => {
        props.sendData({
            type: 'addresses',
            billingDatas: {
                billing_country: country,
                billing_zip: zip_code,
                billing_city: city,
                billing_address: address,
                billing_name: name,
                taxNumber: isCompany ? tax_number : undefined,
            },
        });
    }, [name, tax_number, country, zip_code, city, address]);

    useEffect(() => {
        if (prevAddress) {
            try {
                const obj = JSON.parse(prevAddress);
                delete obj.__typename;

                if (obj.billing_address) setAddress(obj.billing_address);
                if (obj.billing_city) setCity(obj.billing_city);
                if (obj.billing_country) setCountry(obj.billing_country);
                if (obj.billing_zip) setZip_code(obj.billing_zip);
                obj.tax_number ? setIsCompany(true) : setIsCompany(false);
                if (obj.billing_name) setName(obj.billing_name);
                if (obj.tax_number) setTax_number(obj.tax_number);
            } catch (ex) {
                console.error(ex);
            }
        } else {
            setAddress('');
            setCity('');
            setCountry('Magyarország');
            setZip_code('');
            setIsCompany(false);
            setName('');
            setTax_number('');
        }
    }, [prevAddress]);

    useEffect(() => {
        if (customerContext.existingCustomerId) {
            getExistingAddresses({ customer: customerContext.existingCustomerId });
        }
    }, [customerContext.existingCustomerId]);

    useEffect(() => {
        if (!existingAddressesResp.data) {
            setPrevAddress('');
        }
    }, [existingAddressesResp.data]);

    const handleMatchingAddressesChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.checked) {
            setMatchingAddresses(event.target.checked);
            setName(shippingContext.name);
            setCountry(shippingContext.country);
            setZip_code(shippingContext.zip_code);
            setCity(shippingContext.city);
            setAddress(shippingContext.address);
        } else {
            setMatchingAddresses(event.target.checked);
            setName(name === shippingContext.name ? '' : name);
            setCountry(country === shippingContext.country ? '' : country);
            setZip_code(zip_code === shippingContext.zip_code ? '' : zip_code);
            setCity(city === shippingContext.city ? '' : city);
            setAddress(address === shippingContext.address ? '' : address);
        }
    };

    const handleIsCompanyChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setIsCompany(event.target.checked);
    };

    return (
        <SubSection title="Számlázási adatok" css={subSectionStyles}>
            <div css={inputsContainerStyles}>
                <div css={checkboxesContainerStyles}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={matchingAddresses} onChange={handleMatchingAddressesChange} />} label="Megegyezik a szállítási adatokkal" />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={isCompany} onChange={handleIsCompanyChange} />} label="Céges számla" />
                    </FormGroup>
                </div>
                {customerContext.existingCustomerId !== null && (
                    <FormControl fullWidth>
                        <InputLabel id="prevAddress">Előző számlázási adatok</InputLabel>
                        <Select
                            labelId="prevAddress"
                            id="prevAddress"
                            value={existingAddressesResp.data?.map((data) => JSON.stringify(data)).includes(prevAddress) ? prevAddress : ''}
                            label="Előző számlázási adatok"
                            onChange={(e) => setPrevAddress(e.target.value)}
                        >
                            <MenuItem key={v4()} value={''}>
                                Nincs
                            </MenuItem>
                            {existingAddressesResp.data?.map((data) => (
                                <MenuItem key={data.id} value={JSON.stringify(data)}>
                                    {`${data.billing_name} -${data.billing_zip} ${data.billing_city}, ${data.billing_address} - ${data.phone}`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}

                <TextField id="name" label={isCompany ? 'Cégnév *' : 'Név *'} value={name} onChange={(e) => setName(e.target.value)} />
                {isCompany && <TextField id="tax_number" label="Adószám *" value={tax_number} onChange={(e) => setTax_number(e.target.value)} />}
                <FormControl fullWidth>
                    <InputLabel id="country">Ország</InputLabel>
                    <Select labelId="country" id="country" value={country} label="Ország" onChange={(e) => setCountry(e.target.value)}>
                        {countries.map((country) => (
                            <MenuItem key={country} value={country}>
                                {country}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField id="zip_code" label="Irányítószám *" value={zip_code} onChange={(e) => setZip_code(e.target.value)} />
                <TextField id="city" label="Város *" value={city} onChange={(e) => setCity(e.target.value)} />
                <TextField id="address" label="Cím *" value={address} onChange={(e) => setAddress(e.target.value)} />
            </div>
        </SubSection>
    );
};

const subSectionStyles = css`
    width: 100%;
`;

const inputsContainerStyles = css`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const checkboxesContainerStyles = css`
    display: flex;

    @media (max-width: 1100px) {
        flex-direction: column;
    }
`;

export default BillingAddress;
