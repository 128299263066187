import React, { useState } from 'react';
import { IconButton, Popover, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import DataGridLocalization from '../../../utils/DataGridLocalization';
import { Section } from '../../../components/Section';
import { ID } from '../../../components/ID';
import useOrderHistory from './gql/orderHistory';
import useErrorHandler from '../../../utils/userErrorHandler';

type OrderHistoryProps = {
    id: number;
};

type Product = {
    orderID: number;
    product: {
        id: number;
        name: string;
    };
    price: {
        gross: number;
    };
    installments: {
        due_date: string;
        payment_date: string;
        converted_to: {
            id: number;
        };
        converted_from: {
            id: number;
        };
    }[];
    created_at: string;
    seller: {
        firstname: string;
        lastname: string;
    };
};

const OrderHistory: React.FC<OrderHistoryProps> = ({ id }: OrderHistoryProps) => {
    const orderHistory = useOrderHistory({ customer: id });
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    useErrorHandler(orderHistory.error);
    const isNotePopoverOpen = Boolean(anchorEl);

    const getProductsList = () => {
        const list: Product[] = [];

        if (orderHistory.data) {
            orderHistory.data.map((order) => {
                order.ordered_products.map((product) => list.push({ ...product, seller: order.seller, orderID: order.id }));
            });
        }

        return list;
    };

    const getDetails = (product: Product) => {
        const paid = product.installments.filter((installment) => installment.payment_date !== null);
        const pending = product.installments.filter((installment) => installment.payment_date === null && new Date(installment.due_date) > new Date());
        const late = product.installments.filter((installment) => installment.payment_date === null && new Date(installment.due_date) <= new Date());
        const convertedTo = product.installments.filter((installment) => installment.converted_to !== null);
        const convertedFrom = product.installments.filter((installment) => installment.converted_from !== null);

        return { paid: paid.length, pending: pending.length, late: late.length, convertedTo: convertedTo.length, convertedFrom: convertedFrom.length };
    };

    const makeRows = (productList: Product[]) => {
        const rows: GridRowsProp = productList.map((product) => ({
            id: `${product.orderID}${product.product.id}`,
            orderID: product.orderID,
            product: product.product.name,
            price: `${product.price.gross} Ft`,
            seller: `${product.seller.lastname} ${product.seller.firstname}`,
            details: getDetails(product),
            date: product.created_at,
        }));

        return rows;
    };

    const makeCols = () => {
        const columns: GridColDef[] = [
            {
                field: 'orderID',
                headerName: 'ID',
                width: 100,
                renderCell: function renderID(cellValues) {
                    return <ID key={cellValues.row.orderID} id={cellValues.row.orderID.toString()} url={`/orders/${cellValues.row.orderID}`} openable />;
                },
            },
            { field: 'product', headerName: 'Termék', width: 300 },
            { field: 'price', headerName: 'Ár', width: 150 },
            { field: 'seller', headerName: 'Értékesítő', width: 150 },
            {
                field: 'details',
                headerName: 'Részletek',
                width: 170,
                renderCell: function renderActions(cellValues) {
                    return (
                        <Typography fontWeight={700}>
                            <span style={{ color: '#388e3c' }}>{cellValues.row.details.paid} - </span>
                            <span style={{ color: '#9e9e9e' }}>{cellValues.row.details.pending} - </span>
                            <span style={{ color: '#d32f2f' }}>{cellValues.row.details.late} - </span>
                            <span style={{ color: '#f57c00' }}>{cellValues.row.details.convertedTo} - </span>
                            <span style={{ color: '#0288d1' }}>{cellValues.row.details.convertedFrom}</span>
                        </Typography>
                    );
                },
                // eslint-disable-next-line react/display-name
                renderHeader: () => (
                    <div style={{ height: 58, display: 'flex', alignItems: 'center', marginTop: -2 }}>
                        <Typography fontWeight={500} style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: '0.875rem' }}>
                            Részletek
                        </Typography>
                        <IconButton
                            aria-describedby="popover"
                            onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                                event.stopPropagation();
                            }}
                            style={{ padding: 0, marginLeft: 5 }}
                        >
                            <InfoIcon />
                        </IconButton>
                    </div>
                ),
            },
            { field: 'date', headerName: 'Létrehozva', width: 200 },
        ];

        return columns;
    };

    return (
        <Section title="Rendelés előzmények">
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                    componentsProps={{
                        pagination: {
                            labelRowsPerPage: 'Oldalanként',
                            labelDisplayedRows: ({ from, to, count }: { from: number; to: number; count: number }) => `${from}–${to} / ${count !== -1 ? count : `more than ${to}`}`,
                        },
                    }}
                    localeText={DataGridLocalization}
                    rows={makeRows(getProductsList())}
                    columns={makeCols()}
                    style={{ borderRadius: 0, border: 'none' }}
                />
            </div>

            <Popover
                id="popover"
                open={isNotePopoverOpen}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                style={{ maxWidth: 1200 }}
            >
                <Typography sx={{ p: 2, pb: 0 }}>Az ebben az oszlopban látható öt szám azt jelöli, hogy melyik fizetési állapotban hány részlet van. Ezek jelentései sorrendben, színekkel jelölve a következő:</Typography>
                <Typography sx={{ p: 2, pb: 0 }}>
                    <span style={{ fontWeight: 700, color: '#388e3c' }}>zöld:</span> fizetve
                </Typography>
                <Typography sx={{ p: 2, pb: 0, pt: 0 }}>
                    <span style={{ fontWeight: 700, color: '#9e9e9e' }}>szürke:</span> még nem esedékes
                </Typography>
                <Typography sx={{ p: 2, pb: 0, pt: 0 }}>
                    <span style={{ fontWeight: 700, color: '#d32f2f' }}>piros:</span> késett
                </Typography>
                <Typography sx={{ p: 2, pb: 0, pt: 0 }}>
                    <span style={{ fontWeight: 700, color: '#f57c00' }}>sárga:</span> másik rendelésbe be lett számítva
                </Typography>
                <Typography sx={{ p: 2, pb: 0, pt: 0 }}>
                    <span style={{ fontWeight: 700, color: '#0288d1' }}>kék:</span> másik rendelésből be lett számítva ide
                </Typography>
                <Typography sx={{ p: 2 }}>Bővebb részletekért nyisd meg a rendelés adatlapját.</Typography>
            </Popover>
        </Section>
    );
};

export default OrderHistory;
