import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const updateAcTagMutation = gql`
    mutation updateAcTag($id: Int!, $data: AcTagInputUpdate!) {
        updateAcTag(id: $id, data: $data) {
            id
        }
    }
`;

interface AcTag {
    id: number;
}

const useUpdateAcTagMutation = mutationFactory<AcTag>(updateAcTagMutation, 'updateAcTag');

export default useUpdateAcTagMutation;
