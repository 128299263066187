import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const addCategoryToProductMutation = gql`
    mutation addCategoryToProduct($product: Int!, $category: Int!) {
        addCategoryToProduct(product: $product, category: $category) {
            id
        }
    }
`;

interface Category {
    id: number;
}

const useAddCategoryToProductMutation = mutationFactory<Category>(addCategoryToProductMutation, 'addCategoryToProduct');

export default useAddCategoryToProductMutation;
