import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const createAcTagMutation = gql`
    mutation createAcTag($data: AcTagInputCreate!) {
        createAcTag(data: $data) {
            id
            name
            description
        }
    }
`;

interface NewTag {
    id: number;
    name: string;
    description: string;
}

const useCreateAcTagMutation = mutationFactory<NewTag>(createAcTagMutation, 'createAcTag');

export type { NewTag };
export default useCreateAcTagMutation;
