import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const removeEmailAccountPermissionFromUser = gql`
    mutation removeEmailAccountPermissionFromUser($account: Int!, $user: Int!) {
        removeEmailAccountPermissionFromUser(account: $account, user: $user) {
            id
        }
    }
`;

interface EmailAccount {
    id: number;
}

const useRemoveEmailAccountPermissionFromUser = mutationFactory<EmailAccount>(removeEmailAccountPermissionFromUser, 'removeEmailAccountPermissionFromUser');

export default useRemoveEmailAccountPermissionFromUser;
