/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Section } from '../../components/Section';
import { Paper, TextField, Button, FormControl, Select, MenuItem, InputLabel, Autocomplete } from '@mui/material';
import useCreateEmailAccount from './gql/createEmailAccount';
import useUsers, { User } from '../EmailAccountEditPage/gql/users';
import useErrorHandler from '../../utils/userErrorHandler';
import { useSnackbar } from 'notistack';

const EmailAccountCreatePage: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const users = useUsers();
    const [createEmailAccount, createdEmailAccount] = useCreateEmailAccount();
    const [host, setHost] = useState('');
    const [port, setPort] = useState(0);
    const [username, setUsername] = useState('');
    const [displayedName, setDisplayedName] = useState('');
    const [password, setPassword] = useState('');
    const [secure, setSecure] = useState(1);
    const [options, setOptions] = useState<User[]>([]);
    const [selected, setSelected] = useState<User[]>([]);
    useErrorHandler(users.error, createdEmailAccount.error);

    useEffect(() => {
        if (createdEmailAccount.data) {
            navigate('/emails/accounts');
        }
    }, [createdEmailAccount]);

    useEffect(() => {
        if (users.data) {
            setOptions(users.data);
        }
    }, [users.data]);

    const handleCreate = () => {
        if (isValid()) {
            createEmailAccount({ usersWithPermission: selected.map((elem) => elem.id), data: { host, port, username, displayed_name: displayedName, password, secure: secure === 1 ? true : false } });
        } else {
            enqueueSnackbar('A csillaggal jelölt mezők kitöltése kötelező!', { variant: 'error' });
        }
    };

    const isValid = () => {
        return username !== '' && displayedName !== '' && password !== '' && host !== '' && port !== 0;
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`E-mail fiók készítése`}>
                <div css={sectionStyles}>
                    <TextField label="Felhasználónév*" variant="outlined" value={username} onChange={(e) => setUsername(e.target.value)} />
                    <TextField label="Név*" variant="outlined" value={displayedName} onChange={(e) => setDisplayedName(e.target.value)} />
                    <TextField label="Jelszó*" variant="outlined" value={password} onChange={(e) => setPassword(e.target.value)} />
                    <TextField label="Host*" variant="outlined" value={host} onChange={(e) => setHost(e.target.value)} />
                    <TextField label="Port*" type="number" InputLabelProps={{ shrink: true }} value={port} onChange={(e) => setPort(Number(e.target.value))} />
                    <FormControl fullWidth>
                        <InputLabel id="secure-select">TLS kapcsolat</InputLabel>
                        <Select label="TLS kapcsolat" id="secure-select" value={secure} onChange={(e) => setSecure(Number(e.target.value))}>
                            <MenuItem value={1}>Igen</MenuItem>
                            <MenuItem value={2}>Nem</MenuItem>
                        </Select>
                    </FormControl>
                    <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={options}
                        getOptionLabel={(option) => `${option.id} - ${option.username}`}
                        filterSelectedOptions
                        onChange={(_event, newValue) => setSelected(newValue)}
                        renderInput={(params) => <TextField {...params} label="Jogosultságok" placeholder="Jogosultságok" />}
                    />
                    <div style={{ marginLeft: 'auto' }}>
                        <Button variant="contained" color="success" size="large" onClick={handleCreate} style={{ marginLeft: 'auto' }}>
                            Létrehozás
                        </Button>
                    </div>
                </div>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;

    & > * + * {
        margin-top: 20px !important;
    }
`;

export default EmailAccountCreatePage;
