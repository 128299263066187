/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useContext, useEffect, useId, useState } from 'react';

import { ShippingAddressContext } from '../../../contexts/ShippingAddressContext';
import { NewOrderContext } from '../../../contexts/NewOrderContext';

import { Checkbox, TextField, FormControlLabel, FormGroup, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import { SubSection } from '../../../components/SubSection';
import { countries } from '../../../utils/countryData';
import useCustomerAllDeliveriesQuery, { ShippingDetails } from '../../DeliveryCreatePage/gql/customerAllDeliveries';
import useErrorHandler from '../../../utils/userErrorHandler';
import { NewOrderArguments } from '../NewOrderPage';
import { v4 } from 'uuid';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import huLocale from 'date-fns/locale/hu';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

type ShippingAddressProps = {
    sendData: (data: NewOrderArguments) => void;
};

const ShippingAddress: React.FC<ShippingAddressProps> = (props) => {
    const [getExistingAddresses, existingAddressesResp] = useCustomerAllDeliveriesQuery();

    useErrorHandler(existingAddressesResp.error);

    const customerContext = useContext(NewOrderContext);
    const shippingContext = useContext(ShippingAddressContext);

    const [matchingName, setMatchingName] = useState<boolean>(false);
    const [matchingPhone, setMatchingPhone] = useState<boolean>(false);
    const [prevAddress, setPrevAddress] = useState<string>('');
    const [date, setDate] = useState<Date | null>(null);

    useEffect(() => {
        props.sendData({
            type: 'addresses',
            shippingDatas: {
                type: 1,
                date: date,
                name: shippingContext.name,
                phone: shippingContext.phone,
                country: shippingContext.country,
                city: shippingContext.city,
                zip: shippingContext.zip_code,
                address: shippingContext.address,
                comment: shippingContext.notes,
            },
        });
    }, [shippingContext.address, shippingContext.city, shippingContext.country, shippingContext.name, shippingContext.notes, shippingContext.phone, shippingContext.zip_code, date]);

    useEffect(() => {
        if (prevAddress) {
            try {
                const obj: ShippingDetails = JSON.parse(prevAddress);

                if (obj.phone) shippingContext.handleTextChange('phone', obj.phone);
                if (obj.address) shippingContext.handleTextChange('address', obj.address);
                if (obj.city) shippingContext.handleTextChange('city', obj.city);
                if (obj.country) shippingContext.handleTextChange('country', obj.country);
                if (obj.zip) shippingContext.handleTextChange('zip_code', obj.zip);
                if (obj.name) shippingContext.handleTextChange('name', obj.name);
                if (obj.comment) shippingContext.handleTextChange('notes', obj.comment);
            } catch (ex) {
                console.error(ex);
            }
        } else {
            shippingContext.handleTextChange('phone', '');
            shippingContext.handleTextChange('address', '');
            shippingContext.handleTextChange('city', '');
            shippingContext.handleTextChange('country', 'Magyarország');
            shippingContext.handleTextChange('zip_code', '');
            shippingContext.handleTextChange('name', '');
            shippingContext.handleTextChange('notes', '');
        }
    }, [prevAddress]);

    useEffect(() => {
        if (customerContext.existingCustomerId) {
            getExistingAddresses({ customer: customerContext.existingCustomerId });
        }
    }, [customerContext.existingCustomerId]);

    const handleMatchingNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.checked) {
            setMatchingName(event.target.checked);
            customerContext.name && shippingContext.handleTextChange('name', customerContext.name);
        } else {
            setMatchingName(event.target.checked);
            shippingContext.handleTextChange('name', '');
        }
    };

    const handleMatchingPhoneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.checked) {
            setMatchingPhone(event.target.checked);
            customerContext.phone && shippingContext.handleTextChange('phone', customerContext.phone);
        } else {
            setMatchingPhone(event.target.checked);
            shippingContext.handleTextChange('phone', '');
        }
    };

    return (
        <SubSection title="Szállítási adatok" css={subSectionStyles}>
            <div css={inputsContainerStyles}>
                <div css={checkboxesContainerStyles}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={matchingName} onChange={handleMatchingNameChange} />} label="Név megegyezik a vásárlói adatokkal" />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={matchingPhone} onChange={handleMatchingPhoneChange} />} label="Telefonszám megegyezik a vásárlói adatokkal" />
                    </FormGroup>
                </div>

                {customerContext.existingCustomerId !== null && (
                    <FormControl fullWidth>
                        <InputLabel id="prevAddress">Előző szállítási adatok</InputLabel>
                        <Select labelId="prevAddress" id="prevAddress" value={prevAddress} label="Előző szállítási adatok" onChange={(e) => setPrevAddress(e.target.value)}>
                            <MenuItem key={v4()} value={''}>
                                Nincs
                            </MenuItem>
                            {existingAddressesResp.data?.map((data) => (
                                <MenuItem key={data.id} value={JSON.stringify(data)}>
                                    {`${data.name} - ${data.phone}, ${data.zip} ${data.city}, ${data.address} `}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}

                <TextField id="name" label="Név" placeholder="Átvevő neve" value={shippingContext.name} onChange={(e) => shippingContext.handleTextChange('name', e.target.value)} required />
                <TextField id="phone" label="Telefonszám" placeholder="Átvevő telefonszáma" value={shippingContext.phone} onChange={(e) => shippingContext.handleTextChange('phone', e.target.value)} required />
                <FormControl fullWidth>
                    <InputLabel id="country">Ország</InputLabel>
                    <Select labelId="country" id="country" value={shippingContext.country} label="Ország" onChange={(e) => shippingContext.handleTextChange('country', e.target.value)}>
                        {countries.map((country) => (
                            <MenuItem key={useId()} value={country}>
                                {country}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField id="zip_code" label="Irányítószám" placeholder="Irányítószám" value={shippingContext.zip_code} onChange={(e) => shippingContext.handleTextChange('zip_code', e.target.value)} required />
                <TextField id="city" label="Város" placeholder="Város" value={shippingContext.city} onChange={(e) => shippingContext.handleTextChange('city', e.target.value)} required />
                <TextField id="address" label="Cím" placeholder="Cím" value={shippingContext.address} onChange={(e) => shippingContext.handleTextChange('address', e.target.value)} required />
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={huLocale}>
                    <DatePicker
                        label="Kiszállítás tervezett napja *"
                        views={['year', 'month', 'day']}
                        inputFormat="yyyy.MM.dd."
                        mask={'____.__.__.'}
                        value={date}
                        onChange={(newDate) => setDate(newDate)}
                        minDate={new Date()}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
                <TextField id="notes" label="Megjegyzés a futárnak" value={shippingContext.notes} multiline onChange={(e) => shippingContext.handleTextChange('notes', e.target.value)} />
            </div>
        </SubSection>
    );
};

const subSectionStyles = css`
    width: 100%;
`;

const inputsContainerStyles = css`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const checkboxesContainerStyles = css`
    display: flex;

    @media (max-width: 1100px) {
        flex-direction: column;
    }
`;

export default ShippingAddress;
