import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const priceQuery = gql`
    query price($id: Int!) {
        price(id: $id) {
            id
            net
            gross
            type {
                id
                name
                description
            }
            campaign {
                id
                name
            }
            fallback {
                id
                gross
                net
                type {
                    id
                    name
                    description
                }
            }
        }
    }
`;

interface PriceType {
    id: number;
    name: string;
    description: string;
}

interface Campaign {
    id: number;
    name: string;
}

interface FallbackPrice {
    id: number;
    net: number;
    gross: number;
    type: PriceType;
    campaign: Campaign;
}

interface ProductPrice {
    id: number;
    net: number;
    gross: number;
    type: PriceType;
    campaign?: Campaign;
    fallback?: FallbackPrice;
}

const usePriceQuery = queryFactory<ProductPrice>(priceQuery, 'price');

export type { ProductPrice };
export default usePriceQuery;
