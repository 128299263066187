/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import useErrorHandler from '../../../utils/userErrorHandler';

import { Alert, Button, Chip } from '@mui/material';
import { SubSection } from '../../../components/SubSection';
import InstallmentRow from '../InstallmentRow/InstallmentRow';
import { UpdatedOrderedProductInstallments } from '../InstallmentsEditPage';

import { OrderedProduct } from '../gql/orderedProduct';
import usePaymentMethodsQuery, { PaymentMethod } from '../gql/paymentMethods';

interface CustomPaymentInstallment {
    id?: number;
    gross: number;
    due_date: string;
    payment_date?: string | null;
    converted_to: { id: number } | null;
    converted_from: { id: number } | null;
    payment_method: { id: number; name: string };
    custom: boolean;
}

interface InstallmentsSectionProps {
    data: OrderedProduct;
    modifyData: React.Dispatch<React.SetStateAction<UpdatedOrderedProductInstallments>>;
}

const InstallmentsSection: React.FC<InstallmentsSectionProps> = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const paymentMethodsResp = usePaymentMethodsQuery();

    const newIds: number[] = [];
    const installmentsInitial = () => {
        return props.data.installments.map((installment) => {
            newIds.push(installment.id);
            return { ...installment, custom: false };
        });
    };

    const [paymentMethods, setpaymentMethods] = useState<PaymentMethod[]>([]);
    const [installments, setInstallments] = useState<CustomPaymentInstallment[]>(installmentsInitial());

    useErrorHandler(paymentMethodsResp.error);

    useEffect(() => {
        paymentMethodsResp.data && setpaymentMethods(paymentMethodsResp.data);
    }, [paymentMethodsResp.data]);

    const handleNewRow = () => {
        let newId = 0;
        const todayConverted = new Date().toISOString().split('T')[0];

        while (!newIds.includes(newId)) {
            newId = Math.floor(Math.random() * 10000);
            if (!newIds.includes(newId)) {
                newIds.push(newId);
            }
        }

        todayConverted && setInstallments([...installments, { id: newId, gross: 0, payment_method: paymentMethods[0], payment_date: null, due_date: todayConverted, converted_to: null, converted_from: null, custom: true }]);
    };

    const handleDeleteRow = (id: number) => {
        setInstallments(
            installments.filter((installment) => {
                return installment.id !== id;
            }),
        );
    };

    const handleRowsSave = () => {
        const installmentsDataToSend = installments.map((installment) => {
            const temp = {
                id: installment.id,
                gross: installment.gross,
                payment_method: installment.payment_method.id,
                due_date: installment.due_date,
                converted_to: installment.converted_to ? installment.converted_to.id : null,
                payment_date: installment.payment_date ? installment.payment_date : null,
            };
            if (installment.custom) {
                delete temp.id;
                return temp;
            } else {
                return temp;
            }
        });
        props.modifyData((prevState) => {
            return { ...prevState, installments: installmentsDataToSend };
        });
        enqueueSnackbar('Részletek módosítva.', { variant: 'success' });
    };

    return (
        <SubSection title={`Termék fizetési részleteinek szerkesztése`}>
            <Alert variant="outlined" severity="info" sx={{ marginBottom: '20px' }}>
                Ha terméken nincs egy fizetési részlet se, vagy csak átszámított fizetési részleteket tartalmaz, akkor a termék lemondásra kerül!
            </Alert>
            <div css={rowsContainerStyles}>
                {paymentMethods.length > 0 &&
                    installments.map((installment) => <InstallmentRow key={installment.id} paymentTypes={paymentMethods} installment={installment} modifyInstallments={setInstallments} deleteRow={handleDeleteRow} />)}

                <Chip label="+" color="success" onClick={handleNewRow} />

                <Button variant="contained" color="primary" css={buttonContainerStyles} onClick={handleRowsSave}>
                    Módosít
                </Button>
            </div>
        </SubSection>
    );
};

const rowsContainerStyles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    @media (max-width: 1100px) {
        flex-direction: row;
        flex-wrap: wrap;
    }
`;

const buttonContainerStyles = css`
    margin-left: auto;
    margin-top: 20px;
    margin-bottom: 20px;
`;

export type { CustomPaymentInstallment };
export default InstallmentsSection;
