/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useId, useState } from 'react';
import { Paper, FormControl, InputLabel, Select, MenuItem, Button, TextField, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { v4 } from 'uuid';
import { Section } from '../../components/Section';
import useErrorHandler from '../../utils/userErrorHandler';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import huLocale from 'date-fns/locale/hu';
import { countries } from '../../utils/countryData';
import useCustomerPendingDeliveriesQuery, { PendingShippingDetail } from './gql/customerPendingDeliveries';
import useCustomerAllDeliveriesQuery, { ShippingDetails } from './gql/customerAllDeliveries';
import useCreateDeliveryMutation from './gql/createDelivery';
import useJoinDeliveryToOrderedProductMutation from './gql/joinDeliveryToOrderedProduct';

const DeliveryCreatePage: React.FC = () => {
    const params = useParams();
    const customerId = params.customerId as string;
    const orderedProductId = params.orderedProductId as string;
    const orderId = params.orderId as string;
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [pendingDeliveries, pendingDeliveriesResp] = useCustomerPendingDeliveriesQuery();
    const [allDeliveries, allDeliveriesResp] = useCustomerAllDeliveriesQuery();
    const [createDelivery, createDeliveryResp] = useCreateDeliveryMutation();
    const [joinDelivery, joinDeliveryResp] = useJoinDeliveryToOrderedProductMutation();

    useErrorHandler(pendingDeliveriesResp.error, allDeliveriesResp.error, createDeliveryResp.error, joinDeliveryResp.error);

    const [isNewDelivery, setIsNewDelivery] = useState<boolean>(true);
    const [pendingDetails, setPendingDetails] = useState<string>('');
    const [allDetails, setAllDetails] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [country, setCountry] = useState<string>('Magyarország');
    const [zip_code, setZip_code] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [note, setNote] = useState<string>('');
    const [date, setDate] = useState<Date | null>(new Date());
    const [selectedDelivery, setSelectedDelivery] = useState<number | null>(null);

    useEffect(() => {
        if (createDeliveryResp.data || joinDeliveryResp.data) navigate(`/orders/${orderId}`);
    }, [createDeliveryResp.data, joinDeliveryResp.data]);

    useEffect(() => {
        if (isNewDelivery === false) pendingDeliveries({ customer: parseInt(customerId) });
        if (isNewDelivery === true) allDeliveries({ customer: parseInt(customerId) });
    }, [isNewDelivery]);

    useEffect(() => {
        if (allDetails) {
            try {
                const obj: ShippingDetails = JSON.parse(allDetails);

                if (obj.address) setAddress(obj.address);
                if (obj.city) setCity(obj.city);
                if (obj.country) setCountry(obj.country);
                if (obj.zip) setZip_code(obj.zip);
                if (obj.name) setName(obj.name);
                if (obj.phone) setPhone(obj.phone);
                if (obj.comment) setNote(obj.comment);
            } catch (ex) {
                console.error(ex);
            }
        } else {
            setAddress('');
            setCity('');
            setCountry('Magyarország');
            setZip_code('');
            setName('');
            setPhone('');
            setNote('');
        }
    }, [allDetails]);

    useEffect(() => {
        if (pendingDetails) {
            try {
                const obj: PendingShippingDetail = JSON.parse(pendingDetails);
                if (obj.id) setSelectedDelivery(obj.id);
            } catch (ex) {
                console.error(ex);
            }
        } else {
            setSelectedDelivery(null);
        }
    }, [pendingDetails]);

    const handleInputTypeChange = (_event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
        if (newAlignment === 'new') {
            setIsNewDelivery(true);
        }
        if (newAlignment === 'existing') {
            setIsNewDelivery(false);
        }
    };

    const handleSave = () => {
        if (isNewDelivery === true) {
            if (name && phone && country && zip_code && city && address && date) {
                createDelivery({
                    data: {
                        method: 1,
                        date: date,
                        name: name,
                        phone: phone,
                        country: country,
                        zip: zip_code,
                        city: city,
                        address: address,
                        comment: note,
                    },
                    orderedProducts: [parseInt(orderedProductId)],
                });
            } else {
                enqueueSnackbar('A csillaggal jelölt mezők kitöltése kötelező!', { variant: 'error' });
            }
        } else if (isNewDelivery === false) {
            if (selectedDelivery) {
                joinDelivery({ orderedProduct: parseInt(orderedProductId), delivery: selectedDelivery });
            } else {
                enqueueSnackbar('A csillaggal jelölt mezők kitöltése kötelező!', { variant: 'error' });
            }
        }
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`#${orderedProductId} termék szállítása`}>
                <div css={sectionStyles}>
                    <div css={inputsContainerStyles}>
                        <ToggleButtonGroup color="primary" value={isNewDelivery ? 'new' : 'existing'} exclusive onChange={handleInputTypeChange} size="small">
                            <ToggleButton value="new">Új szállítás</ToggleButton>
                            <ToggleButton value="existing">Aktuális szállításhoz csatolás</ToggleButton>
                        </ToggleButtonGroup>

                        {pendingDeliveriesResp.data && isNewDelivery === false && (
                            <FormControl fullWidth>
                                <InputLabel id="pendingDetails">Aktuális szállítási adatok *</InputLabel>
                                <Select
                                    labelId="pendingDetails"
                                    id="pendingDetails"
                                    value={pendingDeliveriesResp.data?.map((data) => JSON.stringify(data)).includes(pendingDetails) ? pendingDetails : ''}
                                    label="Aktuális szállítási adatok *"
                                    onChange={(e) => setPendingDetails(e.target.value)}
                                >
                                    <MenuItem key={v4()} value={''}>
                                        Nincs
                                    </MenuItem>
                                    {pendingDeliveriesResp.data?.map((data) => (
                                        <MenuItem key={data.id} value={JSON.stringify(data)}>
                                            {`#${data.id}: ${data.name} - ${data.phone}, ${data.zip}  ${data.city} ${data.address}`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}

                        {allDeliveriesResp.data && isNewDelivery === true && (
                            <FormControl fullWidth>
                                <InputLabel id="allDetails">Előző szállítási adatok</InputLabel>
                                <Select
                                    labelId="allDetails"
                                    id="allDetails"
                                    value={allDeliveriesResp.data?.map((data) => JSON.stringify(data)).includes(allDetails) ? allDetails : ''}
                                    label="Előző szállítási adatok"
                                    onChange={(e) => setAllDetails(e.target.value)}
                                >
                                    <MenuItem key={v4()} value={''}>
                                        Nincs
                                    </MenuItem>
                                    {allDeliveriesResp.data?.map((data) => (
                                        <MenuItem key={data.id} value={JSON.stringify(data)}>
                                            {`${data.name} - ${data.phone}, ${data.zip}  ${data.city} ${data.address}`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}

                        {isNewDelivery && (
                            <>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={huLocale}>
                                    <DatePicker
                                        label="Szállítás tervezett napja"
                                        views={['year', 'month', 'day']}
                                        inputFormat="yyyy.MM.dd."
                                        mask={'____.__.__.'}
                                        value={date}
                                        onChange={(newDate) => setDate(newDate)}
                                        renderInput={(params) => <TextField {...params} css={inputsStyles} required />}
                                    />
                                </LocalizationProvider>
                                <TextField id="name" label="Név" placeholder="Átvevő neve" value={name} onChange={(e) => setName(e.target.value)} required css={inputsStyles} />
                                <TextField id="phone" label="Telefonszám" placeholder="Átvevő telefonszáma" value={phone} onChange={(e) => setPhone(e.target.value)} required css={inputsStyles} />
                                <FormControl fullWidth>
                                    <InputLabel id="country">Ország</InputLabel>
                                    <Select labelId="country" id="country" value={country} label="Ország" onChange={(e) => setCountry(e.target.value)} required>
                                        {countries.map((country) => (
                                            <MenuItem key={v4()} value={country}>
                                                {country}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField id="zip_code" label="Irányítószám" placeholder="Irányítószám" value={zip_code} onChange={(e) => setZip_code(e.target.value)} required css={inputsStyles} />
                                <TextField id="city" label="Város" placeholder="Város" value={city} onChange={(e) => setCity(e.target.value)} required css={inputsStyles} />
                                <TextField id="address" label="Cím" placeholder="Cím" value={address} onChange={(e) => setAddress(e.target.value)} required css={inputsStyles} />
                                <TextField id="notes" label="Megjegyzés a futárnak" value={note} multiline onChange={(e) => setNote(e.target.value)} css={inputsStyles} />
                            </>
                        )}
                    </div>
                    <div css={buttonInputsContainerStyles}>
                        <Button size="large" variant="contained" color="success" onClick={handleSave}>
                            {isNewDelivery ? `Létrehozás` : 'Csatol'}
                        </Button>
                    </div>
                </div>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    position: relative;
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;
    & > * + * {
        margin-top: 20px !important;
    }
`;

const inputsContainerStyles = css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    & > * + * {
        margin-top: 20px !important;
    }
`;

const inputsStyles = css`
    width: 100%;
`;

const buttonInputsContainerStyles = css`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    @media (max-width: 1200px) {
        justify-content: center;
        align-items: center;
    }
`;

const chipSelectStyles = css`
    width: 100%;
`;
const checkboxStyles = css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
`;

const boxStyles = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 2px solid #0288d1;
    boxshadow: 24;
    padding: 1rem;
`;

const boxButtonStyles = css`
    float: right;
    margin-top: 1rem;
`;

export default DeliveryCreatePage;
