/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import useProductCategories from '../../CategoriesPage/gql/productCategories';
import useAcTagsQuery, { Tag } from '../../ProductCreatePage/gql/acTags';
import useErrorHandler from '../../../utils/userErrorHandler';
import ProductCategories from './ProductCategories/ProductCategories';
import ProductTags from './ProductTags/ProductTags';

type ProductClassificationProps = {
    id: string;
    selectedCategories: number[] | [];
    selectedTags: Tag[] | [];
};

const ProductClassification: React.FC<ProductClassificationProps> = (props) => {
    const categoriesResp = useProductCategories();
    const tagsResp = useAcTagsQuery();
    useErrorHandler(categoriesResp.error, tagsResp.error);

    return (
        <div css={inputsContainerStyles}>
            {categoriesResp.data && <ProductCategories id={props.id} selectedCategories={props.selectedCategories} categories={categoriesResp.data} />}
            {tagsResp.data && <ProductTags id={props.id} selectedTags={props.selectedTags} tags={tagsResp.data} />}
        </div>
    );
};

const inputsContainerStyles = css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    & > * + * {
        margin-top: 20px !important;
    }
`;

export default ProductClassification;
