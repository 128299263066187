/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, FormControl, InputLabel, Select, MenuItem, Button, Typography } from '@mui/material';
import { Section } from '../../components/Section';
import { v4 } from 'uuid';
import useErrorHandler from '../../utils/userErrorHandler';
import useWorkingDayModsQuery from './gql/workingDayMods';
import useDeleteWorkingDayModMutation from './gql/deleteWorkingDayMod';

const AlteredWorkSchedulePage: React.FC = () => {
    const [workingDays, workingDaysResp] = useWorkingDayModsQuery();
    const [deleteWorkingDay, deletedWorkingDay] = useDeleteWorkingDayModMutation();
    useErrorHandler(workingDaysResp.error, deletedWorkingDay.error);

    const [year, setYear] = useState(`${new Date().getFullYear()}`);

    useEffect(() => {
        workingDays({ year: parseInt(year) });
    }, [year]);

    const getDayName = (date: string): string => {
        switch (new Date(date).getDay()) {
            case 0:
                return 'Vasárnap';
            case 1:
                return 'Hétfő';
            case 2:
                return 'Kedd';
            case 3:
                return 'Szerda';
            case 4:
                return 'Csütörtök';
            case 5:
                return 'Péntek';
            case 6:
                return 'Szombat';
            default:
                return '-';
        }
    };

    const getSelectOptions = (): string[] => {
        const years = [];
        for (let i = 0; i < new Date().getFullYear() - 2022 + 10; i++) {
            years.push((2022 + i).toString());
        }
        return years;
    };

    const handleDelete = async (id: number) => {
        await deleteWorkingDay({ id });
        workingDaysResp.refetch();
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`Módosított munkarend`}>
                <div css={sectionStyles}>
                    <FormControl sx={{ minWidth: 200, width: '100%' }}>
                        <InputLabel id="sender">Szűrés évre</InputLabel>
                        <Select labelId="sender" id="sender-select" label="Szűrés évre" value={year} onChange={(e) => setYear(e.target.value)}>
                            {getSelectOptions().map((year: string) => {
                                return (
                                    <MenuItem key={year} value={year}>
                                        {year}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    {workingDaysResp.data?.length !== undefined && workingDaysResp.data?.length !== 0 ? (
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Dátum</TableCell>
                                        <TableCell align="center">Nap</TableCell>
                                        <TableCell align="center">Típus</TableCell>
                                        <TableCell align="center">Műveletek</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {workingDaysResp.data?.map((row) => (
                                        <TableRow key={v4()} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="center">{row.date} </TableCell>
                                            <TableCell align="center">{getDayName(row.date)}</TableCell>
                                            <TableCell align="center" css={row.type.name === 'áthelyezett munkanap' ? { color: 'red' } : { color: 'green' }}>
                                                {row.type.name}
                                            </TableCell>
                                            <TableCell align="center">
                                                <Button variant="contained" color="error" onClick={() => handleDelete(row.id)}>
                                                    Törlés
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : workingDaysResp.data?.length === 0 ? (
                        <Typography variant="body1" color="primary" align="center">
                            Nincs megjeleníthető adat a megadott feltételek alapján!
                        </Typography>
                    ) : null}
                </div>
                <Link to="/working-hours/altered-work-schedule/create" css={buttonContainerStyle}>
                    <Button variant="contained" color="success">
                        Új nap hozzáadása
                    </Button>
                </Link>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    & > * + * {
        margin-top: 20px !important;
    }
`;

const buttonContainerStyle = css`
    text-decoration: none;
    margin-left: auto;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
`;

export default AlteredWorkSchedulePage;
