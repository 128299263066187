import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const deleteProductMutation = gql`
    mutation deleteProduct($id: Int!) {
        deleteProduct(id: $id)
    }
`;

const useDeleteProductMutation = mutationFactory(deleteProductMutation, 'deleteProduct');

export default useDeleteProductMutation;
