import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const currenciesQuery = gql`
    query currencies {
        currencies {
            id
            name
        }
    }
`;

interface Currency {
    id: number;
    name: string;
}

const useCurrenciesQuery = queryFactory<Currency[]>(currenciesQuery, 'currencies');

export type { Currency };
export default useCurrenciesQuery;
