import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const addEmailTemplateToEvent = gql`
    mutation addEmailTemplateToEvent($template: Int!, $event: Int!) {
        addEmailTemplateToEvent(template: $template, event: $event) {
            id
        }
    }
`;

interface EmailAccount {
    id: number;
}

const useAddEmailTemplateToEvent = mutationFactory<EmailAccount>(addEmailTemplateToEvent, 'addEmailTemplateToEvent');

export default useAddEmailTemplateToEvent;
