import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const updateInstallmentMutation = gql`
    mutation updateInstallment($id: Int!, $data: InstallmentInputUpdate!) {
        updateInstallment(id: $id, data: $data) {
            payment_date
        }
    }
`;

interface UpdatedInstallment {
    payment_date: string;
}

const useUpdateInstallmentMutation = mutationFactory<UpdatedInstallment>(updateInstallmentMutation, 'updateInstallment');

export default useUpdateInstallmentMutation;
