import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const createProductMutation = gql`
    mutation createProduct($data: ProductInputCreate!, $tags: [Int], $categories: [Int], $packageProducts: [Int]) {
        createProduct(data: $data, tags: $tags, categories: $categories, packageProducts: $packageProducts) {
            id
        }
    }
`;

interface Product {
    id: number;
}

const useCreateProductMutation = mutationFactory<Product>(createProductMutation, 'createProduct');

export default useCreateProductMutation;
