/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { Button, FormControl, InputLabel, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { SubSection } from '../../../components/SubSection';
import ExistingPriceSelection from '../ExistingPriceSelection/ExistingPriceSelection';
import { UpdatedOrderedProductInstallments } from '../InstallmentsEditPage';

import { OrderedProduct } from '../gql/orderedProduct';

type ProductPriceSectionProps = {
    data: OrderedProduct;
    modifyData: React.Dispatch<React.SetStateAction<UpdatedOrderedProductInstallments>>;
    setFullPrice: React.Dispatch<React.SetStateAction<number | null>>;
};

const ProductPriceSection: React.FC<ProductPriceSectionProps> = (props) => {
    const { enqueueSnackbar } = useSnackbar();

    const [active, setActive] = useState(false);
    const [netPrice, setNetPrice] = useState<string>(props.data.price.net.toString());
    const [grossPrice, setGrossPrice] = useState<string>(props.data.price.gross.toString());
    const [VAT, setVAT] = useState(0.27);
    const [lastUsed, setLastUsed] = useState<string>(props.data.product.package ? '' : 'gross');
    const [selProductPrice, setSelProductPrice] = useState<number | null>(props.data.price.id);

    useEffect(() => {
        if (!props.data.product.package && lastUsed === 'net') {
            netPrice ? setGrossPrice(Math.round(parseInt(netPrice) * (1 + VAT)).toString()) : setGrossPrice('');
        }
    }, [netPrice]);

    useEffect(() => {
        if (!props.data.product.package && lastUsed === 'gross') {
            grossPrice ? setNetPrice(Math.round(parseInt(grossPrice) * (1 - VAT)).toString()) : setNetPrice('');
        }
    }, [grossPrice]);

    useEffect(() => {
        if (!props.data.product.package && lastUsed === 'net') {
            netPrice ? setGrossPrice(Math.round(parseInt(netPrice) * (1 + VAT)).toString()) : setGrossPrice('');
        } else if (!props.data.product.package && lastUsed === 'gross') {
            grossPrice ? setNetPrice(Math.round(parseInt(grossPrice) * (1 - VAT)).toString()) : setNetPrice('');
        }
    }, [VAT]);

    const handlePriceChange = (id: 'net' | 'gross', e: string) => {
        let input = e;
        if (id === 'net') {
            if (e.match(/\D/)) input = input.slice(0, -1);
            if (input.length < 21) setNetPrice(input);
        } else {
            if (e.match(/\D/)) input = input.slice(0, -1);
            if (input.length < 21) setGrossPrice(input);
        }
        setLastUsed(id);
    };

    const handlePriceSave = () => {
        if (active && netPrice && grossPrice) {
            props.setFullPrice(parseInt(grossPrice));
            props.modifyData((prevState) => {
                const temp = prevState;
                delete temp.price;
                return { ...temp, newPrice: { net: parseInt(netPrice), gross: parseInt(grossPrice) } };
            });
            enqueueSnackbar('Teljes termék ár módosítva.', { variant: 'success' });
        } else if (!active && selProductPrice) {
            props.modifyData((prevState) => {
                const temp = prevState;
                delete temp.newPrice;
                return { ...temp, price: selProductPrice };
            });
            enqueueSnackbar('Teljes termék ár módosítva.', { variant: 'success' });
        }
    };

    const handleChange = (_event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
        newAlignment === 'new' && setActive(true);
        newAlignment === 'existing' && setActive(false);
    };

    const renderPriceSelector = () => {
        if (active) {
            return (
                <>
                    <TextField id="netPrice" label="Nettó ár (Ft)" placeholder="A termék nettó ára" value={netPrice} onChange={(e) => handlePriceChange('net', e.target.value)} required sx={{ width: '100%' }} />
                    {!props.data.product.package && (
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="VAT">Áfa</InputLabel>
                            <Select labelId="VAT" id="VAT-select" label="Áfa" value={VAT} onChange={(e) => setVAT(e.target.value as number)} disabled={props.data.product.package}>
                                <MenuItem value={0.05}>5%</MenuItem>
                                <MenuItem value={0.27}>27%</MenuItem>
                            </Select>
                        </FormControl>
                    )}
                    <TextField id="grossPrice" label="Bruttó ár (Ft)" placeholder="A termék bruttó ára" value={grossPrice} onChange={(e) => handlePriceChange('gross', e.target.value)} required sx={{ width: '100%' }} />
                </>
            );
        } else {
            return <ExistingPriceSelection selections={selProductPrice} setSelections={setSelProductPrice} productId={props.data.product.id} setFullPrice={props.setFullPrice} />;
        }
    };

    return (
        <SubSection title={`Termék teljes árának szerkesztése`}>
            <div css={inputsContainerStyles}>
                <ToggleButtonGroup color="primary" value={active ? 'new' : 'existing'} exclusive onChange={handleChange} size="small">
                    <ToggleButton value="existing">Már létező ár</ToggleButton>
                    <ToggleButton value="new">Új egyedi ár</ToggleButton>
                </ToggleButtonGroup>

                {renderPriceSelector()}

                <Button variant="contained" color="primary" css={buttonContainerStyles} onClick={handlePriceSave}>
                    Módosít
                </Button>
            </div>
        </SubSection>
    );
};

const inputsContainerStyles = css`
    width: 100%;
    display: flex;
    flex-direction: column;

    justify-content: center;
    gap: 10px;
`;

const buttonContainerStyles = css`
    text-decoration: none;
    margin-left: auto;
    margin-top: 20px;
    margin-bottom: 20px;
`;

export default ProductPriceSection;
