import { gql } from '@apollo/client';
import { mutationFactory } from '../../../../utils/gqlFactories';

const deleteCustomerCharacteristic = gql`
    mutation deleteCustomerCharacteristic($id: Int!) {
        deleteCustomerCharacteristic(id: $id)
    }
`;

const useDeleteCustomerCharacteristic = mutationFactory<number>(deleteCustomerCharacteristic, 'deleteCustomerCharacteristic');

export default useDeleteCustomerCharacteristic;
