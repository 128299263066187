/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { Chip, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import huLocale from 'date-fns/locale/hu';
import { CustomPaymentInstallment } from '../InstallmentsSection/InstallmentsSection';
import { useSnackbar } from 'notistack';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

interface InstallmentRowProps {
    paymentTypes: {
        id: number;
        name: string;
    }[];
    installment: CustomPaymentInstallment;
    modifyInstallments: React.Dispatch<React.SetStateAction<CustomPaymentInstallment[]>>;
    deleteRow: (id: number) => void;
}

const InstallmentRow: React.FC<InstallmentRowProps> = (props) => {
    const { enqueueSnackbar } = useSnackbar();

    const [selPaymentType, setSelPaymentType] = useState(props.installment.payment_method.id.toString());
    const [selDueDate, setSelDueDate] = useState<Date | null>(new Date(props.installment.due_date));
    const [selPaymentDate, setSelPaymentDate] = useState<Date | null>(props.installment.payment_date ? new Date(props.installment.payment_date) : null);
    const [gross, setGross] = useState<string>(props.installment.gross.toString());
    const [convertedTo, setConvertedTo] = useState<string>(props.installment.converted_to ? props.installment.converted_to.id.toString() : '');

    useEffect(() => {
        props.modifyInstallments((prevState) => {
            const newInstallments = prevState.map((obj) => {
                if (obj.id === props.installment.id) {
                    const selectedPaymentMethod = props.paymentTypes.find((x) => x.id === parseInt(selPaymentType));
                    const convertedDue_date = selDueDate?.toISOString();
                    if (selectedPaymentMethod && convertedDue_date)
                        return {
                            ...obj,
                            payment_method: selectedPaymentMethod,
                            gross: parseInt(gross),
                            due_date: convertedDue_date,
                            converted_to: convertedTo ? { id: parseInt(convertedTo) } : null,
                            payment_date: selPaymentDate ? selPaymentDate.toISOString() : null,
                        };
                }
                return obj;
            });

            return newInstallments;
        });
    }, [selPaymentType, selDueDate, selPaymentDate, gross, convertedTo]);

    const handlePriceChange = (setState: React.Dispatch<React.SetStateAction<string>>, e: string) => {
        let input = e;
        if (e.match(/\D/)) input = input.slice(0, -1);
        if (input.length < 21) setState(input);
    };

    const handleDelete = () => {
        if (props.installment.payment_date) {
            enqueueSnackbar('Kifizetett részlet nem törölhető!', { variant: 'warning' });
        } else {
            props.installment.id && props.deleteRow(props.installment.id);
        }
    };

    const renderId = () => {
        if (props.installment.custom) {
            return <Chip label={`-`} color="error" onClick={handleDelete} css={chipStyles} />;
        } else {
            return <Chip label={`#${props.installment.id}`} onClick={handleDelete} color="primary" css={chipStyles} />;
        }
    };

    return (
        <div css={rowContainerStyles}>
            <Tooltip title={props.installment.payment_date ? 'Kifizetett részlet nem törölhető!' : 'Kattints a részlet törléséhez.'} placement="top" arrow>
                {renderId()}
            </Tooltip>

            <FormControl css={inputStyles}>
                <InputLabel id="workHours">Fizetési mód</InputLabel>
                <Select labelId="workHours" id="workHours-select" label="Fizetési mód" value={selPaymentType} onChange={(e) => setSelPaymentType(e.target.value)}>
                    {props.paymentTypes.map((type) => {
                        return (
                            <MenuItem key={v4()} value={type.id}>
                                {type.name}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>

            <LocalizationProvider dateAdapter={AdapterDateFns} locale={huLocale}>
                <DatePicker
                    label="Fizetési határidő"
                    views={['year', 'month', 'day']}
                    inputFormat="yyyy.MM.dd."
                    mask={'____.__.__.'}
                    value={selDueDate}
                    onChange={(newDate) => setSelDueDate(newDate)}
                    renderInput={(params) => <TextField {...params} css={inputStyles} />}
                />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDateFns} locale={huLocale}>
                <DateTimePicker
                    label="Fizetés dátuma"
                    views={['year', 'month', 'day', 'hours', 'minutes']}
                    inputFormat="yyyy.MM.dd. HH:mm"
                    mask={'____.__.__. __:__'}
                    ampm={false}
                    value={selPaymentDate}
                    maxDate={new Date()}
                    onChange={(newDate) => setSelPaymentDate(newDate)}
                    renderInput={(params) => <TextField {...params} css={inputStyles} />}
                    css={inputStyles}
                />
            </LocalizationProvider>

            <TextField id="grossPrice" label="Bruttó (Ft)" placeholder="A termék bruttó ára" value={gross} onChange={(e) => handlePriceChange(setGross, e.target.value)} css={inputStyles} />

            <Tooltip title={selPaymentDate ? 'Csak azonos összeg számítható be!' : 'Csak kifizetett részletet lehet beszámtani!'} placement="top" arrow>
                <TextField
                    id="converted"
                    label="Beszámítás ide"
                    placeholder="Add meg az ID-t"
                    value={convertedTo}
                    onChange={(e) => handlePriceChange(setConvertedTo, e.target.value)}
                    css={inputStyles}
                    disabled={selPaymentDate ? false : true}
                />
            </Tooltip>
        </div>
    );
};

const rowContainerStyles = css`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    @media (max-width: 1100px) {
        flex-direction: column;
    }
`;

const inputStyles = css`
    width: 300px;

    @media (max-width: 1100px) {
        width: 280px;
    }
`;

const chipStyles = css`
    width: 150px;

    @media (max-width: 1100px) {
        width: 140px;
    }
`;

export default InstallmentRow;
