/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Button, TextField, Box, Autocomplete, Modal, Typography } from '@mui/material';
import { PriceType } from '../gql/priceTypes';
import useCreatePriceTypeMutation from '../gql/createPriceType';
import useErrorHandler from '../../../utils/userErrorHandler';

interface NewType {
    name: string;
    description: string;
}

type CategorySelectionProps = {
    options: PriceType[];
    selections: PriceType;
    setSelections: (inputType: PriceType) => Promise<void>;
};

const TypeSelection: React.FC<CategorySelectionProps> = (props) => {
    const [createType, newTypeResp] = useCreatePriceTypeMutation();
    useErrorHandler(newTypeResp.error);

    const [newTypeDescription, setNewTypeDescription] = useState('');
    const [tempType, setTempType] = useState<NewType>();
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        if (newTypeResp.data) props.setSelections(newTypeResp.data);
    }, [newTypeResp.data]);

    useEffect(() => {
        (async () => {
            if (tempType?.description && tempType.name) {
                await handleTypeCreate(tempType);
                if (newTypeResp.data)
                    setTempType({
                        name: '',
                        description: '',
                    });
                setNewTypeDescription('');
                setModalOpen(false);
            }
        })();
    }, [tempType]);

    const handleTypeChange = async (selectedValue: string | null) => {
        if (props.options.find((o) => o.name === selectedValue)) {
            props.setSelections(props.options.find((o) => o.name === selectedValue) as PriceType);
        } else if (selectedValue) {
            console.log(selectedValue);
            setNewTypeDescription('');
            setModalOpen(true);

            const newTypeData: NewType = {
                name: selectedValue,
                description: newTypeDescription,
            };
            setTempType(newTypeData);
        }
    };

    const handleTypeCreate = (type: NewType) => {
        return createType({ data: type });
    };

    const handleModalSave = () => {
        if (tempType) {
            setTempType({
                name: tempType?.name,
                description: newTypeDescription,
            });
        }
    };

    return (
        <>
            <Autocomplete
                id="priceType-autocomplete"
                options={props.options.map((data: PriceType) => {
                    return data.name;
                })}
                renderInput={(params) => <TextField {...params} label="Ár típus *" placeholder="Ár típus" variant="outlined" />}
                value={props.selections.name}
                freeSolo
                renderTags={(value: string[], getTypeProps) =>
                    value.map((option: string, index: number) => (
                        <Typography variant="h6" component="h2" {...getTypeProps({ index })} key={index}>
                            {option}
                        </Typography>
                    ))
                }
                onChange={(_event, newSelection) => {
                    handleTypeChange(newSelection);
                }}
                css={inputsStyles}
            />
            <Modal open={modalOpen} onClose={() => setModalOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" style={{ position: 'absolute' }}>
                <Box css={boxStyles}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ marginBottom: '1rem' }}>
                        Add meg a tag leírását:
                    </Typography>
                    <TextField fullWidth id="subject" label="Leírás" placeholder="Az új tag leírása" value={newTypeDescription} onChange={(e) => setNewTypeDescription(e.target.value)} css={inputsStyles} />
                    <Button size="large" variant="contained" css={boxButtonStyles} onClick={handleModalSave}>
                        Létrehoz
                    </Button>
                </Box>
            </Modal>
        </>
    );
};

const inputsStyles = css`
    width: 100%;
`;

const boxStyles = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 2px solid #0288d1;
    boxshadow: 24;
    padding: 1rem;
`;

const boxButtonStyles = css`
    float: right;
    margin-top: 1rem;
`;

export default TypeSelection;
