import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const customers = gql`
    query {
        customers {
            id
            email
            details {
                firstname
                lastname
                phone
                created_at
            }
        }
    }
`;

export interface Customer {
    id: number;
    email: string;
    details: {
        firstname: string;
        lastname: string;
        phone: string;
        created_at: string;
    }[];
}

const useCustomers = queryFactory<Customer[]>(customers, 'customers');

export default useCustomers;
