import React from 'react';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { ID } from '../../../components/ID';
import DataGridLocalization from '../../../utils/DataGridLocalization';
import { Order } from '../gql/orders';
import NumberFormat from 'react-number-format';

type OrderListProps = {
    orders: Order[];
};

type List = {
    orderID: number;
    seller: {
        lastname: string;
        firstname: string;
        middlename: string;
    };
    customer: {
        lastname: string;
        firstname: string;
        customer: {
            email: string;
        };
    };
    id: number;
    product: {
        name: string;
    };
    price: {
        gross: number;
    };
    deliveries: {
        date: string;
    };
    created_at: string;
};

const OrderList: React.FC<OrderListProps> = ({ orders }) => {
    const getList = () => {
        const list: List[] = [];

        orders.map((order) => {
            order.ordered_products.map((prod) => {
                list.push({ ...prod, orderID: order.id, seller: order.seller, customer: order.customer, created_at: order.created_at });
            });
        });

        return list;
    };

    const makeRows = (prodList: List[]) => {
        const rows: GridRowsProp = prodList.map((product: List) => ({
            id: `${product.orderID}-${product.id}`,
            orderID: `${product.orderID}-${product.id}`,
            created_at: product.created_at,
            deliveryDate: product.deliveries.date || '-',
            seller: `${product.seller.lastname} ${product.seller.firstname} ${product.seller.middlename ? product.seller.middlename : ''}`,
            customerName: `${product.customer.lastname} ${product.customer.firstname}`,
            email: product.customer.customer.email,
            products: product.product.name,
            price: product.price.gross,
        }));

        return rows;
    };

    const makeCols = () => {
        const columns: GridColDef[] = [
            {
                field: 'orderID',
                headerName: 'ID',
                width: 100,
                renderCell: function renderID(cellValues) {
                    return <ID key={cellValues.row.orderID} id={cellValues.row.orderID.toString()} url={`/orders/${cellValues.row.orderID.split('-')[0]}`} openable />;
                },
            },
            { field: 'created_at', headerName: 'Rendelés dátuma', width: 200 },
            { field: 'deliveryDate', headerName: 'Szállítás dátuma', width: 200 },
            { field: 'seller', headerName: 'Értékesítő', width: 150 },
            { field: 'customerName', headerName: 'Vevő neve', width: 150 },
            { field: 'email', headerName: 'Vevő e-mail címe', width: 200 },
            { field: 'products', headerName: 'Termék', width: 340 },
            {
                field: 'price',
                headerName: 'Bruttó ár',
                width: 150,
                renderCell: function renderCurrency(cellValues) {
                    return <NumberFormat value={cellValues.row.price} thousandSeparator={' '} suffix={` Ft`} displayType="text" />;
                },
            },
        ];

        return columns;
    };

    return (
        <div style={{ height: 800, width: '100%' }}>
            <DataGrid
                componentsProps={{
                    pagination: {
                        labelRowsPerPage: 'Oldalanként',
                        labelDisplayedRows: ({ from, to, count }: { from: number; to: number; count: number }) => `${from}–${to} / ${count !== -1 ? count : `more than ${to}`}`,
                    },
                }}
                localeText={DataGridLocalization}
                rows={makeRows(getList())}
                columns={makeCols()}
            />
        </div>
    );
};

export default OrderList;
