import { gql } from '@apollo/client';
import { lazyQueryFactory } from '../../../utils/gqlFactories';

const workingDayModsQuery = gql`
    query workingDayMods($year: Int) {
        workingDayMods(year: $year) {
            id
            date
            type {
                id
                name
            }
        }
    }
`;

interface WorkingDay {
    id: number;
    date: string;
    type: { id: number; name: string };
}

const useWorkingDayModsQuery = lazyQueryFactory<WorkingDay[]>(workingDayModsQuery, 'workingDayMods');

export default useWorkingDayModsQuery;
