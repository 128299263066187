import React from 'react';

import EmailAddressProps from './EmailAddressProps';

class EmailAddress extends React.Component<EmailAddressProps> {
    constructor(props: EmailAddressProps) {
        super(props);
    }

    render(): React.ReactNode {
        if (this.props.openable || this.props.openable === undefined) {
            return <a href={`mailto:${this.props.email}`}>{this.props.email}</a>;
        } else {
            return <span>{this.props.email}</span>;
        }
    }
}

export default EmailAddress;
