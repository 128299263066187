import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const lists = gql`
    query {
        lists {
            id
            name
            description
            dynamic
            frequency
            ttl
            created_at
            creator {
                firstname
                lastname
            }
        }
    }
`;

export interface List {
    id: number;
    name: string;
    description: string;
    dynamic: boolean;
    frequency: number;
    ttl: number;
    created_at: string;
    creator: {
        firstname: string;
        lastname: string;
    };
}

const useLists = queryFactory<List[]>(lists, 'lists');

export default useLists;
