import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const createPlaceholderMutation = gql`
    mutation CreatePlaceholder($data: PlaceholderInputCreate!) {
        createPlaceholder(data: $data) {
            id
            name
            description
            custom
        }
    }
`;

interface NewPlaceHolder {
    id: number;
    name: string;
    description: string;
    custom: boolean;
}

const useCreatePlaceholderMutation = mutationFactory<NewPlaceHolder>(createPlaceholderMutation, 'createPlaceholder');

export type { NewPlaceHolder };
export default useCreatePlaceholderMutation;
