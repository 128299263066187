import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const deleteWorkingHourMutation = gql`
    mutation deleteWorkingHour($id: Int!) {
        deleteWorkingHour(id: $id)
    }
`;

const useDeleteWorkingHourMutation = mutationFactory(deleteWorkingHourMutation, 'deleteWorkingHour');

export default useDeleteWorkingHourMutation;
