import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const sentEmailQuery = gql`
    query sentEmail($id: Int!) {
        sentEmail(id: $id) {
            id
            template {
                id
                sender_name
                sender_email
                content
                subject
            }
            substituted_placeholders {
                id
                name
                value
            }
            sender {
                username
            }
        }
    }
`;

interface SentMail {
    id: number;
    template: { id: number; sender_name: string; sender_email: string; content: string; subject: string };
    substituted_placeholders: { id: number; name: string; value: string }[];
    sender: { username: string };
}

const useSentEmailQuery = queryFactory<SentMail>(sentEmailQuery, 'sentEmail');

export default useSentEmailQuery;
