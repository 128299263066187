import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const createPriceTypeMutation = gql`
    mutation createPriceType($data: PriceTypeInputCreate!) {
        createPriceType(data: $data) {
            id
            name
            description
        }
    }
`;

interface NewPriceType {
    id: number;
    name: string;
    description: string;
}

const useCreatePriceTypeMutation = mutationFactory<NewPriceType>(createPriceTypeMutation, 'createPriceType');

export type { NewPriceType };
export default useCreatePriceTypeMutation;
