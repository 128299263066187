/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import useProductPricesQuery, { ProductPrice } from '../gql/productPrices';
import useErrorHandler from '../../../utils/userErrorHandler';

type ProductPriceSelectionProps = {
    selections: number | null;
    productId: number;
    setSelections: React.Dispatch<React.SetStateAction<number | null>>;
    setFullPrice: React.Dispatch<React.SetStateAction<number | null>>;
};

const ExistingPriceSelection: React.FC<ProductPriceSelectionProps> = (props) => {
    const productPricesResp = useProductPricesQuery({ product: props.productId });

    useErrorHandler(productPricesResp.error);

    const [value, setValue] = useState<ProductPrice | null>(null);

    useEffect(() => {
        productPricesResp.data && handleValue();
    }, [productPricesResp.data]);

    const handleChange = (selectedValue: ProductPrice | null): void => {
        if (selectedValue) {
            setValue(selectedValue);
            props.setSelections(selectedValue.id);
            props.setFullPrice(selectedValue.gross);
        } else {
            setValue(null);
            props.setSelections(null);
            props.setFullPrice(null);
        }
    };

    const handleValue = () => {
        productPricesResp.data ? productPricesResp.data[0] : null;
        const selected = productPricesResp.data?.find((price) => price.id === props.selections);
        if (selected) {
            setValue(selected);
            props.setFullPrice(selected.gross);
        } else {
            setValue(null);
            props.setFullPrice(null);
        }
    };

    return (
        <Autocomplete
            options={productPricesResp.data ? productPricesResp.data : []}
            value={value}
            renderInput={(params) => <TextField {...params} label="Termék ára *" placeholder="Termék ára" />}
            getOptionLabel={(option) => `${option.type.name}, ${option.gross} Ft`}
            onChange={(_event, value) => {
                handleChange(value);
            }}
            css={inputsStyles}
        />
    );
};

const inputsStyles = css`
    width: 100%;
`;

export default ExistingPriceSelection;
