import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const orderQuery = gql`
    query order($id: Int!) {
        order(id: $id) {
            id
            seller {
                lastname
                firstname
                middlename
            }
            customer {
                id
                lastname
                firstname
                phone
                customer {
                    email
                }
                billing_name
                billing_address
                tax_number
            }
            accepted
            ordered_products {
                id
                installments {
                    id
                    gross
                    due_date
                    payment_date
                    converted_to {
                        id
                    }
                    converted_from {
                        id
                    }
                    payment_method {
                        name
                    }
                    currency {
                        id
                        name
                    }
                    currency_gross
                }
                product {
                    id
                    name
                }
                price {
                    gross
                    net
                }
                deliveries {
                    installment {
                        gross
                    }
                    method {
                        name
                    }
                    id
                    date
                    state_changes {
                        created_at
                        state {
                            name
                        }
                    }
                    name
                    phone
                    country
                    zip
                    city
                    address
                    comment
                }
            }
        }
    }
`;

interface Delivery {
    id: number;
    date: string;
    comment: string;
    name: string;
    phone: string;
    country: string;
    zip: string;
    city: string;
    address: string;
    method: { name: string };
    installment: {
        gross: number;
    } | null;
    state_changes: {
        created_at: string;
        state: {
            name: string;
        };
    }[];
}

interface PaymentInstallment {
    id: number;
    gross: number;
    due_date: string;
    payment_date: string;
    converted_to: { id: number } | null;
    converted_from: { id: number } | null;
    payment_method: { name: string };
    currency?: { id: number; name: string };
    currency_gross?: number;
}

interface Customer {
    id: number;
    lastname: string;
    firstname: string;
    phone: string;
    customer: { email: string };
    billing_name: string;
    billing_address: string;
    tax_number: string;
}

interface OrderedProductData {
    id: number;
    product: {
        id: number;
        name: string;
    };
    price: {
        gross: number;
        net: number;
    };
    installments: PaymentInstallment[];
    deliveries: Delivery[];
}
interface Order {
    id: number;
    accepted: boolean;
    seller: {
        lastname: string;
        firstname: string;
        middlename: string;
    };
    customer: Customer;
    ordered_products: OrderedProductData[];
}

const useOrderQuery = queryFactory<Order>(orderQuery, 'order');

export type { Order, Customer, OrderedProductData, PaymentInstallment, Delivery };
export default useOrderQuery;
