import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const createFreebieMutation = gql`
    mutation createFreebie($data: FreebieInputCreate!) {
        createFreebie(data: $data) {
            id
        }
    }
`;

interface Freebie {
    id: number;
}

const useCreateFreebieMutation = mutationFactory<Freebie>(createFreebieMutation, 'createFreebie');

export default useCreateFreebieMutation;
