import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const deleteFreebieMutation = gql`
    mutation deleteFreebie($id: Int!) {
        deleteFreebie(id: $id)
    }
`;

interface Freebie {
    id: number;
}

const useDeleteFreebieMutation = mutationFactory<Freebie>(deleteFreebieMutation, 'deleteFreebie');

export default useDeleteFreebieMutation;
