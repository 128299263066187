import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const DeleteEmailTemplateMutation = gql`
    mutation deleteEmailTemplate($id: Int!) {
        deleteEmailTemplate(id: $id)
    }
`;

const useDeleteEmailTemplateMutation = mutationFactory(DeleteEmailTemplateMutation, 'deleteEmailTemplate');

export default useDeleteEmailTemplateMutation;
