import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const productCategoriesQuery = gql`
    query {
        productCategories {
            id
            name
        }
    }
`;

interface ProductCategory {
    id: number;
    name: string;
}

const useProductCategories = queryFactory<ProductCategory[]>(productCategoriesQuery, 'productCategories');

export type { ProductCategory };
export default useProductCategories;
