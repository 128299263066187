/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Paper, Button, TextField } from '@mui/material';
import { Section } from '../../components/Section';
import useCreateCustomerCharacteristicOption from './gql/createCustomerCharacteristicOption';
import useErrorHandler from '../../utils/userErrorHandler';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const CharacteristicsCreatePage: React.FC = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [createCustomerCharacteristicOption, createdCustomerCharacteristicOption] = useCreateCustomerCharacteristicOption();
    const [name, setName] = useState('');
    useErrorHandler(createdCustomerCharacteristicOption.error);

    useEffect(() => {
        if (createdCustomerCharacteristicOption.data) {
            enqueueSnackbar('A jellemző sikeresen létrehozva!', { variant: 'success' });
            navigate('/telesales/characteristics');
        }
    }, [createdCustomerCharacteristicOption.data]);

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title="Jellemző létrehozása">
                <div css={sectionStyles}>
                    <TextField fullWidth label="Név" variant="outlined" value={name} onChange={(e) => setName(e.target.value)} />
                    <Button variant="contained" color="success" size="large" style={{ minWidth: 250 }} onClick={() => createCustomerCharacteristicOption({ data: { name: name } })}>
                        Jellemző létrehozása
                    </Button>
                </div>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    padding: 20px;

    & > * + * {
        margin-left: 20px !important;
    }
`;

export default CharacteristicsCreatePage;
