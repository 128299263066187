import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const UpdateEmailTemplateMutation = gql`
    mutation UpdateEmailTemplate($id: Int!, $placeholders: [Int], $data: EmailTemplateInputUpdate!) {
        updateEmailTemplate(id: $id, placeholders: $placeholders, data: $data) {
            id
        }
    }
`;

interface UpdatedTempalte {
    id: number;
}

const useUpdateEmailTemplateMutation = mutationFactory<UpdatedTempalte>(UpdateEmailTemplateMutation, 'updateEmailTemplate');

export type { UpdatedTempalte };
export default useUpdateEmailTemplateMutation;
