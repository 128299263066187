import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const logout = gql`
    mutation {
        logout
    }
`;

const useLogout = mutationFactory<string>(logout, 'logout');

export default useLogout;
