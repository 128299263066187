import { gql } from '@apollo/client';
import { queryFactory } from '../../../../utils/gqlFactories';

const listFilterTypes = gql`
    query listFilterTypes {
        listFilterTypes {
            id
            name
        }
    }
`;

const useListFilterTypes = queryFactory<{ id: number; name: string }[]>(listFilterTypes, 'listFilterTypes');

export default useListFilterTypes;
