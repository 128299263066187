import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const createBlockedContact = gql`
    mutation createBlockedContact($data: BlockedContactInputCreate!) {
        createBlockedContact(data: $data) {
            id
        }
    }
`;

const useCreateBlockedContact = mutationFactory<{ id: number }>(createBlockedContact, 'createBlockedContact');

export default useCreateBlockedContact;
