import React from 'react';
import OrdersProps from './OrdersProps';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { ID } from '../../../components/ID';
import DataGridLocalization from '../../../utils/DataGridLocalization';
import { Order } from '../gql/orders';
import NumberFormat from 'react-number-format';
import SellerSelection from '../../AwaitingOrdersPage/SellerSelection/SellerSelection';
import { User } from '../../AwaitingOrdersPage/gql/users';

class Orders extends React.Component<OrdersProps> {
    render(): React.ReactNode {
        return (
            <div style={{ height: 800, width: '100%' }}>
                <DataGrid
                    componentsProps={{
                        pagination: {
                            labelRowsPerPage: 'Oldalanként',
                            labelDisplayedRows: ({ from, to, count }: { from: number; to: number; count: number }) => `${from}–${to} / ${count !== -1 ? count : `more than ${to}`}`,
                        },
                    }}
                    localeText={DataGridLocalization}
                    rows={this.makeRows(this.props.orders)}
                    columns={this.makeCols(this.props.sellers, this.props.selectSeller)}
                />
            </div>
        );
    }

    makeRows(orders: Order[]): GridRowsProp {
        const rows: GridRowsProp = orders.map((order: Order) => ({
            id: order.id,
            orderID: order.id, // will get rendered as an ID component
            created_at: order.created_at,
            seller: `${order.seller.lastname} ${order.seller.firstname} ${order.seller.middlename ? order.seller.middlename : ''}`,
            customerName: `${order.customer.lastname} ${order.customer.firstname}`,
            email: order.customer.customer.email,
            products: order.ordered_products.map((product) => product.product.name).join(', '),
            price: order.ordered_products.map((product) => product.price.gross).reduce((a, b) => a + b, 0), // will get rendered as an NumberFormat component
        }));

        return rows;
    }

    makeCols(sellers?: User[], selectSeller?: (orderId: number, sellerId: number | null) => void): GridColDef[] {
        const columns: GridColDef[] = [
            {
                field: 'orderID',
                headerName: 'ID',
                width: 100,
                renderCell: function renderID(cellValues) {
                    return <ID key={cellValues.row.orderID} id={cellValues.row.orderID.toString()} url={`/orders/${cellValues.row.orderID}`} openable />;
                },
            },
            { field: 'created_at', headerName: 'Rendelés dátuma', width: 200 },
            sellers && selectSeller !== undefined // { field: 'seller', headerName: 'Értékesítő', width: 150 },
                ? {
                      field: 'seller',
                      headerName: 'Értékesítő',
                      width: 300,
                      renderCell: function renderCurrency(cellValues) {
                          return <SellerSelection options={sellers} setSelection={selectSeller} orderId={cellValues.row.orderID} />;
                      },
                  }
                : { field: 'seller', headerName: 'Értékesítő', width: 150 },
            { field: 'customerName', headerName: 'Vevő neve', width: 150 },
            { field: 'email', headerName: 'Vevő e-mail címe', width: 200 },
            { field: 'products', headerName: 'Termék', width: 450 },
            {
                field: 'price',
                headerName: 'Teljes ár',
                width: 150,
                renderCell: function renderCurrency(cellValues) {
                    return <NumberFormat value={cellValues.row.price} thousandSeparator={' '} suffix={` Ft`} displayType="text" />;
                },
            },
        ];

        return columns;
    }
}

export default Orders;
