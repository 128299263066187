/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';

import { Section } from '../../../components/Section';
import { ID } from '../../../components/ID';
import { EmailAddress } from '../../../components/EmailAddress';

import { Customer } from '../gql/order';

interface CustomerDataProps {
    data: Customer;
}

class CustomerData extends React.Component<CustomerDataProps> {
    constructor(props: CustomerDataProps) {
        super(props);
    }

    render(): React.ReactNode {
        return (
            <Section title="Vásárlói adatok">
                <TableContainer>
                    <Table aria-label="table">
                        <TableBody>
                            <TableRow css={trStyle}>
                                <TableCell css={tdStyle}>ID</TableCell>
                                <TableCell>
                                    <ID id={this.props.data.id.toString()} url={`/customers/${this.props.data.id}`} openable></ID>
                                </TableCell>
                            </TableRow>
                            <TableRow css={trStyle}>
                                <TableCell>Vásárló neve</TableCell>
                                <TableCell>{`${this.props.data.lastname} ${this.props.data.firstname}`}</TableCell>
                            </TableRow>
                            <TableRow css={trStyle}>
                                <TableCell>Telefon</TableCell>
                                <TableCell>{this.props.data.phone}</TableCell>
                            </TableRow>
                            <TableRow css={trStyle}>
                                <TableCell>Email</TableCell>
                                <TableCell>
                                    <EmailAddress email={this.props.data.customer.email}></EmailAddress>
                                </TableCell>
                            </TableRow>
                            {this.renderBillingData(this.props.data)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Section>
        );
    }

    renderBillingData(billingData?: Customer): React.ReactNode {
        if (billingData) {
            return (
                <>
                    <TableRow css={trStyle}>
                        <TableCell>Számlázási név</TableCell>
                        <TableCell>{billingData.billing_name}</TableCell>
                    </TableRow>
                    <TableRow css={trStyle}>
                        <TableCell>Számlázási cím</TableCell>
                        <TableCell>{billingData.billing_address} </TableCell>
                    </TableRow>
                    <TableRow css={trStyle}>
                        <TableCell>Adószám</TableCell>
                        <TableCell>{billingData.tax_number}</TableCell>
                    </TableRow>
                </>
            );
        } else {
            return null;
        }
    }
}

const trStyle = css`
    &: nth-of-type(odd) {
        background-color: #e3f2fd;
    }

    &:last-child td,
    &:last-child th {
        border: 0;
    }
`;

const tdStyle = css`
    width: 50%;
`;

export default CustomerData;
