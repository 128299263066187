/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import { Paper, Button, TextField } from '@mui/material';
import { Section } from '../../components/Section';
import useCreateProductCategory from './gql/createProductCategory';
import useErrorHandler from '../../utils/userErrorHandler';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const CategoryCreatePage: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [createCategory, createdCategory] = useCreateProductCategory();
    useErrorHandler(createdCategory.error);

    const [name, setName] = useState<string>('');

    const handleSave = async () => {
        if (name) {
            await createCategory({ data: { name } });
            navigate('/products/categories');
        } else {
            enqueueSnackbar('A csillaggal jelölt mezők kitöltése kötelező!', { variant: 'error' });
        }
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`Kategória létrehozása`}>
                <div css={sectionStyles}>
                    <div css={inputsContainerStyles}>
                        <TextField fullWidth id="description" label="Név" placeholder="A kategória neve" value={name} onChange={(e) => setName(e.target.value)} css={inputsStyles} required />
                        <div css={buttonInputsContainerStyles}>
                            <Button size="large" variant="contained" color="success" css={buttonStyle} onClick={handleSave}>
                                Létrehoz
                            </Button>
                        </div>
                    </div>
                </div>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;
    & > * + * {
        margin-top: 20px !important;
    }
`;

const inputsContainerStyles = css`
    display: flex;
    gap: 20px;
    @media (max-width: 1200px) {
        flex-direction: column;
    }
`;

const inputsStyles = css`
    width: 300px;
    @media (max-width: 1200px) {
        width: 100%;
    }
`;

const buttonInputsContainerStyles = css`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    gap: 20px;
    @media (max-width: 1200px) {
        justify-content: center;
        align-items: center;
    }
`;

const buttonStyle = css`
    padding: 0 3rem;
`;

export default CategoryCreatePage;
