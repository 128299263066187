import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const createWorkingHourMutation = gql`
    mutation createWorkingHour($data: WorkingHourInputCreate!) {
        createWorkingHour(data: $data) {
            id
        }
    }
`;

interface WorkingHour {
    id: number;
}

const useCreateWorkingHourMutation = mutationFactory<WorkingHour>(createWorkingHourMutation, 'createWorkingHour');

export default useCreateWorkingHourMutation;
