import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const updateProductMutation = gql`
    mutation updateProduct($id: Int!, $data: ProductInputUpdate!) {
        updateProduct(id: $id, data: $data) {
            id
        }
    }
`;

interface Product {
    id: number;
}

const useUpdateProductMutation = mutationFactory<Product>(updateProductMutation, 'updateProduct');

export default useUpdateProductMutation;
