import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const deleteEmailAccount = gql`
    mutation deleteEmailAccount($id: Int!) {
        deleteEmailAccount(id: $id)
    }
`;

const useDeleteEmailAccount = mutationFactory<number>(deleteEmailAccount, 'deleteEmailAccount');

export default useDeleteEmailAccount;
