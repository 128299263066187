import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const users = gql`
    query {
        users {
            id
            username
        }
    }
`;

type User = {
    id: number;
    username: string;
};

const useUsers = queryFactory<User[]>(users, 'users');

export type { User };
export default useUsers;
