import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const callStates = gql`
    query callStates {
        callStates {
            id
            name
            description
        }
    }
`;

interface CallState {
    id: number;
    name: string;
    description: string;
}

const useCallStates = queryFactory<CallState[]>(callStates, 'callStates');

export default useCallStates;
