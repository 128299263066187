import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const ordersQuery = gql`
    query {
        orders(notAccepted: true) {
            id
            seller {
                lastname
                firstname
                middlename
            }
            customer {
                lastname
                firstname
                customer {
                    email
                }
            }
            ordered_products {
                id
                product {
                    name
                }
                price {
                    gross
                }
                deliveries {
                    date
                }
            }
            created_at
        }
    }
`;

interface Order {
    id: number;
    seller: {
        lastname: string;
        firstname: string;
        middlename: string;
    };
    customer: {
        lastname: string;
        firstname: string;
        customer: {
            email: string;
        };
    };
    ordered_products: {
        id: number;
        product: {
            name: string;
        };
        price: {
            gross: number;
        };
        deliveries: {
            date: string;
        };
    }[];
    created_at: string;
}

const useOrdersQuery = queryFactory<Order[]>(ordersQuery, 'orders');

export type { Order };
export default useOrdersQuery;
