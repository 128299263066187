import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const removeCategoryFromProductMutation = gql`
    mutation removeCategoryFromProduct($product: Int!, $category: Int!) {
        removeCategoryFromProduct(product: $product, category: $category) {
            id
        }
    }
`;

interface Category {
    id: number;
}

const useRemoveCategoryFromProductMutation = mutationFactory<Category>(removeCategoryFromProductMutation, 'removeCategoryFromProduct');

export default useRemoveCategoryFromProductMutation;
