import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const emailAccountsQuery = gql`
    query {
        emailAccounts {
            id
            displayed_name
        }
    }
`;

interface EmailAccount {
    id: number;
    displayed_name: string;
}

const useEmailAccountsQuery = queryFactory<EmailAccount[]>(emailAccountsQuery, 'emailAccounts');

export type { EmailAccount };
export default useEmailAccountsQuery;
