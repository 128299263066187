/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Paper, Button, TextField, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import { Section } from '../../components/Section';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import useMeQuery from '../ProfilePage/gql/me';
import useErrorHandler from '../../utils/userErrorHandler';
import { useSnackbar } from 'notistack';
import useUpdateMeMutation from './gql/updateMe';
import useUpdatePasswordMutation from './gql/updatePassword';

const ProfileEditPage: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const meData = useMeQuery();
    const [meUpdate, updatedMe] = useUpdateMeMutation();
    const [passwordUpdate, updatedPassword] = useUpdatePasswordMutation();
    useErrorHandler(meData.error, updatedMe.error, updatedPassword.error);

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPasswords, setShowNewPasswords] = useState(false);
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [middlename, setMiddlename] = useState('');
    const [email, setEmail] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    useEffect(() => {
        if (meData.data) {
            setFirstname(meData.data.firstname);
            setLastname(meData.data.lastname);
            setMiddlename(meData.data.middlename);
            setEmail(meData.data.email);
        }
    }, [meData.data]);

    useEffect(() => {
        if (updatedMe.data) {
            enqueueSnackbar('A profiladatok sikeresen frissítve lettek!', { variant: 'success' });
        }
    }, [updatedMe.data]);

    useEffect(() => {
        if (updatedPassword.data) {
            enqueueSnackbar('A jelszó sikeresen megváltoztatva!', { variant: 'success' });
        }
    }, [updatedPassword.data]);

    const handleSave = async () => {
        const rgx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;
        const match = email.match(rgx);

        if (lastname && firstname && email) {
            if (match) {
                await meUpdate({ data: { firstname: firstname, lastname: lastname, middlename: middlename, email: email } });
            } else {
                enqueueSnackbar('Az e-mail formátuma nem megfelelő!', { variant: 'error' });
            }
        } else {
            enqueueSnackbar('A csillaggal jelölt mezők kitöltése kötelező!', { variant: 'error' });
        }
    };

    const handlePasswordEdit = async () => {
        if (oldPassword.length > 0) {
            if (newPassword.length >= 8 && confirmPassword.length >= 8) {
                if (newPassword === confirmPassword) {
                    await passwordUpdate({ data: { oldPassword, newPassword } });
                } else {
                    enqueueSnackbar('A jelszavak nem egyeznek meg!', { variant: 'error' });
                }
            } else {
                enqueueSnackbar('A jelszónak legalább 8 karakter hosszúnak kell lennie!', { variant: 'error' });
            }
        } else {
            enqueueSnackbar('Kérlek add meg a régi jelszavad!', { variant: 'error' });
        }
    };

    const handleNameChange = (input: string, setNameState: React.Dispatch<React.SetStateAction<string>>) => {
        const formattedName = input.charAt(0).toUpperCase() + input.slice(1).toLocaleLowerCase();
        setNameState(formattedName);
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`Profiladatok szerkesztése`}>
                <div css={sectionStyles}>
                    <div css={dataContainerStyles}>
                        <div css={dataColStyles}>
                            <TextField required id="outlined-required-lastname" label="Vezetéknév" value={lastname} onChange={(e) => handleNameChange(e.target.value, setLastname)} />
                            <TextField required id="outlined-required-firstname" label="Keresztnév" value={firstname} onChange={(e) => handleNameChange(e.target.value, setFirstname)} />
                            <TextField id="outlined-middlename" label="Utónév" value={middlename} onChange={(e) => handleNameChange(e.target.value, setMiddlename)} />
                        </div>
                        <div css={dataColStyles}>
                            <TextField id="outlined-username" label="Felhasználónév" value={meData.data ? meData.data.username : 'Nem sikerült betölteni.'} disabled />
                            <TextField id="outlined-permission" label="Jogosultság" value={meData.data ? meData.data.permission.name : 'Nem sikerült betölteni.'} disabled />
                            <TextField required id="outlined-required-email" label="E-mail" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    </div>
                    <div css={buttonInputsContainerStyles}>
                        <Button size="large" variant="contained" color="success" onClick={handleSave}>
                            Mentés
                        </Button>
                    </div>
                </div>
            </Section>
            <Section title={`Jelszó módosítás`}>
                <div css={sectionStyles}>
                    <div css={dataContainerStyles}>
                        <div css={dataRowStyles}>
                            <FormControl variant="outlined" css={passwordInputStyles}>
                                <InputLabel htmlFor="oldPassword-input">Régi jelszó</InputLabel>
                                <OutlinedInput
                                    id="oldPassword-input"
                                    type={showOldPassword ? 'text' : 'password'}
                                    value={oldPassword}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" onClick={() => setShowOldPassword(!showOldPassword)} onMouseDown={(e) => e.preventDefault()} edge="end">
                                                {showOldPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Régi jelszó"
                                />
                            </FormControl>
                            <FormControl variant="outlined" css={passwordInputStyles}>
                                <InputLabel htmlFor="newPassword-input">Új jelszó</InputLabel>
                                <OutlinedInput
                                    id="newPassword-input"
                                    type={showNewPasswords ? 'text' : 'password'}
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" onClick={() => setShowNewPasswords(!showNewPasswords)} onMouseDown={(e) => e.preventDefault()} edge="end">
                                                {showNewPasswords ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Új jelszó"
                                />
                            </FormControl>
                            <FormControl variant="outlined" css={passwordInputStyles}>
                                <InputLabel htmlFor="confirmPassword-input">Új jelszó megerősítése</InputLabel>
                                <OutlinedInput
                                    id="confirmPassword-input"
                                    type={showNewPasswords ? 'text' : 'password'}
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" onClick={() => setShowNewPasswords(!showNewPasswords)} onMouseDown={(e) => e.preventDefault()} edge="end">
                                                {showNewPasswords ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Új jelszó megerősítése"
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div css={buttonInputsContainerStyles}>
                        <Button size="large" variant="contained" color="success" onClick={handlePasswordEdit}>
                            Mentés
                        </Button>
                    </div>
                </div>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;

    & > * + * {
        margin-top: 20px !important;
    }
`;

const dataContainerStyles = css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 30px;
`;

const dataColStyles = css`
    min-width: 360px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const dataRowStyles = css`
    display: flex;
    gap: 20px;
    @media (max-width: 1000px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

const passwordInputStyles = css`
    min-width: 300px;
`;

const buttonInputsContainerStyles = css`
    align-self: end;
`;

export default ProfileEditPage;
