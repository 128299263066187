/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, TextField, Typography, Select, MenuItem } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import DataGridLocalization from '../../../utils/DataGridLocalization';
import { Section } from '../../../components/Section';
import useTelesalesUsers from '../../ListPage/ListContacts/gql/telesalesUsers';
import useMoveAssignedContacts from '../../ListPage/ListContacts/gql/moveAssignedContacts';
import useUpdateStuckOrderListContact from './gql/updateStuckOrderListContact';
import useMyStuckOrderListContacts, { StuckOrderListContact } from './gql/myStuckOrderListContacts';
import useErrorHandler from '../../../utils/userErrorHandler';
import formatPhoneNumber from '../../../utils/formatPhoneNumber';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

type SalesPopupListProps = {
    id: number;
    name: string;
    refreshList: () => void;
};

const StuckOrderList: React.FC<SalesPopupListProps> = ({ id, name, refreshList }: SalesPopupListProps) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const myStuckOrderListContacts = useMyStuckOrderListContacts({ stuckOrderList: id });
    const telesalesUsers = useTelesalesUsers();
    const [moveAssignedContacts, movedAssignedContacts] = useMoveAssignedContacts();
    const [updateStuckOrderListContact, updatedStuckOrderListContact] = useUpdateStuckOrderListContact();
    const [isNoteDialogOpen, setIsNoteDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isMovingMultipleDialogOpen, setIsMovingMultipleDialogOpen] = useState(false);
    const [note, setNote] = useState('');
    const [selectedUser, setSelectedUser] = useState(0);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [clickedContact, setClickedContact] = useState({ id: 0, name: '' });
    useErrorHandler(myStuckOrderListContacts.error, updatedStuckOrderListContact.error, telesalesUsers.error, movedAssignedContacts.error);

    useEffect(() => {
        if (updatedStuckOrderListContact.data || movedAssignedContacts.data) {
            setIsNoteDialogOpen(false);
            setIsDeleteDialogOpen(false);
            setIsMovingMultipleDialogOpen(false);
            setNote('');
            setSelectedUser(0);
            enqueueSnackbar('A kontakt(ok) sikeresen módosítva!', { variant: 'success' });
            refreshList();
            myStuckOrderListContacts.refetch();
        }
    }, [updatedStuckOrderListContact.data, movedAssignedContacts.data]);

    const handleActionClick = (action: 'note' | 'delete', id: number, name: string) => {
        setClickedContact({ id: id, name: name });

        if (action === 'note') {
            setIsNoteDialogOpen(true);

            const contact = myStuckOrderListContacts.data?.find((contact) => contact.id === id);
            setNote(contact?.comment || '');
        }
        if (action === 'delete') setIsDeleteDialogOpen(true);
    };

    const saveNote = () => {
        if (clickedContact.id !== 0) updateStuckOrderListContact({ id: clickedContact.id, data: { comment: note } });
    };

    const deleteContact = () => {
        if (clickedContact.id !== 0) updateStuckOrderListContact({ id: clickedContact.id, data: { deleted: true } });
    };

    const movingMultipleContact = () => {
        if (selectedRows.length > 0 && selectedUser !== 0) moveAssignedContacts({ tsUser: selectedUser, assignedContacts: selectedRows });
    };

    const makeRows = (listContacts: StuckOrderListContact[]) => {
        const rows: GridRowsProp = listContacts.map((contact) => ({
            id: contact.assignedContact.id,
            contactID: contact.id,
            name: `${contact.customer.lastname} ${contact.customer.firstname}`,
            phone: formatPhoneNumber(contact.customer.phone),
            email: contact.customer.customer.email,
            status: `${contact.assignedContact.call_state.name} ${contact.assignedContact.talk_state?.name ? ` / ${contact.assignedContact.talk_state?.name}` : ''}`,
            comment: contact.comment,
            deleted: contact.deleted,
        }));

        return rows;
    };

    const makeCols = () => {
        const columns: GridColDef[] = [
            { field: 'contactID', headerName: 'ID', width: 100 },
            { field: 'name', headerName: 'Név', width: 150 },
            { field: 'phone', headerName: 'Telefonszám', width: 150 },
            { field: 'email', headerName: 'E-mail', width: 200 },
            { field: 'status', headerName: 'Állapot', width: 250 },
            { field: 'comment', headerName: 'Megjegyzés', width: 300 },
            {
                field: 'actions',
                headerName: 'Műveletek',
                width: 250,
                renderCell: function renderActions(cellValues) {
                    return (
                        <div css={buttonsContainerStyles}>
                            {!cellValues.row.deleted && (
                                <Button variant="contained" size="small" color="error" onClick={() => handleActionClick('delete', cellValues.row.contactID, cellValues.row.name)} style={{ minWidth: 32 }}>
                                    <DeleteForeverIcon />
                                </Button>
                            )}
                            {cellValues.row.deleted && (
                                <Button variant="contained" size="small" color="secondary">
                                    Visszaállítás
                                </Button>
                            )}
                            <Button variant="contained" size="small" color="primary" onClick={() => handleActionClick('note', cellValues.row.contactID, cellValues.row.name)} style={{ minWidth: 32 }}>
                                <EditIcon />
                            </Button>
                            <Button variant="contained" size="small" color="success" onClick={() => navigate(`/telesales/stuck-contact/${cellValues.row.contactID}`)}>
                                Megnyitás
                            </Button>
                        </div>
                    );
                },
            },
        ];

        return columns;
    };

    return (
        <Section title={name}>
            <div style={{ height: 600, width: '100%' }}>
                <DataGrid
                    componentsProps={{
                        pagination: {
                            labelRowsPerPage: 'Oldalanként',
                            labelDisplayedRows: ({ from, to, count }: { from: number; to: number; count: number }) => `${from}–${to} / ${count !== -1 ? count : `more than ${to}`}`,
                        },
                    }}
                    onSelectionModelChange={(selectionModel) => setSelectedRows(selectionModel as number[])}
                    checkboxSelection
                    localeText={DataGridLocalization}
                    rows={makeRows(myStuckOrderListContacts.data || [])}
                    columns={makeCols()}
                    style={{ borderRadius: 0, border: 'none' }}
                />
            </div>
            {selectedRows.length > 0 && (
                <Button variant="contained" color="primary" style={{ maxWidth: 130, marginLeft: 16, marginBottom: 20 }} onClick={() => setIsMovingMultipleDialogOpen(true)}>
                    Mozgatás
                </Button>
            )}

            <Dialog onClose={() => setIsNoteDialogOpen(false)} open={isNoteDialogOpen}>
                <DialogTitle style={{ backgroundColor: '#0288d1', color: 'white', minWidth: 370 }}>Megjegyzés hozzáadása</DialogTitle>
                <div css={dialogContentContainerStyles}>
                    <Typography variant="body1" component="div">
                        Megjegyzés hozzáadása a kontakthoz:
                    </Typography>
                    <Typography variant="h6" component="div" align="center" style={{ marginTop: 20 }}>
                        #{clickedContact.id} - {clickedContact.name}
                    </Typography>
                    <TextField multiline fullWidth label="Megjegyzés" variant="outlined" value={note} onChange={(e) => setNote(e.target.value)} />
                    <div css={dialogButtonsContainerStyle}>
                        <Button variant="contained" color="error" size="large" onClick={() => setIsNoteDialogOpen(false)}>
                            Mégse
                        </Button>
                        <Button variant="contained" color="info" size="large" onClick={saveNote}>
                            Mentés
                        </Button>
                    </div>
                </div>
            </Dialog>

            <Dialog onClose={() => setIsDeleteDialogOpen(false)} open={isDeleteDialogOpen}>
                <DialogTitle style={{ backgroundColor: '#0288d1', color: 'white', minWidth: 370 }}>Kontakt törlése</DialogTitle>
                <div css={dialogContentContainerStyles}>
                    <Typography variant="body1" component="div">
                        Biztosan törlöd a kontaktot?
                    </Typography>
                    <Typography variant="h6" component="div" align="center">
                        #{clickedContact.id} - {clickedContact.name}
                    </Typography>
                    <div css={dialogButtonsContainerStyle}>
                        <Button variant="contained" color="primary" size="large" onClick={() => setIsDeleteDialogOpen(false)}>
                            Mégse
                        </Button>
                        <Button variant="contained" color="error" size="large" onClick={deleteContact}>
                            Törlés
                        </Button>
                    </div>
                </div>
            </Dialog>

            <Dialog onClose={() => setIsMovingMultipleDialogOpen(false)} open={isMovingMultipleDialogOpen}>
                <DialogTitle style={{ backgroundColor: '#0288d1', color: 'white', minWidth: 370 }}>Értékesítő választása</DialogTitle>
                <div css={dialogContentContainerStyles}>
                    <Select style={{ backgroundColor: 'white' }} fullWidth value={selectedUser === 0 ? '' : selectedUser} onChange={(e) => setSelectedUser(Number(e.target.value))}>
                        {telesalesUsers.data?.map((user) => (
                            <MenuItem value={user.id} key={user.id}>
                                #{user.id} - {user.lastname} {user.firstname}
                            </MenuItem>
                        ))}
                    </Select>
                    <div css={dialogButtonsContainerStyle}>
                        <Button variant="contained" color="error" size="large" onClick={() => setIsMovingMultipleDialogOpen(false)}>
                            Mégse
                        </Button>
                        <Button variant="contained" color="primary" size="large" onClick={movingMultipleContact}>
                            Hozzárendelés
                        </Button>
                    </div>
                </div>
            </Dialog>
        </Section>
    );
};

const buttonsContainerStyles = css`
    & > * + * {
        margin-left: 10px !important;
    }
`;

const dialogContentContainerStyles = css`
    padding: 24px;

    & > * + * {
        margin-top: 25px !important;
    }
`;

const dialogButtonsContainerStyle = css`
    display: flex;
    justify-content: center;
    margin-top: 30px;

    & > * + * {
        margin-left: 20px !important;
    }
`;

export default StuckOrderList;
