import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const createList = gql`
    mutation createList($data: ListInputCreate!) {
        createList(data: $data) {
            id
        }
    }
`;

const useCreateList = mutationFactory<{ id: number }>(createList, 'createList');

export default useCreateList;
