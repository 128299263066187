/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Paper, Button, Typography } from '@mui/material';
import { Section } from '../../components/Section';
import useMeQuery from './gql/me';
import useErrorHandler from '../../utils/userErrorHandler';

const ProfilePage: React.FC = () => {
    const navigate = useNavigate();
    const meData = useMeQuery();
    useErrorHandler(meData.error);

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`Profil adatok`}>
                <div css={sectionStyles}>
                    <div css={dataContainerStyles}>
                        <div css={dataColStyles}>
                            <Typography variant="h6" component="div">
                                <span css={textStyles}>Vezetéknév: </span>
                                {meData.data ? meData.data.lastname : 'Nem sikerült betölteni.'}
                            </Typography>
                            <Typography variant="h6" component="div">
                                <span css={textStyles}>Keresztnév: </span>
                                {meData.data ? meData.data.firstname : 'Nem sikerült betölteni.'}
                            </Typography>
                            <Typography variant="h6" component="div">
                                <span css={textStyles}>Utónév: </span>
                                {meData.data ? meData.data.middlename : 'Nem sikerült betölteni.'}
                            </Typography>
                        </div>
                        <div css={dataColStyles}>
                            <Typography variant="h6" component="div">
                                <span css={textStyles}>Felhasználónév: </span>
                                {meData.data ? meData.data.username : 'Nem sikerült betölteni.'}
                            </Typography>
                            <Typography variant="h6" component="div">
                                <span css={textStyles}>E-mail: </span>
                                {meData.data ? meData.data.email : 'Nem sikerült betölteni.'}
                            </Typography>
                            <Typography variant="h6" component="div">
                                <span css={textStyles}>Jogosultság: </span>
                                {meData.data ? meData.data.permission.name : 'Nem sikerült betölteni.'}
                            </Typography>
                        </div>
                    </div>
                    <div css={buttonInputsContainerStyles}>
                        <Button size="large" variant="contained" onClick={() => navigate('/profile/edit')}>
                            Szerkesztés
                        </Button>
                    </div>
                </div>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;

    & > * + * {
        margin-top: 20px !important;
    }
`;

const textStyles = css`
    color: #0288d1;
`;

const dataContainerStyles = css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 30px;
`;
const dataColStyles = css`
    display: flex;
    flex-direction: column;
`;

const buttonInputsContainerStyles = css`
    align-self: end;
`;

export default ProfilePage;
