import { gql } from '@apollo/client';
import { queryFactory } from '../../../../utils/gqlFactories';

const customerNotes = gql`
    query customerNotes($customer: Int!) {
        customerNotes(customer: $customer) {
            id
            creator {
                firstname
                lastname
            }
            note
            created_at
        }
    }
`;

export interface CustomerNote {
    id: number;
    creator: {
        firstname: string;
        lastname: string;
    };
    note: string;
    created_at: string;
}

const useCustomerNotes = queryFactory<CustomerNote[]>(customerNotes, 'customerNotes');

export default useCustomerNotes;
