/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { Button, InputLabel, Autocomplete, TextField, Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Section } from '../../components/Section';
import { orders, Order } from '../../utils/ordersData';
import useEmailTemplateContentQuery from './gql/emailTemplateContent';

const customers = orders.map((order: Order) => order.customer);

const SendEmailPage: React.FC = () => {
    const params = useParams();
    const id = parseInt(params.id as string);
    const EmailTemplateContent = useEmailTemplateContentQuery({ id: id });

    const [file, setFile] = useState<File | undefined>(undefined);
    const [displayedEmails, setDisplayedEmails] = useState<'select' | 'file'>('select');

    const emailTemplatePLaceholderMarkup = (): { __html: string } => {
        return EmailTemplateContent.data ? { __html: EmailTemplateContent.data?.content } : { __html: 'Nem sikerült betölteni az előnézetet.' };
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.files) {
            setFile(event.target.files[0]);
        }
    };

    const handleTabChange = (_event: React.SyntheticEvent, newValue: 'select' | 'file') => {
        setDisplayedEmails(newValue);
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title="Címzettek">
                <TabContext value={displayedEmails}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                            <Tab label="Címzettek" value="select" />
                            <Tab label="Címzettek fájlból" value="file" />
                        </TabList>
                    </Box>
                    <TabPanel value="select" style={{ padding: 0 }}>
                        <div css={sectionStyles}>
                            <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={customers}
                                getOptionLabel={(option) => `${option.email} - ${option.name}`}
                                filterSelectedOptions
                                renderInput={(params) => <TextField {...params} label="Címzettek" placeholder="Címzettek" />}
                            />
                            <div css={sendButtonContainerStyles}>
                                <Button variant="contained" color="success" size="large">
                                    Küldés
                                </Button>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value="file" style={{ padding: 0 }}>
                        <div css={sectionStyles}>
                            <InputLabel htmlFor="upload" css={uploadInputStyles}>
                                <input id="upload" type="file" style={{ display: 'none' }} onChange={(event) => handleFileChange(event)} />
                                <Button variant="outlined" component="span">
                                    {file ? file.name : 'Lista feltöltése'}
                                </Button>
                            </InputLabel>
                            <div css={sendButtonContainerStyles}>
                                <Button variant="contained" color="success" size="large">
                                    Küldés
                                </Button>
                            </div>
                        </div>
                    </TabPanel>
                </TabContext>
            </Section>
            <Section title="Előnézet">
                <div css={sectionStyles}>
                    <div dangerouslySetInnerHTML={emailTemplatePLaceholderMarkup()} css={templatePreviewStyles} />
                </div>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;

    & > * + * {
        margin-top: 20px !important;
    }
`;

const templatePreviewStyles = css`
    overflow-x: scroll;
    background-color: white;
    filter: drop-shadow(0 0.1rem 0.25rem #999);
`;

const uploadInputStyles = css`
    width: min-content;
    display: flex;
    align-items: center;
`;

const sendButtonContainerStyles = css`
    align-self: end;
`;

export default SendEmailPage;
