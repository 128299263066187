import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const productsQuery = gql`
    query {
        products {
            id
            name
            prices {
                id
                gross
                type {
                    id
                    name
                }
            }
        }
    }
`;

interface PriceType {
    id: number;
    name: string;
}

interface Price {
    id: number;
    gross: number;
    type: PriceType;
}

interface Product {
    id: number;
    name: string;
    prices: Price[];
}

const useProductsQuery = queryFactory<Product[]>(productsQuery, 'products');

export type { Product, Price };
export default useProductsQuery;
