/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Paper, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { v4 } from 'uuid';
import { Section } from '../../components/Section';
import huLocale from 'date-fns/locale/hu';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useCreateWorkingDayModMutation from './gql/createWorkingDayMod';
import useErrorHandler from '../../utils/userErrorHandler';
import { useNavigate } from 'react-router-dom';

const dayType = [
    { id: 1, type: 'munkaszüneti nap' },
    { id: 2, type: 'áthelyezett munkanap' },
];

const AlteredWorkdayPage: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [createWorkingDayMod, createdWorkingDay] = useCreateWorkingDayModMutation();
    useErrorHandler(createdWorkingDay.error);

    const [type, setType] = useState<string>('');
    const [date, setDate] = useState<Date | null>(new Date(new Date().setHours(2, 0, 0, 0)));

    const handleSave = async () => {
        if (type && date) {
            await createWorkingDayMod({ data: { type: parseInt(type), date: date.toISOString() } });
            navigate('/working-hours/altered-work-schedule');
        } else {
            enqueueSnackbar('Minden mező kitöltése kötelező!', { variant: 'error' });
        }
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`Új módosított nap hozzáadása`}>
                <div css={sectionStyles}>
                    <div css={inputsContainerStyles}>
                        <FormControl sx={{ minWidth: 300 }} required>
                            <InputLabel id="type">Nap típusa</InputLabel>
                            <Select labelId="type" id="type-select" label="Nap típusa" value={type} onChange={(e) => setType(e.target.value)}>
                                {dayType.map((type) => {
                                    return (
                                        <MenuItem key={v4()} value={type.id}>
                                            {type.type}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={huLocale}>
                            <DatePicker
                                label="Dátum"
                                views={['year', 'month', 'day']}
                                inputFormat="yyyy.MM.dd."
                                mask={'____.__.__.'}
                                minDate={new Date('2022-01-01T00:00:00')}
                                value={date}
                                onChange={(newDate: Date | null) => setDate(newDate)}
                                renderInput={(params) => <TextField {...params} css={inputsStyles} required />}
                            />
                        </LocalizationProvider>
                        <div css={buttonInputsContainerStyles}>
                            <Button size="large" variant="contained" color="success" onClick={handleSave} css={buttonStyle}>
                                Rögzít
                            </Button>
                        </div>
                    </div>
                </div>
            </Section>
        </Paper>
    );
};
const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;
    & > * + * {
        margin-top: 20px !important;
    }
`;

const inputsContainerStyles = css`
    display: flex;
    gap: 20px;

    @media (max-width: 1200px) {
        flex-direction: column;
    }
`;

const inputsStyles = css`
    width: 300px;
    @media (max-width: 1200px) {
        width: 100%;
    }
`;

const buttonInputsContainerStyles = css`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    gap: 20px;

    @media (max-width: 1200px) {
        justify-content: center;
        align-items: center;
    }
`;

const buttonStyle = css`
    padding: 0 3rem;
`;

export default AlteredWorkdayPage;
