/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { Paper, Button, FormControl, InputLabel, Select, MenuItem, Chip, Autocomplete, Box, Typography, Modal, TextField } from '@mui/material';
import { Section } from '../../components/Section';
import { useSnackbar } from 'notistack';
import usePlaceholdersQuery, { Placeholder } from '../EmailTemplateEditPage/gql/placeholders';
import useEmailAccountsQuery, { EmailAccount } from '../EmailTemplateEditPage/gql/emailAccounts';
import useCreatePlaceholderMutation, { NewPlaceHolder } from '../EmailTemplateEditPage/gql/createPlaceholder';
import useCreateEmailTemplateMutation from './gql/createEmailTemplate';
import useErrorHandler from '../../utils/userErrorHandler';

type NewPlaceHolderToSend = Pick<NewPlaceHolder, 'name' | 'description' | 'custom'>;

type EmailTemplateInputCreate = {
    name: string;
    subject: string;
    sender: number;
    reply_to: number;
    content: string;
};

const EmailTemplateCreatePage: React.FC = () => {
    const navigate = useNavigate();
    const placeholdersData = usePlaceholdersQuery();
    const accounts = useEmailAccountsQuery();
    const { enqueueSnackbar } = useSnackbar();
    const [createNewPlaceholder, newPlaceholder] = useCreatePlaceholderMutation();
    const [createEmailTemplate, createdEmailTemplate] = useCreateEmailTemplateMutation();
    useErrorHandler(newPlaceholder.error, createdEmailTemplate.error);

    const [name, setName] = useState('');
    const [subject, setSubject] = useState('');
    const [sender, setSender] = useState('');
    const [replyTo, setReplyTo] = useState('');
    const [placeholders, setPlaceholders] = useState<Placeholder[]>([]);
    const [selectedPlaceholders, setSelectedPlaceholders] = useState<Placeholder[]>([]);
    const [newPlaceholderDescription, setNewPlaceholderDescription] = useState('');
    const [editorValue, setEditorValue] = useState('<p><strong>Templete szerkesztő</strong></p> <p> Helló {{NAME}}! </p>');
    const [modalOpen, setModalOpen] = useState(false);
    const [tempPlaceholder, setTempPlaceholder] = useState<NewPlaceHolderToSend>();

    useEffect(() => {
        (async () => {
            if (tempPlaceholder?.description && tempPlaceholder.name) {
                await handlePlaceholderCreate(tempPlaceholder);
                setTempPlaceholder({
                    name: '',
                    description: '',
                    custom: true,
                });
                setNewPlaceholderDescription('');
                setModalOpen(false);
            }
        })();
    }, [tempPlaceholder]);

    useEffect(() => {
        if (newPlaceholder.data) {
            const newSelection: Placeholder[] = [...selectedPlaceholders];
            const palceohlderList: Placeholder[] = [...placeholders];

            newSelection.push(newPlaceholder.data);
            palceohlderList.push(newPlaceholder.data);

            setSelectedPlaceholders(newSelection);
            setPlaceholders(palceohlderList);
        }
    }, [newPlaceholder.data]);

    useEffect(() => {
        if (placeholdersData.data) {
            setPlaceholders(placeholdersData.data);
        }
    }, [placeholdersData.data]);

    useEffect(() => {
        if (createdEmailTemplate.data) {
            navigate('/emails/templates');
        }
    }, [createdEmailTemplate]);

    const handlePlaceHolderChange = (selectedValues: string[]): void => {
        const newSelections: Placeholder[] = [];

        for (const selectedPlaceholderName of selectedValues) {
            const placeholderToAddToSelections = placeholders.find((ph) => ph.name === selectedPlaceholderName);
            if (placeholderToAddToSelections) {
                newSelections.push(placeholderToAddToSelections);
            } else if (typeof selectedPlaceholderName === 'string') {
                setNewPlaceholderDescription('');
                setModalOpen(true);

                const newPlaceholderData: NewPlaceHolderToSend = {
                    name: selectedPlaceholderName.toUpperCase(),
                    description: newPlaceholderDescription,
                    custom: true,
                };
                setTempPlaceholder(newPlaceholderData);
            }
        }

        setSelectedPlaceholders(newSelections);
    };

    const handlePlaceholderCreate = async (placeholder: NewPlaceHolderToSend) => {
        return createNewPlaceholder({ data: placeholder });
    };

    const handleModalSave = () => {
        if (tempPlaceholder) {
            setTempPlaceholder({
                name: tempPlaceholder?.name,
                description: newPlaceholderDescription,
                custom: true,
            });
        }
    };

    const handleTemplateEditSave = () => {
        let newData: EmailTemplateInputCreate;
        if (selectedPlaceholders.length !== 0 && name !== '' && subject !== '' && sender !== '' && replyTo !== '' && editorValue !== '') {
            const selPlaceh = selectedPlaceholders.map((sph) => sph.id);
            newData = {
                name: name,
                subject: subject,
                sender: parseInt(sender),
                reply_to: parseInt(replyTo),
                content: editorValue,
            };
            createEmailTemplate({ placeholders: selPlaceh, data: newData });
        } else {
            enqueueSnackbar('Az összes mező kitöltése kötelező!', { variant: 'error' });
        }
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`Új sablon készítése`}>
                <div css={sectionStyles}>
                    <div css={inputsContainerStyles}>
                        <div css={textInputsContainerStyles}>
                            <TextField fullWidth id="name" label="Név" placeholder="A sablon neve" value={name} onChange={(e) => setName(e.target.value)} css={inputsStyles} />
                            <TextField fullWidth id="subject" label="Tárgy" placeholder="Az e-mail tárgya" value={subject} onChange={(e) => setSubject(e.target.value)} css={inputsStyles} />
                            <FormControl sx={{ minWidth: 200, width: '100%' }}>
                                <InputLabel id="sender">Feladó</InputLabel>
                                <Select labelId="sender" id="sender-select" label="Feladó" value={sender} onChange={(e) => setSender(e.target.value)}>
                                    {accounts.data?.map((account: EmailAccount) => {
                                        return (
                                            <MenuItem key={account.id} value={account.id}>
                                                {account.displayed_name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ minWidth: 200, width: '100%' }}>
                                <InputLabel id="replyTo">Válasz cím</InputLabel>
                                <Select labelId="replyTo" id="replyTo-select" label="Válasz cím" value={replyTo} onChange={(e) => setReplyTo(e.target.value)}>
                                    {accounts.data?.map((account: EmailAccount) => {
                                        return (
                                            <MenuItem key={account.id} value={account.id}>
                                                {account.displayed_name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <div css={buttonInputsContainerStyles}>
                            <Button size="large" variant="contained" css={buttonStyles} onClick={handleTemplateEditSave}>
                                Mentés
                            </Button>
                        </div>
                    </div>
                    <Editor
                        value={editorValue}
                        onEditorChange={setEditorValue}
                        apiKey="lgcmej97gxzusi32t5d28t7clbmhhtsi5l2zi7gp0r15gltn"
                        init={{
                            height: 500,
                            menubar: true,
                            plugins: ['advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'],
                            toolbar:
                                'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist blockquote | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        }}
                    />
                    <Autocomplete
                        multiple
                        id="tags-standard"
                        options={placeholders.map((ph: Placeholder) => {
                            return ph.name;
                        })}
                        value={selectedPlaceholders.map((sph: Placeholder) => {
                            return sph.name;
                        })}
                        freeSolo
                        renderTags={(value: string[], getTagProps) => value.map((option: string, index: number) => <Chip variant="outlined" label={option} {...getTagProps({ index })} key={index} />)}
                        renderInput={(params) => <TextField {...params} label="Placeholderek: " placeholder="Placeholder" />}
                        onChange={(_event, value) => {
                            handlePlaceHolderChange(value);
                        }}
                    />
                </div>
            </Section>
            <Modal open={modalOpen} onClose={() => setModalOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" style={{ position: 'absolute' }}>
                <Box css={boxStyles}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ marginBottom: '1rem' }}>
                        Add meg a placeholder leírását:
                    </Typography>
                    <TextField fullWidth id="subject" label="Leírás" placeholder="Az új placeholder leírása" value={newPlaceholderDescription} onChange={(e) => setNewPlaceholderDescription(e.target.value)} css={inputsStyles} />
                    <Button size="large" variant="contained" css={boxButtonStyles} onClick={handleModalSave}>
                        Létrehoz
                    </Button>
                </Box>
            </Modal>
        </Paper>
    );
};

const containerStyles = css`
    position: relative;
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
`;

const inputsContainerStyles = css`
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @media (max-width: 1200px) {
        flex-direction: column;
    }
`;

const textInputsContainerStyles = css`
    display: flex;
    flex-grow: 4;
    gap: 20px;

    @media (max-width: 1200px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

const buttonInputsContainerStyles = css`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    gap: 20px;

    @media (max-width: 1200px) {
        justify-content: center;
        align-items: center;
    }
`;

const inputsStyles = css`
    min-width: 200px;
`;

const buttonStyles = css`
    padding: 0 3rem;
`;

const boxStyles = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 2px solid #0288d1;
    boxshadow: 24;
    padding: 1rem;
`;

const boxButtonStyles = css`
    float: right;
    margin-top: 1rem;
`;

export default EmailTemplateCreatePage;
