/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import { Paper, Button, TableContainer, Table, TableBody, TableRow, TableCell, Dialog, DialogTitle, Typography, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Section } from '../../components/Section';
import useCustomer from './gql/customer';
import useBlockReasons from './gql/blockReasons';
import useDeleteBlockedContact from '../BlockedContactsPage/gql/deleteBlockedContact';
import useUpdateBlockedContact from './gql/updateBlockedContact';
import useCreateBlockedContact from './gql/createBlockedContact';
import { useParams } from 'react-router-dom';
import useErrorHandler from '../../utils/userErrorHandler';
import formatDatetime from '../../utils/formatDate';
import formatPhoneNumber from '../../utils/formatPhoneNumber';
import OrderHistory from '../ListContactPage/OrderHistory/OrderHistory';
import CallHistory from '../ListContactPage/CallHistory/CallHistory';
import CustomerCharacteristics from '../ListContactPage/CustomerCharacteristics/CustomerCharacteristics';
import { useSnackbar } from 'notistack';

const CustomerPage: React.FC = () => {
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const customer = useCustomer({ id: Number(params.id) });
    const blockReasons = useBlockReasons();
    const [deleteBlockedContact, deletedBlockedContact] = useDeleteBlockedContact();
    const [updateBlockedContact, updatedBlockedContact] = useUpdateBlockedContact();
    const [createBlockedContact, createdBlockedContact] = useCreateBlockedContact();
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [isBlockingDialogOpen, setIsBlockingDialogOpen] = useState(false);
    const [blockComment, setBlockComment] = useState('');
    const [selectedReason, setSelectedReason] = useState(0);
    useErrorHandler(customer.error, blockReasons.error, deletedBlockedContact.error, updatedBlockedContact.error, createdBlockedContact.error);

    useEffect(() => {
        if (deletedBlockedContact.data || updatedBlockedContact.data || createdBlockedContact.data) {
            setIsDeleteDialogOpen(false);
            setIsEditDialogOpen(false);
            setIsBlockingDialogOpen(false);
            setBlockComment('');
            setSelectedReason(0);
            enqueueSnackbar('Az ügyfél blokkolási státusza sikeresen módosítva!', { variant: 'success' });
            customer.refetch();
        }
    }, [deletedBlockedContact.data, updatedBlockedContact.data, createdBlockedContact.data]);

    const openEditDialog = () => {
        setBlockComment(customer.data?.blocked.comment || '');
        setSelectedReason(customer.data?.blocked.reason.id || 0);
        setIsEditDialogOpen(true);
    };

    const editBlockContact = () => {
        if (selectedReason !== 0 && customer.data?.blocked.id) updateBlockedContact({ id: customer.data?.blocked.id, data: { comment: blockComment, reason: selectedReason } });
    };

    const blockContact = () => {
        if (selectedReason !== 0 && customer.data?.id) createBlockedContact({ data: { customer: customer.data?.id, comment: blockComment, reason: selectedReason } });
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title="Adatok">
                <TableContainer>
                    <Table aria-label="table">
                        <TableBody>
                            <TableRow css={trStyle}>
                                <TableCell css={tdStyle}>ID</TableCell>
                                <TableCell css={tdStyle}>{customer.data?.id}</TableCell>
                            </TableRow>
                            <TableRow css={trStyle}>
                                <TableCell css={tdStyle}>Név</TableCell>
                                <TableCell css={tdStyle}>
                                    {customer.data?.details.length ? `${customer.data?.details[customer.data?.details.length - 1].lastname} ${customer.data?.details[customer.data?.details.length - 1].firstname}` : '-'}
                                </TableCell>
                            </TableRow>
                            <TableRow css={trStyle}>
                                <TableCell css={tdStyle}>Telefonszám</TableCell>
                                <TableCell css={tdStyle}>{(customer.data?.details.length && formatPhoneNumber(customer.data?.details[customer.data?.details.length - 1].phone)) || '-'}</TableCell>
                            </TableRow>
                            <TableRow css={trStyle}>
                                <TableCell css={tdStyle}>E-mail cím</TableCell>
                                <TableCell css={tdStyle}>{customer.data?.email}</TableCell>
                            </TableRow>
                            <TableRow css={trStyle}>
                                <TableCell css={tdStyle}>Számlázási név</TableCell>
                                <TableCell css={tdStyle}>{(customer.data?.details.length && customer.data?.details[customer.data?.details.length - 1].billing_name) || '-'}</TableCell>
                            </TableRow>
                            <TableRow css={trStyle}>
                                <TableCell css={tdStyle}>Számlázási cím</TableCell>
                                <TableCell css={tdStyle}>{(customer.data?.details.length && customer.data?.details[customer.data?.details.length - 1].billing_address) || '-'}</TableCell>
                            </TableRow>
                            <TableRow css={trStyle}>
                                <TableCell css={tdStyle}>Adószám</TableCell>
                                <TableCell css={tdStyle}>{(customer.data?.details.length && customer.data?.details[customer.data?.details.length - 1].tax_number) || '-'}</TableCell>
                            </TableRow>
                            <TableRow css={trStyle}>
                                <TableCell css={tdStyle}>Létrehozva</TableCell>
                                <TableCell css={tdStyle}>{(customer.data?.details.length && formatDatetime(customer.data?.details[customer.data?.details.length - 1].created_at)) || '-'}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Section>

            <OrderHistory id={Number(params.id)} />

            <CallHistory id={Number(params.id)} />

            {customer.data && <CustomerCharacteristics id={Number(params.id)} characteristics={customer.data.characteristics} refetch={customer.refetch} />}

            <Section title="Blokkolás">
                {customer.data?.blocked !== null && (
                    <TableContainer>
                        <Table aria-label="table">
                            <TableBody>
                                <TableRow css={trStyle}>
                                    <TableCell css={tdStyle}>ID</TableCell>
                                    <TableCell css={tdStyle}>{customer.data?.id}</TableCell>
                                </TableRow>
                                <TableRow css={trStyle}>
                                    <TableCell css={tdStyle}>Indok</TableCell>
                                    <TableCell css={tdStyle}>{customer.data?.blocked.reason.name}</TableCell>
                                </TableRow>
                                <TableRow css={trStyle}>
                                    <TableCell css={tdStyle}>Megjegyzés</TableCell>
                                    <TableCell css={tdStyle}>{customer.data?.blocked.comment || '-'}</TableCell>
                                </TableRow>
                                <TableRow css={trStyle}>
                                    <TableCell css={tdStyle}>Blokkolta</TableCell>
                                    <TableCell css={tdStyle}>
                                        {customer.data?.blocked.blocker.lastname} {customer.data?.blocked.blocker.firstname}
                                    </TableCell>
                                </TableRow>
                                <TableRow css={trStyle}>
                                    <TableCell css={tdStyle}>Blokkolva</TableCell>
                                    <TableCell css={tdStyle}>{customer.data?.blocked.created_at}</TableCell>
                                </TableRow>
                                <TableRow css={trStyle}>
                                    <TableCell css={tdStyle}>Műveletek</TableCell>
                                    <TableCell css={tdStyle}>
                                        <Button variant="contained" size="small" color="primary" onClick={openEditDialog} style={{ minWidth: 32, marginRight: 10 }}>
                                            <EditIcon />
                                        </Button>
                                        <Button variant="contained" size="small" color="success" onClick={() => setIsDeleteDialogOpen(true)}>
                                            Feloldás
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

                {customer.data?.blocked === null && (
                    <div css={sectionStyles}>
                        <TextField fullWidth multiline label="Megjegyzés" variant="outlined" value={blockComment} onChange={(e) => setBlockComment(e.target.value)} />
                        <FormControl fullWidth>
                            <InputLabel id="select">Indok</InputLabel>
                            <Select label="Indok" id="select" value={selectedReason === 0 ? '' : selectedReason} onChange={(e) => setSelectedReason(Number(e.target.value))}>
                                {blockReasons.data?.map((reason) => (
                                    <MenuItem value={reason.id} key={reason.id}>
                                        {reason.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div>
                            <Button variant="contained" color="error" size="large" onClick={() => setIsBlockingDialogOpen(true)} disabled={selectedReason === 0}>
                                Ügyfél blokkolása
                            </Button>
                        </div>
                    </div>
                )}
            </Section>

            <Dialog onClose={() => setIsDeleteDialogOpen(false)} open={isDeleteDialogOpen}>
                <DialogTitle style={{ backgroundColor: '#0288d1', color: 'white', minWidth: 370 }}>Blokkolás feloldása</DialogTitle>
                <div css={dialogContentContainerStyles}>
                    <Typography variant="body1" component="div">
                        Biztosan feloldod az ügyfél blokkolását?
                    </Typography>
                    <div css={dialogButtonsContainerStyle}>
                        <Button variant="contained" color="error" size="large" onClick={() => setIsDeleteDialogOpen(false)}>
                            Mégse
                        </Button>
                        <Button variant="contained" color="success" size="large" onClick={() => deleteBlockedContact({ id: customer.data?.blocked.id })}>
                            Feloldás
                        </Button>
                    </div>
                </div>
            </Dialog>

            <Dialog onClose={() => setIsEditDialogOpen(false)} open={isEditDialogOpen}>
                <DialogTitle style={{ backgroundColor: '#0288d1', color: 'white', minWidth: 370 }}>Blokkolás szerkesztése</DialogTitle>
                <div css={dialogContentContainerStyles}>
                    <TextField fullWidth multiline label="Megjegyzés" variant="outlined" value={blockComment} onChange={(e) => setBlockComment(e.target.value)} />
                    <FormControl fullWidth>
                        <InputLabel id="select">Indok</InputLabel>
                        <Select label="Indok" id="select" value={selectedReason === 0 ? '' : selectedReason} onChange={(e) => setSelectedReason(Number(e.target.value))}>
                            {blockReasons.data?.map((reason) => (
                                <MenuItem value={reason.id} key={reason.id}>
                                    {reason.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div css={dialogButtonsContainerStyle}>
                        <Button variant="contained" color="error" size="large" onClick={() => setIsEditDialogOpen(false)}>
                            Mégse
                        </Button>
                        <Button variant="contained" color="success" size="large" onClick={editBlockContact}>
                            Mentés
                        </Button>
                    </div>
                </div>
            </Dialog>

            <Dialog onClose={() => setIsBlockingDialogOpen(false)} open={isBlockingDialogOpen}>
                <DialogTitle style={{ backgroundColor: '#0288d1', color: 'white', minWidth: 370 }}>Ügyfél blokkolása</DialogTitle>
                <div css={dialogContentContainerStyles}>
                    <Typography variant="body1" component="div" style={{ maxWidth: 450 }}>
                        Az ügyfél az összes jelenlegi és jövőbeli telefonszámával blokkolva lesz!
                    </Typography>
                    <Typography variant="body1" component="div">
                        Biztosan blokkolod az ügyfelet?
                    </Typography>
                    <div css={dialogButtonsContainerStyle}>
                        <Button variant="contained" color="primary" size="large" onClick={() => setIsBlockingDialogOpen(false)}>
                            Mégse
                        </Button>
                        <Button variant="contained" color="error" size="large" onClick={blockContact}>
                            Blokkolás
                        </Button>
                    </div>
                </div>
            </Dialog>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const trStyle = css`
    &: nth-of-type(odd) {
        background-color: #e3f2fd;
    }

    &:last-child td,
    &:last-child th {
        border: 0;
    }
`;

const tdStyle = css`
    width: 50%;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;

    & > * + * {
        margin-top: 20px !important;
    }
`;

const dialogContentContainerStyles = css`
    padding: 24px;

    & > * + * {
        margin-top: 25px !important;
    }
`;

const dialogButtonsContainerStyle = css`
    display: flex;
    justify-content: center;
    margin-top: 30px;

    & > * + * {
        margin-left: 20px !important;
    }
`;

export default CustomerPage;
