import { useQuery, useMutation, useLazyQuery, DocumentNode, FetchResult } from '@apollo/client';

export function queryFactory<T>(query: DocumentNode, name: string) {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    return (args?: unknown) => {
        const res = useQuery(query, { variables: args, fetchPolicy: 'network-only', errorPolicy: 'all' });
        return { ...res, data: res.data ? (res.data[name] as T) : undefined };
    };
}

type MutationFunction<T> = (args?: unknown) => Promise<FetchResult<T>>;

export function mutationFactory<T>(mutation: DocumentNode, name: string) {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    return (args?: unknown) => {
        const [run, q] = useMutation(mutation, { variables: args, fetchPolicy: 'network-only', errorPolicy: 'all' });
        const q2 = { ...q, data: q.data ? (q.data[name] as T) : undefined };
        const run2: MutationFunction<T> = (args?: unknown) => run({ variables: args });
        return [run2, q2] as [typeof run2, typeof q2];
    };
}

type LazyQueryExecFunction<T> = (args?: unknown) => Promise<FetchResult<T>>;

export function lazyQueryFactory<T>(query: DocumentNode, name: string) {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    return (args?: unknown) => {
        const [run, q] = useLazyQuery(query, { variables: args, fetchPolicy: 'network-only', errorPolicy: 'all' });
        const q2 = { ...q, data: q.data ? (q.data[name] as T) : undefined };
        const run2: LazyQueryExecFunction<T> = (args?: unknown) => run({ variables: args });
        return [run2, q2] as [typeof run2, typeof q2];
    };
}
