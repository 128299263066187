import { gql } from '@apollo/client';
import { queryFactory } from '../../../../utils/gqlFactories';

const callHistory = gql`
    query callHistory($customer: Int!) {
        callHistory(customer: $customer) {
            id
            list_contact {
                customer {
                    firstname
                    lastname
                    phone
                    customer {
                        email
                    }
                }
                comment
                created_at
            }
            late_payment_list_contact {
                customer {
                    firstname
                    lastname
                    phone
                    customer {
                        email
                    }
                }
                comment
                created_at
            }
            ts_user {
                firstname
                lastname
            }
            call_state {
                name
            }
            talk_state {
                name
            }
        }
    }
`;

export interface AssignedContact {
    id: number;
    list_contact: {
        customer: {
            firstname: string;
            lastname: string;
            phone: string;
            customer: {
                email: string;
            };
        };
        comment: string;
        created_at: string;
    };
    late_payment_list_contact: {
        customer: {
            firstname: string;
            lastname: string;
            phone: string;
            customer: {
                email: string;
            };
        };
        comment: string;
        created_at: string;
    };
    ts_user: {
        firstname: string;
        lastname: string;
    };
    call_state: {
        name: string;
    };
    talk_state: {
        name: string;
    };
}

const useCallHistory = queryFactory<AssignedContact[]>(callHistory, 'callHistory');

export default useCallHistory;
