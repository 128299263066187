import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const workingHourQuery = gql`
    query workingHour($id: Int!) {
        workingHour(id: $id) {
            id
            user {
                id
            }
            date
            hours
        }
    }
`;

interface WorkingHour {
    id: number;
    user: { id: number };
    date: Date;
    hours: number;
}

const useWorkingHourQuery = queryFactory<WorkingHour>(workingHourQuery, 'workingHour');

export default useWorkingHourQuery;
