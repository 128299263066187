/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import { ListedProduct } from '../Products';

type ProductSelectionProps = {
    selected: ListedProduct[];
    setSelected: React.Dispatch<React.SetStateAction<ListedProduct[]>>;
    options: ListedProduct[];
};

const ProductSelection: React.FC<ProductSelectionProps> = (props) => {
    const lookForValue = () => {
        if (props.selected[0] && props.options.find((o) => o.priceId === props.selected[0].priceId)) {
            return props.selected[0];
        } else return null;
    };

    return (
        <Autocomplete
            value={lookForValue()}
            onChange={(event, newValue, reason) => {
                if (event.type === 'keydown' && (event as React.KeyboardEvent).key === 'Backspace' && reason === 'removeOption') {
                    return;
                }
                newValue ? props.setSelected([newValue]) : props.setSelected([]);
            }}
            renderTags={() => null}
            noOptionsText="Nincs ilyen termék rögzítve."
            renderOption={(props, option) => (
                <li {...props}>
                    <Box
                        sx={{
                            flexGrow: 1,
                        }}
                    >
                        <span>{`${option.gross} Ft, ${option.priceType}`}</span>
                    </Box>
                </li>
            )}
            options={props.options.slice(0).sort(function (a, b) {
                const x = a.name.toLowerCase();
                const y = b.name.toLowerCase();
                return x < y ? -1 : x > y ? 1 : 0;
            })}
            groupBy={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.priceId === value.priceId}
            getOptionLabel={(option) => `#${option.priceId} - ${option.name}: ${option.gross} Ft, ${option.priceType}`}
            renderInput={(params) => <TextField ref={params.InputProps.ref} inputProps={params.inputProps} autoFocus label="Termék *" placeholder="Termékre szűrés" css={inputsStyles} />}
            css={inputsStyles}
        />
    );
};

const inputsStyles = css`
    width: 100%;
`;

export default ProductSelection;
