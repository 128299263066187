import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const productQuery = gql`
    query product($id: Int!) {
        product(id: $id) {
            id
            name
            description
            stripe_id
            subscription
            categories {
                id
                name
            }
            ac_tags {
                id
                name
                description
            }
            package
            subproducts {
                id
                name
                description
            }
        }
    }
`;
interface SubProduct {
    id: number;
    name: string;
    description: string;
}

interface ProductCategory {
    id: number;
    name: string;
}

interface AcTag {
    id: number;
    name: string;
    description: string;
}

interface Product {
    id: number;
    name: string;
    description: string;
    stripe_id: string;
    subscription: boolean;
    categories: ProductCategory[];
    ac_tags: AcTag[];
    package: boolean;
    subproducts: SubProduct[];
}

const useProductQuery = queryFactory<Product>(productQuery, 'product');

export type { SubProduct };
export default useProductQuery;
