import { gql, OperationVariables } from '@apollo/client';
import { useQuery } from '@apollo/client';

const myPaymentRate = gql`
    query myPaymentRate($year: Int!, $month: Int!, $quarter: Int!) {
        monthly: myMonthlyOrderPaymentRate(year: $year, month: $month) {
            totalPrice
            totalPayed
        }

        quarterly: myQuarterlyOrderPaymentRate(year: $year, quarter: $quarter) {
            totalPrice
            totalPayed
        }
    }
`;

export interface OrderPaymentRate {
    monthly: {
        totalPrice: number;
        totalPayed: number;
    };
    quarterly: {
        totalPrice: number;
        totalPayed: number;
    };
}

const useMyPaymentRate = (args?: unknown) => {
    const res = useQuery<OrderPaymentRate>(myPaymentRate, { variables: args as OperationVariables | undefined, fetchPolicy: 'network-only', errorPolicy: 'all' });
    return res;
};

export default useMyPaymentRate;
