/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paper, Button, Dialog, DialogTitle, Typography } from '@mui/material';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import DataGridLocalization from '../../utils/DataGridLocalization';
import { Section } from '../../components/Section';
import useBlockedContacts, { BlockedContact } from './gql/blockedContacts';
import useDeleteBlockedContact from './gql/deleteBlockedContact';
import useErrorHandler from '../../utils/userErrorHandler';
import { useSnackbar } from 'notistack';

const BlockedContactsPage: React.FC = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const blockedContacts = useBlockedContacts();
    const [deleteBlockedContact, deletedBlockedContact] = useDeleteBlockedContact();
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [clickedContact, setClickedContact] = useState({ id: 0, name: '' });
    useErrorHandler(blockedContacts.error, deletedBlockedContact.error);

    useEffect(() => {
        if (deletedBlockedContact.data) {
            setIsDeleteDialogOpen(false);
            enqueueSnackbar('Az ügyfél blokkolása sikeresen feloldva!', { variant: 'success' });
            blockedContacts.refetch();
        }
    }, [deletedBlockedContact.data]);

    const unblockContact = () => {
        if (clickedContact.id !== 0) deleteBlockedContact({ id: clickedContact.id });
    };

    const openDialog = (id: number, name: string) => {
        setClickedContact({ id: id, name: name });
        setIsDeleteDialogOpen(true);
    };

    const makeRows = (blockedContacts: BlockedContact[]) => {
        const rows: GridRowsProp = blockedContacts.map((contact) => ({
            id: contact.id,
            contactID: contact.id,
            name: `${contact.customer.details[contact.customer.details.length - 1].lastname} ${contact.customer.details[contact.customer.details.length - 1].firstname}`,
            reason: contact.reason.name,
            comment: contact.comment,
            blocker: `${contact.blocker.lastname} ${contact.blocker.firstname}`,
            date: contact.created_at,
            customerID: contact.customer.id,
        }));

        return rows;
    };

    const makeCols = () => {
        const columns: GridColDef[] = [
            { field: 'contactID', headerName: 'ID', width: 100 },
            { field: 'name', headerName: 'Név', width: 200 },
            { field: 'reason', headerName: 'Indok', width: 250 },
            { field: 'comment', headerName: 'Megjegyzés', width: 250 },
            { field: 'blocker', headerName: 'Blokkoló', width: 200 },
            { field: 'date', headerName: 'Blokkolva', width: 200 },
            {
                field: 'actions',
                headerName: 'Műveletek',
                width: 300,
                renderCell: function renderActions(cellValues) {
                    return (
                        <div css={buttonsContainerStyles}>
                            <Button variant="contained" color="success" onClick={() => openDialog(cellValues.row.contactID, cellValues.row.name)}>
                                Feloldás
                            </Button>
                            <Button variant="contained" color="primary" onClick={() => navigate(`/customers/${cellValues.row.customerID}`)}>
                                Megtekintés
                            </Button>
                        </div>
                    );
                },
            },
        ];

        return columns;
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title="Listák">
                <div style={{ height: 600, width: '100%' }}>
                    <DataGrid
                        componentsProps={{
                            pagination: {
                                labelRowsPerPage: 'Oldalanként',
                                labelDisplayedRows: ({ from, to, count }: { from: number; to: number; count: number }) => `${from}–${to} / ${count !== -1 ? count : `more than ${to}`}`,
                            },
                        }}
                        localeText={DataGridLocalization}
                        rows={makeRows(blockedContacts.data || [])}
                        columns={makeCols()}
                        style={{ borderRadius: 0, border: 'none' }}
                    />
                </div>

                <Dialog onClose={() => setIsDeleteDialogOpen(false)} open={isDeleteDialogOpen}>
                    <DialogTitle style={{ backgroundColor: '#0288d1', color: 'white', minWidth: 370 }}>Blokkolás feloldása</DialogTitle>
                    <div css={dialogContentContainerStyles}>
                        <Typography variant="body1" component="div">
                            Biztosan feloldod az ügyfél blokkolását?
                        </Typography>
                        <Typography variant="h6" component="div" align="center">
                            #{clickedContact.id} - {clickedContact.name}
                        </Typography>
                        <div css={dialogButtonsContainerStyle}>
                            <Button variant="contained" color="error" size="large" onClick={() => setIsDeleteDialogOpen(false)}>
                                Mégse
                            </Button>
                            <Button variant="contained" color="success" size="large" onClick={unblockContact}>
                                Feloldás
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const buttonsContainerStyles = css`
    & > * + * {
        margin-left: 10px !important;
    }
`;

const dialogContentContainerStyles = css`
    padding: 24px;

    & > * + * {
        margin-top: 25px !important;
    }
`;

const dialogButtonsContainerStyle = css`
    display: flex;
    justify-content: center;
    margin-top: 30px;

    & > * + * {
        margin-left: 20px !important;
    }
`;

export default BlockedContactsPage;
