import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const dayClosings = gql`
    query {
        dayClosings {
            id
            day
            time
            closed_by {
                firstname
                lastname
            }
        }
    }
`;

export interface DayClosing {
    id: number;
    day: string;
    time: string;
    closed_by: {
        firstname: string;
        lastname: string;
    };
}

const useDayClosings = queryFactory<DayClosing[]>(dayClosings, 'dayClosings');

export default useDayClosings;
