import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const userQuery = gql`
    query user($id: Int!) {
        user(id: $id) {
            id
            username
            firstname
            lastname
            middlename
            email
            active
            permission {
                id
                name
            }
        }
    }
`;

interface User {
    id: number;
    username: string;
    firstname: string;
    lastname: string;
    middlename: string;
    email: string;
    active: boolean;
    permission: {
        id: number;
        name: string;
    };
}

const useUserQuery = queryFactory<User>(userQuery, 'user');

export default useUserQuery;
