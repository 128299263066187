import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const placeholdersQuery = gql`
    query {
        placeholders {
            id
            name
        }
    }
`;

interface Placeholder {
    id: number;
    name: string;
}

const usePlaceholdersQuery = queryFactory<Placeholder[]>(placeholdersQuery, 'placeholders');

export type { Placeholder };
export default usePlaceholdersQuery;
