import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const updateOrderedProductInstallmentsMutation = gql`
    mutation updateOrderedProductInstallments($data: OrderedProductInstallmentsInput!) {
        updateOrderedProductInstallments(data: $data) {
            id
        }
    }
`;

interface UpdatedOrderedProductInstallments {
    id: number;
}

const useUpdateOrderedProductInstallmentsMutation = mutationFactory<UpdatedOrderedProductInstallments>(updateOrderedProductInstallmentsMutation, 'updateOrderedProductInstallments');

export default useUpdateOrderedProductInstallmentsMutation;
