/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { User } from '../gql/users';

type ProductPriceSelectionProps = {
    selection?: number | null;
    setSelection: (orderId: number, sellerId: number | null) => void;
    options: User[];
    orderId: number;
};

const SellerSelection: React.FC<ProductPriceSelectionProps> = (props) => {
    const [value, setValue] = useState<User | null>(null);

    useEffect(() => {
        handleValue();
    }, [props.selection]);

    const handleChange = (selectedValue: User | null): void => {
        if (selectedValue) {
            setValue(selectedValue);
            props.setSelection(props.orderId, selectedValue.id);
        } else {
            setValue(null);
        }
    };

    const handleValue = () => {
        const selected = props.options?.find((price) => price.id === props.selection);
        if (selected) {
            setValue(selected);
        } else {
            setValue(null);
        }
    };

    return (
        <Autocomplete
            options={props.options ? props.options : []}
            value={value}
            getOptionDisabled={(option) => option.active}
            renderInput={(params) => <TextField {...params} placeholder="Értékesítők" />}
            getOptionLabel={(option) => `${option.lastname} ${option.firstname}${option.middlename ? ' ' : ''}${option.middlename ? option.middlename : ''}`}
            onChange={(_event, value) => {
                handleChange(value);
            }}
            css={inputsStyles}
        />
    );
};

const inputsStyles = css`
    width: 100%;
`;

export default SellerSelection;
