import { gql } from '@apollo/client';
import { queryFactory } from '../../../../utils/gqlFactories';

const orderHistory = gql`
    query orderHistory($customer: Int!) {
        orderHistory(customer: $customer) {
            id
            seller {
                firstname
                lastname
            }
            ordered_products {
                product {
                    id
                    name
                }
                price {
                    gross
                }
                installments {
                    payment_date
                    due_date
                    converted_to {
                        id
                    }
                    converted_from {
                        id
                    }
                }
                created_at
            }
        }
    }
`;

export interface Order {
    id: number;
    seller: {
        firstname: string;
        lastname: string;
    };
    ordered_products: {
        product: {
            id: number;
            name: string;
        };
        price: {
            gross: number;
        };
        installments: {
            due_date: string;
            payment_date: string;
            converted_to: {
                id: number;
            };
            converted_from: {
                id: number;
            };
        }[];
        created_at: string;
    }[];
}

const useOrderHistory = queryFactory<Order[]>(orderHistory, 'orderHistory');

export default useOrderHistory;
