/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Paper, TableContainer, Table, TableBody, TableRow, TableCell, TableHead } from '@mui/material';
import { Section } from '../../components/Section';
import useCallStates from './gql/callStates';
import useTalkStates from './gql/talkStates';
import useErrorHandler from '../../utils/userErrorHandler';

const ListContactStatesPage: React.FC = () => {
    const callStates = useCallStates();
    const talkStates = useTalkStates();
    useErrorHandler(callStates.error, talkStates.error);

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title="Hívás állapotok">
                <TableContainer>
                    <Table aria-label="table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: 50 }}>ID</TableCell>
                                <TableCell style={{ width: 250 }}>Név</TableCell>
                                <TableCell style={{ minWidth: 400 }}>Leírás</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {callStates.data?.map((callState) => (
                                <TableRow css={trStyle} key={callState.id}>
                                    <TableCell>#{callState.id}</TableCell>
                                    <TableCell>{callState.name}</TableCell>
                                    <TableCell>{callState.description}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Section>

            <Section title="Beszélgetés állapotok">
                <TableContainer>
                    <Table aria-label="table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: 50 }}>ID</TableCell>
                                <TableCell style={{ width: 250 }}>Név</TableCell>
                                <TableCell style={{ minWidth: 400 }}>Leírás</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {talkStates.data?.map((talkState) => (
                                <TableRow css={trStyle} key={talkState.id}>
                                    <TableCell>#{talkState.id}</TableCell>
                                    <TableCell>{talkState.name}</TableCell>
                                    <TableCell>{talkState.description}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const trStyle = css`
    &: nth-of-type(odd) {
        background-color: #e3f2fd;
    }

    &:last-child td,
    &:last-child th {
        border: 0;
    }
`;

export default ListContactStatesPage;
