/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import { CustomerDetails } from '../gql/customerDetails';

type CustomerFilterProps = {
    options: CustomerDetails[];
    selected: CustomerDetails[];
    setSelected: React.Dispatch<React.SetStateAction<CustomerDetails[]>>;
    search: string;
    setSearch: React.Dispatch<React.SetStateAction<string>>;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const TestFilter: React.FC<CustomerFilterProps> = (props) => {
    const lookForValue = () => {
        if (props.selected[0] && props.options.find((o) => o.id === props.selected[0].id)) {
            return props.selected[0];
        } else return null;
    };

    return (
        <Autocomplete
            value={lookForValue()}
            open={props.open}
            onOpen={() => {
                props.options.length > 0 && props.setOpen(true);
            }}
            onClose={() => {
                props.setOpen(false);
            }}
            onChange={(event, newValue, reason) => {
                if (event.type === 'keydown' && (event as React.KeyboardEvent).key === 'Backspace' && reason === 'removeOption') {
                    return;
                }
                newValue ? props.setSelected([newValue]) : props.setSelected([]);
            }}
            renderTags={() => null}
            noOptionsText="Nincs ilyen vevő rögzítve."
            renderOption={(props, option) => (
                <li {...props}>
                    <Box
                        sx={{
                            flexGrow: 1,
                        }}
                    >
                        <span>{`${option.lastname} ${option.firstname}${option.phone ? ' - ' + option.phone : ''}`}</span>
                    </Box>
                </li>
            )}
            options={props.options.slice(0).sort(function (a, b) {
                const x = a.customer.email.toLowerCase();
                const y = b.customer.email.toLowerCase();
                return x < y ? -1 : x > y ? 1 : 0;
            })}
            groupBy={(option) => option.customer.email}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => `${option.customer.email}: ${option.lastname} ${option.firstname}${option.phone ? ' - ' + option.phone : ''} (#${option.id})`}
            renderInput={(params) => (
                <TextField
                    ref={params.InputProps.ref}
                    inputProps={params.inputProps}
                    autoFocus
                    label="Vevőre keresés email alapján *"
                    placeholder="Vevőre keresés email alapján"
                    value={props.search}
                    onChange={(e) => props.setSearch(e.target.value)}
                    css={inputsStyles}
                />
            )}
            css={inputsStyles}
        />
    );
};

const inputsStyles = css`
    width: 100%;
`;

export default TestFilter;
