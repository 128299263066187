import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const myLatePaymentLists = gql`
    query myLatePaymentLists($lastNDays: Int!) {
        myLatePaymentLists(lastNDays: $lastNDays) {
            id
            created_at
        }
    }
`;

const useMyLatePaymentLists = queryFactory<{ id: number; created_at: string }[]>(myLatePaymentLists, 'myLatePaymentLists');

export default useMyLatePaymentLists;
