import { gql } from '@apollo/client';
import { lazyQueryFactory } from '../../../utils/gqlFactories';

const expectedOrders = gql`
    query expectedOrders($fulfilled: Boolean) {
        expectedOrders(fulfilled: $fulfilled) {
            id
            seller {
                id
                firstname
                lastname
                username
            }
            customer {
                id
                firstname
                lastname
                phone
                customer {
                    email
                }
            }
            product {
                id
                name
            }
            fulfilled {
                id
            }
            created_at
        }
    }
`;

interface Product {
    id: number;
    name: string;
}

interface Customer {
    id: number;
    firstname: string;
    lastname: string;
    phone: string;
    customer: { email: string };
}

interface User {
    id: number;
    firstname: string;
    lastname: string;
    username: string;
}

interface ExpectedOrder {
    id: number;
    seller: User;
    customer: Customer;
    product: Product;
    fulfilled: { id: number };
    created_at: string;
}

const useExpectedOrdersQuery = lazyQueryFactory<ExpectedOrder[]>(expectedOrders, 'expectedOrders');

export default useExpectedOrdersQuery;
