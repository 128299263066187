/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paper, Button, Typography, Table, TableContainer, TableHead, TableBody, TableCell, TableRow, Dialog, DialogTitle } from '@mui/material';
import { Section } from '../../components/Section';
import useEmailAccounts, { EmailAccount } from './gql/emailAccounts';
import useDeleteEmailAccount from './gql/deleteEmailAccount';
import useErrorHandler from '../../utils/userErrorHandler';
import { useSnackbar } from 'notistack';

const EmailAccountsPage: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const emailAccounts = useEmailAccounts();
    const [deleteEmailAccount, deletedEmailAccount] = useDeleteEmailAccount();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [deletingAccount, setDeletingAccount] = useState<EmailAccount>();
    useErrorHandler(emailAccounts.error, deletedEmailAccount.error);

    const handleDeleteClick = (account: EmailAccount) => {
        setIsDialogOpen(true);
        setDeletingAccount(account);
    };

    const handleDelete = () => {
        if (deletingAccount) {
            deleteEmailAccount({ id: deletingAccount.id });
            emailAccounts.refetch();
        }
    };

    useEffect(() => {
        setIsDialogOpen(false);
        if (deletedEmailAccount.data) enqueueSnackbar('Az e-mail fiók sikeresen törölve!', { variant: 'success' });
    }, [deletedEmailAccount.data]);

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title="E-mail fiókok">
                <TableContainer>
                    <Table aria-label="table" style={{ minWidth: 1310 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">ID</TableCell>
                                <TableCell align="center">Felhasználónév</TableCell>
                                <TableCell align="center">Név</TableCell>
                                <TableCell align="center">Host</TableCell>
                                <TableCell align="center">Port</TableCell>
                                <TableCell align="center">TLS kapcsolat</TableCell>
                                <TableCell align="center">Műveletek</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {emailAccounts.data &&
                                emailAccounts.data.map((account) => (
                                    <TableRow css={trStyle} key={account.id}>
                                        <TableCell align="center">{account.id}</TableCell>
                                        <TableCell align="center">{account.username}</TableCell>
                                        <TableCell align="center">{account.displayed_name}</TableCell>
                                        <TableCell align="center">{account.host}</TableCell>
                                        <TableCell align="center">{account.port}</TableCell>
                                        <TableCell align="center">{account.secure ? 'Igen' : 'Nem'}</TableCell>
                                        <TableCell align="center" css={actionsColStyle}>
                                            <Button variant="contained" color="error" onClick={() => handleDeleteClick(account)}>
                                                Törlés
                                            </Button>
                                            <Button variant="contained" color="primary" onClick={() => navigate(`/emails/accounts/${account.id}/edit`)}>
                                                Szerkesztés
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button css={createNewButtonStyles} variant="contained" color="success" onClick={() => navigate('/emails/accounts/create')}>
                    Új hozzáadása
                </Button>

                <Dialog onClose={() => setIsDialogOpen(false)} open={isDialogOpen}>
                    <DialogTitle style={{ backgroundColor: '#0288d1', color: 'white', minWidth: 370 }}>E-mail fiók törlése</DialogTitle>
                    <div css={dialogContentContainerStyles}>
                        <Typography variant="body1" component="div">
                            Biztosan törlöd az e-mail fiókot?
                        </Typography>
                        <Typography variant="h6" component="div" align="center">
                            #{deletingAccount?.id} - {deletingAccount?.displayed_name}
                        </Typography>
                        <div css={dialogButtonsContainerStyle}>
                            <Button variant="contained" color="primary" size="large" onClick={() => setIsDialogOpen(false)}>
                                Mégse
                            </Button>
                            <Button variant="contained" color="error" size="large" onClick={handleDelete}>
                                Törlés
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const trStyle = css`
    &: nth-of-type(odd) {
        background-color: #e3f2fd;
    }

    &:last-child td,
    &:last-child th {
        border: 0;
    }
`;

const actionsColStyle = css`
    & > * + * {
        margin-left: 20px !important;
    }
`;

const createNewButtonStyles = css`
    text-decoration: none;
    margin-left: auto;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const dialogContentContainerStyles = css`
    padding: 24px;
`;

const dialogButtonsContainerStyle = css`
    display: flex;
    justify-content: center;
    margin-top: 30px;

    & > * + * {
        margin-left: 20px !important;
    }
`;

export default EmailAccountsPage;
