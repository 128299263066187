import React, { createContext, useEffect, useReducer, useState } from 'react';
import listReducer, { ListAction } from '../reducers/listReducer';

type TypeMap = {
    '1': string;
    '2': string;
    '3': string;
    '4': string;
    '5': string;
    '6': string;
    '7': string;
};

export type FilterType = {
    id: number;
    typeID: number;
    valueID: string;
    negation: boolean;
    from: Date;
    to: Date;
};

export type BlockType = {
    id: number;
    filters: FilterType[];
    operators: OperatorType[];
    content: FilterContentType[];
};

export type OperatorType = {
    id: number;
    name: 'ÉS' | 'VAGY';
};

export type FilterContentType = { type: 'operator'; content: OperatorType } | { type: 'filter'; content: FilterType };
type ContentType = { type: 'operator'; content: OperatorType } | { type: 'block'; content: BlockType };

export type ListContextType = {
    content: ContentType[];
    blocks: BlockType[];
    operators: OperatorType[];
    typeMap: TypeMap;
    dispatch: React.Dispatch<ListAction>;
    addBlock: (block: BlockType) => void;
    addNewBlock: () => void;
    removeBlock: (blockID: number) => void;
    toggleOperator: (operatorID: number) => void;
};

export const ListContext = createContext<ListContextType | null>(null);

type ListProviderProps = {
    children: React.ReactNode;
};

export function ListProvider({ children }: ListProviderProps): JSX.Element {
    const [blocks, dispatch] = useReducer(listReducer, []);
    const [operators, setOperators] = useState<OperatorType[]>([]);
    const [content, setContent] = useState<ContentType[]>([]);
    const typeMap = { '1': 'list', '2': 'email', '3': 'email', '4': 'sms', '5': 'tag', '6': 'tag', '7': 'product' };

    useEffect(() => {
        const newContent: ContentType[] = [];

        blocks.map((block, idx) => {
            const newFilterContent: FilterContentType[] = [];
            block.filters.map((filter, idx) => {
                newFilterContent.push({ type: 'filter', content: filter });
                if (block.operators[idx]) newFilterContent.push({ type: 'operator', content: block.operators[idx] });
            });

            newContent.push({ type: 'block', content: { ...block, content: newFilterContent } });
            if (operators[idx]) newContent.push({ type: 'operator', content: operators[idx] });
        });

        setContent(newContent);
    }, [blocks, operators]);

    const addBlock = (block: BlockType) => {
        dispatch({ type: 'ADD_BLOCK', payload: block });
        if (blocks.length > 0) setOperators([...operators, { id: Math.floor(Math.random() * 100000000), name: 'ÉS' }]);
    };

    const addNewBlock = () => {
        dispatch({ type: 'ADD_NEW_BLOCK' });
        if (blocks.length > 0) setOperators([...operators, { id: Math.floor(Math.random() * 100000000), name: 'ÉS' }]);
    };

    const removeBlock = (blockID: number) => {
        dispatch({ type: 'REMOVE_BLOCK', payload: blockID });
        const blockToUpdate = blocks.find((block) => block.id === blockID);
        let index = 0;
        if (blockToUpdate) index = blocks.indexOf(blockToUpdate);

        if (index === 0 || blocks.length < 3) {
            const newOperators: OperatorType[] = operators;
            newOperators.shift();
            setOperators(newOperators);
        } else {
            console.log(index, blocks.length);
            const newOperators: OperatorType[] = operators;
            newOperators.splice(index - 1, 1);
            setOperators(newOperators);
        }
    };

    const toggleOperator = (operatorID: number) => {
        const operatorToToggle = operators.find((operator) => operator.id === operatorID);
        let toggledOperator: OperatorType;
        if (operatorToToggle) toggledOperator = { ...operatorToToggle, name: operatorToToggle?.name === 'ÉS' ? 'VAGY' : 'ÉS' };
        setOperators(operators.map((operator) => (operator.id === operatorID ? toggledOperator : operator)));
    };

    return <ListContext.Provider value={{ content, blocks, operators, typeMap, dispatch, addBlock, addNewBlock, removeBlock, toggleOperator }}>{children}</ListContext.Provider>;
}
