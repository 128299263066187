/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import useErrorHandler from '../../utils/userErrorHandler';

import { Paper, Button } from '@mui/material';
import { Section } from '../../components/Section';
import ProductPriceSection from './ProductPriceSection/ProductPriceSection';
import InstallmentsSection from './InstallmentsSection/InstallmentsSection';

import useOrderedProductQuery, { OrderedProduct } from './gql/orderedProduct';
import useUpdateOrderedProductInstallmentsMutation from './gql/updateOrderedProductInstallments';

interface UpdatedOrderedProductInstallments {
    orderedProduct: number;
    newPrice?: {
        gross: number;
        net: number;
    };
    price?: number;
    installments: {
        id?: number;
        gross: number;
        payment_method: number;
        due_date: string;
        payment_date?: string | null;
        converted_to?: number | null;
    }[];
}

const InstallmentsEditPage: React.FC = () => {
    const params = useParams();
    const orderedProductId = params.id as string;
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const orderedProductDataResp = useOrderedProductQuery({ id: parseInt(orderedProductId) });
    const [updateOrderedProductInstallments, updateOrderedProductInstallmentsResp] = useUpdateOrderedProductInstallmentsMutation();

    useErrorHandler(orderedProductDataResp.error, updateOrderedProductInstallmentsResp.error);

    const [fullProductPrice, setFullProductPrice] = useState<number | null>(null);
    const [oderedProducts, setOrderedProducts] = useState<OrderedProduct>();
    const [dataToSend, setDataToSend] = useState<UpdatedOrderedProductInstallments>({
        orderedProduct: parseInt(orderedProductId),
        installments: [],
    });

    useEffect(() => {
        if (updateOrderedProductInstallmentsResp.data) navigate(`/orders/${orderedProductDataResp.data?.order.id}`);
    }, [updateOrderedProductInstallmentsResp.data]);

    useEffect(() => {
        orderedProductDataResp.data && setOrderedProducts(orderedProductDataResp.data);
        if (orderedProductDataResp.data) {
            setDataToSend((prevState) => {
                if (orderedProductDataResp.data) {
                    return {
                        ...prevState,
                        installments: orderedProductDataResp.data.installments.map((installment) => {
                            return {
                                id: installment.id,
                                gross: installment.gross,
                                payment_method: installment.payment_method.id,
                                due_date: installment.due_date,
                                payment_date: installment.payment_date ? installment.payment_date : null,
                                converted_to: installment.converted_to?.id ? installment.converted_to.id : null,
                            };
                        }),
                    };
                } else {
                    return prevState;
                }
            });
        }
    }, [orderedProductDataResp.data]);

    const handleSave = async () => {
        const sumOfInstallments = dataToSend.installments.reduce((prevValue, currentInstalment) => {
            return prevValue + currentInstalment.gross;
        }, 0);

        if (sumOfInstallments === fullProductPrice) {
            await updateOrderedProductInstallments({ data: dataToSend });
        } else {
            dataToSend.installments.length > 0 && enqueueSnackbar('A részletek árainak összegének meg kell egyeznie a teljes termék árral.', { variant: 'warning' });
        }
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`#${orderedProductId} rendelés: ${oderedProducts?.product.name} termék fizetési részleteinek szerkesztése`}>
                <div css={sectionStyles}>
                    {oderedProducts && <ProductPriceSection data={oderedProducts} modifyData={setDataToSend} setFullPrice={setFullProductPrice} />}
                    {oderedProducts && <InstallmentsSection data={oderedProducts} modifyData={setDataToSend} />}
                    <Button variant="contained" color="success" css={buttonContainerStyles} onClick={handleSave}>
                        Összes módosítás véglegesítése
                    </Button>
                </div>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
`;

const buttonContainerStyles = css`
    text-decoration: none;
    margin-left: auto;
    margin-top: 20px;
    margin-bottom: 20px;
`;

export type { UpdatedOrderedProductInstallments };
export default InstallmentsEditPage;
