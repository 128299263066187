import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const permissionLevelsQuery = gql`
    query {
        permissionLevels {
            id
            name
        }
    }
`;

interface Permission {
    id: number;
    name: string;
}

const usePermissionLevelsQuery = queryFactory<Permission[]>(permissionLevelsQuery, 'permissionLevels');

export type { Permission };
export default usePermissionLevelsQuery;
