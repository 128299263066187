/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Paper, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from '@mui/material';
import List from './List/List';
import LatePaymentList from './LatePaymentList/LatePaymentList';
import SalesPopupList from './SalesPopupList/SalesPopupList';
import StuckOrderList from './StuckOrderList/StuckOrderList';
import UpsellList from './UpsellList/UpsellList';
import useMyLists from './gql/myLists';
import useMyLatePaymentLists from './gql/myLatePaymentLists';
import useMySalesPopupLists from './gql/mySalesPopupLists';
import useMyUpsellLists from './gql/myUpsellLists';
import useMyStuckOrderLists from './gql/myStuckOrderLists';
import useMyMixedContacts, { Contact } from './MixedList/gql/myMixedContacts';
import useErrorHandler from '../../utils/userErrorHandler';
import { Section } from '../../components/Section';
import MixedList from './MixedList/MixedList';

const MyListsPage: React.FC = () => {
    const [days, setDays] = useState(5);
    const [latePaymentDays, setLatePaymentDays] = useState(5);
    const [salesDays, setSalesDays] = useState(5);
    const [upsellDays, setUpsellDays] = useState(5);
    const [mixedDays, setMixedDays] = useState(5);
    const [stuckDays, setStuckDays] = useState(5);
    const myLists = useMyLists({ lastNDays: days });
    const myLatePaymentLists = useMyLatePaymentLists({ lastNDays: latePaymentDays });
    const mySalesPopupLists = useMySalesPopupLists({ lastNDays: salesDays });
    const myUpsellLists = useMyUpsellLists({ lastNDays: upsellDays });
    const myStuckOrderLists = useMyStuckOrderLists({ lastNDays: stuckDays });
    const myMixedContacts = useMyMixedContacts(mixedDays);
    const [showLists, setShowLists] = useState(true);
    const [showLateLists, setShowLateLists] = useState(true);
    const [showPopupLists, setShowPopupLists] = useState(true);
    const [showUpsellLists, setShowUpsellLists] = useState(true);
    const [showStuckLists, setShowStuckLists] = useState(true);
    const [showMixedList, setShowMixedList] = useState(false);
    useErrorHandler(myLists.error, myLatePaymentLists.error, mySalesPopupLists.error, myUpsellLists.error, myStuckOrderLists.error, myMixedContacts.error);

    useEffect(() => {
        if (showMixedList) {
            setShowLists(false);
            setShowLateLists(false);
            setShowPopupLists(false);
            setShowUpsellLists(false);
            setShowStuckLists(false);
        }
    }, [showMixedList]);

    useEffect(() => {
        if (showLists || showLateLists || showPopupLists || showUpsellLists || showStuckLists) setShowMixedList(false);
    }, [showLists, showLateLists, showPopupLists, showUpsellLists, showStuckLists]);

    const refreshList = () => {
        myLists.refetch();
    };

    const refreshLatePaymentList = () => {
        myLatePaymentLists.refetch();
    };

    const refreshSalesPopupList = () => {
        mySalesPopupLists.refetch();
    };

    const refreshUpsellList = () => {
        myUpsellLists.refetch();
    };

    const refreshStuckOrderList = () => {
        myStuckOrderLists.refetch();
    };

    const refreshMixedList = () => {
        console.log('refreshing mixed');
        myMixedContacts.refetch(mixedDays);
    };

    const getContactsForMixedList = () => {
        console.log('getting data');
        if (myMixedContacts.data) {
            const contactsList: Contact[] = [];

            myMixedContacts.data.map((contact) => {
                if (contact.listContact) contactsList.push({ ...contact.listContact, type: 'list' });
                if (contact.latePaymentListContact) contactsList.push({ ...contact.latePaymentListContact, type: 'late' });
                if (contact.salesPopupListContact) contactsList.push({ ...contact.salesPopupListContact, type: 'sales' });
                if (contact.upsellListContact) contactsList.push({ ...contact.upsellListContact, type: 'upsell' });
                if (contact.stuckOrderListContact) contactsList.push({ ...contact.stuckOrderListContact, type: 'stuck' });
            });

            return contactsList;
        }
        return [];
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <div>
                <FormControlLabel control={<Checkbox checked={showLists} onChange={() => setShowLists(!showLists)} />} label="Listák" />
                <FormControlLabel control={<Checkbox checked={showLateLists} onChange={() => setShowLateLists(!showLateLists)} />} label="Késett fizetési listák" />
                <FormControlLabel control={<Checkbox checked={showPopupLists} onChange={() => setShowPopupLists(!showPopupLists)} />} label="Sales popup listák" />
                <FormControlLabel control={<Checkbox checked={showUpsellLists} onChange={() => setShowUpsellLists(!showUpsellLists)} />} label="Upsell listák" />
                <FormControlLabel control={<Checkbox checked={showStuckLists} onChange={() => setShowStuckLists(!showStuckLists)} />} label="Stuck Order listák" />
                <FormControlLabel control={<Checkbox checked={showMixedList} onChange={() => setShowMixedList(!showMixedList)} />} label="Összesített lista nézet" />
            </div>

            {showMixedList && (
                <Section title="Összesített nézet">
                    <div css={sectionStyles}>
                        <FormControl style={{ maxWidth: 150 }}>
                            <InputLabel id="days-select">Időszak</InputLabel>
                            <Select label="Időszak" id="days-select" value={mixedDays} onChange={(e) => setMixedDays(Number(e.target.value))}>
                                <MenuItem value={1}>Utolsó 1 nap</MenuItem>
                                <MenuItem value={2}>Utolsó 2 nap</MenuItem>
                                <MenuItem value={3}>Utolsó 3 nap</MenuItem>
                                <MenuItem value={4}>Utolsó 4 nap</MenuItem>
                                <MenuItem value={5}>Utolsó 5 nap</MenuItem>
                                <MenuItem value={30}>Utolsó 30 nap</MenuItem>
                                <MenuItem value={60}>Utolsó 60 nap</MenuItem>
                            </Select>
                        </FormControl>
                        {myMixedContacts.data && <MixedList contacts={getContactsForMixedList()} refreshList={refreshMixedList} />}
                    </div>
                </Section>
            )}

            {showLists && (
                <Section title="Listák">
                    <div css={sectionStyles}>
                        <FormControl style={{ maxWidth: 150 }}>
                            <InputLabel id="days-select">Időszak</InputLabel>
                            <Select label="Időszak" id="days-select" value={days} onChange={(e) => setDays(Number(e.target.value))}>
                                <MenuItem value={1}>Utolsó 1 nap</MenuItem>
                                <MenuItem value={2}>Utolsó 2 nap</MenuItem>
                                <MenuItem value={3}>Utolsó 3 nap</MenuItem>
                                <MenuItem value={4}>Utolsó 4 nap</MenuItem>
                                <MenuItem value={5}>Utolsó 5 nap</MenuItem>
                                <MenuItem value={30}>Utolsó 30 nap</MenuItem>
                                <MenuItem value={60}>Utolsó 60 nap</MenuItem>
                            </Select>
                        </FormControl>
                        {myLists.data?.map((list) => (
                            <List id={list.id} name={list.name} key={list.id} refreshList={refreshList} />
                        ))}
                    </div>
                </Section>
            )}

            {showLateLists && (
                <Section title="Késett fizetési listák">
                    <div css={sectionStyles}>
                        <FormControl style={{ maxWidth: 150 }}>
                            <InputLabel id="late-days-select">Időszak</InputLabel>
                            <Select label="Időszak" id="late-days-select" value={latePaymentDays} onChange={(e) => setLatePaymentDays(Number(e.target.value))}>
                                <MenuItem value={1}>Utolsó 1 nap</MenuItem>
                                <MenuItem value={2}>Utolsó 2 nap</MenuItem>
                                <MenuItem value={3}>Utolsó 3 nap</MenuItem>
                                <MenuItem value={4}>Utolsó 4 nap</MenuItem>
                                <MenuItem value={5}>Utolsó 5 nap</MenuItem>
                                <MenuItem value={30}>Utolsó 30 nap</MenuItem>
                                <MenuItem value={60}>Utolsó 60 nap</MenuItem>
                            </Select>
                        </FormControl>
                        {myLatePaymentLists.data?.map((list) => (
                            <LatePaymentList id={list.id} name={`Késett fizetések - ${list.created_at}`} key={list.id} refreshList={refreshLatePaymentList} />
                        ))}
                    </div>
                </Section>
            )}

            {showPopupLists && (
                <Section title="Sales popup listák">
                    <div css={sectionStyles}>
                        <FormControl style={{ maxWidth: 150 }}>
                            <InputLabel id="sales-select">Időszak</InputLabel>
                            <Select label="Időszak" id="sales-select" value={salesDays} onChange={(e) => setSalesDays(Number(e.target.value))}>
                                <MenuItem value={1}>Utolsó 1 nap</MenuItem>
                                <MenuItem value={2}>Utolsó 2 nap</MenuItem>
                                <MenuItem value={3}>Utolsó 3 nap</MenuItem>
                                <MenuItem value={4}>Utolsó 4 nap</MenuItem>
                                <MenuItem value={5}>Utolsó 5 nap</MenuItem>
                                <MenuItem value={30}>Utolsó 30 nap</MenuItem>
                                <MenuItem value={60}>Utolsó 60 nap</MenuItem>
                            </Select>
                        </FormControl>
                        {mySalesPopupLists.data?.map((list) => (
                            <SalesPopupList id={list.id} name={`Sales popup - ${list.created_at}`} key={list.id} refreshList={refreshSalesPopupList} />
                        ))}
                    </div>
                </Section>
            )}

            {showUpsellLists && (
                <Section title="Upsell listák">
                    <div css={sectionStyles}>
                        <FormControl style={{ maxWidth: 150 }}>
                            <InputLabel id="sales-select">Időszak</InputLabel>
                            <Select label="Időszak" id="sales-select" value={upsellDays} onChange={(e) => setUpsellDays(Number(e.target.value))}>
                                <MenuItem value={1}>Utolsó 1 nap</MenuItem>
                                <MenuItem value={2}>Utolsó 2 nap</MenuItem>
                                <MenuItem value={3}>Utolsó 3 nap</MenuItem>
                                <MenuItem value={4}>Utolsó 4 nap</MenuItem>
                                <MenuItem value={5}>Utolsó 5 nap</MenuItem>
                                <MenuItem value={30}>Utolsó 30 nap</MenuItem>
                                <MenuItem value={60}>Utolsó 60 nap</MenuItem>
                            </Select>
                        </FormControl>
                        {myUpsellLists.data?.map((list) => (
                            <UpsellList id={list.id} name={`Upsell - ${list.created_at}`} key={list.id} refreshList={refreshUpsellList} />
                        ))}
                    </div>
                </Section>
            )}

            {showStuckLists && (
                <Section title="Stuck Order listák">
                    <div css={sectionStyles}>
                        <FormControl style={{ maxWidth: 150 }}>
                            <InputLabel id="sales-select">Időszak</InputLabel>
                            <Select label="Időszak" id="sales-select" value={stuckDays} onChange={(e) => setStuckDays(Number(e.target.value))}>
                                <MenuItem value={1}>Utolsó 1 nap</MenuItem>
                                <MenuItem value={2}>Utolsó 2 nap</MenuItem>
                                <MenuItem value={3}>Utolsó 3 nap</MenuItem>
                                <MenuItem value={4}>Utolsó 4 nap</MenuItem>
                                <MenuItem value={5}>Utolsó 5 nap</MenuItem>
                                <MenuItem value={30}>Utolsó 30 nap</MenuItem>
                                <MenuItem value={60}>Utolsó 60 nap</MenuItem>
                            </Select>
                        </FormControl>
                        {myUpsellLists.data?.map((list) => (
                            <StuckOrderList id={list.id} name={`Stuck - ${list.created_at}`} key={list.id} refreshList={refreshStuckOrderList} />
                        ))}
                    </div>
                </Section>
            )}
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;

    & > * + * {
        margin-top: 20px !important;
    }
`;

export default MyListsPage;
