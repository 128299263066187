import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const offDayReasonsQuery = gql`
    query {
        offDayReasons {
            id
            name
        }
    }
`;

interface OffDayReason {
    id: number;
    name: string;
}

const useOffDayReasonsQuery = queryFactory<OffDayReason[]>(offDayReasonsQuery, 'offDayReasons');

export default useOffDayReasonsQuery;
