/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Paper, FormControl, InputLabel, Select, MenuItem, Button, TextField, SelectChangeEvent, OutlinedInput, Box, Chip, Autocomplete, Modal, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { v4 } from 'uuid';
import { Section } from '../../components/Section';
import useProductCategories from '../CategoriesPage/gql/productCategories';
import useErrorHandler from '../../utils/userErrorHandler';
import useAcTagsQuery, { Tag } from './gql/acTags';
import useCreateAcTagMutation from './gql/createAcTag';
import useCreateProductMutation from './gql/createProduct';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import useProductsQuery, { Product } from '../ProductsPage/gql/products';

interface NewData {
    name: string;
    description: string;
}

const ProductCreatePage: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const categories = useProductCategories();
    const tagsResp = useAcTagsQuery();
    const products = useProductsQuery({ package: false });
    const [createAcTag, newAcTagResp] = useCreateAcTagMutation();
    const [createProduct, newProductResp] = useCreateProductMutation();
    useErrorHandler(categories.error, tagsResp.error, newAcTagResp.error, newProductResp.error, products.error);

    const [name, setName] = useState<string>('');
    const [category, setCategory] = useState<number[]>([]);
    const [description, setDescription] = useState<string>('');
    const [tags, setTags] = useState<Tag[]>([]);
    const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
    const [newTagDescription, setNewTagDescription] = useState<string>('');
    const [tempTag, setTempTag] = useState<NewData>();
    const [modalOpen, setModalOpen] = useState(false);
    const [isSub, setIsSub] = useState(false);
    const [checked, setChecked] = useState(false);
    const [selProducts, setSelProducts] = useState<Product[]>([]);

    useEffect(() => {
        (async () => {
            if (tempTag?.description && tempTag.name) {
                await handleTagCreate(tempTag);
                setTempTag({
                    name: '',
                    description: '',
                });
                setNewTagDescription('');
                setModalOpen(false);
            }
        })();
    }, [tempTag]);

    useEffect(() => {
        if (newAcTagResp.data) {
            const newSelection: Tag[] = [...selectedTags];
            const palceohlderList: Tag[] = [...tags];

            newSelection.push(newAcTagResp.data);
            palceohlderList.push(newAcTagResp.data);

            setSelectedTags(newSelection);
            setTags(palceohlderList);
        }
    }, [newAcTagResp.data]);

    useEffect(() => {
        if (tagsResp.data) {
            setTags(tagsResp.data);
        }
    }, [tagsResp.data]);

    useEffect(() => {
        if (checked) {
            setIsSub(false);
        }
    }, [checked]);

    const handleTagChange = (selectedValues: string[]): void => {
        const newSelections: Tag[] = [];

        for (const selectedTagName of selectedValues) {
            const tagToAddToSelections = tags.find((tag) => tag.name === selectedTagName);
            if (tagToAddToSelections) {
                newSelections.push(tagToAddToSelections);
            } else if (typeof selectedTagName === 'string') {
                setNewTagDescription('');
                setModalOpen(true);

                const newTagData: NewData = {
                    name: selectedTagName.toUpperCase(),
                    description: newTagDescription,
                };
                setTempTag(newTagData);
            }
        }

        setSelectedTags(newSelections);
    };

    const handleProductChange = (selectedValues: string[]): void => {
        const newSelections: Product[] = [];

        for (const selectedProductName of selectedValues) {
            const productToAddToSelections = products.data?.find((product) => product.name === selectedProductName);
            if (productToAddToSelections) {
                newSelections.push(productToAddToSelections);
            }
        }
        setSelProducts(newSelections);
    };

    const handleTagCreate = async (tag: NewData) => {
        return await createAcTag({ data: tag });
    };

    const handleModalSave = () => {
        if (tempTag) {
            setTempTag({
                name: tempTag?.name,
                description: newTagDescription,
            });
        }
    };

    const handleCategoryChange = (event: SelectChangeEvent<typeof category>) => {
        const { value } = event.target;
        setCategory(value as typeof category);
    };

    const handleSave = async () => {
        if (name && category.length !== 0) {
            await createProduct({ data: { name, description, subscription: isSub }, tags: selectedTags.map((tag) => tag.id), categories: category, packageProducts: selProducts.map((prod) => prod.id) });
            navigate('/products/list');
        } else {
            enqueueSnackbar('A csillaggal jelölt mezők kitöltése kötelező!', { variant: 'error' });
        }
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`Termék${checked ? 'csomag' : ''} létrehozása`}>
                <div css={sectionStyles}>
                    <div css={inputsContainerStyles}>
                        <TextField fullWidth id="name" label="Név" placeholder="A termék neve" value={name} onChange={(e) => setName(e.target.value)} css={inputsStyles} required />
                        <TextField id="description" label="Leírás" multiline rows={4} value={description} onChange={(e) => setDescription(e.target.value)} style={{ width: '100%' }} />
                        <FormControl css={chipSelectStyles}>
                            <InputLabel required id="category-chip-label">
                                Kategóriák
                            </InputLabel>
                            <Select
                                required
                                multiple
                                value={category}
                                onChange={handleCategoryChange}
                                input={<OutlinedInput id="select-multiple-chip" label="Kategóriák" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((catId: number) => (
                                            <Chip key={v4()} label={categories.data?.find((e) => e.id === catId)?.name} variant="filled" color="info" />
                                        ))}
                                    </Box>
                                )}
                            >
                                {categories.data?.map((cat) => (
                                    <MenuItem key={cat.id} value={cat.id}>
                                        {cat.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Autocomplete
                            multiple
                            options={tags.map((data: Tag) => {
                                return data.name;
                            })}
                            value={selectedTags.map((selData: Tag) => {
                                return selData.name;
                            })}
                            freeSolo
                            renderTags={(value: string[], getTagProps) => value.map((option: string, index: number) => <Chip variant="outlined" color="info" label={option} {...getTagProps({ index })} key={index} />)}
                            renderInput={(params) => <TextField {...params} label="Tagek" placeholder="Tagek" />}
                            onChange={(_event, value) => {
                                handleTagChange(value);
                            }}
                            css={inputsStyles}
                        />
                        <div css={checkboxStyles}>
                            <FormControlLabel control={<Checkbox checked={isSub} onChange={() => setIsSub(!isSub)} />} label={`Előfizetéses termék${checked ? ' - Csomag esetén nem elérhető' : ''}`} disabled={checked} />
                            <FormControlLabel control={<Checkbox checked={checked} onChange={() => setChecked(!checked)} />} label={'Termékcsomag'} />
                        </div>
                        {checked && products.data && (
                            <Autocomplete
                                multiple
                                options={products.data.map((data: Product) => {
                                    return data.name;
                                })}
                                value={selProducts.map((selData: Product) => {
                                    return selData.name;
                                })}
                                renderTags={(value: string[], getTagProps) => value.map((option: string, index: number) => <Chip variant="outlined" color="secondary" label={option} {...getTagProps({ index })} key={index} />)}
                                renderInput={(params) => <TextField {...params} label="Termékek" placeholder="Termékek" />}
                                onChange={(_event, value) => {
                                    handleProductChange(value);
                                }}
                                css={inputsStyles}
                            />
                        )}
                    </div>
                    <div css={buttonInputsContainerStyles}>
                        <Button size="large" variant="contained" color="success" onClick={handleSave}>
                            {`Termék${checked ? 'csomag' : ''} létrehozása`}
                        </Button>
                    </div>
                </div>
            </Section>
            <Modal open={modalOpen} onClose={() => setModalOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" style={{ position: 'absolute' }}>
                <Box css={boxStyles}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ marginBottom: '1rem' }}>
                        Add meg a tag leírását:
                    </Typography>
                    <TextField fullWidth id="subject" label="Leírás" placeholder="Az új tag leírása" value={newTagDescription} onChange={(e) => setNewTagDescription(e.target.value)} css={inputsStyles} />
                    <Button size="large" variant="contained" css={boxButtonStyles} onClick={handleModalSave}>
                        Létrehoz
                    </Button>
                </Box>
            </Modal>
        </Paper>
    );
};

const containerStyles = css`
    position: relative;
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;
    & > * + * {
        margin-top: 20px !important;
    }
`;

const inputsContainerStyles = css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    & > * + * {
        margin-top: 20px !important;
    }
`;

const inputsStyles = css`
    width: 100%;
`;

const buttonInputsContainerStyles = css`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    @media (max-width: 1200px) {
        justify-content: center;
        align-items: center;
    }
`;

const chipSelectStyles = css`
    width: 100%;
`;
const checkboxStyles = css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
`;

const boxStyles = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 2px solid #0288d1;
    boxshadow: 24;
    padding: 1rem;
`;

const boxButtonStyles = css`
    float: right;
    margin-top: 1rem;
`;

export default ProductCreatePage;
