/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useReducer, useState } from 'react';

import { useSnackbar } from 'notistack';
import useErrorHandler from '../../../utils/userErrorHandler';
import { v4 } from 'uuid';

import { Typography, Button, TextField, Modal, Box } from '@mui/material';

import { SubSection } from '../../../components/SubSection';
import ProductSelection from './ProductSelection/ProductSelection';
import useProductsQuery from '../gql/products';
import usePaymentMethodsQuery, { PaymentMethod } from '../../InstallmentsEditPage/gql/paymentMethods';
import useCurrenciesQuery, { Currency } from '../gql/currencies';
import MemorizedProduct from './MemorizedProduct/MemorizedProduct';
import { NewOrderArguments } from '../NewOrderPage';

type NewInstallment = {
    id: string;
    gross: string;
    due_date: Date | null;
    payment_method: {
        id: number;
        name: string;
    };
    currency?: number; // currency id
    currency_rate?: number; // átváltás mértéke float
    currency_gross?: number; // átváltva bruttóban
};

type ListedProduct = {
    renderId?: string;
    id: number;
    name: string;
    priceType: string;
    priceId?: number;
    net?: number;
    gross: number;
    installments: NewInstallment[];
};

type Action =
    | { type: 'addProduct'; product: ListedProduct }
    | { type: 'deleteProduct'; renderId: string | undefined }
    | { type: 'changeProductPrice'; renderId: string | undefined; net: number; gross: number }
    | {
          type: 'addInstallment';
          productRenderId: string;
          installment: NewInstallment;
      }
    | {
          type: 'deleteInstallment';
          productRenderId: string;
          installmentId: string;
      }
    | {
          type: 'changeInstallmentGross';
          productRenderId: string;
          installmentId: string;
          gross: string;
      }
    | {
          type: 'changeInstallmentPaymentMethod';
          productRenderId: string;
          installmentId: string;
          payment_method: {
              id: number;
              name: string;
          };
      }
    | {
          type: 'changeInstallmentDueDate';
          productRenderId: string;
          installmentId: string;
          due_date: Date | null;
      }
    | {
          type: 'changeInstallmentCurrency';
          productRenderId: string;
          installmentId: string;
          currency?: number; // currency id
          currency_rate?: number; // átváltás mértéke float
          currency_gross?: number; // átváltva bruttóban
      };

const reducer = (state: ListedProduct[], action: Action) => {
    switch (action.type) {
        case 'addProduct':
            return [...state, action.product];

        case 'deleteProduct':
            const idx = state.findIndex((prod) => prod.renderId === action.renderId);
            const newState = [...state];
            newState.splice(idx, 1);

            return newState;

        case 'addInstallment':
            const idx2 = state.findIndex((prod) => prod.renderId === action.productRenderId);
            const newState2 = [...state];

            // keep it pure (can be called multiple times with the same arguments without multiplying the effect)
            const instIdx2 = newState2[idx2].installments.findIndex((inst) => inst.id === action.installment.id);
            if (instIdx2 === -1) {
                // not added yet
                newState2[idx2].installments = [...newState2[idx2].installments, action.installment];
            }

            return newState2;

        case 'deleteInstallment':
            const idx3 = state.findIndex((prod) => prod.renderId === action.productRenderId);
            const newState3 = [...state];
            const instIdx3 = newState3[idx3].installments.findIndex((inst) => inst.id === action.installmentId);
            if (instIdx3 !== -1) newState3[idx3].installments.splice(instIdx3, 1);

            return newState3;

        case 'changeInstallmentGross':
            const productIdx4 = state.findIndex((prod) => prod.renderId === action.productRenderId);
            const installmentIdx4 = state[productIdx4].installments.findIndex((inst) => inst.id === action.installmentId);

            const newState4 = [...state];
            newState4[productIdx4].installments[installmentIdx4].gross = action.gross;

            return newState4;

        case 'changeInstallmentPaymentMethod':
            const productIdx5 = state.findIndex((prod) => prod.renderId === action.productRenderId);
            const installmentIdx5 = state[productIdx5].installments.findIndex((inst) => inst.id === action.installmentId);

            const newState5 = [...state];
            newState5[productIdx5].installments[installmentIdx5].payment_method = action.payment_method;

            return newState5;

        case 'changeInstallmentDueDate':
            const productIdx6 = state.findIndex((prod) => prod.renderId === action.productRenderId);
            const installmentIdx6 = state[productIdx6].installments.findIndex((inst) => inst.id === action.installmentId);

            const newState6 = [...state];
            newState6[productIdx6].installments[installmentIdx6].due_date = action.due_date;

            return newState6;

        case 'changeInstallmentCurrency':
            const productIdx7 = state.findIndex((prod) => prod.renderId === action.productRenderId);
            const installmentIdx7 = state[productIdx7].installments.findIndex((inst) => inst.id === action.installmentId);

            const newState7 = [...state];
            newState7[productIdx7].installments[installmentIdx7].currency = action.currency;
            newState7[productIdx7].installments[installmentIdx7].currency_rate = action.currency_rate;
            newState7[productIdx7].installments[installmentIdx7].currency_gross = action.currency_gross;

            return newState7;

        case 'changeProductPrice':
            const idx8 = state.findIndex((prod) => prod.renderId === action.renderId);
            const newState8 = [...state];
            delete newState8[idx8].priceId;
            newState8[idx8].net = action.net;
            newState8[idx8].gross = action.gross;

            return newState8;

        default:
            throw new Error();
    }
};

type ProductsProps = {
    sendData: (data: NewOrderArguments) => void;
};

const Products: React.FC<ProductsProps> = (props) => {
    const { enqueueSnackbar } = useSnackbar();

    const productsResp = useProductsQuery();
    const paymentMethodsResp = usePaymentMethodsQuery();
    const currenciesResp = useCurrenciesQuery();

    useErrorHandler(productsResp.error, paymentMethodsResp.error, currenciesResp.error);

    const [productOptions, setProductOptions] = useState<ListedProduct[]>([]);
    const [selProduct, setSelProduct] = useState<ListedProduct[]>([]);
    const [addedProducts, setAddedProducts] = useReducer(reducer, []);
    const [paymentMethods, setpaymentMethods] = useState<PaymentMethod[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [customGross, setCustomGross] = useState<string>('');
    const [customNet, setCustomNet] = useState<string>('');
    const [modalOpen, setModalOpen] = useState(false);
    const [priceToBeEdited, setPriceToBeEdited] = useState<string>('');

    useEffect(() => {
        console.log(addedProducts);
        props.sendData({ type: 'products', products: addedProducts });
    }, [addedProducts]);

    useEffect(() => {
        paymentMethodsResp.data && setpaymentMethods(paymentMethodsResp.data);
    }, [paymentMethodsResp.data]);

    useEffect(() => {
        currenciesResp.data && setCurrencies(currenciesResp.data);
    }, [currenciesResp.data]);

    useEffect(() => {
        if (productsResp.data) {
            const productsWithExactPrice: ListedProduct[] = [];
            productsResp.data.forEach((product) => {
                product.prices.forEach((price) => {
                    productsWithExactPrice.push({
                        id: product.id,
                        name: product.name,
                        priceId: price.id,
                        priceType: price.type.name,
                        gross: price.gross,
                        installments: [],
                    });
                });
            });

            setProductOptions(productsWithExactPrice);
        }
    }, [productsResp.data]);

    const handleAddProduct = (): void => {
        if (selProduct[0]) {
            const productToBeAdded: ListedProduct = JSON.parse(JSON.stringify(selProduct[0]));
            productToBeAdded.renderId = v4();
            setAddedProducts({ type: 'addProduct', product: productToBeAdded });
            setSelProduct([]);
        }
    };

    const handlePriceChange = (setState: React.Dispatch<React.SetStateAction<string>>, e: string) => {
        let input = e;

        if (e.match(/\D/)) input = input.slice(0, -1);
        if (input.length < 21) setState(input);
    };

    const handleCustomPriceSave = (renderId: string) => {
        if (customNet && customGross) {
            const cNet = parseInt(customNet);
            const cGross = parseInt(customGross);

            setModalOpen(false);
            cNet && cGross && setAddedProducts({ type: 'changeProductPrice', renderId: renderId, net: cNet, gross: cGross });
            setCustomNet('');
            setCustomGross('');
        } else {
            enqueueSnackbar('Kérlek tölsd ki az ár mezőket!', { variant: 'error' });
        }
    };

    return (
        <SubSection title="Termékek" css={subSectionStyles}>
            <div css={containerStyles}>
                {addedProducts.length > 0 && (
                    <div css={addedProductsListContainer}>
                        {addedProducts.map((product: ListedProduct, index) => (
                            <MemorizedProduct
                                key={index}
                                product={product}
                                setSelectedProductId={setPriceToBeEdited}
                                isModalOpen={modalOpen}
                                setIsModalOpen={setModalOpen}
                                currencies={currencies}
                                paymentMethods={paymentMethods}
                                dispatch={setAddedProducts}
                            />
                        ))}
                    </div>
                )}
                <div css={inputsContainerStyles}>
                    {productOptions.length > 0 && <ProductSelection options={productOptions} selected={selProduct} setSelected={setSelProduct} />}

                    <Button variant="contained" onClick={() => handleAddProduct()}>
                        Hozzáad
                    </Button>
                </div>
            </div>
            <Modal open={modalOpen} onClose={() => setModalOpen(false)} aria-labelledby="priceEdit-modal-title" aria-describedby="priceEdit-modal-description">
                <Box css={boxStyles}>
                    <Typography id="priceEdit-modal-title" variant="h6" component="h2" style={{ marginBottom: '1rem' }}>
                        Add meg a termék egyedi árát!
                    </Typography>
                    <div css={buttonContainerStyles}>
                        <div css={customPriceContainerStyles}>
                            <TextField id="netPrice" label="Egyedi nettó ár (Ft)" placeholder="A termék nettó ára" value={customNet} onChange={(e) => handlePriceChange(setCustomNet, e.target.value)} css={priceInputstyles} required />
                            <TextField
                                id="grossPrice"
                                label=" Egyedi bruttó ár (Ft)"
                                placeholder="A termék bruttó ára"
                                value={customGross}
                                onChange={(e) => handlePriceChange(setCustomGross, e.target.value)}
                                css={priceInputstyles}
                                required
                            />
                            <Button size="large" variant="contained" color="success" onClick={() => handleCustomPriceSave(priceToBeEdited)}>
                                Módosít
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </SubSection>
    );
};

const subSectionStyles = css`
    width: 100%;
`;

const containerStyles = css`
    display: flex;
    flex-direction: column;
    gap: 40px;
`;

const inputsContainerStyles = css`
    display: flex;
    gap: 20px;
`;

const addedProductsListContainer = css`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const customPriceContainerStyles = css`
    display: flex;
    align-items: center;
    gap: 5px;

    @media (max-width: 1100px) {
        width: 100%;
        justify-content: space-between;
        gap: 10px;
        text-align: end;
    }
`;

const priceInputstyles = css`
    width: 175px;
`;

const boxStyles = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 2px solid #0288d1;
    boxshadow: 24;
    padding: 1rem;
`;

const buttonContainerStyles = css`
    display: flex;
    justify-content: space-evenly;
    margin-top: 1rem;
`;

export type { NewInstallment, ListedProduct, Action };
export default Products;
