import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const emailTemplateQuery = gql`
    query emailTemplate($id: Int!) {
        emailTemplate(id: $id) {
            id
            name
            subject
            sender {
                id
                displayed_name
            }
            reply_to {
                id
                displayed_name
            }
            content
            placeholders {
                id
                name
            }
        }
    }
`;

interface EmailTemplate {
    id: number;
    name: string;
    subject: string;
    sender: {
        id: number;
        displayed_name: string;
    };
    reply_to: {
        id: number;
        displayed_name: string;
    };
    content: string;
    placeholders: {
        id: number;
        name: string;
    }[];
}

const useEmailTemplateEditQuery = queryFactory<EmailTemplate>(emailTemplateQuery, 'emailTemplate');

export default useEmailTemplateEditQuery;
