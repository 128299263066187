import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const myLists = gql`
    query myLists($lastNDays: Int!) {
        myLists(lastNDays: $lastNDays) {
            id
            name
        }
    }
`;
const useMyLists = queryFactory<{ id: number; name: string }[]>(myLists, 'myLists');

export default useMyLists;
