import React from 'react';
import NewInstallmentRow, { NewInstallmentRowProps } from '../NewInstallmentRow/NewInstallmentRow';

const MemorizedInstallmentRow = (props: NewInstallmentRowProps): JSX.Element => {
    const payment_methodHash = props.installment.payment_method.id + '@' + props.installment.payment_method.name;
    return React.useMemo(
        () => <NewInstallmentRow paymentTypes={props.paymentTypes} installment={props.installment} currencies={props.currencies} productRenderId={props.productRenderId} dispatch={props.dispatch} />,

        [payment_methodHash, props.installment.due_date, props.installment.gross, props.installment.currency, props.installment.id],
    );
};

export default MemorizedInstallmentRow;
