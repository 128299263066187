/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import { Paper, TableContainer, Table, TableBody, TableRow, TableCell, Dialog, DialogTitle, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem, Popover, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import useLatePaymentListContact from './gql/latePaymentListContact';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import useErrorHandler from '../../utils/userErrorHandler';
import { Section } from '../../components/Section';
import { useNavigate, useParams } from 'react-router-dom';
import formatPhoneNumber from '../../utils/formatPhoneNumber';
import { useSnackbar } from 'notistack';
import useUpdateLatePaymentListContact from '../MyListsPage/LatePaymentList/gql/updateLatePaymentListContact';
import useUpdateAssignedContact from '../ListContactPage/gql/updateAssignedContact';
import useCreateBlockedContact from '../CustomerPage/gql/createBlockedContact';
import useCallStates from '../ListContactStatesPage/gql/callStates';
import useTalkStates from '../ListContactStatesPage/gql/talkStates';
import useBlockReasons from '../CustomerPage/gql/blockReasons';
import hu from 'date-fns/locale/hu';
import CustomerNotes from '../ListContactPage/CustomerNotes/CustomerNotes';
import CallHistory from '../ListContactPage/CallHistory/CallHistory';
import CustomerCharacteristics from '../ListContactPage/CustomerCharacteristics/CustomerCharacteristics';
import Orders from './Orders/Orders';

const ListContactLatePage: React.FC = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const blockReasons = useBlockReasons();
    const callStates = useCallStates();
    const talkStates = useTalkStates();
    const latePaymentListContact = useLatePaymentListContact({ id: Number(params.id) });
    const [updateLatePaymentListContact, updatedLatePaymentListContact] = useUpdateLatePaymentListContact();
    const [updateAssignedContact, updatedAssignedContact] = useUpdateAssignedContact();
    const [createBlockedContact, createdBlockedContact] = useCreateBlockedContact();
    const [selectedCallState, setSelectedCallState] = useState(0);
    const [selectedTalkState, setSelectedTalkState] = useState(0);
    const [isNoteDialogOpen, setIsNoteDialogOpen] = useState(false);
    const [isBlockingDialogOpen, setIsBlockingDialogOpen] = useState(false);
    const [blockComment, setBlockComment] = useState('');
    const [selectedReason, setSelectedReason] = useState(0);
    const [note, setNote] = useState('');
    const [callBackDate, setCallBackDate] = useState(new Date());
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    useErrorHandler(latePaymentListContact.error, updatedLatePaymentListContact.error, callStates.error, talkStates.error, updatedAssignedContact.error, createdBlockedContact.error);
    const isNotePopoverOpen = Boolean(anchorEl);

    useEffect(() => {
        if (updatedLatePaymentListContact.data || updatedAssignedContact.data || createdBlockedContact.data) {
            setIsNoteDialogOpen(false);
            setIsBlockingDialogOpen(false);
            setBlockComment('');
            setSelectedReason(0);
            setNote('');
            enqueueSnackbar('A kontakt sikeresen módosítva!', { variant: 'success' });
            latePaymentListContact.refetch();
        }
    }, [updatedLatePaymentListContact.data, updatedAssignedContact.data, createdBlockedContact.data]);

    useEffect(() => {
        if (selectedCallState !== 0) {
            updateAssignedContact({ id: latePaymentListContact.data?.assignedContact.id, data: { call_state: selectedCallState, talk_state: null } });
        }
    }, [selectedCallState]);

    useEffect(() => {
        if (selectedTalkState !== 0) {
            updateAssignedContact({ id: latePaymentListContact.data?.assignedContact.id, data: { talk_state: selectedTalkState } });
        }
    }, [selectedTalkState]);

    const saveNote = () => {
        updateLatePaymentListContact({ id: latePaymentListContact.data?.id, data: { comment: note } });
    };

    const openNoteDialog = () => {
        setIsNoteDialogOpen(true);
        setNote(latePaymentListContact.data?.comment || '');
    };

    const handleChange = (newDate: Date | null) => {
        if (newDate) setCallBackDate(newDate);
    };

    const blockContact = () => {
        if (selectedReason !== 0 && latePaymentListContact.data?.customer.id) createBlockedContact({ data: { customer: latePaymentListContact.data?.customer.id, comment: blockComment, reason: selectedReason } });
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Orders />

            <Section title="Adatok">
                <TableContainer>
                    <Table aria-label="table">
                        <TableBody>
                            <TableRow css={trStyle}>
                                <TableCell css={tdStyle}>Név</TableCell>
                                <TableCell css={tdStyle}>
                                    {latePaymentListContact.data?.customer.lastname} {latePaymentListContact.data?.customer.firstname}
                                </TableCell>
                            </TableRow>
                            <TableRow css={trStyle}>
                                <TableCell css={tdStyle}>Telefonszám</TableCell>
                                <TableCell css={tdStyle}>{formatPhoneNumber(latePaymentListContact.data?.customer.phone || '')}</TableCell>
                            </TableRow>
                            <TableRow css={trStyle}>
                                <TableCell css={tdStyle}>E-mail cím</TableCell>
                                <TableCell css={tdStyle}>{latePaymentListContact.data?.customer.customer.email}</TableCell>
                            </TableRow>
                            <TableRow css={trStyle}>
                                <TableCell css={tdStyle}>Állapot</TableCell>
                                <TableCell css={tdStyle}>
                                    {`${latePaymentListContact.data?.assignedContact.call_state.name} ${
                                        latePaymentListContact.data?.assignedContact.talk_state?.name ? ` / ${latePaymentListContact.data?.assignedContact.talk_state?.name}` : ''
                                    }`}
                                </TableCell>
                            </TableRow>
                            <TableRow css={trStyle}>
                                <TableCell css={tdStyle}>Értékesítő</TableCell>
                                <TableCell css={tdStyle}>
                                    {latePaymentListContact.data?.assignedContact.ts_user.lastname} {latePaymentListContact.data?.assignedContact.ts_user.firstname}
                                </TableCell>
                            </TableRow>
                            <TableRow css={trStyle}>
                                <TableCell css={tdStyle}>Létrehozva</TableCell>
                                <TableCell css={tdStyle}>{latePaymentListContact.data?.created_at}</TableCell>
                            </TableRow>
                            <TableRow css={trStyle}>
                                <TableCell css={tdStyle}>
                                    Megjegyzés
                                    <IconButton aria-describedby="note-popover" onClick={(event) => setAnchorEl(event.currentTarget)} style={{ padding: 0, marginLeft: 5 }}>
                                        <InfoIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell css={tdStyle}>
                                    {latePaymentListContact.data?.comment || 'Nincs megjegyzés.'}
                                    <Button variant="contained" size="small" color="primary" onClick={openNoteDialog} style={{ minWidth: 32, marginLeft: 10 }}>
                                        <EditIcon />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Section>

            <Section title="Műveletek">
                <div css={sectionStyles}>
                    <FormControl>
                        <InputLabel id="call-state-select">Hívás eredménye</InputLabel>
                        <Select label="Hívás eredménye" id="call-state-select" value={selectedCallState === 0 ? '' : selectedCallState} onChange={(e) => setSelectedCallState(Number(e.target.value))}>
                            {callStates.data?.map((callState) => (
                                <MenuItem value={callState.id} key={callState.id}>
                                    {callState.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {selectedCallState === 2 && (
                        <FormControl>
                            <InputLabel id="talk-state-select">Beszélgetés eredménye</InputLabel>
                            <Select label="Beszélgetés eredménye" id="talk-state-select" value={selectedTalkState === 0 ? '' : selectedTalkState} onChange={(e) => setSelectedTalkState(Number(e.target.value))}>
                                {talkStates.data?.map((talkState) => (
                                    <MenuItem value={talkState.id} key={talkState.id}>
                                        {talkState.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    {(selectedTalkState === 9 || selectedTalkState === 10) && (
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={hu}>
                            <DateTimePicker label="Visszahívás" value={callBackDate} onChange={handleChange} disablePast renderInput={(params) => <TextField {...params} />} />
                        </LocalizationProvider>
                    )}

                    <div css={actionsButtonsContainer}>
                        <Button variant="contained" color="success" onClick={() => navigate('/orders/create-order')}>
                            Rendelés létrehozása
                        </Button>
                        <Button variant="contained" color="success">
                            Várható rendelés létrehozása
                        </Button>
                    </div>
                </div>
            </Section>

            {latePaymentListContact.data?.customer.id && (
                <CustomerCharacteristics id={latePaymentListContact.data?.customer.id} characteristics={latePaymentListContact.data.customer.customer.characteristics} refetch={latePaymentListContact.refetch} />
            )}

            {latePaymentListContact.data?.customer.id && <CustomerNotes id={latePaymentListContact.data?.customer.id} />}

            {latePaymentListContact.data?.customer.id && <CallHistory id={latePaymentListContact.data?.customer.id} />}

            {/* {latePaymentListContact.data?.customer.id && <OrderHistory id={latePaymentListContact.data?.customer.id} />} */}

            {latePaymentListContact.data?.customer.customer.blocked === null && (
                <Section title="Blokkolás">
                    <div css={sectionStyles}>
                        <TextField fullWidth multiline label="Megjegyzés" variant="outlined" value={blockComment} onChange={(e) => setBlockComment(e.target.value)} />
                        <FormControl fullWidth>
                            <InputLabel id="select">Indok</InputLabel>
                            <Select label="Indok" id="select" value={selectedReason === 0 ? '' : selectedReason} onChange={(e) => setSelectedReason(Number(e.target.value))}>
                                {blockReasons.data?.map((reason) => (
                                    <MenuItem value={reason.id} key={reason.id}>
                                        {reason.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div>
                            <Button variant="contained" color="error" size="large" onClick={() => setIsBlockingDialogOpen(true)} disabled={selectedReason === 0}>
                                Ügyfél blokkolása
                            </Button>
                        </div>
                    </div>
                </Section>
            )}

            <Dialog onClose={() => setIsNoteDialogOpen(false)} open={isNoteDialogOpen}>
                <DialogTitle style={{ backgroundColor: '#0288d1', color: 'white', minWidth: 370 }}>Megjegyzés hozzáadása</DialogTitle>
                <div css={dialogContentContainerStyles}>
                    <Typography variant="body1" component="div">
                        Megjegyzés hozzáadása a kontakthoz:
                    </Typography>
                    <Typography variant="h6" component="div" align="center" style={{ marginTop: 20 }}>
                        #{latePaymentListContact.data?.id} - {latePaymentListContact.data?.customer.lastname} {latePaymentListContact.data?.customer.firstname}
                    </Typography>
                    <TextField multiline fullWidth label="Megjegyzés" variant="outlined" value={note} onChange={(e) => setNote(e.target.value)} />
                    <div css={dialogButtonsContainerStyle}>
                        <Button variant="contained" color="error" size="large" onClick={() => setIsNoteDialogOpen(false)}>
                            Mégse
                        </Button>
                        <Button variant="contained" color="info" size="large" onClick={saveNote}>
                            Mentés
                        </Button>
                    </div>
                </div>
            </Dialog>

            <Dialog onClose={() => setIsBlockingDialogOpen(false)} open={isBlockingDialogOpen}>
                <DialogTitle style={{ backgroundColor: '#0288d1', color: 'white', minWidth: 370 }}>Ügyfél blokkolása</DialogTitle>
                <div css={dialogContentContainerStyles}>
                    <Typography variant="body1" component="div" style={{ maxWidth: 450 }}>
                        Az ügyfél az összes jelenlegi és jövőbeli telefonszámával blokkolva lesz!
                    </Typography>
                    <Typography variant="body1" component="div">
                        Biztosan blokkolod az ügyfelet?
                    </Typography>
                    <div css={dialogButtonsContainerStyle}>
                        <Button variant="contained" color="primary" size="large" onClick={() => setIsBlockingDialogOpen(false)}>
                            Mégse
                        </Button>
                        <Button variant="contained" color="error" size="large" onClick={blockContact}>
                            Blokkolás
                        </Button>
                    </div>
                </div>
            </Dialog>

            <Popover
                id="note-popover"
                open={isNotePopoverOpen}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                style={{ maxWidth: 1200 }}
            >
                <Typography sx={{ p: 2 }}>
                    Ez az információ a híváshoz kerül hozzárendelésre és csak a hívással kapcsolatos rövidtávú megjegyzések rögzítésére szolgál. Az ügyfélhez kapcsolódó, mindig elérhető megjegyzés létrehozására használd alább a megjegyzések
                    szekciót. A rendelésre vonatkozó, a rendelés élettartamán keresztül elérhető megjegyzés létrehozásához adj hozzá egy megjegyzést magához a rendeléshez. Ehhez nyisd meg a rendelés adatlapot (például az alább látható
                    rendelés előzmények listából kattintva) és ott adj hozzá egy új megjegyzést.
                </Typography>
            </Popover>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const trStyle = css`
    &: nth-of-type(odd) {
        background-color: #e3f2fd;
    }

    &:last-child td,
    &:last-child th {
        border: 0;
    }
`;

const tdStyle = css`
    width: 50%;
`;

const actionsButtonsContainer = css`
    display: flex;

    & > * + * {
        margin-left: 20px !important;
    }

    @media (max-width: 620px) {
        flex-direction: column;

        & > * + * {
            margin-top: 20px !important;
            margin-left: 0 !important;
        }
    }
`;

const dialogContentContainerStyles = css`
    padding: 24px;

    & > * + * {
        margin-top: 25px !important;
    }
`;

const dialogButtonsContainerStyle = css`
    display: flex;
    justify-content: center;
    margin-top: 30px;

    & > * + * {
        margin-left: 20px !important;
    }
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;

    & > * + * {
        margin-top: 20px !important;
    }
`;

export default ListContactLatePage;
