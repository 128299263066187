import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const joinDeliveryToOrderedProductMutation = gql`
    mutation joinDeliveryToOrderedProduct($orderedProduct: Int!, $delivery: Int!) {
        joinDeliveryToOrderedProduct(orderedProduct: $orderedProduct, delivery: $delivery) {
            id
        }
    }
`;

interface JoinedDelivery {
    id: number;
}

const useJoinDeliveryToOrderedProductMutation = mutationFactory<JoinedDelivery>(joinDeliveryToOrderedProductMutation, 'joinDeliveryToOrderedProduct');

export default useJoinDeliveryToOrderedProductMutation;
