/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect } from 'react';
import { Paper, MenuItem, InputLabel, FormControl, Select } from '@mui/material';
import { Section } from '../../components/Section';
import useEvents from './gql/events';
import useAddEmailTemplateToEvent from './gql/addEmailTemplateToEvent';
import useRemoveEmailTemplateFromEvent from './gql/removeEmailTemplateFromEvent';
import useEmailTemplatesQuery from '../EmailTemplatesPage/gql/emailTemplates';
import useErrorHandler from '../../utils/userErrorHandler';
import { useSnackbar } from 'notistack';

const AutomaticEmailsPage: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const events = useEvents();
    const emailTemplates = useEmailTemplatesQuery();
    const [addEmailTemplateToEvent, addedEmailTemplateToEvent] = useAddEmailTemplateToEvent();
    const [removeEmailTemplateFromEvent, removedEmailTemplateToEvent] = useRemoveEmailTemplateFromEvent();
    useErrorHandler(events.error, emailTemplates.error, addedEmailTemplateToEvent.error, removedEmailTemplateToEvent.error);

    const handleSelect = (newTemplateID: number, eventID: number, oldTemplateID: number) => {
        addEmailTemplateToEvent({ template: newTemplateID, event: eventID });
        removeEmailTemplateFromEvent({ template: oldTemplateID, event: eventID });
    };

    useEffect(() => {
        events.refetch();

        if (addedEmailTemplateToEvent.data) {
            enqueueSnackbar('Az új sablon sikeresen hozzárendelve!', { variant: 'success' });
        }
    }, [addedEmailTemplateToEvent.data]);

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title="Automatikus e-mail-ek">
                <div css={sectionStyles}>
                    {events.data &&
                        events.data.map((event) => (
                            <FormControl fullWidth key={event.id}>
                                <InputLabel id={event.name}>{event.name}</InputLabel>
                                <Select
                                    labelId={event.name}
                                    value={event.automatic_email.length > 0 ? event.automatic_email[0].id.toString() : ''}
                                    label={event.name}
                                    onChange={(e) => handleSelect(Number(e.target.value), event.id, event.automatic_email[0].id)}
                                >
                                    {emailTemplates.data?.map((template) => (
                                        <MenuItem value={template.id} key={template.id}>
                                            {template.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ))}
                </div>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;

    & > * + * {
        margin-top: 20px !important;
    }
`;

export default AutomaticEmailsPage;
