/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';
import useMyProgression, { Progression } from '../gql/myProgression';
import getCurrentBusinessQuarter from '../../../utils/getCurrentBusinessQuarter';
import useErrorHandler from '../../../utils/userErrorHandler';
import getYearsForSelect from '../../../utils/getYearsForSelect';
import months from '../../../utils/months';

type PerformanceProps = {
    quarterly?: boolean;
    upliftData: (data: Progression) => void;
    comparisonBlockData: Progression;
};

const Performance: React.FC<PerformanceProps> = ({ quarterly, upliftData, comparisonBlockData }) => {
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const [quarter, setQuarter] = useState(getCurrentBusinessQuarter());
    const myProgression = useMyProgression({ year: year, month: month, quarter: quarter });
    useErrorHandler(myProgression.error);

    useEffect(() => {
        myProgression.refetch();
    }, [year, month, quarter]);

    useEffect(() => {
        if (myProgression.data) upliftData(myProgression.data);
    }, [myProgression.data]);

    const getProgressionPercentage = (progress: number, target: number): number => {
        return Math.floor((progress / target) * 100);
    };

    return (
        <div style={{ width: '100%' }}>
            <FormControl style={{ width: '50%' }}>
                <InputLabel id="year-select">Év</InputLabel>
                <Select label="Év" id="year-select" value={year} onChange={(e) => setYear(Number(e.target.value))}>
                    {getYearsForSelect().map((year) => (
                        <MenuItem key={year} value={year}>
                            {year}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {!quarterly && (
                <FormControl style={{ width: '50%' }}>
                    <InputLabel id="month-select">Hónap</InputLabel>
                    <Select label="Hónap" id="month-select" value={month} onChange={(e) => setMonth(Number(e.target.value))}>
                        {months.map((month) => (
                            <MenuItem key={month.number} value={month.number}>
                                {month.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}

            {quarterly && (
                <FormControl style={{ width: '50%' }}>
                    <InputLabel id="quarter-select">Negyedév</InputLabel>
                    <Select label="Negyedév" id="quarter-select" value={quarter} onChange={(e) => setQuarter(Number(e.target.value))}>
                        <MenuItem value={1}>Első negyedév</MenuItem>
                        <MenuItem value={2}>Második negyedév</MenuItem>
                        <MenuItem value={3}>Harmadik negyedév</MenuItem>
                        <MenuItem value={4}>Negyedik negyedév</MenuItem>
                    </Select>
                </FormControl>
            )}

            {!quarterly && (
                <div css={resultsDisplayStyles}>
                    <Typography>
                        Havi eddig elért: {myProgression.data?.monthly.progress} Ft{' '}
                        <span style={{ fontWeight: 700, color: getProgressionPercentage(myProgression.data?.monthly.progress as number, myProgression.data?.monthly.target as number) >= 100 ? '#2e7d32' : '#d32f2f' }}>
                            ({getProgressionPercentage(myProgression.data?.monthly.progress as number, myProgression.data?.monthly.target as number)}%)
                        </span>
                    </Typography>
                    <Typography>Havi cél: {myProgression.data?.monthly.target} Ft</Typography>
                </div>
            )}

            {quarterly && (
                <div css={resultsDisplayStyles}>
                    <Typography>
                        Negyedéves eddig elért: {myProgression.data?.quarterly.progress} Ft{' '}
                        <span style={{ fontWeight: 700, color: getProgressionPercentage(myProgression.data?.quarterly.progress as number, myProgression.data?.quarterly.target as number) >= 100 ? '#2e7d32' : '#d32f2f' }}>
                            ({getProgressionPercentage(myProgression.data?.quarterly.progress as number, myProgression.data?.quarterly.target as number)}%)
                        </span>
                    </Typography>
                    <Typography>Negyedéves cél: {myProgression.data?.quarterly.target} Ft</Typography>
                </div>
            )}

            {!quarterly && (myProgression.data?.monthly.progress as number) > comparisonBlockData.monthly.progress && (
                <div css={resultContainerStyles}>
                    <Typography fontWeight={700} variant="body1" style={{ color: '#2e7d32' }}>
                        Ebben az időszakban ennyivel:
                    </Typography>
                    <Typography fontWeight={700} variant="h4" style={{ color: '#2e7d32' }}>
                        +{(myProgression.data?.monthly.progress as number) - comparisonBlockData.monthly.progress} Ft
                    </Typography>
                    <Typography fontWeight={700} variant="body1" style={{ color: '#2e7d32' }}>
                        több az elért összeg!
                    </Typography>
                </div>
            )}

            {quarterly && (myProgression.data?.quarterly.progress as number) > comparisonBlockData.quarterly.progress && (
                <div css={resultContainerStyles}>
                    <Typography fontWeight={700} variant="body1" style={{ color: '#2e7d32' }}>
                        Ebben az időszakban ennyivel:
                    </Typography>
                    <Typography fontWeight={700} variant="h4" style={{ color: '#2e7d32' }}>
                        +{(myProgression.data?.quarterly.progress as number) - comparisonBlockData.quarterly.progress} Ft
                    </Typography>
                    <Typography fontWeight={700} variant="body1" style={{ color: '#2e7d32' }}>
                        több az elért összeg!
                    </Typography>
                </div>
            )}
        </div>
    );
};

const resultsDisplayStyles = css`
    display: flex;
    flex-direction: column;
    padding-top: 20px;

    & > * + * {
        margin-top: 20px !important;
    }
`;

const resultContainerStyles = css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    & > * + * {
        margin-top: 10px !important;
    }
`;

export default Performance;
