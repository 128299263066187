import { gql } from '@apollo/client';
import { mutationFactory } from '../../../../utils/gqlFactories';

const createBlockTemplate = gql`
    mutation createBlockTemplate($data: BlockTemplateInputCreate!) {
        createBlockTemplate(data: $data) {
            id
        }
    }
`;

const useCreateBlockTemplate = mutationFactory<{ id: number }>(createBlockTemplate, 'createBlockTemplate');

export default useCreateBlockTemplate;
