/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Paper, Card, CardContent, Typography, TextField, Button, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate } from 'react-router-dom';
import useLogin from './gql/login';

const LoginPage: React.FC = () => {
    const [login, expirationDate] = useLogin();
    const navigate = useNavigate();
    const [usernameInput, setUsernameInput] = useState('');
    const [passwordInput, setPasswordInput] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleLogin = () => {
        login({ data: { username: usernameInput, password: passwordInput } });
    };

    useEffect(() => {
        if (expirationDate.data) {
            localStorage.setItem('erp3-session-expiration', expirationDate.data);
            navigate('/');
        }
    }, [expirationDate.data]);

    return (
        <Paper css={containerStyles} elevation={0}>
            <Card css={cardStyles} elevation={3}>
                <CardContent css={cardContentStyles}>
                    <Typography variant="h5" style={{ marginBottom: 12 }}>
                        Bejelentkezés
                    </Typography>
                    <TextField css={inputStyles} value={usernameInput} onChange={(e) => setUsernameInput(e.target.value)} label="Felhasználónév" variant="outlined" />
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="password-input">Jelszó</InputLabel>
                        <OutlinedInput
                            id="password-input"
                            type={showPassword ? 'text' : 'password'}
                            value={passwordInput}
                            onChange={(e) => setPasswordInput(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword(!showPassword)} onMouseDown={(e) => e.preventDefault()} edge="end">
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Jelszó"
                        />
                    </FormControl>
                    {expirationDate.error && (
                        <Typography variant="body1" color="error" align="center">
                            {expirationDate.error?.message}
                        </Typography>
                    )}
                    <Button css={loginButtonStyles} variant="contained" size="large" onClick={handleLogin}>
                        Bejelentkezés
                    </Button>
                </CardContent>
            </Card>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 5%;

    @media (max-width: 991px) {
        margin-top: 20%;
    }
`;

const cardStyles = css`
    width: 100%;
    max-width: 500px;
`;

const cardContentStyles = css`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const inputStyles = css`
    width: 100%;
`;

const loginButtonStyles = css`
    margin: auto;
    margin-top: 1rem;
`;

export default LoginPage;
