import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const products = gql`
    query {
        products(package: false) {
            id
            name
        }
    }
`;

type Product = {
    id: number;
    name: string;
};

const useProductsOnlyQuery = queryFactory<Product[]>(products, 'products');

export type { Product };
export default useProductsOnlyQuery;
