export default {
    // Root
    noRowsLabel: 'Nincsenek sorok',
    noResultsOverlayLabel: 'Nincs találat.',
    errorOverlayDefaultLabel: 'Hiba történt.',

    // Density selector toolbar button text
    toolbarDensity: '',
    toolbarDensityLabel: 'Sűrűség',
    toolbarDensityCompact: 'Kompakt',
    toolbarDensityStandard: 'Standard',
    toolbarDensityComfortable: 'Kényelmes',

    // Columns selector toolbar button text
    toolbarColumns: 'Oszlopok',
    toolbarColumnsLabel: 'Válassz oszlopokat',

    // Filters toolbar button text
    toolbarFilters: 'Filterek',
    toolbarFiltersLabel: 'Filterek mutatása',
    toolbarFiltersTooltipHide: 'Filterek elrejtése',
    toolbarFiltersTooltipShow: 'Filterek mutatása',
    toolbarFiltersTooltipActive: (count: number): string => (count !== 1 ? `${count} aktív szűrő` : `${count} aktív szűrő`),

    // Export selector toolbar button text
    toolbarExport: 'Export',
    toolbarExportLabel: 'Export',
    toolbarExportCSV: 'Letöltés CSV-ként',
    toolbarExportPrint: 'Nyomtatás',

    // Columns panel text
    columnsPanelTextFieldLabel: 'Oszlop keresés',
    columnsPanelTextFieldPlaceholder: 'Oszlop cím',
    columnsPanelDragIconLabel: 'Oszlop újrarendezés',
    columnsPanelShowAllButton: 'Összes',
    columnsPanelHideAllButton: 'Elrejtés',

    // Filter panel text
    filterPanelAddFilter: 'Szűrő hozzáadása',
    filterPanelDeleteIconLabel: 'Törlés',
    filterPanelLinkOperator: 'Logikai operátor',
    filterPanelOperators: 'Operátor', // TODO v6: rename to filterPanelOperator
    filterPanelOperatorAnd: 'És',
    filterPanelOperatorOr: 'Vagy',
    filterPanelColumns: 'Oszlopok',
    filterPanelInputLabel: 'Érték',
    filterPanelInputPlaceholder: 'Szűrési érték',

    // Filter operators text
    filterOperatorContains: 'tartalmaz',
    filterOperatorEquals: 'egyenlő',
    filterOperatorStartsWith: 'kezdődik',
    filterOperatorEndsWith: 'végződik',
    filterOperatorIs: 'létezik',
    filterOperatorNot: 'nem létezik',
    filterOperatorAfter: 'utáni',
    filterOperatorOnOrAfter: 'bekapcsolt vagy utáni',
    filterOperatorBefore: 'előtti',
    filterOperatorOnOrBefore: 'bekapcsolt vagy előtti',
    filterOperatorIsEmpty: 'üres',
    filterOperatorIsNotEmpty: 'nem üres',
    filterOperatorIsAnyOf: 'bármelyik',

    // Filter values text
    filterValueAny: 'bármi',
    filterValueTrue: 'igaz',
    filterValueFalse: 'hamis',

    // Column menu text
    columnMenuLabel: 'Menü',
    columnMenuShowColumns: 'Oszlopok',
    columnMenuFilter: 'Szűrő',
    columnMenuHideColumn: 'Elrejtés',
    columnMenuUnsort: 'Rendezés törlése',
    columnMenuSortAsc: 'Növekvő',
    columnMenuSortDesc: 'Csökkenő',

    // Column header text
    columnHeaderFiltersTooltipActive: (count: number): string => (count !== 1 ? `${count} aktív szűrő` : `${count} aktív szűrő`),
    columnHeaderFiltersLabel: 'Szűrők megjelenítése',
    columnHeaderSortIconLabel: 'Rendezés',

    // Rows selected footer text
    footerRowSelected: (count: number): string => (count !== 1 ? `${count.toLocaleString()} sor kiválasztva` : `${count.toLocaleString()} sor kiválasztva`),

    // Total row amount footer text
    footerTotalRows: 'Összes sor:',

    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount: number, totalCount: number): string => `${visibleCount.toLocaleString()} / ${totalCount.toLocaleString()}`,

    // Checkbox selection text
    checkboxSelectionHeaderName: 'Checkbox kiválasztása',
    checkboxSelectionSelectAllRows: 'Összes sor kijelölése',
    checkboxSelectionUnselectAllRows: 'Kijelölések megszűntetése',
    checkboxSelectionSelectRow: 'Sor kijelölése',
    checkboxSelectionUnselectRow: 'Sor kijelölésének megszűntetése',

    // Boolean cell text
    booleanCellTrueLabel: 'igen',
    booleanCellFalseLabel: 'nem',

    // Actions cell more text
    actionsCellMore: 'Továbbiak',

    // Column pinning text
    pinToLeft: 'Kitűzés balra',
    pinToRight: 'Kitűzés jobbra',
    unpin: 'Kitűzés megszüntetése',

    // Tree Data
    treeDataGroupingHeaderName: 'Csoport',
    treeDataExpand: 'see children',
    treeDataCollapse: 'hide children',

    // Grouping columns
    groupingColumnHeaderName: 'Csoport',
    groupColumn: (name: string): string => `Group by ${name}`,
    unGroupColumn: (name: string): string => `Stop grouping by ${name}`,

    // Master/detail
    expandDetailPanel: 'Expand',
    collapseDetailPanel: 'Collapse',

    // Used core components translation keys
    MuiTablePagination: {},

    // Row reordering text
    rowReorderingHeaderName: 'Sor újrarendezés',
};
