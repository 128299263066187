import React, { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { defaultStaticRanges, defaultInputRanges } from 'react-date-range/dist/defaultRanges';
import { hu } from 'date-fns/locale';
import DateRangeProps from './CustomDateRangePickerProps';
import './CustomDateRangePickerStyles.css';

const staticRangesLabels = {
    Today: 'Ma',
    Yesterday: 'Tegnap',
    'This Week': 'Ez a hét',
    'Last Week': 'Múlt hét',
    'This Month': 'Ez a hónap',
    'Last Month': 'Múlt hónap',
};

const inputRangesLabels = {
    'days up to today': 'nap mai napig bezárólag',
    'days starting today': 'nap mai naptól kezdve',
};

function translateRange(dictionary: { [key: string]: string }) {
    return (item: { [key: string]: string }) => (dictionary[item.label] ? { ...item, label: dictionary[item.label] } : item);
}

const huStaticRanges = defaultStaticRanges.map(translateRange(staticRangesLabels));
const huInputRanges = defaultInputRanges.map(translateRange(inputRangesLabels));

const CustomDateRangePicker: React.FC<DateRangeProps> = (props: DateRangeProps) => {
    const [isDesktop, setDesktop] = useState(window.innerWidth > 1000);

    const updateMedia = () => {
        setDesktop(window.innerWidth > 1000);
    };

    useEffect(() => {
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    });

    return (
        <DateRangePicker
            startDatePlaceholder="Kezdő dátum"
            endDatePlaceholder="Záró dátum"
            onChange={props.onChange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={props.ranges}
            direction={isDesktop ? 'horizontal' : 'vertical'}
            locale={hu}
            staticRanges={huStaticRanges}
            inputRanges={huInputRanges}
        />
    );
};

export default CustomDateRangePicker;
