/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useContext, useEffect } from 'react';
import { BlockType, FilterContentType, FilterType, ListContext, ListContextType, OperatorType } from '../../../contexts/ListContext';
import { Button, Dialog, DialogTitle, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import useBlockTemplates from './gql/blockTemplates';

type LoadFilterBlockDialogProps = {
    isDialogOpen: boolean;
    setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const FilterBlockLoadDialog: React.FC<LoadFilterBlockDialogProps> = ({ isDialogOpen, setIsDialogOpen }: LoadFilterBlockDialogProps) => {
    const blockTemplates = useBlockTemplates();
    const { addBlock } = useContext(ListContext) as ListContextType;
    const [selected, setSelected] = useState<number>(0);

    useEffect(() => {
        if (isDialogOpen) blockTemplates.refetch();
    }, [isDialogOpen]);

    const loadBlock = () => {
        const blockToAdd = blockTemplates.data?.find((template) => template.id === selected);
        if (blockToAdd) {
            const convertedFilters = blockToAdd.block.filters.map((filter) => {
                const valueID = filter.list?.id || filter.email?.id || filter.sms?.id || filter.tag?.id || filter.product?.id;
                return {
                    id: filter.id,
                    typeID: filter.type.id,
                    valueID: `${filter.type.id}-${valueID}`,
                    negation: filter.negation,
                    from: new Date(filter.from),
                    to: new Date(filter.to),
                } as FilterType;
            });
            const content: FilterContentType[] = [];
            convertedFilters.map((filter, idx) => {
                content.push({ type: 'filter', content: filter });
                if (blockToAdd.block.operators[idx]) content.push({ type: 'operator', content: blockToAdd.block.operators[idx] as OperatorType });
            });

            const block: BlockType = {
                id: Math.floor(Math.random() * 100000000),
                filters: convertedFilters,
                operators: blockToAdd.block.operators as OperatorType[],
                content: content,
            };
            addBlock(block);
            setIsDialogOpen(false);
        }
    };

    return (
        <Dialog onClose={() => setIsDialogOpen(false)} open={isDialogOpen}>
            <DialogTitle style={{ backgroundColor: '#0288d1', color: 'white', minWidth: 370 }}>Blokk betöltése</DialogTitle>
            <div css={dialogContentContainerStyles}>
                <FormControl fullWidth>
                    <InputLabel id="secure-select">Blokk</InputLabel>
                    <Select fullWidth value={selected === 0 ? '' : selected} onChange={(e) => setSelected(Number(e.target.value))} label="Blokk">
                        {blockTemplates.data?.map((template) => (
                            <MenuItem value={template.id} key={template.id}>
                                {template.id} - {template.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <div css={dialogButtonsContainerStyle}>
                    <Button variant="contained" color="error" size="large" onClick={() => setIsDialogOpen(false)}>
                        Mégse
                    </Button>
                    <Button variant="contained" color="info" size="large" onClick={loadBlock}>
                        Betöltés
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

const dialogContentContainerStyles = css`
    padding: 24px;
`;

const dialogButtonsContainerStyle = css`
    display: flex;
    justify-content: center;
    margin-top: 30px;

    & > * + * {
        margin-left: 20px !important;
    }
`;

export default FilterBlockLoadDialog;
