import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const deleteCustomerCharacteristicOption = gql`
    mutation deleteCustomerCharacteristicOption($id: Int!) {
        deleteCustomerCharacteristicOption(id: $id)
    }
`;

const useDeleteCustomerCharacteristicOption = mutationFactory<number>(deleteCustomerCharacteristicOption, 'deleteCustomerCharacteristicOption');

export default useDeleteCustomerCharacteristicOption;
