import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const updateOffDayMutation = gql`
    mutation updateOffDay($id: Int!, $data: OffDayInputUpdate!) {
        updateOffDay(id: $id, data: $data) {
            id
            start
            end
        }
    }
`;

interface UpdatedOffDay {
    id: number;
}

const useUpdateOffDayMutation = mutationFactory<UpdatedOffDay>(updateOffDayMutation, 'updateOffDay');

export default useUpdateOffDayMutation;
