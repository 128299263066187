import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const createDeliveryMutation = gql`
    mutation createDelivery($data: DeliveryInputCreate!, $orderedProducts: [Int]) {
        createDelivery(data: $data, orderedProducts: $orderedProducts) {
            id
        }
    }
`;

interface CreatedDelivery {
    id: number;
}

const useCreateDeliveryMutation = mutationFactory<CreatedDelivery>(createDeliveryMutation, 'createDelivery');

export default useCreateDeliveryMutation;
