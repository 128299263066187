/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

export interface NewOrderState {
    name: string;
    phone: string;
    existingCustomerId: number | null;
    handleContextValueChange: (target: string, value: string | number | null) => void;
}
export interface NewOrderProps {
    children: React.ReactNode;
}

export const NewOrderContext = React.createContext<NewOrderState>({
    name: '',
    phone: '',
    existingCustomerId: null,
    handleContextValueChange: () => {},
});

export class NewOrderProvider extends React.Component<NewOrderProps, NewOrderState> {
    listenerId!: string;

    constructor(props: NewOrderProps) {
        super(props);

        this.state = {
            name: '',
            phone: '',
            existingCustomerId: null,
            handleContextValueChange: () => {},
        };

        this.handleContextValueChange = this.handleContextValueChange.bind(this);
    }

    render(): React.ReactNode {
        return (
            <NewOrderContext.Provider
                value={{
                    name: this.state.name,
                    phone: this.state.phone,
                    existingCustomerId: this.state.existingCustomerId,
                    handleContextValueChange: this.handleContextValueChange,
                }}
            >
                {this.props.children}
            </NewOrderContext.Provider>
        );
    }

    handleContextValueChange(target: string, value: string | number | null): void {
        this.setState(({ [target]: value } as unknown) as Pick<NewOrderState, keyof NewOrderState>);
    }
}
