/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

export interface ShippingAddressState {
    name: string;
    phone: string;
    country: string;
    zip_code: string;
    city: string;
    address: string;
    notes: string;
    handleTextChange: (target: string, value: string) => void;
}
export interface ShippingAddressProps {
    children: React.ReactNode;
}

export const ShippingAddressContext = React.createContext<ShippingAddressState>({
    name: '',
    phone: '',
    country: '',
    zip_code: '',
    city: '',
    address: '',
    notes: '',
    handleTextChange: () => {},
});

export class ShippingAddressProvider extends React.Component<ShippingAddressProps, ShippingAddressState> {
    listenerId!: string;

    constructor(props: ShippingAddressProps) {
        super(props);

        this.state = {
            name: '',
            phone: '',
            country: 'Magyarország',
            zip_code: '',
            city: '',
            address: '',
            notes: '',
            handleTextChange: () => {},
        };

        this.handleTextChange = this.handleTextChange.bind(this);
    }

    render(): React.ReactNode {
        return (
            <ShippingAddressContext.Provider
                value={{
                    name: this.state.name,
                    phone: this.state.phone,
                    country: this.state.country,
                    zip_code: this.state.zip_code,
                    city: this.state.city,
                    address: this.state.address,
                    notes: this.state.notes,
                    handleTextChange: this.handleTextChange,
                }}
            >
                {this.props.children}
            </ShippingAddressContext.Provider>
        );
    }

    handleTextChange(target: string, value: string): void {
        this.setState(({ [target]: value } as unknown) as Pick<ShippingAddressState, keyof ShippingAddressState>);
    }
}
