/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { ShippingAddressProvider } from '../../../contexts/ShippingAddressContext';

import { BillingAddress } from '../BillingAddress';
import { NewOrderArguments } from '../NewOrderPage';
import { ShippingAddress } from '../ShippingAddress';

type AddressesProps = {
    sendData: (data: NewOrderArguments) => void;
};

const Addresses: React.FC<AddressesProps> = (props) => {
    return (
        <div css={containerStyles}>
            <ShippingAddressProvider>
                <ShippingAddress sendData={props.sendData} />

                <BillingAddress sendData={props.sendData} />
            </ShippingAddressProvider>
        </div>
    );
};

const containerStyles = css`
    display: flex;
    gap: 20px;

    @media (max-width: 767px) {
        flex-direction: column;
    }
`;

export default Addresses;
