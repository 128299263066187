/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Section } from '../../components/Section';
import { Paper, TextField, Button, FormControl, Select, MenuItem, InputLabel, Box, FormControlLabel, Checkbox, Modal, Typography, OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import useErrorHandler from '../../utils/userErrorHandler';
import { useSnackbar } from 'notistack';
import useUserQuery from './gql/user';
import usePermissionLevelsQuery, { Permission } from './gql/permissionLevels';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import useUpdateUserMutation from './gql/updateUser';
import useDeleteUserMutation from './gql/deleteUser';

const AccountEditPage: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const id = parseInt(params.id as string);
    const navigate = useNavigate();
    const userData = useUserQuery({ id: id });
    const permissionsData = usePermissionLevelsQuery();
    const [updateUser, updatedUser] = useUpdateUserMutation();
    const [deleteUser, deletedUser] = useDeleteUserMutation();
    useErrorHandler(userData.error, permissionsData.error, updatedUser.error, deletedUser.error);

    const [username, setUsername] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [middlename, setMiddlename] = useState('');
    const [email, setEmail] = useState('');
    const [active, setActive] = useState(false);
    const [permission, setPermission] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showNewPasswords, setShowNewPasswords] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        if (userData.data) {
            setUsername(userData.data.username);
            setFirstname(userData.data.firstname);
            setLastname(userData.data.lastname);
            userData.data.middlename === null ? setMiddlename('') : setMiddlename(userData.data.middlename);
            setEmail(userData.data.email);
            setActive(userData.data.active);
            setPermission(userData.data.permission.id.toString());
        }
    }, [userData.data]);

    const handleDelete = async () => {
        await deleteUser({ id });
        setModalOpen(false);
        navigate('/settings/accounts');
    };

    const handleSave = async () => {
        const rgx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;
        const match = email.match(rgx);

        if (lastname && firstname && email && permission) {
            if (match) {
                if (newPassword !== '') {
                    if (newPassword.length >= 8 && confirmPassword.length >= 8) {
                        if (newPassword === confirmPassword) {
                            await updateUser({
                                id,
                                data: {
                                    firstname,
                                    lastname,
                                    middlename,
                                    email,
                                    password: confirmPassword,
                                    active,
                                    permission: parseInt(permission),
                                },
                            });
                            navigate('/settings/accounts');
                        } else {
                            enqueueSnackbar('A jelszavak nem egyeznek meg!', { variant: 'error' });
                        }
                    } else {
                        enqueueSnackbar('A jelszónak legalább 8 karakter hosszúnak kell lennie!', { variant: 'error' });
                    }
                } else {
                    await updateUser({
                        id,
                        data: {
                            firstname,
                            lastname,
                            middlename,
                            email,
                            active,
                            permission: parseInt(permission),
                        },
                    });
                    navigate('/settings/accounts');
                }
            } else {
                enqueueSnackbar('Az e-mail formátuma nem megfelelő!', { variant: 'error' });
            }
        } else {
            enqueueSnackbar('A csillaggal jelölt mezők kitöltése kötelező!', { variant: 'error' });
        }
    };

    const handleNameChange = (input: string, setNameState: React.Dispatch<React.SetStateAction<string>>) => {
        const formattedName = input.charAt(0).toUpperCase() + input.slice(1).toLocaleLowerCase();
        setNameState(formattedName);
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`#${id + ': ' + userData.data?.username} fiók szerkesztése`}>
                <div css={sectionStyles}>
                    <div css={dataContainerStyles}>
                        <div css={dataColStyles}>
                            <TextField required fullWidth id="lastname" label="Vezetéknév" placeholder="Vezetéknév" value={lastname} onChange={(e) => handleNameChange(e.target.value, setLastname)} css={inputsStyles} />
                            <TextField required fullWidth id="firstname" label="Keresztnév" placeholder="Keresztnév" value={firstname} onChange={(e) => handleNameChange(e.target.value, setFirstname)} css={inputsStyles} />
                            <TextField fullWidth id="middlename" label="Utónév" placeholder="Utónév" value={middlename} onChange={(e) => handleNameChange(e.target.value, setMiddlename)} css={inputsStyles} />
                            <FormControl sx={{ minWidth: 200, width: '100%' }} required>
                                <InputLabel id="permissions">Jogosultság</InputLabel>
                                <Select labelId="permissions" id="permissions-select" label="Jogosultság" value={permission} onChange={(e) => setPermission(e.target.value)}>
                                    {permissionsData.data?.map((permission: Permission) => {
                                        return (
                                            <MenuItem key={permission.id} value={permission.id}>
                                                {permission.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <FormControlLabel control={<Checkbox checked={active} onChange={() => setActive(!active)} />} label={`Állapot: ${active ? 'Aktív' : 'Inaktív'}`} />
                        </div>
                        <div css={dataColStyles}>
                            <TextField fullWidth id="username" label="Felhasználónév" placeholder="Felhasználónév" value={username} onChange={(e) => setUsername(e.target.value)} css={inputsStyles} disabled />
                            <TextField required fullWidth id="email" label="E-mail" placeholder="E-mail cím" value={email} onChange={(e) => setEmail(e.target.value)} css={inputsStyles} />
                            <FormControl variant="outlined" css={passwordInputStyles}>
                                <InputLabel htmlFor="password-input">Új jelszó (opcionális)</InputLabel>
                                <OutlinedInput
                                    id="password-input"
                                    type={showNewPasswords ? 'text' : 'password'}
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" onClick={() => setShowNewPasswords(!showNewPasswords)} onMouseDown={(e) => e.preventDefault()} edge="end">
                                                {showNewPasswords ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Új jelszó (opcionális)"
                                />
                            </FormControl>
                            <FormControl variant="outlined" css={passwordInputStyles}>
                                <InputLabel htmlFor="password-input">Új jelszó megerősítése</InputLabel>
                                <OutlinedInput
                                    id="password-input"
                                    type={showNewPasswords ? 'text' : 'password'}
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" onClick={() => setShowNewPasswords(!showNewPasswords)} onMouseDown={(e) => e.preventDefault()} edge="end">
                                                {showNewPasswords ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Új jelszó megerősítése"
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div css={buttonInputsContainerStyles}>
                        <Button size="large" variant="contained" color="error" onClick={() => setModalOpen(true)} style={{ marginRight: '20px' }}>
                            Törlés
                        </Button>
                        <Button size="large" variant="contained" color="success" onClick={handleSave}>
                            Mentés
                        </Button>
                    </div>
                </div>
            </Section>
            <Modal open={modalOpen} onClose={() => setModalOpen(false)} aria-labelledby="delete-modal-title" aria-describedby="delete-modal-description">
                <Box css={boxStyles}>
                    <Typography id="delete-modal-title" variant="h6" component="h2" style={{ marginBottom: '1rem' }}>
                        Biztosan törölni szeretnéd a fiókot?
                    </Typography>
                    <div css={buttonContainerStyles}>
                        <Button size="large" variant="contained" color="error" onClick={handleDelete}>
                            Igen
                        </Button>
                        <Button size="large" variant="contained" onClick={() => setModalOpen(false)}>
                            Nem
                        </Button>
                    </div>
                </Box>
            </Modal>
        </Paper>
    );
};

const containerStyles = css`
    position: relative;
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;

    & > * + * {
        margin-top: 20px !important;
    }
`;

const dataContainerStyles = css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 30px;
`;

const dataColStyles = css`
    min-width: 360px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const inputsStyles = css`
    min-width: 200px;
`;

const buttonInputsContainerStyles = css`
    align-self: end;
`;

const boxStyles = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 2px solid #0288d1;
    boxshadow: 24;
    padding: 1rem;
`;

const buttonContainerStyles = css`
    display: flex;
    justify-content: space-evenly;
    margin-top: 1rem;
`;

const passwordInputStyles = css`
    min-width: 300px;
`;

export default AccountEditPage;
