import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const events = gql`
    query {
        events(emailImplemented: true) {
            id
            name
            description
            automatic_email {
                id
            }
        }
    }
`;

interface Event {
    id: number;
    name: string;
    description: string;
    automatic_email: {
        id: number;
    }[];
}

const useEvents = queryFactory<Event[]>(events, 'events');

export default useEvents;
