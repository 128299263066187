/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState, useContext } from 'react';
import { BlockType, ListContext, ListContextType } from '../../../contexts/ListContext';
import { Button, Dialog, DialogTitle, TextField } from '@mui/material';
import useCreateBlockTemplate from './gql/createBlockTemplate';
import useErrorHandler from '../../../utils/userErrorHandler';
import { useSnackbar } from 'notistack';

type LoadFilterBlockDialogProps = {
    block: BlockType;
    isDialogOpen: boolean;
    setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type ConvertedBlock = {
    name: string;
    operators: number[];
    filters: {
        type: number;
        list?: number;
        email?: number;
        sms?: number;
        tag?: number;
        product?: number;
        negation: boolean;
        from: Date;
        to: Date;
    }[];
};

const FilterBlockSaveDialog: React.FC<LoadFilterBlockDialogProps> = ({ block, isDialogOpen, setIsDialogOpen }: LoadFilterBlockDialogProps) => {
    const [createBlockTemplate, createdBlockTemplate] = useCreateBlockTemplate();
    const { typeMap } = useContext(ListContext) as ListContextType;
    const { enqueueSnackbar } = useSnackbar();
    const [name, setName] = useState('');
    useErrorHandler(createdBlockTemplate.error);

    useEffect(() => {
        if (createdBlockTemplate.data) {
            setIsDialogOpen(false);
            enqueueSnackbar('A block sikeresen elmentve', { variant: 'success' });
        }
    }, [createdBlockTemplate.data]);

    const saveBlock = () => {
        const convertedOperators = block.operators.map((operator) => (operator.name === 'ÉS' ? 1 : 2));
        const convertedFilters = block.filters.map((filter) => {
            const [type, id] = filter.valueID.split('-');
            const filterIDs = { type: Number(type) };
            filterIDs[typeMap[type as keyof typeof typeMap] as keyof typeof filterIDs] = Number(id);

            return {
                ...filterIDs,
                negation: filter.negation,
                from: filter.from,
                to: filter.to,
            };
        });

        const convertedBlock: ConvertedBlock = {
            name: name,
            operators: block.operators.length > 0 ? convertedOperators : [],
            filters: convertedFilters,
        };

        createBlockTemplate({ data: convertedBlock });
    };

    return (
        <Dialog onClose={() => setIsDialogOpen(false)} open={isDialogOpen}>
            <DialogTitle style={{ backgroundColor: '#0288d1', color: 'white', minWidth: 370 }}>Blokk mentése</DialogTitle>
            <div css={dialogContentContainerStyles}>
                <TextField fullWidth label="Blokk neve" variant="outlined" value={name} onChange={(e) => setName(e.target.value)} />
                <div css={dialogButtonsContainerStyle}>
                    <Button variant="contained" color="error" size="large" onClick={() => setIsDialogOpen(false)}>
                        Mégse
                    </Button>
                    <Button variant="contained" color="info" size="large" onClick={saveBlock} disabled={name === ''}>
                        Mentés
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

const dialogContentContainerStyles = css`
    padding: 24px;
`;

const dialogButtonsContainerStyle = css`
    display: flex;
    justify-content: center;
    margin-top: 30px;

    & > * + * {
        margin-left: 20px !important;
    }
`;

export default FilterBlockSaveDialog;
