import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const updatePasswordMutation = gql`
    mutation updatePassword($data: UpdatePasswordInput!) {
        updatePassword(data: $data)
    }
`;

const useUpdatePasswordMutation = mutationFactory(updatePasswordMutation, 'updatePassword');

export default useUpdatePasswordMutation;
