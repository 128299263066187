import { BlockType, FilterType, OperatorType } from '../contexts/ListContext';

type AddBlockAction = {
    type: 'ADD_BLOCK';
    payload: BlockType;
};

type AddNewBlockAction = {
    type: 'ADD_NEW_BLOCK';
};

type RemoveBlockAction = {
    type: 'REMOVE_BLOCK';
    payload: number;
};

type AddNewFilterAction = {
    type: 'ADD_NEW_FILTER';
    payload: number;
};

type RemoveFilterAction = {
    type: 'REMOVE_FILTER';
    payload: { blockID: number; filterID: number };
};

type UpdateFilterAction = {
    type: 'UPDATE_FILTER';
    payload: { blockID: number; filterID: number; updatedFilter: FilterType };
};

type ToggleOperatorAction = {
    type: 'TOGGLE_OPERATOR';
    payload: { blockID: number; operatorID: number };
};

type SetDateAction = {
    type: 'SET_DATE';
    payload: { blockID: number; filterID: number; from: Date; to: Date };
};

export type ListAction = AddBlockAction | AddNewBlockAction | RemoveBlockAction | AddNewFilterAction | RemoveFilterAction | UpdateFilterAction | ToggleOperatorAction | SetDateAction;

const reducer = (state: BlockType[], action: ListAction): BlockType[] => {
    let blockToUpdate: BlockType | undefined;
    let updatedBlock: BlockType;
    const emptyFilter = { id: Math.floor(Math.random() * 100000000), typeID: 0, valueID: '', negation: false, from: new Date(), to: new Date() };
    const emptyBlock = { id: Math.floor(Math.random() * 100000000), filters: [emptyFilter], operators: [], content: [] };

    switch (action.type) {
        case 'ADD_BLOCK':
            return [...state, action.payload];
        case 'ADD_NEW_BLOCK':
            return [...state, emptyBlock];
        case 'REMOVE_BLOCK':
            return state.filter((block) => block.id !== action.payload);
        case 'ADD_NEW_FILTER':
            blockToUpdate = state.find((block) => block.id === action.payload);

            if (blockToUpdate) {
                updatedBlock = { ...blockToUpdate, filters: [...blockToUpdate.filters, emptyFilter] };
                if (updatedBlock.filters.length > 1) updatedBlock.operators.push({ id: Math.floor(Math.random() * 100000000), name: 'ÉS' });
            }

            return state.map((block) => (block.id === blockToUpdate?.id ? updatedBlock : block));
        case 'REMOVE_FILTER':
            blockToUpdate = state.find((block) => block.id === action.payload.blockID);

            if (blockToUpdate) {
                const indexOfFilter = blockToUpdate.filters.findIndex((filter) => filter.id === action.payload.filterID);
                const updatedOperators = blockToUpdate.operators;
                if (blockToUpdate.operators[indexOfFilter - 1]) {
                    updatedOperators.splice(indexOfFilter - 1, 1);
                } else {
                    updatedOperators.shift();
                }
                updatedBlock = { ...blockToUpdate, filters: blockToUpdate.filters.filter((filter) => filter.id !== action.payload.filterID), operators: updatedOperators };
            }

            return state.map((block) => (block.id === blockToUpdate?.id ? updatedBlock : block));
        case 'UPDATE_FILTER':
            blockToUpdate = state.find((block) => block.id === action.payload.blockID);

            if (blockToUpdate) {
                updatedBlock = { ...blockToUpdate, filters: blockToUpdate.filters.map((filter) => (filter.id === action.payload.filterID ? action.payload.updatedFilter : filter)) };
            }

            return state.map((block) => (block.id === blockToUpdate?.id ? updatedBlock : block));
        case 'TOGGLE_OPERATOR':
            blockToUpdate = state.find((block) => block.id === action.payload.blockID);

            if (blockToUpdate) {
                const updatedOperators = blockToUpdate.operators.map((operator) => (operator.id === action.payload.operatorID ? ({ ...operator, name: operator.name === 'ÉS' ? 'VAGY' : 'ÉS' } as OperatorType) : operator));

                updatedBlock = { ...blockToUpdate, operators: updatedOperators };
            }

            return state.map((block) => (block.id === blockToUpdate?.id ? updatedBlock : block));
        case 'SET_DATE':
            blockToUpdate = state.find((block) => block.id === action.payload.blockID);

            if (blockToUpdate) {
                const filterToUpdate = blockToUpdate.filters.find((filter) => filter.id === action.payload.filterID);

                let updatedFilter: FilterType;
                if (filterToUpdate) {
                    updatedFilter = { ...filterToUpdate, to: action.payload.to, from: action.payload.from };
                }

                updatedBlock = { ...blockToUpdate, filters: blockToUpdate.filters.map((filter) => (filter.id === action.payload.filterID ? updatedFilter : filter)) };
            }

            return state.map((block) => (block.id === blockToUpdate?.id ? updatedBlock : block));
        default:
            return state;
    }
};
export default reducer;
