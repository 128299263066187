import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const createExpectedOrderMutation = gql`
    mutation createExpectedOrder($data: ExpectedOrderInputCreate!) {
        createExpectedOrder(data: $data) {
            id
        }
    }
`;

interface CreatedExpectedOrder {
    id: number;
}

const useCreateExpectedOrderMutation = mutationFactory<CreatedExpectedOrder>(createExpectedOrderMutation, 'createExpectedOrder');

export default useCreateExpectedOrderMutation;
