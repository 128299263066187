const getCurrentBusinessQuarter = (): number => {
    const currentMonth = new Date().getMonth() + 1;
    if (currentMonth < 4) return 1;
    if (currentMonth < 7) return 2;
    if (currentMonth < 10) return 3;
    if (currentMonth <= 12) return 4;
    return 0;
};

export default getCurrentBusinessQuarter;
