/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { Editor } from '@tinymce/tinymce-react';
import { Paper, Button, TextField, FormControl, InputLabel, Select, MenuItem, Chip, Autocomplete, Box, Typography, Modal } from '@mui/material';
import { Section } from '../../components/Section';
import useEmailTemplateEditQuery from './gql/emailTemplateEdit';
import usePlaceholdersQuery, { Placeholder } from './gql/placeholders';
import useEmailAccountsQuery, { EmailAccount } from './gql/emailAccounts';
import useCreatePlaceholderMutation, { NewPlaceHolder } from './gql/createPlaceholder';
import useUpdateEmailTemplateMutation from './gql/updateEmailTemplate';
import useErrorHandler from '../../utils/userErrorHandler';
import useDeleteEmailTemplateMutation from './gql/deleteEmailTemplate';

type NewPlaceHolderToSend = Pick<NewPlaceHolder, 'name' | 'description' | 'custom'>;

const EmailTemplateEditPage: React.FC = () => {
    const params = useParams();
    const id = parseInt(params.id as string);
    const navigate = useNavigate();
    const emailTemplates = useEmailTemplateEditQuery({ id: id });
    const placeholdersData = usePlaceholdersQuery();
    const accounts = useEmailAccountsQuery();
    const [createNewPlaceholder, newPlaceholder] = useCreatePlaceholderMutation();
    const [updateTemplate, editedTemplate] = useUpdateEmailTemplateMutation();
    const [deleteTemplate, deleteResponse] = useDeleteEmailTemplateMutation();
    useErrorHandler(editedTemplate.error, newPlaceholder.error, deleteResponse.error);

    const [subject, setSubject] = useState('');
    const [sender, setSender] = useState('');
    const [replyTo, setReplyTo] = useState('');
    const [placeholders, setPlaceholders] = useState<Placeholder[]>([]);
    const [selectedPlaceholders, setSelectedPlaceholders] = useState<Placeholder[]>([]);
    const [newPlaceholderDescription, setNewPlaceholderDescription] = useState('');
    const [editorValue, setEditorValue] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [tempPlaceholder, setTempPlaceholder] = useState<NewPlaceHolderToSend>();

    useEffect(() => {
        (async () => {
            if (tempPlaceholder?.description && tempPlaceholder.name) {
                await handlePlaceholderCreate(tempPlaceholder);
                setTempPlaceholder({
                    name: '',
                    description: '',
                    custom: true,
                });
                setNewPlaceholderDescription('');
                setModalOpen(false);
            }
        })();
    }, [tempPlaceholder]);

    useEffect(() => {
        if (newPlaceholder.data) {
            const newSelection: Placeholder[] = [...selectedPlaceholders];
            const palceohlderList: Placeholder[] = [...placeholders];

            newSelection.push(newPlaceholder.data);
            palceohlderList.push(newPlaceholder.data);

            setSelectedPlaceholders(newSelection);
            setPlaceholders(palceohlderList);
        }
    }, [newPlaceholder.data]);

    useEffect(() => {
        if (emailTemplates.data) {
            setSubject(emailTemplates.data.subject);
            setSelectedPlaceholders(emailTemplates.data.placeholders);
            setSender(emailTemplates.data.sender.id.toString());
            setReplyTo(emailTemplates.data.reply_to.id.toString());
            setEditorValue(emailTemplates.data.content);
        }
    }, [emailTemplates.data]);

    useEffect(() => {
        if (placeholdersData.data) {
            setPlaceholders(placeholdersData.data);
        }
    }, [placeholdersData.data]);

    useEffect(() => {
        if (editedTemplate.data) {
            navigate('/emails/templates');
        }
    }, [editedTemplate]);

    const handleTemplateDelete = async () => {
        await deleteTemplate({ id: id });
        navigate('/emails/templates');
    };

    const handleTemplateEditSave = (): void => {
        if (emailTemplates.data && accounts.data) {
            const selPlaceh = selectedPlaceholders.map((sph) => sph.id),
                newData = {
                    name: emailTemplates.data.name,
                    subject: subject,
                    sender: parseInt(sender),
                    reply_to: parseInt(replyTo),
                    content: editorValue,
                };

            updateTemplate({
                id: id,
                placeholders: selPlaceh,
                data: newData,
            });
        }
    };

    const handlePlaceHolderChange = async (selectedValues: string[]) => {
        const newSelections: Placeholder[] = [];

        for (const selectedPlaceholderName of selectedValues) {
            const placeholderToAddToSelections = placeholders.find((ph) => ph.name === selectedPlaceholderName);
            if (placeholderToAddToSelections) {
                newSelections.push(placeholderToAddToSelections);
            } else if (typeof selectedPlaceholderName === 'string') {
                setNewPlaceholderDescription('');
                setModalOpen(true);

                const newPlaceholderData: NewPlaceHolderToSend = {
                    name: selectedPlaceholderName.toUpperCase(),
                    description: newPlaceholderDescription,
                    custom: true,
                };
                setTempPlaceholder(newPlaceholderData);
            }
        }

        setSelectedPlaceholders(newSelections);
    };

    const handlePlaceholderCreate = async (placeholder: NewPlaceHolderToSend) => {
        return createNewPlaceholder({ data: placeholder });
    };

    const handleModalSave = (): void => {
        if (tempPlaceholder) {
            setTempPlaceholder({
                name: tempPlaceholder?.name,
                description: newPlaceholderDescription,
                custom: true,
            });
        }
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`Sablon: ${id} szerkesztése`}>
                <div css={sectionStyles}>
                    <div css={inputsContainerStyles}>
                        <div css={textInputsContainerStyles}>
                            <TextField fullWidth id="subject" label="Tárgy" placeholder="Az e-mail tárgya" value={subject} onChange={(e) => setSubject(e.target.value)} css={inputsStyles} />
                            <FormControl sx={{ minWidth: 200, width: '100%' }}>
                                <InputLabel id="sender">Feladó</InputLabel>
                                <Select labelId="sender" id="sender-select" label="Feladó" value={sender} onChange={(e) => setSender(e.target.value)}>
                                    {accounts.data?.map((account: EmailAccount) => {
                                        return (
                                            <MenuItem key={account.id} value={account.id}>
                                                {account.displayed_name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ minWidth: 200, width: '100%' }}>
                                <InputLabel id="replyTo">Válasz cím</InputLabel>
                                <Select labelId="replyTo" id="replyTo-select" label="Válasz cím" value={replyTo} onChange={(e) => setReplyTo(e.target.value)}>
                                    {accounts.data?.map((account: EmailAccount) => {
                                        return (
                                            <MenuItem key={account.id} value={account.id}>
                                                {account.displayed_name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <div css={buttonInputsContainerStyles}>
                            <Button size="large" variant="contained" color="error" css={buttonStyle} onClick={() => setDeleteModalOpen(true)}>
                                Törlés
                            </Button>
                            <Button size="large" variant="contained" color="success" css={buttonStyle} onClick={handleTemplateEditSave}>
                                Mentés
                            </Button>
                        </div>
                    </div>
                    <Editor
                        value={editorValue}
                        onEditorChange={setEditorValue}
                        apiKey="lgcmej97gxzusi32t5d28t7clbmhhtsi5l2zi7gp0r15gltn"
                        init={{
                            height: 500,
                            menubar: true,
                            plugins: ['advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'],
                            toolbar:
                                'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist blockquote | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        }}
                    />
                    <Autocomplete
                        multiple
                        id="tags-standard"
                        options={placeholders.map((ph: Placeholder) => {
                            return ph.name;
                        })}
                        value={selectedPlaceholders.map((sph: Placeholder) => {
                            return sph.name;
                        })}
                        freeSolo
                        renderTags={(value: string[], getTagProps) => value.map((option: string, index: number) => <Chip variant="outlined" label={option} {...getTagProps({ index })} key={index} />)}
                        renderInput={(params) => <TextField {...params} label="Placeholderek: " placeholder="Placeholder" />}
                        onChange={(_event, value) => {
                            handlePlaceHolderChange(value);
                        }}
                    />
                </div>
            </Section>
            <Modal open={modalOpen} onClose={() => setModalOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box css={boxStyles}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ marginBottom: '1rem' }}>
                        Add meg a placeholder leírását:
                    </Typography>
                    <TextField fullWidth id="subject" label="Leírás" placeholder="Az új placeholder leírása" value={newPlaceholderDescription} onChange={(e) => setNewPlaceholderDescription(e.target.value)} css={inputsStyles} />
                    <Button size="large" variant="contained" css={boxButtonStyles} onClick={handleModalSave}>
                        Létrehoz
                    </Button>
                </Box>
            </Modal>
            <Modal open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)} aria-labelledby="delete-modal-title" aria-describedby="delete-modal-description">
                <Box css={boxStyles}>
                    <Typography id="delete-modal-title" variant="h6" component="h2" style={{ marginBottom: '1rem' }}>
                        Biztosan törölni szeretnéd a sablont?
                    </Typography>
                    <div css={buttonContainerStyles}>
                        <Button size="large" variant="contained" color="error" onClick={handleTemplateDelete}>
                            Igen
                        </Button>
                        <Button size="large" variant="contained" onClick={() => setDeleteModalOpen(false)}>
                            Nem
                        </Button>
                    </div>
                </Box>
            </Modal>
        </Paper>
    );
};

const containerStyles = css`
    position: relative;
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
`;

const inputsContainerStyles = css`
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @media (max-width: 1200px) {
        flex-direction: column;
    }
`;

const textInputsContainerStyles = css`
    display: flex;
    flex-grow: 4;
    gap: 20px;

    @media (max-width: 1200px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

const buttonInputsContainerStyles = css`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    gap: 20px;

    @media (max-width: 1200px) {
        justify-content: center;
        align-items: center;
    }
`;

const inputsStyles = css`
    min-width: 200px;
`;

const buttonStyle = css`
    padding: 0 3rem;
`;

const boxStyles = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 2px solid #0288d1;
    boxshadow: 24;
    padding: 1rem;
`;

const boxButtonStyles = css`
    float: right;
    margin-top: 1rem;
`;

const buttonContainerStyles = css`
    display: flex;
    justify-content: space-evenly;
    margin-top: 1rem;
`;

export default EmailTemplateEditPage;
