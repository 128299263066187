/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Button, TextField, Box, Chip, Autocomplete, Modal, Typography } from '@mui/material';
import useErrorHandler from '../../../../utils/userErrorHandler';
import { Tag } from '../../../ProductCreatePage/gql/acTags';
import useCreateAcTagMutation from '../../../ProductCreatePage/gql/createAcTag';
import useRemoveTagFromProductMutation from '../../gql/removeTagFromProduct';
import useAddTagToProductMutation from '../../gql/addTagToProduct';

interface NewTag {
    name: string;
    description: string;
}

type ProductTagsProps = {
    id: string;
    selectedTags: Tag[];
    tags: Tag[];
};

const ProductTags: React.FC<ProductTagsProps> = (props) => {
    const [createAcTag, newAcTagResp] = useCreateAcTagMutation();
    const [removeTag, removedTagResp] = useRemoveTagFromProductMutation();
    const [addTag, addedTagResp] = useAddTagToProductMutation();
    useErrorHandler(newAcTagResp.error, removedTagResp.error, addedTagResp.error);

    const [selectedTags, setSelectedTags] = useState<Tag[]>(props.selectedTags);
    const [tags, setTags] = useState<Tag[]>(props.tags);
    const [newTagDescription, setNewTagDescription] = useState('');
    const [tempTag, setTempTag] = useState<NewTag>();
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        (async () => {
            if (tempTag?.description && tempTag.name) {
                await handleTagCreate(tempTag);
                setTempTag({
                    name: '',
                    description: '',
                });
                setNewTagDescription('');
                setModalOpen(false);
            }
        })();
    }, [tempTag]);

    useEffect(() => {
        if (newAcTagResp.data) {
            const newSelection: Tag[] = [...selectedTags];
            const palceohlderList: Tag[] = [...tags];

            newSelection.push(newAcTagResp.data);
            palceohlderList.push(newAcTagResp.data);

            addTag({ product: parseInt(props.id), tag: newAcTagResp.data.id });

            setSelectedTags(newSelection);
            setTags(palceohlderList);
        }
    }, [newAcTagResp.data]);

    const handleTagChange = async (selectedValues: string[]) => {
        const newSelections: Tag[] = [];

        for (const selectedTagName of selectedValues) {
            const tagToAddToSelections = tags.find((ph) => ph.name === selectedTagName);
            if (tagToAddToSelections) {
                newSelections.push(tagToAddToSelections);
            } else if (typeof selectedTagName === 'string') {
                setNewTagDescription('');
                setModalOpen(true);

                const newTagData: NewTag = {
                    name: selectedTagName.toUpperCase(),
                    description: newTagDescription,
                };
                setTempTag(newTagData);
            }
        }

        const newSelectionsIds = newSelections.map((sel) => sel.id);
        const selectedTagsIds = selectedTags.map((sel) => sel.id);
        const difference = newSelectionsIds.filter((x) => !selectedTagsIds.includes(x)).concat(selectedTagsIds.filter((x) => !newSelectionsIds.includes(x)));

        if (difference.length !== 0) {
            if (newSelectionsIds.length > selectedTagsIds.length) {
                await addTag({ product: parseInt(props.id), tag: difference[0] });
            } else {
                await removeTag({ product: parseInt(props.id), tag: difference[0] });
            }
        }

        setSelectedTags(newSelections);
    };

    const handleTagCreate = async (tag: NewTag) => {
        return await createAcTag({ data: tag });
    };

    const handleModalSave = () => {
        if (tempTag) {
            setTempTag({
                name: tempTag?.name,
                description: newTagDescription,
            });
        }
    };

    return (
        <>
            <Autocomplete
                multiple
                id="tags-standard"
                options={tags.map((data: Tag) => {
                    return data.name;
                })}
                value={selectedTags.map((selData: Tag) => {
                    return selData.name;
                })}
                freeSolo
                renderTags={(value: string[], getTagProps) => value.map((option: string, index: number) => <Chip variant="outlined" color="info" label={option} {...getTagProps({ index })} key={index} />)}
                renderInput={(params) => <TextField {...params} label="Tagek" placeholder="Tagek" />}
                onChange={(_event, value) => {
                    handleTagChange(value);
                }}
                css={inputsStyles}
            />
            <Modal open={modalOpen} onClose={() => setModalOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" style={{ position: 'absolute' }}>
                <Box css={boxStyles}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ marginBottom: '1rem' }}>
                        Add meg a tag leírását:
                    </Typography>
                    <TextField fullWidth id="subject" label="Leírás" placeholder="Az új tag leírása" value={newTagDescription} onChange={(e) => setNewTagDescription(e.target.value)} css={inputsStyles} />
                    <Button size="large" variant="contained" css={boxButtonStyles} onClick={handleModalSave}>
                        Létrehoz
                    </Button>
                </Box>
            </Modal>
        </>
    );
};

const inputsStyles = css`
    width: 100%;
`;

const boxStyles = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 2px solid #0288d1;
    boxshadow: 24;
    padding: 1rem;
`;

const boxButtonStyles = css`
    float: right;
    margin-top: 1rem;
`;

export default ProductTags;
