import { gql } from '@apollo/client';
import { queryFactory } from '../../../../utils/gqlFactories';

const listContacts = gql`
    query listContacts($list: Int!) {
        listContacts(list: $list) {
            id
            customer {
                id
                firstname
                lastname
                phone
                customer {
                    email
                }
            }
            comment
            deleted
            assignedContact {
                id
                ts_user {
                    firstname
                    lastname
                }
                call_state {
                    name
                }
                talk_state {
                    name
                }
            }
        }
    }
`;

export interface ListContact {
    id: number;
    customer: {
        id: number;
        firstname: string;
        lastname: string;
        phone: string;
        customer: {
            email: string;
        };
    };
    comment: string;
    deleted: boolean;
    assignedContact: {
        id: number;
        ts_user: {
            firstname: string;
            lastname: string;
        };
        call_state: {
            name: string;
        };
        talk_state: {
            name: string;
        };
    };
}

const useListContacts = queryFactory<ListContact[]>(listContacts, 'listContacts');

export default useListContacts;
