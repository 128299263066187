import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const updateCustomerCharacteristicOption = gql`
    mutation updateCustomerCharacteristicOption($id: Int!, $data: CustomerCharacteristicOptionInputUpdate!) {
        updateCustomerCharacteristicOption(id: $id, data: $data) {
            id
        }
    }
`;

const useUpdateCustomerCharacteristicOption = mutationFactory<{ id: number }>(updateCustomerCharacteristicOption, 'updateCustomerCharacteristicOption');

export default useUpdateCustomerCharacteristicOption;
