/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { TableContainer, Table, TableBody, TableRow, TableCell, TableHead, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { Section } from '../../../components/Section';
import useCustomerCharacteristicOptions from './gql/customerCharacteristicOptions';
import useErrorHandler from '../../../utils/userErrorHandler';
import useCreateCustomerCharacteristic from './gql/createCustomerCharacteristic';
import useDeleteCustomerCharacteristic from './gql/deleteCustomerCharacteristic';
import { useSnackbar } from 'notistack';

type CustomerCharacteristicsProps = {
    id: number;
    characteristics: {
        id: number;
        characteristic_option: {
            id: number;
            name: string;
        };
        assigner: {
            firstname: string;
            lastname: string;
        };
        created_at: string;
    }[];
    refetch: () => void;
};

const CustomerCharacteristics: React.FC<CustomerCharacteristicsProps> = ({ id, characteristics, refetch }: CustomerCharacteristicsProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const customerCharacteristicOptions = useCustomerCharacteristicOptions();
    const [createCustomerCharacteristic, createdCustomerCharacteristic] = useCreateCustomerCharacteristic();
    const [deleteCustomerCharacteristic, deletedCustomerCharacteristic] = useDeleteCustomerCharacteristic();
    const [selectedCharacteristic, setSelectedCharacteristic] = useState(0);
    useErrorHandler(customerCharacteristicOptions.error, createdCustomerCharacteristic.error, deletedCustomerCharacteristic.error);

    useEffect(() => {
        if (createdCustomerCharacteristic.data || deletedCustomerCharacteristic.data) {
            setSelectedCharacteristic(0);
            enqueueSnackbar('A jellemzők sikeresen módosítva!', { variant: 'success' });
            refetch();
        }
    }, [createdCustomerCharacteristic.data, deletedCustomerCharacteristic.data]);

    const filteredOptions = () => {
        return customerCharacteristicOptions.data?.filter((option) => !characteristics.find((characteristic) => option.id === characteristic.characteristic_option.id));
    };

    const addCharacteristic = () => {
        if (selectedCharacteristic !== 0) {
            createCustomerCharacteristic({ data: { customer: id, characteristic: selectedCharacteristic } });
        }
    };

    return (
        <Section title="Ügyfél információk">
            <TableContainer>
                <Table aria-label="table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '50%', minWidth: 150 }}>Jellemző</TableCell>
                            <TableCell style={{ width: '20%', minWidth: 150 }}>Hozzáadta</TableCell>
                            <TableCell style={{ width: '20%', minWidth: 150 }}>Hozzáadva</TableCell>
                            <TableCell style={{ width: '10%', minWidth: 100 }}>Műveletek</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {characteristics.map((characteristic) => (
                            <TableRow css={trStyle} key={characteristic.characteristic_option.id}>
                                <TableCell>{characteristic.characteristic_option.name}</TableCell>
                                <TableCell>
                                    {characteristic.assigner.lastname} {characteristic.assigner.firstname}
                                </TableCell>
                                <TableCell>{characteristic.created_at}</TableCell>
                                <TableCell>
                                    <Button variant="contained" color="error" onClick={() => deleteCustomerCharacteristic({ id: characteristic.id })}>
                                        Törlés
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <div css={sectionStyles}>
                <FormControl fullWidth>
                    <InputLabel id="select">Jellemző</InputLabel>
                    <Select label="Jellemző" id="select" value={selectedCharacteristic === 0 ? '' : selectedCharacteristic} onChange={(e) => setSelectedCharacteristic(Number(e.target.value))}>
                        {filteredOptions()?.map((option) => (
                            <MenuItem value={option.id} key={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button variant="contained" color="success" size="large" style={{ minWidth: 250 }} onClick={addCharacteristic}>
                    Jellemző hozzáadása
                </Button>
            </div>
        </Section>
    );
};

const sectionStyles = css`
    display: flex;
    padding: 20px;

    & > * + * {
        margin-left: 20px !important;
    }

    @media (max-width: 620px) {
        flex-direction: column;

        & > * + * {
            margin-top: 20px !important;
            margin-left: 0 !important;
        }
    }
`;

const trStyle = css`
    &: nth-of-type(odd) {
        background-color: #e3f2fd;
    }

    &:last-child td,
    &:last-child th {
        border: 0;
    }
`;

export default CustomerCharacteristics;
