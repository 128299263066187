import { gql } from '@apollo/client';
import { lazyQueryFactory } from '../../../utils/gqlFactories';

const freebieSubscribersByEmail = gql`
    query freebieSubscribersByEmail($freebie: Int, $to: LocaleDateTime, $from: LocaleDateTime, $email: String) {
        freebieSubscribersByEmail(freebie: $freebie, to: $to, from: $from, email: $email) {
            id
            contact {
                firstname
                lastname
                customer {
                    email
                }
                phone
            }
            created_at
        }
    }
`;

type Customer = {
    firstname: string;
    lastname: string;
    customer: {
        email: string;
    };
    phone: string | null;
};

type Subscribers = {
    id: number;
    contact: Customer;
    created_at: string;
};

const useFreebieSubscribersByEmailQuery = lazyQueryFactory<Subscribers[]>(freebieSubscribersByEmail, 'freebieSubscribersByEmail');

export type { Subscribers };
export default useFreebieSubscribersByEmailQuery;
