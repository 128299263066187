import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const freebies = gql`
    query {
        freebies {
            id
            name
            description
            webinar_id
            subscribers
            created_at
        }
    }
`;

type Freebie = {
    id: number;
    name: string;
    description: string | null;
    webinar_id: number | null;
    subscribers: number;
    created_at: string;
};

const useFreebiesQuery = queryFactory<Freebie[]>(freebies, 'freebies');

export type { Freebie };
export default useFreebiesQuery;
