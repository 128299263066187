import { gql } from '@apollo/client';
import { lazyQueryFactory } from '../../../utils/gqlFactories';

const campaignsQuery = gql`
    query campaigns($from: LocaleDate, $to: LocaleDate) {
        campaigns(from: $from, to: $to) {
            id
            name
            start
            end
        }
    }
`;

interface Campaign {
    id: number;
    name: string;
    start: string;
    end: string;
}

const useCampaignsQuery = lazyQueryFactory<Campaign[]>(campaignsQuery, 'campaigns');

export default useCampaignsQuery;
