import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const deletePrice = gql`
    mutation deletePrice($id: Int!) {
        deletePrice(id: $id)
    }
`;

const useDeletePriceMutation = mutationFactory<number>(deletePrice, 'deletePrice');

export default useDeletePriceMutation;
