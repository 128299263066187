/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Paper, Button, TextField } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Section } from '../../components/Section';
import ProductSelection from './ProductSelection/ProductSelection';
import useProductsQuery, { Price } from './gql/products';
import useErrorHandler from '../../utils/userErrorHandler';
import ProductPriceSelection from './ProductPriceSelection/ProductPriceSelection';
import { useSnackbar } from 'notistack';
import { useParams, useNavigate } from 'react-router-dom';
import useCreateCustomerDetailMutation from './gql/createCustomerDetail';
import useCreateExpectedOrderMutation from './gql/createExpectedOrder';
import CustomerFilter from './CustomerFilter/CustomerFilter';
import { CustomerDetails } from './gql/customerDetails';
import useCustomerDetailQuery from './gql/customerDetail';
import useCustomersLazyQuery from '../NewOrderPage/gql/customers';

const ExpectedOrderCreatePage: React.FC = () => {
    const params = useParams();
    const customerId = params.customerid as string;
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const customerDetailResp = customerId ? useCustomerDetailQuery({ id: parseInt(customerId) }) : null;
    const productsResp = useProductsQuery();
    const [customers, customersResp] = useCustomersLazyQuery();
    const [createCustomerDetail, createCustomerDetailResp] = useCreateCustomerDetailMutation();
    const [createExpectedOrder, createExpectedOrderResp] = useCreateExpectedOrderMutation();

    useErrorHandler(productsResp.error, createCustomerDetailResp.error, createExpectedOrderResp.error, customerDetailResp?.error, customersResp.error);

    const [lastname, setLastname] = useState<string>('');
    const [firstname, setFirstname] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [selProduct, setSelProduct] = useState<number | null>(null);
    const [selProductPriceOptions, setSelProductPriceOptions] = useState<Price[]>([]);
    const [hasPrices, setHasPrices] = useState<boolean | null>(null);
    const [selProductPrice, setSelProductPrice] = useState<number | null>(null);
    const [detailsOptions, setDetailsOptions] = React.useState<CustomerDetails[]>([]);
    const [selCustomer, setSelCustomer] = useState<CustomerDetails[]>([]);
    const [searchEmail, setSearchEmail] = useState<string>('');
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (customerId) {
            customerDetailResp?.data && setSelCustomer([customerDetailResp.data]);
        }
    }, [customerDetailResp?.data]);

    useEffect(() => {
        if (createCustomerDetailResp.data) {
            setSelCustomer([createCustomerDetailResp.data]);
            enqueueSnackbar('Sikeres vevő adat rögzítés/módosítás!', { variant: 'success' });
        }
    }, [createCustomerDetailResp.data]);

    useEffect(() => {
        if (!customerId) {
            setDetailsOptions([]);
            setSelCustomer([]);
            if (searchEmail !== '') {
                console.log(searchEmail);
                const timer = setTimeout(() => {
                    customers({ email: searchEmail });
                }, 500);

                return () => clearTimeout(timer);
            } else if (searchEmail === '') {
                setDetailsOptions([]);
                setSelCustomer([]);
            }
        }
    }, [searchEmail]);

    useEffect(() => {
        if (customersResp.data) {
            setDetailsOptions(customersResp.data.map((customer) => customer.details).flat());
        }
        customersResp.data?.length === 0 && searchEmail.length > 0 && enqueueSnackbar('Nincs egy találat sem!', { variant: 'warning' });
    }, [customersResp.data]);

    useEffect(() => {
        if (selCustomer.length === 1) {
            setLastname(selCustomer[0].lastname);
            setFirstname(selCustomer[0].firstname);
            setEmail(selCustomer[0].customer.email);
            setPhone(selCustomer[0].phone);
        } else {
            setLastname('');
            setFirstname('');
            setEmail('');
            setPhone('');
        }
    }, [selCustomer]);

    useEffect(() => {
        if (selProduct && productsResp.data) {
            const choosenProduct = productsResp.data.find((product) => product.id === selProduct);
            if (choosenProduct?.prices && choosenProduct.prices.length > 0) {
                setSelProductPriceOptions(choosenProduct.prices);
                setHasPrices(true);
            } else {
                setHasPrices(false);
            }
        } else {
            setHasPrices(null);
        }
    }, [selProduct]);

    useEffect(() => {
        if (hasPrices === false && selProduct) {
            setSelProductPriceOptions([]);
            setSelProductPrice(null);
            enqueueSnackbar('Nem szerepel listázható ár a terméken! Ezért előrendelés nem rögzíthető a kiválasztott termékkel!', { variant: 'warning' });
        }
    }, [hasPrices]);

    useEffect(() => {
        if (detailsOptions.length > 0) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [detailsOptions]);

    const handleSave = async () => {
        if (selCustomer && selProduct && selProductPrice) {
            await createExpectedOrder({ data: { customer: selCustomer[0].id, product: selProduct, price: selProductPrice } });
            navigate('/orders/expected-orders/list');
        } else {
            enqueueSnackbar('Az összes csillaggal jelölt mező kitöltése kötelező!', { variant: 'error' });
        }
    };

    const handleCustomerSave = async () => {
        const rgx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;
        const match = email.match(rgx);
        if (selCustomer.length !== 0) {
            if (lastname === selCustomer[0].lastname && firstname === selCustomer[0].firstname && phone === selCustomer[0].phone) {
                enqueueSnackbar('Az adatok megegyeznek a szerveren találhatóval!', { variant: 'warning' });
            } else {
                if (lastname && firstname && email && phone) {
                    if (match) {
                        await createCustomerDetail({ data: { email, firstname, lastname, phone } });
                        customersResp.refetch();
                    } else {
                        enqueueSnackbar('Az e-mail formátuma nem megfelelő!', { variant: 'error' });
                    }
                } else {
                    enqueueSnackbar('Az összes csillaggal jelölt mező kitöltése kötelező!', { variant: 'error' });
                }
            }
        } else {
            if (lastname && firstname && email && phone) {
                if (match) {
                    await createCustomerDetail({ data: { email, firstname, lastname, phone } });
                    customersResp.refetch();
                } else {
                    enqueueSnackbar('Az e-mail formátuma nem megfelelő!', { variant: 'error' });
                }
            } else {
                enqueueSnackbar('Az összes csillaggal jelölt mező kitöltése kötelező!', { variant: 'error' });
            }
        }
    };

    const swapNames = () => {
        const fname = firstname;
        const lname = lastname;
        setFirstname(lname);
        setLastname(fname);
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`Várható rendelés létrehozása`}>
                <div css={sectionStyles}>
                    <div css={inputsContainerStyles}>
                        {!customerId && <CustomerFilter selected={selCustomer} setSelected={setSelCustomer} options={detailsOptions} search={searchEmail} setSearch={setSearchEmail} open={open} setOpen={setOpen} />}
                        {productsResp.data && <ProductSelection selections={selProduct} setSelections={setSelProduct} options={productsResp.data} />}
                        {hasPrices && <ProductPriceSelection selections={selProductPrice} setSelections={setSelProductPrice} options={selProductPriceOptions} />}
                    </div>
                    <div css={buttonInputsContainerStyles}>
                        <Button size="large" variant="contained" color="success" onClick={handleSave}>
                            {`Létrehozás`}
                        </Button>
                    </div>
                </div>
            </Section>
            <Section title={selCustomer.length !== 0 || customerId ? `Vevő adatainak szerkesztése` : 'Új vevő hozzáadása'}>
                <div css={sectionStyles}>
                    <div css={inputsContainerStyles}>
                        <TextField fullWidth id="lastname" label="Vezetéknév" placeholder="A vevő vezetékneve" value={lastname} onChange={(e) => setLastname(e.target.value)} css={inputsStyles} required />
                        <Button onClick={swapNames} css={inputsStyles}>
                            <AutorenewIcon />
                        </Button>
                        <TextField fullWidth id="firstname" label="Keresznév" placeholder="A vevő kereszneve" value={firstname} onChange={(e) => setFirstname(e.target.value)} css={inputsStyles} required />
                        <TextField
                            fullWidth
                            id="email"
                            label="Email"
                            placeholder="A vevő e-mail címe"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            css={inputsStyles}
                            required
                            disabled={selCustomer.length !== 0 || customerId ? true : false}
                        />
                        <TextField fullWidth id="phone" label="Telefon" placeholder="A vevő telefon száma" value={phone} onChange={(e) => setPhone(e.target.value)} css={inputsStyles} required />
                    </div>
                    <div css={buttonInputsContainerStyles}>
                        <Button size="large" variant="contained" color="success" onClick={handleCustomerSave}>
                            {selCustomer.length !== 0 || customerId ? `Szerkeszt` : `Rögzít`}
                        </Button>
                    </div>
                </div>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    position: relative;
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;

    & > * + * {
        margin-top: 25px !important;
    }
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;

    & > * + * {
        margin-top: 20px !important;
    }
`;

const inputsContainerStyles = css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    & > * + * {
        margin-top: 20px !important;
    }
`;

const inputsStyles = css`
    width: 100%;
`;

const buttonInputsContainerStyles = css`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;

    @media (max-width: 1200px) {
        justify-content: center;
        align-items: center;
    }
`;

export default ExpectedOrderCreatePage;
