import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const meQuery = gql`
    query {
        me {
            id
            username
            firstname
            lastname
            middlename
            email
            permission {
                name
            }
        }
    }
`;

interface MeData {
    id: number;
    username: string;
    firstname: string;
    lastname: string;
    middlename: string;
    email: string;
    permission: {
        name: string;
    };
}

const useMeQuery = queryFactory<MeData>(meQuery, 'me');

export default useMeQuery;
