/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';

import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { Section } from '../../components/Section';

import { Orders } from './Orders';
import useOrdersQuery, { Order } from './gql/orders';
import useErrorHandler from '../../utils/userErrorHandler';
import OrderList from './OrderList/OrderList';

const OrdersPage: React.FC = () => {
    const ordersResp = useOrdersQuery();
    useErrorHandler(ordersResp.error);

    const [orders, setOrders] = useState<Order[]>([]);
    const [compactView, setCompactView] = useState(false);

    useEffect(() => {
        ordersResp.data && setOrders(ordersResp.data);
    }, [ordersResp.data]);

    return (
        <Paper css={containerStyles} elevation={0}>
            <FormControlLabel control={<Switch checked={compactView} onChange={() => setCompactView(!compactView)} />} label="Kompakt nézet" />
            <Section title="Rendelések">{orders && compactView ? <Orders orders={orders} /> : <OrderList orders={orders} />}</Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

export default OrdersPage;
