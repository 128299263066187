/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Section } from '../../components/Section';
import { Paper, Table, TableHead, TableBody, TableCell, TableRow, TableContainer, Button, Modal, Box, Typography } from '@mui/material';
import useProductCategories from './gql/productCategories';
import useErrorHandler from '../../utils/userErrorHandler';
import useDeleteProductCategoryMutation from './gql/deleteProductCategory';

const CategoriesPage: React.FC = () => {
    const categories = useProductCategories();
    const [deleteCategory, deletedCategoryResp] = useDeleteProductCategoryMutation();
    useErrorHandler(categories.error, deletedCategoryResp.error);

    const [modalOpen, setModalOpen] = useState(false);
    const [selected, setSelected] = useState<number>();

    useEffect(() => {
        if (selected) categories.refetch();
    }, [deletedCategoryResp.data]);

    const handleModal = (id: number) => {
        setModalOpen(true);
        setSelected(id);
    };

    const handleDelete = async () => {
        await deleteCategory({ id: selected });
        setModalOpen(false);
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title="Kategóriák">
                <TableContainer>
                    <Table aria-label="table" style={{ minWidth: 1310 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">ID</TableCell>
                                <TableCell align="center">Név</TableCell>
                                <TableCell align="center">Műveletek</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {categories.data?.map((product) => (
                                <TableRow css={trStyle} key={product.id}>
                                    <TableCell align="center">{product.id}</TableCell>
                                    <TableCell align="center">{product.name}</TableCell>
                                    <TableCell align="center" css={actionsColStyle}>
                                        <Button variant="contained" color="error" onClick={() => handleModal(product.id)}>
                                            Törlés
                                        </Button>
                                        <Link to={`/products/categories/${product.id}/edit`} style={{ textDecoration: 'none' }}>
                                            <Button variant="contained" color="primary">
                                                Szerkesztés
                                            </Button>
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Link to="/products/categories/create" css={buttonContainerStyle}>
                    <Button variant="contained" color="success">
                        Új hozzáadása
                    </Button>
                </Link>
            </Section>
            <Modal open={modalOpen} onClose={() => setModalOpen(false)} aria-labelledby="delete-modal-title" aria-describedby="delete-modal-description">
                <Box css={boxStyles}>
                    <Typography id="delete-modal-title" variant="h6" component="h2" style={{ marginBottom: '1rem' }}>
                        Biztosan törölni szeretné a kategóriát?
                    </Typography>
                    <div css={buttonContainerStyles}>
                        <Button size="large" variant="contained" color="error" onClick={handleDelete}>
                            Igen
                        </Button>
                        <Button size="large" variant="contained" onClick={() => setModalOpen(false)}>
                            Nem
                        </Button>
                    </div>
                </Box>
            </Modal>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const trStyle = css`
    &: nth-of-type(odd) {
        background-color: #e3f2fd;
    }

    &:last-child td,
    &:last-child th {
        border: 0;
    }
`;

const actionsColStyle = css`
    & > * + * {
        margin-left: 20px !important;
    }
`;

const buttonContainerStyle = css`
    text-decoration: none;
    margin-left: auto;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const boxStyles = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 2px solid #0288d1;
    boxshadow: 24;
    padding: 1rem;
`;

const buttonContainerStyles = css`
    display: flex;
    justify-content: space-evenly;
    margin-top: 1rem;
`;

export default CategoriesPage;
