import { gql } from '@apollo/client';
import { mutationFactory } from '../../../../utils/gqlFactories';

const createCustomerCharacteristic = gql`
    mutation createCustomerCharacteristic($data: CustomerCharacteristicInputCreate!) {
        createCustomerCharacteristic(data: $data) {
            id
        }
    }
`;

const useCreateCustomerCharacteristic = mutationFactory<{ id: number }>(createCustomerCharacteristic, 'createCustomerCharacteristic');

export default useCreateCustomerCharacteristic;
