import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const deleteCampaignMutation = gql`
    mutation deleteCampaign($id: Int!) {
        deleteCampaign(id: $id)
    }
`;

const useDeleteCampaignMutation = mutationFactory(deleteCampaignMutation, 'deleteCampaign');

export default useDeleteCampaignMutation;
