import { ApolloError } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';

function useErrorHandler(...args: (ApolloError | undefined)[]): void {
    const { enqueueSnackbar } = useSnackbar();

    for (const error of args) {
        useEffect(() => {
            if (error) {
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        }, [error]);
    }
}

export default useErrorHandler;
