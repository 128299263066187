import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const updateWorkingHourMutation = gql`
    mutation updateWorkingHour($id: Int!, $data: WorkingHourInputUpdate!) {
        updateWorkingHour(id: $id, data: $data) {
            id
        }
    }
`;

interface WorkingHour {
    id: number;
}

const useUpdateWorkingHourMutation = mutationFactory<WorkingHour>(updateWorkingHourMutation, 'updateWorkingHour');

export default useUpdateWorkingHourMutation;
