import { gql, OperationVariables } from '@apollo/client';
import { useQuery } from '@apollo/client';

const myProgression = gql`
    query myProgression($year: Int!, $month: Int!, $quarter: Int!) {
        monthly: myMonthlyTargetProgress(year: $year, month: $month) {
            target
            progress
        }

        quarterly: myQuarterlyTargetProgress(year: $year, quarter: $quarter) {
            target
            progress
        }
    }
`;

export interface Progression {
    monthly: {
        target: number;
        progress: number;
    };
    quarterly: {
        target: number;
        progress: number;
    };
}

const useMyProgression = (args?: unknown) => {
    const res = useQuery<Progression>(myProgression, { variables: args as OperationVariables | undefined, fetchPolicy: 'network-only', errorPolicy: 'all' });
    return res;
};

export default useMyProgression;
