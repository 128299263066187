import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const acTags = gql`
    query {
        acTags {
            id
            name
        }
    }
`;

type Tag = {
    id: number;
    name: string;
};

const useAcTagsQuery = queryFactory<Tag[]>(acTags, 'acTags');

export type { Tag };
export default useAcTagsQuery;
