import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const addEmailAccountPermissionForUser = gql`
    mutation addEmailAccountPermissionForUser($account: Int!, $user: Int!) {
        addEmailAccountPermissionForUser(account: $account, user: $user) {
            id
        }
    }
`;

interface EmailAccount {
    id: number;
}

const useAddEmailAccountPermissionForUser = mutationFactory<EmailAccount>(addEmailAccountPermissionForUser, 'addEmailAccountPermissionForUser');

export default useAddEmailAccountPermissionForUser;
