import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const usersQuery = gql`
    query {
        users {
            id
            username
            firstname
            lastname
            middlename
            permission {
                id
                name
            }
            active
        }
    }
`;

interface User {
    id: number;
    username: string;
    lastname: string;
    firstname: string;
    middlename: string;
    permission: {
        id: number;
        name: string;
    };
    active: boolean;
}

const useUsersQuery = queryFactory<User[]>(usersQuery, 'users');

export type { User };
export default useUsersQuery;
