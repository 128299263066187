import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const updateUserMutation = gql`
    mutation updateUser($id: Int!, $data: UserInputUpdate!) {
        updateUser(id: $id, data: $data) {
            id
        }
    }
`;

interface UpdatedUser {
    id: number;
}

const useUpdateUserMutation = mutationFactory<UpdatedUser>(updateUserMutation, 'updateUser');

export default useUpdateUserMutation;
