import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const updateAssignedContact = gql`
    mutation updateAssignedContact($id: Int!, $data: AssignedContactInputUpdate!) {
        updateAssignedContact(id: $id, data: $data) {
            id
        }
    }
`;

const useUpdateAssignedContact = mutationFactory<{ id: number }>(updateAssignedContact, 'updateAssignedContact');

export default useUpdateAssignedContact;
