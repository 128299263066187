import { gql } from '@apollo/client';
import { queryFactory } from '../../../../utils/gqlFactories';

const telesalesUsers = gql`
    query telesalesUsers {
        telesalesUsers {
            id
            lastname
            firstname
        }
    }
`;

interface User {
    id: number;
    firstname: string;
    lastname: string;
}

const useTelesalesUsers = queryFactory<User[]>(telesalesUsers, 'telesalesUsers');

export default useTelesalesUsers;
