import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const removeTagFromProductMutation = gql`
    mutation removeTagFromProduct($product: Int!, $tag: Int!) {
        removeTagFromProduct(product: $product, tag: $tag) {
            id
        }
    }
`;

interface Tag {
    id: number;
}

const useRemoveTagFromProductMutation = mutationFactory<Tag>(removeTagFromProductMutation, 'removeTagFromProduct');

export default useRemoveTagFromProductMutation;
