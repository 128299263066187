/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Section } from '../../components/Section';
import { Paper, Table, TableHead, TableBody, TableCell, TableRow, TableContainer, Button, Typography, Modal, Box } from '@mui/material';
import useAcTags from './gql/acTags';
import useErrorHandler from '../../utils/userErrorHandler';
import useDeleteAcTagMutation from './gql/deleteAcTag';

const TagsPage: React.FC = () => {
    const acTags = useAcTags();
    const [deleteAcTag, deletedAcTagResp] = useDeleteAcTagMutation();

    useErrorHandler(acTags.error, deletedAcTagResp.error);

    const [selected, setSelected] = useState<number>();
    const [modalOpen, setModalOpen] = useState(false);

    const handleModal = (id: number) => {
        setModalOpen(true);
        setSelected(id);
    };

    const handleDelete = async () => {
        await deleteAcTag({ id: selected });
        acTags.refetch();
        setModalOpen(false);
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title="Tagek">
                <TableContainer>
                    <Table aria-label="table" style={{ minWidth: 1310 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">ID</TableCell>
                                <TableCell align="center">Név</TableCell>
                                <TableCell align="center">Leírás</TableCell>
                                <TableCell align="center">Műveletek</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {acTags.data &&
                                acTags.data.map((acTag) => (
                                    <TableRow css={trStyles} key={acTag.id}>
                                        <TableCell align="center">{acTag.id}</TableCell>
                                        <TableCell align="center">{acTag.name}</TableCell>
                                        <TableCell align="center">{acTag.description}</TableCell>
                                        <TableCell align="center" css={actionsColStyles}>
                                            <Button variant="contained" color="error" onClick={() => handleModal(acTag.id)}>
                                                Törlés
                                            </Button>
                                            <Link to={`/marketing/tags/${acTag.id}/edit`} style={{ textDecoration: 'none' }}>
                                                <Button variant="contained" color="primary">
                                                    Szerkesztés
                                                </Button>
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Link to="/marketing/tags/create" css={buttonContainerStyles}>
                    <Button variant="contained" color="success">
                        Új hozzáadása
                    </Button>
                </Link>
            </Section>
            <Modal open={modalOpen} onClose={() => setModalOpen(false)} aria-labelledby="delete-modal-title" aria-describedby="delete-modal-description">
                <Box css={boxStyles}>
                    <Typography id="delete-modal-title" variant="h6" component="h2" style={{ marginBottom: '1rem' }}>
                        Biztosan törölni szeretnéd a taget?
                    </Typography>
                    <div css={modalButtonContainerStyles}>
                        <Button size="large" variant="contained" color="error" onClick={handleDelete}>
                            Igen
                        </Button>
                        <Button size="large" variant="contained" onClick={() => setModalOpen(false)}>
                            Nem
                        </Button>
                    </div>
                </Box>
            </Modal>
        </Paper>
    );
};

const containerStyles = css`
    position: relative;
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const trStyles = css`
    &: nth-of-type(odd) {
        background-color: #e3f2fd;
    }

    &:last-child td,
    &:last-child th {
        border: 0;
    }
`;

const actionsColStyles = css`
    white-space: nowrap;
    & > * + * {
        margin-left: 10px !important;
    }
`;

const buttonContainerStyles = css`
    text-decoration: none;
    margin-left: auto;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const boxStyles = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 2px solid #0288d1;
    boxshadow: 24;
    padding: 1rem;
`;

const modalButtonContainerStyles = css`
    display: flex;
    justify-content: space-evenly;
    margin-top: 1rem;
`;

export default TagsPage;
