import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const deleteAcTagMutation = gql`
    mutation deleteAcTag($id: Int!) {
        deleteAcTag(id: $id)
    }
`;

const useDeleteAcTagMutation = mutationFactory(deleteAcTagMutation, 'deleteAcTag');

export default useDeleteAcTagMutation;
