import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const updateMeMutation = gql`
    mutation updateMe($data: UserInputUpdate!) {
        updateMe(data: $data) {
            id
            username
            firstname
            lastname
            middlename
            email
            permission {
                name
            }
        }
    }
`;

interface MeData {
    id: number;
    username: string;
    firstname: string;
    lastname: string;
    middlename: string;
    email: string;
    permission: {
        name: string;
    };
}

const useUpdateMeMutation = mutationFactory<MeData>(updateMeMutation, 'updateMe');

export default useUpdateMeMutation;
