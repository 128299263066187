import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const createCustomerDetailMutation = gql`
    mutation createCustomerDetail($data: CustomerDetailInputCreate!) {
        createCustomerDetail(data: $data) {
            id
            customer {
                email
            }
            firstname
            lastname
            phone
            created_at
        }
    }
`;

interface CreatedCustomerDetail {
    id: number;
    customer: { email: string };
    firstname: string;
    lastname: string;
    phone: string;
    created_at: string;
}

const useCreateCustomerDetailMutation = mutationFactory<CreatedCustomerDetail>(createCustomerDetailMutation, 'createCustomerDetail');

export default useCreateCustomerDetailMutation;
