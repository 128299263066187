import { gql } from '@apollo/client';
import { lazyQueryFactory } from '../../../utils/gqlFactories';

const currencyRateQuery = gql`
    query currencyRate($currency: Int!) {
        currencyRate(currency: $currency) {
            rate
            source
        }
    }
`;

interface Currencyrate {
    rate: number;
    source: string;
}

const useCurrencyRateQuery = lazyQueryFactory<Currencyrate>(currencyRateQuery, 'currencyRate');

export type { Currencyrate };
export default useCurrencyRateQuery;
