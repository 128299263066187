/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Product } from '../gql/products';

type ProductSelectionProps = {
    selections: number | null;
    setSelections: React.Dispatch<React.SetStateAction<number | null>>;
    options: Product[];
};

const ProductSelection: React.FC<ProductSelectionProps> = (props) => {
    const [value, setValue] = useState<string>('Nincs megadva');

    useEffect(() => {
        if (props.options) handleValue();
    }, [props.options]);

    const handleChange = (selectedValue: string | null): void => {
        if (selectedValue) {
            const value = selectedValue.split(',', 1)[0];
            const selected = props.options?.find((product) => product.name === value);
            selected ? props.setSelections(selected.id) : props.setSelections(null);
            if (selected) {
                setValue(selected.name);
            } else {
                setValue('Nincs megadva');
            }
        }
    };

    const handleValue = () => {
        const selected = props.options?.find((product) => product.id === props.selections);
        if (selected) {
            setValue(selected.name);
        } else {
            setValue('Nincs megadva');
        }
    };

    return (
        <Autocomplete
            options={
                props.options
                    ? [
                          'Nincs megadva',
                          ...props.options.map((data: Product) => {
                              return data.name;
                          }),
                      ]
                    : []
            }
            value={value}
            renderInput={(params) => <TextField {...params} label="Termék *" placeholder="Termék" />}
            onChange={(_event, value) => {
                handleChange(value);
            }}
            css={inputsStyles}
        />
    );
};

const inputsStyles = css`
    width: 100%;
`;

export default ProductSelection;
