import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const meQuery = gql`
    query {
        me {
            id
            username
            permission {
                id
                name
            }
        }
    }
`;

interface MeData {
    id: number;
    username: string;
    permission: {
        id: number;
        name: string;
    };
}

const useMeQuery = queryFactory<MeData>(meQuery, 'me');

export default useMeQuery;
