import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const acTags = gql`
    query {
        acTags {
            id
            name
            description
        }
    }
`;

interface AcTag {
    id: number;
    name: string;
    description: string;
}

const useAcTags = queryFactory<AcTag[]>(acTags, 'acTags');

export type { AcTag };
export default useAcTags;
