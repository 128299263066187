/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { Paper, Button, Alert } from '@mui/material';

import { Section } from '../../components/Section';
import { Orders } from '../OrdersPage/Orders';

import useErrorHandler from '../../utils/userErrorHandler';
import useOrdersQuery, { Order } from './gql/orders';
import useUsersQuery, { User } from './gql/users';

interface TaskData {
    orderId: number;
    sellerId: number | null;
}

const AwaitingOrdersPage: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const ordersResp = useOrdersQuery();
    const usersResp = useUsersQuery();

    useErrorHandler(ordersResp.error, usersResp.error);

    const [orders, setOrders] = useState<Order[]>([]);
    const [sellers, setSellers] = useState<User[]>([]);
    const [tasksData, setTasksData] = useState<TaskData[]>([]);

    useEffect(() => {
        if (ordersResp.data) {
            setOrders(ordersResp.data);
            setTasksData(
                ordersResp.data.map((order) => {
                    return { orderId: order.id, sellerId: null };
                }),
            );
        }
    }, [ordersResp.data]);

    useEffect(() => {
        if (usersResp.data) {
            setSellers(usersResp.data.filter((user) => user.permission.name.includes('telesales')));
        }
    }, [usersResp.data]);

    const changeSellerIdOnOrder = (orderId: number, sellerId: number | null) => {
        if (tasksData.length > 0) {
            setTasksData((prevState) => {
                const newState = prevState.map((obj) => {
                    if (obj.orderId === orderId) {
                        return { ...obj, sellerId: sellerId };
                    }
                    return obj;
                });

                return newState;
            });
        }
    };

    const handleIDK = async () => {
        if (tasksData.find((task) => task.sellerId === null)) {
            enqueueSnackbar('A feladatok kiosztásához az összes sorhoz kötelező értékesítőt rendelni!', { variant: 'error' });
        } else {
            enqueueSnackbar('A feladatok kiosztásra kerültek!', { variant: 'success' });
        }
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title="Jóváhagyásra váró rendelések">{orders && <Orders orders={orders} sellers={sellers} selectSeller={changeSellerIdOnOrder} />}</Section>
            <Alert variant="outlined" severity="warning" sx={{ marginBottom: '20px' }}>
                Sikeres kiosztás esetén az összesrendelés ELFOGADOTTNAK minősül!
            </Alert>
            <Button variant="contained" color="success" css={buttonContainerStyles} onClick={handleIDK}>
                Szétoszt
            </Button>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const buttonContainerStyles = css`
    text-decoration: none;
    margin-left: auto;
`;

export default AwaitingOrdersPage;
