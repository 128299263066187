import { ApolloClient, gql, InMemoryCache } from '@apollo/client';

const myLatePaymentListContacts = gql`
    query myLatePaymentListContacts($latePaymentList: Int!) {
        myLatePaymentListContacts(latePaymentList: $latePaymentList) {
            id
            customer {
                firstname
                lastname
                phone
                customer {
                    email
                }
            }
            assignedContact {
                id
                call_state {
                    name
                }
                talk_state {
                    name
                }
            }
            comment
            deleted
            created_at
        }
    }
`;

export interface LatePaymentListContact {
    id: number;
    customer: {
        firstname: string;
        lastname: string;
        phone: string;
        customer: {
            email: string;
        };
    };
    assignedContact: {
        id: number;
        call_state: {
            name: string;
        };
        talk_state: {
            name: string;
        };
    };
    comment: string;
    deleted: boolean;
    created_at: string;
}

const client = new ApolloClient({
    uri: 'https://api.maganpenzugyiakademia.hu',
    cache: new InMemoryCache(),
    credentials: 'include',
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getMyLatePaymentListContacts = (id: number) =>
    client.query<{ myLatePaymentListContacts: LatePaymentListContact[] }>({ query: myLatePaymentListContacts, variables: { latePaymentList: id }, fetchPolicy: 'network-only', errorPolicy: 'all' });

export default getMyLatePaymentListContacts;
