/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, TextField, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import DataGridLocalization from '../../../utils/DataGridLocalization';
import { Section } from '../../../components/Section';
import useErrorHandler from '../../../utils/userErrorHandler';
import { useSnackbar } from 'notistack';
import useCustomerNotes, { CustomerNote } from './gql/customerNotes';
import useCreateCustomerNote from './gql/createCustomerNote';
import useUpdateCustomerNote from './gql/updateCustomerNote';
import useDeleteCustomerNote from './gql/deleteCustomerNote';

type CustomerNotesProps = {
    id: number;
};

const CustomerNotes: React.FC<CustomerNotesProps> = ({ id }: CustomerNotesProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const customerNotes = useCustomerNotes({ customer: id });
    const [createCustomerNote, createdCustomerNote] = useCreateCustomerNote();
    const [updateCustomerNote, updatedCustomerNote] = useUpdateCustomerNote();
    const [deleteCustomerNote, deletedCustomerNote] = useDeleteCustomerNote();
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
    const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [clickedNoteID, setClickedNoteID] = useState(0);
    const [note, setNote] = useState('');
    useErrorHandler(customerNotes.error, createdCustomerNote.error, updatedCustomerNote.error, deletedCustomerNote.error);

    useEffect(() => {
        if (createdCustomerNote.data || updatedCustomerNote.data || deletedCustomerNote.data) {
            setIsCreateDialogOpen(false);
            setIsUpdateDialogOpen(false);
            setIsDeleteDialogOpen(false);
            setNote('');
            enqueueSnackbar('A megjegyzések sikeresen módosítva!', { variant: 'success' });
            customerNotes.refetch();
        }
    }, [createdCustomerNote.data, updatedCustomerNote.data, deletedCustomerNote.data]);

    const handleActionClick = (action: 'update' | 'delete', id: number) => {
        setClickedNoteID(id);

        if (action === 'update') {
            setIsUpdateDialogOpen(true);

            const note = customerNotes.data?.find((note) => note.id === id);
            setNote(note?.note || '');
        }
        if (action === 'delete') setIsDeleteDialogOpen(true);
    };

    const createNote = () => {
        createCustomerNote({ data: { customer: id, note: note } });
    };

    const updateNote = () => {
        if (clickedNoteID !== 0) updateCustomerNote({ id: clickedNoteID, data: { note: note } });
    };

    const deleteNote = () => {
        if (clickedNoteID !== 0) deleteCustomerNote({ id: clickedNoteID });
    };

    const makeRows = (customerNotes: CustomerNote[]) => {
        const rows: GridRowsProp = customerNotes.map((note) => ({
            id: note.id,
            noteID: note.id,
            note: note.note,
            creator: `${note.creator.lastname} ${note.creator.firstname}`,
            date: note.created_at,
        }));

        return rows;
    };

    const makeCols = () => {
        const columns: GridColDef[] = [
            { field: 'noteID', headerName: 'ID', width: 100 },
            { field: 'note', headerName: 'Megjegyzés', width: 850 },
            { field: 'creator', headerName: 'Létrehozta', width: 150 },
            { field: 'date', headerName: 'Létrehozva', width: 200 },
            {
                field: 'actions',
                headerName: 'Műveletek',
                width: 150,
                renderCell: function renderActions(cellValues) {
                    return (
                        <div css={buttonsContainerStyles}>
                            <Button variant="contained" size="small" color="error" onClick={() => handleActionClick('delete', cellValues.row.id)} style={{ minWidth: 32 }}>
                                <DeleteForeverIcon />
                            </Button>
                            <Button variant="contained" size="small" color="primary" onClick={() => handleActionClick('update', cellValues.row.id)} style={{ minWidth: 32 }}>
                                <EditIcon />
                            </Button>
                        </div>
                    );
                },
            },
        ];

        return columns;
    };

    return (
        <Section title="Megjegyzések">
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                    componentsProps={{
                        pagination: {
                            labelRowsPerPage: 'Oldalanként',
                            labelDisplayedRows: ({ from, to, count }: { from: number; to: number; count: number }) => `${from}–${to} / ${count !== -1 ? count : `more than ${to}`}`,
                        },
                    }}
                    localeText={DataGridLocalization}
                    rows={makeRows(customerNotes.data || [])}
                    columns={makeCols()}
                    style={{ borderRadius: 0, border: 'none' }}
                />
            </div>
            <div style={{ marginLeft: 16, marginBottom: 20 }}>
                <Button variant="contained" color="primary" onClick={() => setIsCreateDialogOpen(true)}>
                    Új megjegyzés
                </Button>
            </div>

            <Dialog onClose={() => setIsCreateDialogOpen(false)} open={isCreateDialogOpen}>
                <DialogTitle style={{ backgroundColor: '#0288d1', color: 'white', minWidth: 370 }}>Megjegyzés hozzáadása</DialogTitle>
                <div css={dialogContentContainerStyles}>
                    <TextField multiline fullWidth label="Megjegyzés" variant="outlined" value={note} onChange={(e) => setNote(e.target.value)} />
                    <div css={dialogButtonsContainerStyle}>
                        <Button variant="contained" color="error" size="large" onClick={() => setIsCreateDialogOpen(false)}>
                            Mégse
                        </Button>
                        <Button variant="contained" color="info" size="large" onClick={createNote}>
                            Mentés
                        </Button>
                    </div>
                </div>
            </Dialog>

            <Dialog onClose={() => setIsUpdateDialogOpen(false)} open={isUpdateDialogOpen}>
                <DialogTitle style={{ backgroundColor: '#0288d1', color: 'white', minWidth: 370 }}>Megjegyzés módosítása</DialogTitle>
                <div css={dialogContentContainerStyles}>
                    <TextField fullWidth label="Megjegyzés" variant="outlined" value={note} onChange={(e) => setNote(e.target.value)} />
                    <div css={dialogButtonsContainerStyle}>
                        <Button variant="contained" color="error" size="large" onClick={() => setIsUpdateDialogOpen(false)}>
                            Mégse
                        </Button>
                        <Button variant="contained" color="info" size="large" onClick={updateNote}>
                            Mentés
                        </Button>
                    </div>
                </div>
            </Dialog>

            <Dialog onClose={() => setIsDeleteDialogOpen(false)} open={isDeleteDialogOpen}>
                <DialogTitle style={{ backgroundColor: '#0288d1', color: 'white', minWidth: 370 }}>Megjegyzés törlése</DialogTitle>
                <div css={dialogContentContainerStyles}>
                    <Typography>Biztosan törlöd a megjegyzést?</Typography>
                    <div css={dialogButtonsContainerStyle}>
                        <Button variant="contained" color="primary" size="large" onClick={() => setIsDeleteDialogOpen(false)}>
                            Mégse
                        </Button>
                        <Button variant="contained" color="error" size="large" onClick={deleteNote}>
                            Törlés
                        </Button>
                    </div>
                </div>
            </Dialog>
        </Section>
    );
};

const buttonsContainerStyles = css`
    & > * + * {
        margin-left: 10px !important;
    }
`;

const dialogContentContainerStyles = css`
    padding: 24px;

    & > * + * {
        margin-top: 25px !important;
    }
`;

const dialogButtonsContainerStyle = css`
    display: flex;
    justify-content: center;
    margin-top: 30px;

    & > * + * {
        margin-left: 20px !important;
    }
`;

export default CustomerNotes;
