/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';

import { SubSection } from '../../../components/SubSection';
import { PaymentInstallments } from '../PaymentInstallments';
import { ProductData } from '../ProductData';
import { TransportData } from '../TransportData';

import OrderedProductProps from './OrderedProductProps';

class OrderedProduct extends React.Component<OrderedProductProps> {
    render(): React.ReactNode {
        return this.props.products.map((product) => (
            <SubSection key={product.product.id} title={`${product.product.name}`} collapsible style={styles.product}>
                <PaymentInstallments installments={product.installments} refetch={this.props.refetch} />
                <div css={innerRowStyle}>
                    <Link to={`/orders/payment-installments/${product.id}/edit`}>
                        <Button variant="contained">Szerkesztés</Button>
                    </Link>
                </div>
                <div css={detailsContainer}>
                    <ProductData data={product} />
                    <TransportData data={product.deliveries} customerId={this.props.customerId} orderedProductId={product.id} orderId={this.props.orderId} />
                </div>
            </SubSection>
        ));
    }
}

const styles = {
    product: {
        marginBottom: '20px',
    },
};

const innerRowStyle = css`
    margin-left: 0;
    margin-right: 0;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const detailsContainer = css`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export default OrderedProduct;
