/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Chip, Autocomplete, TextField } from '@mui/material';
import useErrorHandler from '../../../utils/userErrorHandler';
import useProductsOnlyQuery, { Product } from '../gql/productsOnly';
import useRemoveProductFromPackageMutation from '../gql/removeProductFromPackage';
import useAddProductToPackageMutation from '../gql/addProductToPackage';

type PackageProductsProps = {
    id: string;
    selectedProducts: Product[];
};

const PackageProducts: React.FC<PackageProductsProps> = (props) => {
    const productsOnlyResp = useProductsOnlyQuery();
    const [removeProduct, removedProductResp] = useRemoveProductFromPackageMutation();
    const [addProduct, addedProductResp] = useAddProductToPackageMutation();
    useErrorHandler(productsOnlyResp.error, addedProductResp.error, removedProductResp.error);

    const [products, setProducts] = useState<Product[]>([]);
    const [selProducts, setSelProducts] = useState<Product[]>(props.selectedProducts);

    useEffect(() => {
        if (productsOnlyResp.data) setProducts(productsOnlyResp.data);
    }, [productsOnlyResp.data]);

    const handleProductChange = async (selectedValues: string[]) => {
        const newSelections: Product[] = [];

        for (const selectedProductName of selectedValues) {
            const productToAddToSelections = productsOnlyResp.data?.find((product) => product.name === selectedProductName);
            if (productToAddToSelections) {
                newSelections.push(productToAddToSelections);
            }
        }

        const newSelectionsIds = newSelections.map((sel) => sel.id);
        const selectedProductIds = selProducts.map((sel) => sel.id);
        const difference = newSelectionsIds.filter((x) => !selectedProductIds.includes(x)).concat(selectedProductIds.filter((x) => !newSelectionsIds.includes(x)));

        if (difference.length !== 0) {
            if (newSelectionsIds.length > selectedProductIds.length) {
                await addProduct({ package: parseInt(props.id), subproduct: difference[0] });
            } else {
                await removeProduct({ package: parseInt(props.id), subproduct: difference[0] });
            }
        }

        setSelProducts(newSelections);
    };

    return (
        <Autocomplete
            multiple
            options={products.map((data: Product) => {
                return data.name;
            })}
            value={selProducts.map((selData: Product) => {
                return selData.name;
            })}
            renderTags={(value: string[], getTagProps) => value.map((option: string, index: number) => <Chip variant="outlined" color="secondary" label={option} {...getTagProps({ index })} key={index} />)}
            renderInput={(params) => <TextField {...params} label="Termékek" placeholder="Termékek" />}
            onChange={(_event, value) => {
                handleProductChange(value);
            }}
            css={chipSelectStyles}
        />
    );
};

const chipSelectStyles = css`
    width: 100%;
`;

export default PackageProducts;
