import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const emailTemplateQuery = gql`
    query emailTemplate($id: Int!) {
        emailTemplate(id: $id) {
            id
            content
        }
    }
`;

interface EmailTemplate {
    id: number;
    content: string;
}

const useEmailTemplateContentQuery = queryFactory<EmailTemplate>(emailTemplateQuery, 'emailTemplate');

export default useEmailTemplateContentQuery;
