import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const orderedProductQuery = gql`
    query orderedProduct($id: Int!) {
        orderedProduct(id: $id) {
            id
            price {
                id
                net
                gross
            }
            product {
                id
                name
                package
            }
            order {
                id
            }
            installments {
                id
                gross
                due_date
                payment_date
                converted_to {
                    id
                }
                converted_from {
                    id
                }
                payment_method {
                    id
                    name
                }
            }
        }
    }
`;

interface PaymentInstallment {
    id: number;
    gross: number;
    due_date: string;
    payment_date: string | null;
    converted_to: { id: number } | null;
    converted_from: { id: number } | null;
    payment_method: { id: number; name: string };
}

interface OrderedProduct {
    id: number;
    product: {
        id: number;
        name: string;
        package: boolean;
    };
    order: {
        id: number;
    };
    price: {
        id: number;
        gross: number;
        net: number;
    };
    installments: PaymentInstallment[];
}

const useOrderedProductQuery = queryFactory<OrderedProduct>(orderedProductQuery, 'orderedProduct');

export type { OrderedProduct, PaymentInstallment };
export default useOrderedProductQuery;
