import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const campaignQuery = gql`
    query campaign($id: Int!) {
        campaign(id: $id) {
            id
            name
            start
            end
        }
    }
`;

interface Campaign {
    id: number;
    name: string;
    start: string;
    end: string;
}

const useCampaignQuery = queryFactory<Campaign>(campaignQuery, 'campaign');

export default useCampaignQuery;
