import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const deleteUserMutation = gql`
    mutation deleteUser($id: Int!) {
        deleteUser(id: $id)
    }
`;

const useDeleteUserMutation = mutationFactory(deleteUserMutation, 'deleteUser');

export default useDeleteUserMutation;
