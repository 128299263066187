/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, FormControl, InputLabel, Select, MenuItem, Button, Typography, Modal, Box } from '@mui/material';
import { Section } from '../../components/Section';
import { v4 } from 'uuid';
import { CustomDateRangePicker } from '../../components/CustomDateRangePicker';
import useErrorHandler from '../../utils/userErrorHandler';
import useUsers from '../WorkingTimeListPage/gql/users';
import useMeQuery from './gql/me';
import { Link } from 'react-router-dom';
import useOffDayQuery from './gql/offDays';
import useDeleteOffDayMutation from './gql/deleteOffDay';

const AbsencesPage: React.FC = () => {
    const users = useUsers();
    const me = useMeQuery();
    const [getOffDays, offDaysResp] = useOffDayQuery();
    const [deleteOffDay, deleteOffDayResp] = useDeleteOffDayMutation();
    useErrorHandler(users.error, me.error, offDaysResp.error, deleteOffDayResp.error);

    const [selected, setSelected] = useState<number>();
    const [modalOpen, setModalOpen] = useState(false);
    const [worker, setWorker] = useState('');
    const [date, setDate] = useState<
        {
            startDate: Date;
            endDate: Date;
            key: string;
        }[]
    >([
        {
            startDate: new Date(new Date().getFullYear(), 0, 1, 2, 0, 0, 0),
            endDate: new Date(new Date().getFullYear(), 11, 31, 2, 0, 0, 0),
            key: 'selection',
        },
    ]);

    useEffect(() => {
        if (!isAdmin() && me.data) {
            setWorker(me.data.id.toString());
        }
    }, [me.data]);

    useEffect(() => {
        if (selected) handleDataFetch();
    }, [deleteOffDayResp.data]);

    const handleDateChange = (item: { selection: { startDate: Date; endDate: Date; key: string } }) => {
        setDate([item.selection]);
    };

    const isAdmin = () => {
        return me.data?.permission.name.includes('admin');
    };

    const handleModal = (id: number) => {
        setModalOpen(true);
        setSelected(id);
    };

    const handleDelete = async () => {
        await deleteOffDay({ id: selected });
        setModalOpen(false);
    };

    const handleDataFetch = async () => {
        if (worker === '') {
            await getOffDays({ user: null, from: date[0].startDate, to: date[0].endDate });
        } else {
            await getOffDays({ user: parseInt(worker), from: date[0].startDate, to: date[0].endDate });
        }
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`Távollét`}>
                <div css={sectionStyles}>
                    <CustomDateRangePicker ranges={date} onChange={handleDateChange} />
                    <div css={someInputContainerStyles}>
                        <div css={{ minWidth: 226 }} />
                        <FormControl sx={{ minWidth: 352 }} disabled={!isAdmin()}>
                            <InputLabel id="worker">Név</InputLabel>
                            <Select labelId="worker" id="worker-select" label="Név" value={worker} onChange={(e) => setWorker(e.target.value)}>
                                <MenuItem value="">
                                    <em>Nincs kiválasztva</em>
                                </MenuItem>
                                {users.data?.map((worker) => {
                                    return (
                                        <MenuItem key={v4()} value={worker.id}>
                                            {worker.lastname + ' ' + worker.firstname + ' ' + (worker.middlename ? worker.middlename : '') + ` - (${worker.username})`}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <div css={buttonConatiner}>
                            <Button size="large" variant="contained" onClick={handleDataFetch}>
                                Lekérés
                            </Button>
                        </div>
                    </div>
                    <Link to="/working-hours/absence/create" css={buttonContainerStyle}>
                        <Button variant="contained" color="success">
                            Új távollét rögzítése
                        </Button>
                    </Link>
                    {offDaysResp.data?.length !== undefined && offDaysResp.data?.length !== 0 ? (
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Név</TableCell>
                                        <TableCell align="center">Kezdete</TableCell>
                                        <TableCell align="center">Vége</TableCell>
                                        <TableCell align="center">Hiányzás oka</TableCell>
                                        <TableCell align="center">Műveletek</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {offDaysResp.data?.map((row) => (
                                        <TableRow key={v4()} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="center">{row.user.lastname + ' ' + row.user.firstname + ' ' + (row.user.middlename ? row.user.middlename : '') + ` - (${row.user.username})`}</TableCell>
                                            <TableCell align="center">{row.start}</TableCell>
                                            <TableCell align="center">{row.end}</TableCell>
                                            <TableCell align="center">{row.reason.name}</TableCell>
                                            <TableCell align="center" css={actionsColStyle}>
                                                <Button variant="contained" color="error" onClick={() => handleModal(row.id)}>
                                                    Törlés
                                                </Button>
                                                <Link to={`/working-hours/absence/${row.id}/edit`} style={{ textDecoration: 'none' }}>
                                                    <Button variant="contained" color="success">
                                                        Szerkesztés
                                                    </Button>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : offDaysResp.data?.length === 0 ? (
                        <Typography variant="body1" color="primary" align="center">
                            Nincs megjeleníthető adat a megadott feltételek alapján!
                        </Typography>
                    ) : null}
                </div>
            </Section>
            <Modal open={modalOpen} onClose={() => setModalOpen(false)} aria-labelledby="delete-modal-title" aria-describedby="delete-modal-description">
                <Box css={boxStyles}>
                    <Typography id="delete-modal-title" variant="h6" component="h2" style={{ marginBottom: '1rem' }}>
                        Biztosan törölni szeretnéd a hiányzást?
                    </Typography>
                    <div css={buttonContainerStyles}>
                        <Button size="large" variant="contained" color="error" onClick={handleDelete}>
                            Igen
                        </Button>
                        <Button size="large" variant="contained" onClick={() => setModalOpen(false)}>
                            Nem
                        </Button>
                    </div>
                </Box>
            </Modal>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    & > * + * {
        margin-top: 20px !important;
    }
`;

const someInputContainerStyles = css`
    width: 931px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1000px) {
        flex-direction: column;
        & > * + * {
            margin-top: 20px !important;
        }
    }
`;

const buttonConatiner = css`
    min-width: 352px;
    display: flex;
    justify-content: center;
`;

const buttonContainerStyle = css`
    text-decoration: none;
    margin-left: auto;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const actionsColStyle = css`
    & > * + * {
        margin-left: 20px !important;
    }
`;

const boxStyles = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 2px solid #0288d1;
    boxshadow: 24;
    padding: 1rem;
`;

const buttonContainerStyles = css`
    display: flex;
    justify-content: space-evenly;
    margin-top: 1rem;
`;

export default AbsencesPage;
