/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useContext } from 'react';
import { ListContext, ListContextType, BlockType, OperatorType, FilterType } from '../../../contexts/ListContext';
import { Button, Paper } from '@mui/material';
import Filter from '../Filter/Filter';
import Operator from '../Operator/Operator';
import FilterBlockSaveDialog from '../FilterBlockSaveDialog/FilterBlockSaveDialog';

type FilterBlockProps = {
    block: BlockType;
};

const FilterBlock: React.FC<FilterBlockProps> = ({ block }: FilterBlockProps) => {
    const { dispatch, removeBlock } = useContext(ListContext) as ListContextType;
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const isValid = () => {
        if (block.filters.length === 0) return false;
        const filtered = block.filters.filter((filter) => filter.typeID === 0 || filter.valueID === '');
        return filtered.length === 0;
    };

    return (
        <Paper elevation={3} style={{ backgroundColor: '#4fc3f7' }}>
            <div css={sectionStyles}>
                {block.content.map((filter, idx) => {
                    if (filter.type === 'operator') {
                        return <Operator key={idx} operator={filter.content as OperatorType} blockID={block.id} filterOperator />;
                    } else {
                        return <Filter key={filter.content.id} filter={filter.content as FilterType} blockID={block.id} />;
                    }
                })}

                <div css={buttonsContainerStyles}>
                    <Button variant="contained" color="error" onClick={() => removeBlock(block.id)}>
                        Blokk törlése
                    </Button>
                    <Button variant="contained" color="info" onClick={() => setIsDialogOpen(true)} disabled={!isValid()}>
                        Blokk mentése
                    </Button>
                    <Button variant="contained" color="success" onClick={() => dispatch({ type: 'ADD_NEW_FILTER', payload: block.id })}>
                        Új filter
                    </Button>
                </div>
            </div>

            <FilterBlockSaveDialog block={block} isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} />
        </Paper>
    );
};

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;

    & > * + * {
        margin-top: 20px !important;
    }
`;

const buttonsContainerStyles = css`
    display: flex;
    justify-content: center;

    & > * + * {
        margin-left: 20px !important;
    }
`;

export default FilterBlock;
