import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const mySalesPopupLists = gql`
    query mySalesPopupLists($lastNDays: Int!) {
        mySalesPopupLists(lastNDays: $lastNDays) {
            id
            created_at
        }
    }
`;

const useMySalesPopupLists = queryFactory<{ id: number; created_at: string }[]>(mySalesPopupLists, 'mySalesPopupLists');

export default useMySalesPopupLists;
