import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const offDayQuery = gql`
    query offDay($id: Int!) {
        offDay(id: $id) {
            id
            user {
                id
            }
            reason {
                id
            }
            start
            end
        }
    }
`;

interface OffDay {
    id: number;
    user: { id: number };
    reason: { id: number };
    start: string;
    end: string;
}

const useOffDayQuery = queryFactory<OffDay>(offDayQuery, 'offDay');

export default useOffDayQuery;
