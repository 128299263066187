import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const sentEmails = gql`
    query sentEmails($automatic: Boolean!) {
        sentEmails(automatic: $automatic) {
            id
            template {
                subject
                sender_name
                sender_email
            }
            sender {
                username
            }
            created_at
        }
    }
`;

interface SentEmail {
    id: number;
    template: {
        subject: string;
        sender_name: string;
        sender_email: string;
    };
    sender: {
        username: string;
    };
    created_at: string;
}

const useSentEmails = queryFactory<SentEmail[]>(sentEmails, 'sentEmails');

export type { SentEmail };
export default useSentEmails;
