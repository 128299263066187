/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';

import { Alert, Button, TextField } from '@mui/material';

const UpsellTask: React.FC = () => {
    const [description, setDescription] = useState<string>('');

    const handleSave = async () => {
        console.log('saved');
    };

    return (
        <>
            <Alert variant="outlined" severity="warning" sx={{ marginBottom: '20px' }}>
                Ellenőrizd a rendelés részleteit, ha szükséges módosítsd őket MIELŐTT létrehoznád az upsell feladatot!
            </Alert>
            <TextField
                id="description"
                label="Megjegyzés az értékesítőnek"
                placeholder="Add meg a hívás feladatának leírását!"
                multiline
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                style={{ width: '100%' }}
            />
            <Button variant="contained" color="success" css={buttonContainerStyles} onClick={handleSave}>
                Upsell hívásos feladat létrehozása
            </Button>
        </>
    );
};

const buttonContainerStyles = css`
    text-decoration: none;
    margin-left: auto;
    margin-top: 20px;
    margin-bottom: 20px;
`;

export default UpsellTask;
