import { gql } from '@apollo/client';
import { queryFactory } from '../../../../utils/gqlFactories';

const customerCharacteristicOptions = gql`
    query customerCharacteristicOptions {
        customerCharacteristicOptions {
            id
            name
        }
    }
`;

const useCustomerCharacteristicOptions = queryFactory<{ id: number; name: string }[]>(customerCharacteristicOptions, 'customerCharacteristicOptions');

export default useCustomerCharacteristicOptions;
