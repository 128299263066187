/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Paper, Button, Typography, Tooltip, Chip } from '@mui/material';
import { Section } from '../../components/Section';
import useErrorHandler from '../../utils/userErrorHandler';
import useProductQuery from './gql/product';
import PricesTable from './PricesTable/PricesTable';

const ProductPage: React.FC = () => {
    const params = useParams();
    const id = params.id as string;
    const navigate = useNavigate();
    const productResp = useProductQuery({ id: parseInt(id) });

    useErrorHandler(productResp.error);

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`#${id}: Termék${productResp.data?.package ? 'csomag' : ''} adatok`}>
                <div css={sectionStyles}>
                    <div>
                        <Typography variant="h6" component="div" css={textStyles}>
                            Neve:
                        </Typography>
                        <Typography variant="h6" component="div">
                            {productResp.data ? productResp.data.name : 'Nem sikerült betölteni.'}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="h6" component="div" css={textStyles}>
                            Leírása:
                        </Typography>
                        <Typography variant="body1" component="div">
                            {productResp.data ? productResp.data.description : 'Nem sikerült betölteni.'}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="h6" component="div" css={textStyles}>
                            Előfizetéses:
                        </Typography>
                        <Typography variant="body1" component="div">
                            {productResp.data ? (productResp.data.subscription ? 'Igen' : 'Nem') : 'Nem sikerült betölteni.'}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="h6" component="div" css={textStyles}>
                            Kategóriái:
                        </Typography>
                        <div css={chipContainer}>
                            {productResp.data?.categories.map((category) => (
                                <Tooltip key={category.id} title={category.name} placement="right" arrow>
                                    <Chip variant="filled" color="info" label={category.name} />
                                </Tooltip>
                            ))}
                        </div>
                    </div>
                    <div>
                        <Typography variant="h6" component="div" css={textStyles}>
                            Tagek:
                        </Typography>
                        <div css={chipContainer}>
                            {productResp.data?.ac_tags.map((tag) => (
                                <Tooltip key={tag.id} title={tag.description} placement="right" arrow>
                                    <Chip variant="outlined" color="info" label={tag.name} />
                                </Tooltip>
                            ))}
                        </div>
                    </div>
                    {productResp.data?.package && (
                        <div>
                            <Typography variant="h6" component="div" css={textStyles}>
                                Termékek:
                            </Typography>
                            <div css={chipContainer}>
                                {productResp.data?.subproducts.map((tag) => (
                                    <Tooltip key={tag.id} title={tag.description} placement="right" arrow>
                                        <Chip variant="outlined" color="secondary" label={tag.name} />
                                    </Tooltip>
                                ))}
                            </div>
                        </div>
                    )}
                    <Typography variant="h6" component="div" css={textStyles}>
                        Árak:
                    </Typography>
                    <PricesTable productId={id} />
                    <div css={buttonInputsContainerStyles}>
                        <Button size="large" variant="contained" onClick={() => navigate(`/products/${id}/edit`)}>
                            Szerkesztés
                        </Button>
                        <Button size="large" variant="contained" onClick={() => navigate(`/products/${id}/pricing`)}>
                            Árazás
                        </Button>
                    </div>
                </div>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;

    & > * + * {
        margin-top: 25px !important;
    }
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;

    & > * + * {
        margin-top: 20px !important;
    }
`;

const textStyles = css`
    color: #0288d1;
`;

const chipContainer = css`
    display: flex;

    & > * + * {
        margin-left: 5px !important;
    }
`;

const buttonInputsContainerStyles = css`
    align-self: end;

    & > * + * {
        margin-left: 20px !important;
    }
`;

export default ProductPage;
