import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const createCampaignMutation = gql`
    mutation createCampaign($data: CampaignInputCreate!) {
        createCampaign(data: $data) {
            id
        }
    }
`;

interface CreatedCampaign {
    id: number;
}

const useCreateCampaignMutation = mutationFactory<CreatedCampaign>(createCampaignMutation, 'createCampaign');

export default useCreateCampaignMutation;
