/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Paper, Button } from '@mui/material';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import DataGridLocalization from '../../utils/DataGridLocalization';
import { Section } from '../../components/Section';
import useErrorHandler from '../../utils/userErrorHandler';
import useCustomers, { Customer } from './gql/customers';
import formatPhoneNumber from '../../utils/formatPhoneNumber';
import formatDatetime from '../../utils/formatDate';

const CustomersPage: React.FC = () => {
    const navigate = useNavigate();
    const customers = useCustomers();
    useErrorHandler(customers.error);

    const makeRows = (customers: Customer[]) => {
        const rows: GridRowsProp = customers.map((customer) => ({
            id: customer.id,
            customerID: customer.id,
            name: customer.details.length ? `${customer.details[customer.details.length - 1].lastname} ${customer.details[customer.details.length - 1].firstname}` : '-',
            phone: customer.details.length && customer.details[customer.details.length - 1].phone ? formatPhoneNumber(customer.details[customer.details.length - 1].phone) : '',
            email: customer.email,
            date: customer.details.length ? formatDatetime(customer.details[customer.details.length - 1].created_at) : '-',
        }));

        return rows;
    };

    const makeCols = () => {
        const columns: GridColDef[] = [
            { field: 'customerID', headerName: 'ID', width: 100 },
            { field: 'name', headerName: 'Név', width: 150 },
            { field: 'phone', headerName: 'Telefonszám', width: 150 },
            { field: 'email', headerName: 'E-mail cím', width: 200 },
            { field: 'date', headerName: 'Létrehozva', width: 200 },
            {
                field: 'actions',
                headerName: 'Műveletek',
                width: 180,
                renderCell: function renderActions(cellValues) {
                    return (
                        <Button variant="contained" color="primary" onClick={() => navigate(`/customers/${cellValues.row.id}`)}>
                            Megtekintés
                        </Button>
                    );
                },
            },
        ];

        return columns;
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title="Ügyfelek">
                <div style={{ height: 600, width: '100%' }}>
                    <DataGrid
                        componentsProps={{
                            pagination: {
                                labelRowsPerPage: 'Oldalanként',
                                labelDisplayedRows: ({ from, to, count }: { from: number; to: number; count: number }) => `${from}–${to} / ${count !== -1 ? count : `more than ${to}`}`,
                            },
                        }}
                        localeText={DataGridLocalization}
                        rows={makeRows(customers.data || [])}
                        columns={makeCols()}
                        style={{ borderRadius: 0, border: 'none' }}
                    />
                </div>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

export default CustomersPage;
