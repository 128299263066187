import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const salesPopupListContact = gql`
    query salesPopupListContact($id: Int!) {
        salesPopupListContact(id: $id) {
            id
            customer {
                id
                firstname
                lastname
                phone
                customer {
                    email
                    characteristics {
                        id
                        characteristic_option {
                            id
                            name
                        }
                        assigner {
                            firstname
                            lastname
                        }
                        created_at
                    }
                    blocked {
                        id
                    }
                }
            }
            assignedContact {
                id
                ts_user {
                    firstname
                    lastname
                }
                call_state {
                    id
                    name
                }
                talk_state {
                    id
                    name
                }
            }
            comment
            created_at
        }
    }
`;

export interface SalesPopupListContact {
    id: number;
    customer: {
        id: number;
        firstname: string;
        lastname: string;
        phone: string;
        customer: {
            email: string;
            characteristics: {
                id: number;
                characteristic_option: {
                    id: number;
                    name: string;
                };
                assigner: {
                    firstname: string;
                    lastname: string;
                };
                created_at: string;
            }[];
            blocked: {
                id: number;
            };
        };
    };
    assignedContact: {
        id: number;
        ts_user: {
            firstname: string;
            lastname: string;
        };
        call_state: {
            id: number;
            name: string;
        };
        talk_state: {
            id: number;
            name: string;
        };
    };
    comment: string;
    created_at: string;
}

const useSalesPopupListContact = queryFactory<SalesPopupListContact>(salesPopupListContact, 'salesPopupListContact');

export default useSalesPopupListContact;
