import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const updateCampaignMutation = gql`
    mutation updateCampaign($id: Int!, $data: CampaignInputUpdate!) {
        updateCampaign(id: $id, data: $data) {
            id
        }
    }
`;

interface UpdatedCampaign {
    id: number;
}

const useUpdateCampaignMutation = mutationFactory<UpdatedCampaign>(updateCampaignMutation, 'updateCampaign');

export default useUpdateCampaignMutation;
