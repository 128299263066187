/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { Section } from '../../components/Section';
import { v4 } from 'uuid';
import useErrorHandler from '../../utils/userErrorHandler';
import usePublicHolidaysQuery from './gql/publicHolidays';

const HolidaysPage: React.FC = () => {
    const holidays = usePublicHolidaysQuery();
    useErrorHandler(holidays.error);

    const getDayName = (month: number, day: number): string => {
        switch (new Date(new Date().getFullYear() + '-' + month.toString() + '-' + day.toString()).getDay()) {
            case 0:
                return 'Vasárnap';
            case 1:
                return 'Hétfő';
            case 2:
                return 'Kedd';
            case 3:
                return 'Szerda';
            case 4:
                return 'Csütörtök';
            case 5:
                return 'Péntek';
            case 6:
                return 'Szombat';
            default:
                return '-';
        }
    };

    const getCurrentYearDate = (month: number, day: number): string => {
        return new Date().getFullYear() + '-' + (month > 9 ? month.toString() : '0' + month) + '-' + (day > 9 ? day.toString() : '0' + day);
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`Ünnepnapok`}>
                <div css={sectionStyles}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Név</TableCell>
                                    <TableCell>Dátum</TableCell>
                                    <TableCell>Nap</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {holidays.data?.map((row) => (
                                    <TableRow key={v4()} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{getCurrentYearDate(row.month, row.day)}</TableCell>
                                        <TableCell>{getDayName(row.month, row.day)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    & > * + * {
        margin-top: 20px !important;
    }
`;

export default HolidaysPage;
