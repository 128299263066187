/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

import PaymentInstallmentsProps from './PaymentInstallmentsProps';

import { Box, Modal, Typography, Button, Table, TableHead, TableBody, TableCell, TableRow, TableContainer, TextField } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import huLocale from 'date-fns/locale/hu';

import { SubSubSection } from '../../../components/SubSubSection';
import { ID } from '../../../components/ID';

import { PaymentInstallment } from '../gql/order';
import useUpdateInstallmentMutation from '../gql/updateInstallment';

import { v4 } from 'uuid';
import useErrorHandler from '../../../utils/userErrorHandler';
import { useSnackbar } from 'notistack';

const PaymentInstallments: React.FC<PaymentInstallmentsProps> = (props) => {
    const { enqueueSnackbar } = useSnackbar();

    const [updateInstallment, updateInstallmentResp] = useUpdateInstallmentMutation();

    useErrorHandler(updateInstallmentResp.error);

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedInstallment, setSelectedInstallment] = useState<PaymentInstallment | null>(null);
    const [date, setDate] = useState<Date | null>(new Date(new Date().setSeconds(0)));

    useEffect(() => {
        if (updateInstallmentResp.data && selectedInstallment) {
            props.refetch();
        }
    }, [updateInstallmentResp.data]);

    const handleModalOpen = (installment: PaymentInstallment) => {
        setSelectedInstallment(installment);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setDate(new Date(new Date().setSeconds(0)));
        setSelectedInstallment(null);
        setModalOpen(false);
    };

    const handleModalSave = async () => {
        if (selectedInstallment && date) {
            setModalOpen(false);
            await updateInstallment({ id: selectedInstallment.id, data: { payment_date: date } });
        } else {
            enqueueSnackbar('Dátum megadása kötelező!', { variant: 'error' });
        }
    };

    const renderRow = (installment: PaymentInstallment): React.ReactNode => {
        return (
            <TableRow css={trStyle} key={v4()}>
                <TableCell align="center">
                    <ID id={installment.id.toString()}></ID>
                </TableCell>
                <TableCell align="center">{installment.payment_method.name}</TableCell>
                <TableCell align="center">{installment.due_date}</TableCell>
                <TableCell align="center">{installment.payment_date ?? '-'}</TableCell>
                <TableCell align="center">
                    <NumberFormat value={installment.gross} thousandSeparator={' '} suffix={` Ft`} displayType="text" />
                </TableCell>
                {renderCurrency(installment.currency, installment.currency_gross)}
                {renderConversion(installment.id.toString(), installment.converted_to, installment.converted_from)}
                <TableCell align="center">
                    <Button css={buttonStyle} variant="contained" color="success" size="small" disabled={installment.payment_date ? true : false} onClick={() => handleModalOpen(installment)}>
                        Kifizetve
                    </Button>
                </TableCell>
            </TableRow>
        );
    };

    const renderCurrency = (currency?: { id: number; name: string }, currency_gross?: number): React.ReactNode => {
        if (currency && currency.name === 'Forint') {
            return <TableCell align="center">{'-'}</TableCell>;
        } else if (currency && currency_gross) {
            return (
                <TableCell align="center">
                    <NumberFormat value={currency_gross} thousandSeparator={' '} suffix={` ${currency.name}`} displayType="text" />
                </TableCell>
            );
        } else {
            return null;
        }
    };

    const renderConversion = (paymentId: string, to: { id: number } | null, from: { id: number } | null): React.ReactNode => {
        if (to === null && from === null) {
            return <TableCell align="center">-</TableCell>;
        }

        if (to !== null && from === null) {
            return (
                <>
                    <TableCell align="center">
                        <ID id={to.id.toString()} openable></ID>
                        {LeftArrow}
                        <ID id={paymentId}></ID>
                    </TableCell>
                </>
            );
        } else if (to === null && from !== null) {
            return (
                <>
                    <TableCell align="center">
                        <ID id={from.id.toString()} openable></ID>
                        {RightArrow}
                        <ID id={paymentId}></ID>
                    </TableCell>
                </>
            );
        } else {
            return null;
        }
    };

    return (
        <SubSubSection title="Fizetési részletek">
            <TableContainer>
                <Table css={tableStyle}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">ID</TableCell>
                            <TableCell align="center">Fizetési mód</TableCell>
                            <TableCell align="center">Fizetési határidő</TableCell>
                            <TableCell align="center">Fizetés dátuma</TableCell>
                            <TableCell align="center">Bruttó</TableCell>
                            <TableCell align="center">Valuta</TableCell>
                            <TableCell align="center">Beszámítás</TableCell>
                            <TableCell align="center">Műveletek</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{props.installments.map((ins: PaymentInstallment): React.ReactNode => renderRow(ins))}</TableBody>
                </Table>
            </TableContainer>

            <Modal open={modalOpen} onClose={handleModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" style={{ position: 'absolute' }}>
                <Box css={boxStyles}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ marginBottom: '1rem' }}>
                        Add meg a kifizetés dátumát:
                    </Typography>

                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={huLocale}>
                        <DateTimePicker
                            label="Kifizetés dátuma"
                            views={['year', 'month', 'day', 'hours', 'minutes']}
                            inputFormat="yyyy.MM.dd. HH:mm"
                            mask={'____.__.__. __:__'}
                            ampm={false}
                            value={date}
                            onChange={(newDate) => setDate(newDate)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>

                    <Button size="large" color="success" variant="contained" css={boxButtonStyles} onClick={handleModalSave}>
                        Kifizetve
                    </Button>
                </Box>
            </Modal>
        </SubSubSection>
    );
};

const arrowStyle = css`
    margin: 0 1rem;
`;

const RightArrow = (
    <svg css={arrowStyle} width="39" height="21" viewBox="0 0 39 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M38.8614 10.6829L25.4799 19.1232L25.7194 1.96371L38.8614 10.6829Z" fill="#28A745" />
        <rect x="0.000366211" y="7.10107" width="26.0431" height="6.97729" fill="#28A745" />
    </svg>
);

const LeftArrow = (
    <svg css={arrowStyle} width="39" height="21" viewBox="0 0 39 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.13857 10.4968L13.5201 2.0565L13.2806 19.216L0.13857 10.4968Z" fill="#DC3545" />
        <rect x="38.9996" y="14.0786" width="26.0431" height="6.97729" transform="rotate(-180 38.9996 14.0786)" fill="#DC3545" />
    </svg>
);

const tableStyle = css`
    width: 100%;
    min-width: max-content;
`;

const trStyle = css`
    &: nth-of-type(odd) {
        background-color: #e3f2fd;
    }

    &:last-child td,
    &:last-child th {
        border: 0;
    }
`;

const buttonStyle = css`
    margin: 0 10px;
`;

const boxStyles = css`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 2px solid #0288d1;
    boxshadow: 24;
    padding: 1rem;
`;

const boxButtonStyles = css`
    width: min-content;
    margin-left: auto;
    margin-top: 1rem;
`;

export default PaymentInstallments;
