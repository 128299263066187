/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Paper, Typography, TableContainer, Table, TableBody, TableRow, TableCell, Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Section } from '../../components/Section';
import { useParams } from 'react-router-dom';
import useList from './gql/list';
import useErrorHandler from '../../utils/userErrorHandler';
import ListContacts from './ListContacts/ListContacts';
import Filters from './Filters/Filters';

const ListsPage: React.FC = () => {
    const params = useParams();
    const list = useList({ id: Number(params.id) });
    useErrorHandler(list.error);

    return (
        <Paper css={containerStyles} elevation={0}>
            <Typography variant="h5" component="div">
                Lista: #{params.id} - {list.data?.name}
            </Typography>

            <Section title="Lista adatok">
                <TableContainer>
                    <Table aria-label="table">
                        <TableBody>
                            <TableRow css={trStyle}>
                                <TableCell css={tdStyle}>Név</TableCell>
                                <TableCell css={tdStyle}>{list.data?.name}</TableCell>
                            </TableRow>
                            <TableRow css={trStyle}>
                                <TableCell css={tdStyle}>Leírás</TableCell>
                                <TableCell css={tdStyle}>{list.data?.description}</TableCell>
                            </TableRow>
                            <TableRow css={trStyle}>
                                <TableCell css={tdStyle}>Dinamikus</TableCell>
                                <TableCell css={tdStyle}>{list.data?.dynamic ? `${list.data.frequency} percenként frissül ${list.data.ttl} órán át` : 'Nem'}</TableCell>
                            </TableRow>
                            <TableRow css={trStyle}>
                                <TableCell css={tdStyle}>Kontakt szám</TableCell>
                                <TableCell css={tdStyle}>{list.data?.contacts}</TableCell>
                            </TableRow>
                            <TableRow css={trStyle}>
                                <TableCell css={tdStyle}>Létrehozta</TableCell>
                                <TableCell css={tdStyle}>
                                    {list.data?.creator.lastname} {list.data?.creator.firstname}
                                </TableCell>
                            </TableRow>
                            <TableRow css={trStyle}>
                                <TableCell css={tdStyle}>Létrehozva</TableCell>
                                <TableCell css={tdStyle}>{list.data?.created_at}</TableCell>
                            </TableRow>
                            <TableRow css={trStyle}>
                                <TableCell css={tdStyle}>Utoljára frissítve</TableCell>
                                <TableCell css={tdStyle}>
                                    {list.data?.updated_at || 'Lista generálása folyamatban'}
                                    {!list.data?.updated_at && (
                                        <Button size="small" variant="contained" color="success" style={{ marginLeft: 20, minWidth: 28 }} onClick={() => list.refetch()}>
                                            <RefreshIcon fontSize="small" />
                                        </Button>
                                    )}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Section>

            <ListContacts />

            <Section title="Teljesítmény">
                <TableContainer>
                    <Table aria-label="table">
                        <TableBody>
                            <TableRow css={trStyle}>
                                <TableCell css={tdStyle}>Hívás</TableCell>
                                <TableCell css={tdStyle}>118</TableCell>
                            </TableRow>
                            <TableRow css={trStyle}>
                                <TableCell css={tdStyle}>Tájékoztatva</TableCell>
                                <TableCell css={tdStyle}>86</TableCell>
                            </TableRow>
                            <TableRow css={trStyle}>
                                <TableCell css={tdStyle}>Hibás kontakt</TableCell>
                                <TableCell css={tdStyle}>0</TableCell>
                            </TableRow>
                            <TableRow css={trStyle}>
                                <TableCell css={tdStyle}>Rendelés</TableCell>
                                <TableCell css={tdStyle}>12 db - 247 500 Ft</TableCell>
                            </TableRow>
                            <TableRow css={trStyle}>
                                <TableCell css={tdStyle}>Várható rendelés</TableCell>
                                <TableCell css={tdStyle}>3 db - 87 000 Ft</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Section>

            <Filters />
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const trStyle = css`
    &: nth-of-type(odd) {
        background-color: #e3f2fd;
    }

    &:last-child td,
    &:last-child th {
        border: 0;
    }
`;

const tdStyle = css`
    width: 50%;
`;

export default ListsPage;
