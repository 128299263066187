import React from 'react';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import DataGridLocalization from '../../../utils/DataGridLocalization';
import { SentEmail } from '../gql/sentEmails';
import { useNavigate } from 'react-router-dom';

type SentEmailsProps = {
    emails: SentEmail[];
};

const SentEmails: React.FC<SentEmailsProps> = ({ emails }: SentEmailsProps) => {
    const navigate = useNavigate();

    const makeRows = (emails: SentEmail[]) => {
        const rows: GridRowsProp = emails.map((email: SentEmail) => ({
            id: email.id,
            emailID: email.id,
            date: email.created_at,
            sender: email.sender === null ? 'Rendszer' : email.sender.username,
            sender_name: email.template.sender_name,
            sender_email: email.template.sender_email,
            subject: email.template.subject,
        }));

        return rows;
    };

    const makeCols = () => {
        const columns: GridColDef[] = [
            { field: 'emailID', headerName: 'ID', width: 100 },
            { field: 'date', headerName: 'Dátum', width: 200 },
            { field: 'sender', headerName: 'Felhasználó', width: 200 },
            { field: 'sender_name', headerName: 'Küldő neve', width: 200 },
            { field: 'sender_email', headerName: 'Küldő címe', width: 250 },
            { field: 'subject', headerName: 'Tárgy', width: 300 },
            {
                field: 'actions',
                headerName: 'Műveletek',
                width: 250,
                renderCell: function renderActions(cellValues) {
                    return (
                        <Button variant="contained" color="primary" onClick={() => handleButtonClick(cellValues.row.id)}>
                            Megtekintés
                        </Button>
                    );
                },
            },
        ];

        return columns;
    };

    const handleButtonClick = (id: number) => {
        navigate(`/emails/sent/${id}`);
    };

    return (
        <div style={{ height: 600, width: '100%' }}>
            <DataGrid
                componentsProps={{
                    pagination: {
                        labelRowsPerPage: 'Oldalanként',
                        labelDisplayedRows: ({ from, to, count }: { from: number; to: number; count: number }) => `${from}–${to} / ${count !== -1 ? count : `more than ${to}`}`,
                    },
                }}
                localeText={DataGridLocalization}
                rows={makeRows(emails)}
                columns={makeCols()}
                style={{ borderRadius: 0, border: 'none' }}
            />
        </div>
    );
};

export default SentEmails;
