import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import NewOrderPage from './pages/NewOrderPage/NewOrderPage';
import CustomerPage from './pages/CustomerPage/CustomerPage';
import OrderDetailsPage from './pages/OrderDetailsPage/OrderDetailsPage';
import OrdersPage from './pages/OrdersPage/OrdersPage';
import AwaitingOrdersPage from './pages/AwaitingOrdersPage/AwaitingOrdersPage';
import InstallmentsEditPage from './pages/InstallmentsEditPage/InstallmentsEditPage';
import DeliveryCreatePage from './pages/DeliveryCreatePage/DeliveryCreatePage';
import ExpectedOrderCreatePage from './pages/ExpectedOrderCreatePage/ExpectedOrderCreatePage';
import ExpectedOrdersPage from './pages/ExpectedOrdersPage/ExpectedOrdersPage';
import CodPage from './pages/CodPage/CodPage';
import MarketingCampaignsPage from './pages/MarketingCampaignsPage/MarketingCampaignsPage';
import MarketingCampaignCreatePage from './pages/MarketingCampaignCreatePage/MarketingCampaignCreatePage';
import MarketingCampaignEditPage from './pages/MarketingCampaignEditPage/MarketingCampaignEditPage';
import FreeContentsPage from './pages/FreeContentsPage/FreeContentsPage';
import FreeContentCreatePage from './pages/FreeContentCreatePage/FreeContentCreatePage';
import FreeContentEditPage from './pages/FreeContentEditPage/FreeContentEditPage';
import FreeContentDetailsPage from './pages/FreeContentDetailsPage/FreeContentDetailsPage';
import TagsPage from './pages/TagsPage/TagsPage';
import TagCreatePage from './pages/TagCreatePage/TagCreatePage';
import TagEditPage from './pages/TagEditPage/TagEditPage';
import ProductsPage from './pages/ProductsPage/ProductsPage';
import ProductEditPage from './pages/ProductEditPage/ProductEditPage';
import ProductPage from './pages/ProductPage/ProductPage';
import ProductPricingPage from './pages/ProductPricingPage/ProductPricingPage';
import ProductPriceEditPage from './pages/ProductPriceEditPage/ProductPriceEditPage';
import ProductCreatePage from './pages/ProductCreatePage/ProductCreatePage';
import CategoriesPage from './pages/CategoriesPage/CategoriesPage';
import CategoryEditPage from './pages/CategoryEditPage/CategoryEditPage';
import CategoryCreatePage from './pages/CategoryCreatePage/CategoryCreatePage';
import LoginPage from './pages/LoginPage/LoginPage';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import ProfileEditPage from './pages/ProfileEditPage/ProfileEditPage';
import EmailTemplatesPage from './pages/EmailTemplatesPage/EmailTemplatesPage';
import SendEmailPage from './pages/SendEmailPage/SendEmailPage';
import EmailTemplateEditPage from './pages/EmailTemplateEditPage/EmailTemplateEditPage';
import EmailTemplateCreatePage from './pages/EmailTemplateCreatePage/EmailTemplateCreatePage';
import AutomaticEmailsPage from './pages/AutomaticEmailsPage/AutomaticEmailsPage';
import SentEmailsPage from './pages/SentEmailsPage/SentEmailsPage';
import SentEmailPage from './pages/SentEmailPage/SentEmailPage';
import EmailAccountsPage from './pages/EmailAccountsPage/EmailAccountsPage';
import EmailAccountEditPage from './pages/EmailAccountEditPage/EmailAccountEditPage';
import EmailAccountCreatePage from './pages/EmailAccountCreatePage/EmailAccountCreatePage';
import WorkingTimeListPage from './pages/WorkingTimeListPage/WorkingTimeListPage';
import WorkingTimeRecordPage from './pages/WorkingTimeRecordPage/WorkingTimeRecordPage';
import HolidaysPage from './pages/HolidaysPage/HolidaysPage';
import AlteredWorkSchedulePage from './pages/AlteredWorkSchedulePage/AlteredWorkSchedulePage';
import AlteredWorkdayPage from './pages/AlteredWorkdayPage/AlteredWorkdayPage';
import AbsencesPage from './pages/AbsencesPage/AbsencesPage';
import AbsenceCreatePage from './pages/AbsenceCreatePage/AbsenceCreatePage';
import AbsenceEditPage from './pages/AbsenceEditPage/AbsenceEditPage';
import WorkingTimeEditPage from './pages/WorkingTimeEditPage/WorkingTimeEditPage';
import AccountsPage from './pages/AccountsPage/AccountsPage';
import AccountEditPage from './pages/AccountEditPage/AccountEditPage';
import AccountCreatePage from './pages/AccountCreatePage/AccountCreatePage';
import ShippingListPage from './pages/ShippingListPage/ShippingListPage';
import MyListsPage from './pages/MyListsPage/MyListsPage';
import ListContactPage from './pages/ListContactPage/ListContactPage';
import ListContactLatePage from './pages/ListContactLatePage/ListContactLatePage';
import ListContactSalesPage from './pages/ListContactSalesPage/ListContactSalesPage';
import ListContactUpsellPage from './pages/ListContactUpsellPage/ListContactUpsellPage';
import ListContactStuckPage from './pages/ListContactStuckPage/ListContactStuckPage';
import ListPage from './pages/ListPage/ListPage';
import ListsPage from './pages/ListsPage/ListsPage';
import ListContactStatesPage from './pages/ListContactStatesPage/ListContactStatesPage';
import CharacteristicsPage from './pages/CharacteristicsPage/CharacteristicsPage';
import CharacteristicsCreatePage from './pages/CharacteristicsCreatePage/CharacteristicsCreatePage';
import ListCreatePage from './pages/ListCreatePage/ListCreatePage';
import CustomersPage from './pages/CustomersPage/CustomersPage';
import BlockedContactsPage from './pages/BlockedContactsPage/BlockedContactsPage';
import DayClosingsPage from './pages/DayClosingsPage/DayClosingsPage';
import MyPerformancePage from './pages/MyPerformancePage/MyPerformancePage';
import { ListProvider } from './contexts/ListContext';

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <Navbar />

            <Routes>
                <Route path="/" element={<ProtectedRoute element={<OrdersPage />} />} />
                <Route path="/login" element={<LoginWithRedirect />} />
                <Route path="/orders/:id" element={<ProtectedRoute element={<OrderDetailsPage />} />} />
                <Route path="/orders/create-order">
                    <Route path="/orders/create-order/:customerId" element={<ProtectedRoute element={<NewOrderPage />} />} />
                    <Route path="" element={<ProtectedRoute element={<NewOrderPage />} />} />
                </Route>
                <Route path="/orders/awaiting-approval" element={<ProtectedRoute element={<AwaitingOrdersPage />} />} />
                <Route path="/orders/payment-installments/:id/edit" element={<ProtectedRoute element={<InstallmentsEditPage />} />} />
                <Route path="/orders/:orderId/delivery/:customerId/:orderedProductId/create" element={<ProtectedRoute element={<DeliveryCreatePage />} />} />
                <Route path="/orders/list" element={<ProtectedRoute element={<OrdersPage />} />} />
                <Route path="/orders/expected-orders/list" element={<ProtectedRoute element={<ExpectedOrdersPage />} />} />
                <Route path="/orders/expected-orders/create">
                    <Route path="/orders/expected-orders/create/:customerid" element={<ProtectedRoute element={<ExpectedOrderCreatePage />} />} />
                    <Route path="" element={<ProtectedRoute element={<ExpectedOrderCreatePage />} />} />
                </Route>
                <Route path="/shipping/cod" element={<ProtectedRoute element={<CodPage />} />} />
                <Route path="/marketing/campaigns" element={<ProtectedRoute element={<MarketingCampaignsPage />} />} />
                <Route path="/marketing/campaign/create" element={<ProtectedRoute element={<MarketingCampaignCreatePage />} />} />
                <Route path="/marketing/campaign/:id/edit" element={<ProtectedRoute element={<MarketingCampaignEditPage />} />} />
                <Route path="/marketing/free-content" element={<ProtectedRoute element={<FreeContentsPage />} />} />
                <Route path="/marketing/free-content/create" element={<ProtectedRoute element={<FreeContentCreatePage />} />} />
                <Route path="/marketing/free-content/:id/edit" element={<ProtectedRoute element={<FreeContentEditPage />} />} />
                <Route path="/marketing/free-content/:id/details" element={<ProtectedRoute element={<FreeContentDetailsPage />} />} />
                <Route path="/marketing/tags" element={<ProtectedRoute element={<TagsPage />} />} />
                <Route path="/marketing/tags/create" element={<ProtectedRoute element={<TagCreatePage />} />} />
                <Route path="/marketing/tags/:id/edit" element={<ProtectedRoute element={<TagEditPage />} />} />
                <Route path="/products/list" element={<ProtectedRoute element={<ProductsPage />} />} />
                <Route path="/products/:id/edit" element={<ProtectedRoute element={<ProductEditPage />} />} />
                <Route path="/products/:id/details" element={<ProtectedRoute element={<ProductPage />} />} />
                <Route path="/products/:id/pricing" element={<ProtectedRoute element={<ProductPricingPage />} />} />
                <Route path="/products/:id/price/:priceid/edit" element={<ProtectedRoute element={<ProductPriceEditPage />} />} />
                <Route path="/products/create" element={<ProtectedRoute element={<ProductCreatePage />} />} />
                <Route path="/products/categories" element={<ProtectedRoute element={<CategoriesPage />} />} />
                <Route path="/products/categories/:id/edit" element={<ProtectedRoute element={<CategoryEditPage />} />} />
                <Route path="/products/categories/create" element={<ProtectedRoute element={<CategoryCreatePage />} />} />
                <Route path="/emails/templates" element={<ProtectedRoute element={<EmailTemplatesPage />} />} />
                <Route path="/emails/templates/:id/send" element={<ProtectedRoute element={<SendEmailPage />} />} />
                <Route path="/emails/templates/:id/edit" element={<ProtectedRoute element={<EmailTemplateEditPage />} />} />
                <Route path="/emails/templates/create" element={<ProtectedRoute element={<EmailTemplateCreatePage />} />} />
                <Route path="/emails/automatic" element={<ProtectedRoute element={<AutomaticEmailsPage />} />} />
                <Route path="/emails/sent" element={<ProtectedRoute element={<SentEmailsPage />} />} />
                <Route path="/emails/sent/:id" element={<ProtectedRoute element={<SentEmailPage />} />} />
                <Route path="/emails/accounts" element={<ProtectedRoute element={<EmailAccountsPage />} />} />
                <Route path="/emails/accounts/:id/edit" element={<ProtectedRoute element={<EmailAccountEditPage />} />} />
                <Route path="/emails/accounts/create" element={<ProtectedRoute element={<EmailAccountCreatePage />} />} />
                <Route path="/working-hours/list" element={<ProtectedRoute element={<WorkingTimeListPage />} />} />
                <Route path="/working-hours/:id/edit" element={<ProtectedRoute element={<WorkingTimeEditPage />} />} />
                <Route path="/working-hours/record" element={<ProtectedRoute element={<WorkingTimeRecordPage />} />} />
                <Route path="/working-hours/holidays" element={<ProtectedRoute element={<HolidaysPage />} />} />
                <Route path="/working-hours/altered-work-schedule" element={<ProtectedRoute element={<AlteredWorkSchedulePage />} />} />
                <Route path="/working-hours/altered-work-schedule/create" element={<ProtectedRoute element={<AlteredWorkdayPage />} />} />
                <Route path="/working-hours/absence" element={<ProtectedRoute element={<AbsencesPage />} />} />
                <Route path="/working-hours/absence/create" element={<ProtectedRoute element={<AbsenceCreatePage />} />} />
                <Route path="/working-hours/absence/:id/edit" element={<ProtectedRoute element={<AbsenceEditPage />} />} />
                <Route path="/settings/accounts" element={<ProtectedRoute element={<AccountsPage />} />} />
                <Route path="/settings/accounts/:id/edit" element={<ProtectedRoute element={<AccountEditPage />} />} />
                <Route path="/settings/accounts/create" element={<ProtectedRoute element={<AccountCreatePage />} />} />
                <Route path="/settings/day-closings" element={<ProtectedRoute element={<DayClosingsPage />} />} />
                <Route path="/customers/:id" element={<ProtectedRoute element={<CustomerPage />} />} />
                <Route path="/customers" element={<ProtectedRoute element={<CustomersPage />} />} />
                <Route path="/profile" element={<ProtectedRoute element={<ProfilePage />} />} />
                <Route path="/profile/edit" element={<ProtectedRoute element={<ProfileEditPage />} />} />
                <Route path="/shipping/list" element={<ProtectedRoute element={<ShippingListPage />} />} />
                <Route path="/telesales/my-lists" element={<ProtectedRoute element={<MyListsPage />} />} />
                <Route path="/telesales/contact/:id" element={<ProtectedRoute element={<ListContactPage />} />} />
                <Route path="/telesales/late-contact/:id" element={<ProtectedRoute element={<ListContactLatePage />} />} />
                <Route path="/telesales/sales-contact/:id" element={<ProtectedRoute element={<ListContactSalesPage />} />} />
                <Route path="/telesales/upsell-contact/:id" element={<ProtectedRoute element={<ListContactUpsellPage />} />} />
                <Route path="/telesales/stuck-contact/:id" element={<ProtectedRoute element={<ListContactStuckPage />} />} />
                <Route path="/telesales/lists/:id" element={<ProtectedRoute element={<ListPage />} />} />
                <Route path="/telesales/lists" element={<ProtectedRoute element={<ListsPage />} />} />
                <Route path="/telesales/states" element={<ProtectedRoute element={<ListContactStatesPage />} />} />
                <Route path="/telesales/blocked-contacts" element={<ProtectedRoute element={<BlockedContactsPage />} />} />
                <Route path="/telesales/characteristics" element={<ProtectedRoute element={<CharacteristicsPage />} />} />
                <Route path="/telesales/characteristics/create" element={<ProtectedRoute element={<CharacteristicsCreatePage />} />} />
                <Route path="/telesales/my-performance" element={<ProtectedRoute element={<MyPerformancePage />} />} />
                <Route
                    path="/telesales/lists/create"
                    element={
                        <ListProvider>
                            <ListCreatePage />
                        </ListProvider>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
};

function ProtectedRoute(props: { element: JSX.Element }) {
    return Number(localStorage.getItem('erp3-session-expiration')) - new Date().getTime() > 0 ? props.element : <Navigate to="/login" replace />;
}

function LoginWithRedirect() {
    return Number(localStorage.getItem('erp3-session-expiration')) - new Date().getTime() > 0 ? <Navigate to="/" replace /> : <LoginPage />;
}

export default App;
