import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const createAcTagMutation = gql`
    mutation createAcTag($data: AcTagInputCreate!) {
        createAcTag(data: $data) {
            id
        }
    }
`;

interface AcTag {
    id: number;
}

const useCreateAcTagMutation = mutationFactory<AcTag>(createAcTagMutation, 'createAcTag');

export default useCreateAcTagMutation;
