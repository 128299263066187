import React from 'react';
import { Link } from 'react-router-dom';

import IDProps from './IDProps';

class ID extends React.Component<IDProps> {
    constructor(props: IDProps) {
        super(props);
    }

    render(): React.ReactNode {
        if (this.props.openable && this.props.url) {
            return <Link to={this.props.url}>#{this.props.id}</Link>;
        } else {
            return <span style={style}>#{this.props.id}</span>;
        }
    }
}

const style = {
    color: '#007bff',
};

export default ID;
