import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const usersQuery = gql`
    query {
        users {
            id
            username
            firstname
            lastname
            middlename
            email
            active
            permission {
                name
            }
        }
    }
`;

interface User {
    id: number;
    username: string;
    firstname: string;
    lastname: string;
    middlename: string;
    email: string;
    active: boolean;
    permission: {
        name: string;
    };
}

const useUsersQuery = queryFactory<User[]>(usersQuery, 'users');

export default useUsersQuery;
