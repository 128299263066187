import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const updateBlockedContact = gql`
    mutation updateBlockedContact($id: Int!, $data: BlockedContactInputUpdate!) {
        updateBlockedContact(id: $id, data: $data) {
            id
        }
    }
`;

const useUpdateBlockedContact = mutationFactory<{ id: number }>(updateBlockedContact, 'updateBlockedContact');

export default useUpdateBlockedContact;
