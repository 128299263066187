import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const priceTypesQuery = gql`
    query {
        priceTypes {
            id
            name
            description
        }
    }
`;

interface PriceType {
    id: number;
    name: string;
    description: string;
}

const usePriceTypesQuery = queryFactory<PriceType[]>(priceTypesQuery, 'priceTypes');

export type { PriceType };
export default usePriceTypesQuery;
