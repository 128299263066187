/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Button, Typography, Modal, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Section } from '../../components/Section';
import { v4 } from 'uuid';
import useErrorHandler from '../../utils/userErrorHandler';
import { Link } from 'react-router-dom';
import useExpectedOrdersQuery from './gql/expectedOrders';
import useDeleteExpectedOrderMutation from './gql/deleteExpectedOrder';

const ExpectedOrdersPage: React.FC = () => {
    const [expectedOrders, expectedOrdersResp] = useExpectedOrdersQuery();
    const [deleteOrder, deleteOrderResp] = useDeleteExpectedOrderMutation();
    useErrorHandler(expectedOrdersResp.error, deleteOrderResp.error);

    const [fulfilled, setFulfilled] = useState<string>('all');
    const [selected, setSelected] = useState<number>();
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        expectedOrders({ fulfilled: getStatus() });
    }, [fulfilled]);

    useEffect(() => {
        if (selected) expectedOrders({ fulfilled: getStatus() });
    }, [deleteOrderResp.data]);

    const getStatus = () => {
        if (fulfilled === 'all') return null;
        if (fulfilled === 'true') return true;
        if (fulfilled === 'false') return false;
    };

    const handleModal = (id: number) => {
        setModalOpen(true);
        setSelected(id);
    };

    const handleDelete = async () => {
        await deleteOrder({ id: selected });
        setModalOpen(false);
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`Várható rendelések`}>
                <div css={sectionStyles}>
                    <FormControl sx={{ minWidth: 200, width: '100%' }}>
                        <InputLabel id="status">Szűrés állapotra</InputLabel>
                        <Select labelId="status" id="status-select" label="Szűrés állapotra" value={fulfilled} onChange={(e) => setFulfilled(e.target.value)}>
                            <MenuItem value={'all'}>Összes</MenuItem>
                            <MenuItem value={'true'}>Teljesített</MenuItem>
                            <MenuItem value={'false'}>Teljesítetlen</MenuItem>
                        </Select>
                    </FormControl>
                    {expectedOrdersResp.data?.length !== undefined && expectedOrdersResp.data?.length !== 0 ? (
                        <TableContainer aria-label="table" style={{ minWidth: 1310 }} component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">ID</TableCell>
                                        <TableCell align="center">Termék</TableCell>
                                        <TableCell align="center">Vevő neve</TableCell>
                                        <TableCell align="center">E-mail</TableCell>
                                        <TableCell align="center">Telefon</TableCell>
                                        <TableCell align="center">Teljesítve</TableCell>
                                        <TableCell align="center">Dátum</TableCell>
                                        <TableCell align="center">Értékesítő</TableCell>
                                        <TableCell align="center">Műveletek</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {expectedOrdersResp.data?.map((row) => (
                                        <TableRow key={v4()} css={trStyles}>
                                            <TableCell align="center">{row.id}</TableCell>
                                            <TableCell align="center">{row.product.name}</TableCell>
                                            <TableCell align="center">{`${row.customer.lastname} ${row.customer.firstname}`}</TableCell>
                                            <TableCell align="center">{row.customer.customer.email}</TableCell>
                                            <TableCell align="center">{row.customer.phone}</TableCell>
                                            <TableCell align="center">{row.fulfilled ? <Link to={`/orders/${row.fulfilled.id}`}>#{row.fulfilled.id}</Link> : 'Nem'}</TableCell>
                                            <TableCell align="center">{row.created_at}</TableCell>
                                            <TableCell align="center">{`${row.seller.lastname} ${row.seller.firstname}`}</TableCell>
                                            <TableCell align="center" css={actionsColStyle}>
                                                <Button variant="contained" color="error" onClick={() => handleModal(row.id)}>
                                                    Törlés
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : expectedOrdersResp.data?.length === 0 ? (
                        <Typography variant="body1" color="primary" align="center">
                            Nincs megjeleníthető adat a megadott feltételek alapján!
                        </Typography>
                    ) : null}
                    <Link to="/orders/expected-orders/create" css={buttonContainerStyle}>
                        <Button variant="contained" color="success">
                            Új várható rendelés rögzítése
                        </Button>
                    </Link>
                </div>
            </Section>
            <Modal open={modalOpen} onClose={() => setModalOpen(false)} aria-labelledby="delete-modal-title" aria-describedby="delete-modal-description">
                <Box css={boxStyles}>
                    <Typography id="delete-modal-title" variant="h6" component="h2" style={{ marginBottom: '1rem' }}>
                        Biztosan törölni szeretnéd a várható rendelést?
                    </Typography>
                    <div css={buttonContainerStyles}>
                        <Button size="large" variant="contained" color="error" onClick={handleDelete}>
                            Igen
                        </Button>
                        <Button size="large" variant="contained" onClick={() => setModalOpen(false)}>
                            Nem
                        </Button>
                    </div>
                </Box>
            </Modal>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    & > * + * {
        margin-top: 20px !important;
    }
`;

const trStyles = css`
    &: nth-of-type(odd) {
        background-color: #e3f2fd;
    }

    &:last-child td,
    &:last-child th {
        border: 0;
    }
`;

const buttonContainerStyle = css`
    text-decoration: none;
    margin-left: auto;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const actionsColStyle = css`
    & > * + * {
        margin-left: 20px !important;
    }
`;

const boxStyles = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 2px solid #0288d1;
    boxshadow: 24;
    padding: 1rem;
`;

const buttonContainerStyles = css`
    display: flex;
    justify-content: space-evenly;
    margin-top: 1rem;
`;

export default ExpectedOrdersPage;
