import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const createPriceMutation = gql`
    mutation createPrice($data: PriceInputCreate!, $product: [Int]!) {
        createPrice(data: $data, product: $product) {
            id
        }
    }
`;

interface NewPrice {
    id: number;
}

const useCreatePriceMutation = mutationFactory<NewPrice>(createPriceMutation, 'createPrice');

export type { NewPrice };
export default useCreatePriceMutation;
