import { gql } from '@apollo/client';
import { lazyQueryFactory } from '../../../utils/gqlFactories';

const offDays = gql`
    query offDays($reason: Int, $user: Int, $from: LocaleDate, $to: LocaleDate) {
        offDays(reason: $reason, user: $user, from: $from, to: $to) {
            id
            start
            end
            reason {
                name
            }
            user {
                username
                lastname
                firstname
                middlename
            }
        }
    }
`;

interface OffDay {
    id: number;
    start: string;
    end: string;
    reason: { name: string };
    user: {
        username: string;
        lastname: string;
        firstname: string;
        middlename: string;
    };
}

const useOffDayQuery = lazyQueryFactory<OffDay[]>(offDays, 'offDays');

export default useOffDayQuery;
