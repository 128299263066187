/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Paper, Button, TextField } from '@mui/material';
import { Section } from '../../components/Section';
import { CustomDateRangePicker } from '../../components/CustomDateRangePicker';
import { addDays } from 'date-fns';
import useCreateCampaignMutation from './gql/createCampaign';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import useErrorHandler from '../../utils/userErrorHandler';

const MarketingCampaignCreatePage: React.FC = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [createCampaign, createdCampaignResp] = useCreateCampaignMutation();
    useErrorHandler(createdCampaignResp.error);

    const [name, setName] = useState('');
    const [date, setDate] = useState<
        {
            startDate: Date;
            endDate: Date;
            key: string;
        }[]
    >([
        {
            startDate: new Date(new Date().setHours(0, 0, 0, 0)),
            endDate: addDays(new Date().setHours(0, 0, 0, 0), 7),
            key: 'selection',
        },
    ]);

    useEffect(() => {
        if (createdCampaignResp.data) navigate('/marketing/campaigns');
    }, [createdCampaignResp.data]);

    const handleDateChange = (item: { selection: { startDate: Date; endDate: Date; key: string } }) => {
        setDate([item.selection]);
    };

    const handeSave = async () => {
        if (name && date[0].startDate && date[0].endDate) {
            await createCampaign({ data: { name, start: date[0].startDate, end: date[0].endDate } });
        } else {
            enqueueSnackbar('Az összes mező kitöltése kötelező!', { variant: 'error' });
        }
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`Kampány létrehozása`}>
                <div css={sectionStyles}>
                    <CustomDateRangePicker ranges={date} onChange={handleDateChange} />
                    <div css={someInputContainerStyles}>
                        <div css={{ minWidth: 226 }} />
                        <div css={inputStyles}>
                            <TextField fullWidth id="name" label="Név" placeholder="A kampány neve" value={name} onChange={(e) => setName(e.target.value)} required />
                        </div>
                    </div>
                </div>
                <div css={buttonContainerStyle}>
                    <Button variant="contained" color="success" onClick={handeSave}>
                        Létrehoz
                    </Button>
                </div>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    & > * + * {
        margin-top: 20px !important;
    }
`;

const inputStyles = css`
    width: 100%;
    min-width: 352px;
    @media (max-width: 1000px) {
        width: auto;
    }
`;

const someInputContainerStyles = css`
    width: 931px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1000px) {
        flex-direction: column;
        & > * + * {
            margin-top: 20px !important;
        }
    }
`;

const buttonContainerStyle = css`
    text-decoration: none;
    margin-left: auto;
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
`;

export default MarketingCampaignCreatePage;
