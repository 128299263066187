import { gql } from '@apollo/client';
import { mutationFactory } from '../../../../utils/gqlFactories';

const updateUpsellListContact = gql`
    mutation updateUpsellListContact($id: Int!, $data: UpsellListContactInputUpdate!) {
        updateUpsellListContact(id: $id, data: $data) {
            id
        }
    }
`;

const useUpdateUpsellListContact = mutationFactory<{ id: number }>(updateUpsellListContact, 'updateUpsellListContact');

export default useUpdateUpsellListContact;
