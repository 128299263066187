/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useErrorHandler from '../../../utils/userErrorHandler';
import { useSnackbar } from 'notistack';
import useUpdateProductMutation from '../gql/updateProduct';

type ProductInfoProps = {
    id: string;
    name: string;
    description: string;
    stripeId: string;
};

const ProductInfo: React.FC<ProductInfoProps> = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [updateProduct, updatedProductResp] = useUpdateProductMutation();
    useErrorHandler(updatedProductResp.error);

    const [name, setName] = useState<string>(props.name);
    const [description, setDescription] = useState<string>(props.description);
    const [stripeId, setStripeId] = useState<string>(props.stripeId);

    const handleProductEdit = async () => {
        if (!name || !stripeId) {
            enqueueSnackbar('A csillaggal jelölt mező kitöltése kötelező!', { variant: 'error' });
            return;
        }

        if (!/^prod_[A-Za-z0-9]{14}$/.test(stripeId)) {
            enqueueSnackbar('A Stripe ID prod_MbIRTksxUzHPwT formátumú kell legyen!', { variant: 'error' });
            return;
        }

        try {
            await updateProduct({ id: parseInt(props.id), data: { name, description, stripe_id: stripeId } });
            navigate('/products/list');
        } catch (err) {}
    };

    return (
        <div css={inputsContainerStyles}>
            <TextField fullWidth id="name" label="Név" placeholder="A termék neve" value={name} onChange={(e) => setName(e.target.value)} css={inputsStyles} required />
            <TextField id="description" label="Leírás" multiline rows={4} value={description} onChange={(e) => setDescription(e.target.value)} style={{ width: '100%' }} />
            <TextField id="stripeId" label="Stripe termék ID" placeholder="A prod_ kezdetű Stripe termék ID" value={stripeId} onChange={(e) => setStripeId(e.target.value)} css={inputsStyles} required />
            <div css={buttonInputsContainerStyles}>
                <Button size="large" variant="contained" color="success" onClick={handleProductEdit}>
                    Módosít
                </Button>
            </div>
        </div>
    );
};

const inputsContainerStyles = css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    & > * + * {
        margin-top: 20px !important;
    }
`;

const inputsStyles = css`
    width: 100%;
`;

const buttonInputsContainerStyles = css`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    gap: 20px;
    @media (max-width: 1200px) {
        justify-content: center;
        align-items: center;
    }
`;

export default ProductInfo;
