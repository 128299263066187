import { gql } from '@apollo/client';
import { mutationFactory } from '../../../../utils/gqlFactories';

const createCustomerNote = gql`
    mutation createCustomerNote($data: CustomerNoteInputCreate!) {
        createCustomerNote(data: $data) {
            id
        }
    }
`;

const useCreateCustomerNote = mutationFactory<{ id: number }>(createCustomerNote, 'createCustomerNote');

export default useCreateCustomerNote;
