/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { v4 } from 'uuid';

import { IconButton, Typography, Chip, Tooltip } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { PaymentMethod } from '../../../InstallmentsEditPage/gql/paymentMethods';
import { Currency } from '../../gql/currencies';
import { Action, ListedProduct, NewInstallment } from '../Products';
import { SubSection } from '../../../../components/SubSection';
import MemorizedInstallmentRow from '../MemorizedInstallmentRow/MemorizedInstallmentRow';

type ProductProps = {
    product: ListedProduct;
    setSelectedProductId: React.Dispatch<React.SetStateAction<string>>;
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    currencies: Currency[];
    paymentMethods: PaymentMethod[];
    dispatch: React.Dispatch<Action>;
};

const Product: React.FC<ProductProps> = (props) => {
    const handleNewRow = (renderId: string | undefined) => {
        renderId && props.dispatch({ type: 'addInstallment', productRenderId: renderId, installment: { id: v4(), gross: '0', due_date: null, payment_method: props.paymentMethods[0] } });
    };

    return (
        <div key={props.product.renderId} css={productContainerStyles}>
            <div css={productBasicInfoContainerStyles}>
                <div css={productInfoContainerStyles}>
                    <Typography variant="h6">Terméknév:</Typography>
                    <Typography variant="h6" color="#90caf9">
                        {props.product.name}
                    </Typography>
                </div>
                <div css={productInfoContainerStyles}>
                    <Typography variant="h6" style={{ display: 'inline' }}>
                        {props.product.net ? `Egyedi ár:` : 'Ár:'}{' '}
                    </Typography>
                    <div css={priceContainerStyles}>
                        <Typography variant="h6" color="#90caf9" style={{ display: 'inline' }}>
                            {props.product.gross} Ft {props.product.net ? `(net: ${props.product.net} Ft)` : ''}
                        </Typography>

                        {!props.isModalOpen && (
                            <Tooltip title="Egyedi ár megadása" placement="top" arrow>
                                <IconButton
                                    size="small"
                                    aria-label="editPrice"
                                    onClick={() => {
                                        props.setIsModalOpen(true);
                                        props.setSelectedProductId(props.product.renderId ? props.product.renderId : '');
                                    }}
                                    color="inherit"
                                >
                                    <EditOutlinedIcon fontSize="large" />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                </div>

                <IconButton size="large" aria-label="delete" onClick={() => props.dispatch({ type: 'deleteProduct', renderId: props.product.renderId })} color="inherit" css={deleteButtonStyles}>
                    <DeleteForeverIcon fontSize="large" />
                </IconButton>
            </div>
            <SubSection title="Fizetési részletek" collapsible css={subSectionStyles}>
                <div css={productInstallmentContainerStyles}>
                    {props.product.installments.map((installment: NewInstallment, index) => (
                        <MemorizedInstallmentRow key={index} paymentTypes={props.paymentMethods} currencies={props.currencies} installment={installment} dispatch={props.dispatch} productRenderId={props.product.renderId} />
                    ))}
                    <div>
                        <Chip label="+" color="success" onClick={() => handleNewRow(props.product.renderId)} />
                    </div>
                </div>
            </SubSection>
        </div>
    );
};

const subSectionStyles = css`
    width: 100%;
`;

const productContainerStyles = css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: #42a5f5;
    background-color: #e3f2fd;
    padding: 10px 20px 20px;
    gap: 20px;

    @media (max-width: 1100px) {
        flex-direction: column;
        align-items: start;
        gap: 10px;
    }
`;

const productBasicInfoContainerStyles = css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1100px) {
        flex-direction: column;
        flex-wrap: wrap;
    }
`;

const productInstallmentContainerStyles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    @media (max-width: 1100px) {
        flex-direction: row;
        flex-wrap: wrap;
    }
`;

const productInfoContainerStyles = css`
    width: 45%;
    display: flex;
    align-items: center;
    gap: 30px;

    @media (max-width: 1100px) {
        width: 100%;
        justify-content: space-between;
        gap: 10px;
    }
`;

const priceContainerStyles = css`
    display: flex;
    align-items: center;
    gap: 30px;

    @media (max-width: 1100px) {
        justify-content: space-between;
        gap: 10px;
    }
`;

const deleteButtonStyles = css`
    @media (max-width: 1100px) {
        margin: auto;
    }
`;

export type { ProductProps };
export default Product;
