import { gql } from '@apollo/client';
import { lazyQueryFactory } from '../../../utils/gqlFactories';

const workingHoursQuery = gql`
    query workingHours($user: Int, $from: LocaleDate, $to: LocaleDate) {
        workingHours(user: $user, from: $from, to: $to) {
            id
            date
            user {
                username
                firstname
                lastname
                middlename
            }
            hours
        }
    }
`;

type User = {
    username: string;
    firstname: string;
    lastname: string;
    middlename: string;
};

interface WorkingHours {
    id: number;
    date: string;
    user: User;
    hours: number;
}

const useWorkingHoursQuery = lazyQueryFactory<WorkingHours[]>(workingHoursQuery, 'workingHours');

export default useWorkingHoursQuery;
