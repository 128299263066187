/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Price } from '../gql/products';

type ProductPriceSelectionProps = {
    selections: number | null;
    setSelections: React.Dispatch<React.SetStateAction<number | null>>;
    options: Price[];
};

const ProductPriceSelection: React.FC<ProductPriceSelectionProps> = (props) => {
    const [value, setValue] = useState<Price | null>(null);

    useEffect(() => {
        if (props.options) handleValue();
    }, [props.options]);

    const handleChange = (selectedValue: Price | null): void => {
        if (selectedValue) {
            setValue(selectedValue);
            props.setSelections(selectedValue.id);
        } else {
            setValue(null);
            props.setSelections(null);
        }
    };

    const handleValue = () => {
        const selected = props.options.find((price) => price.id === props.selections);
        if (selected) {
            setValue(selected);
        } else {
            setValue(null);
        }
    };

    return (
        <Autocomplete
            options={props.options ? props.options : []}
            value={value}
            renderInput={(params) => <TextField {...params} label="Termék ára *" placeholder="Termék ára" />}
            getOptionLabel={(option) => `${option.type.name}, ${option.gross} Ft`}
            onChange={(_event, value) => {
                handleChange(value);
            }}
            css={inputsStyles}
        />
    );
};

const inputsStyles = css`
    width: 100%;
`;

export default ProductPriceSelection;
