/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import { Paper, FormControl, RadioGroup, Radio, FormControlLabel, Typography } from '@mui/material';
import { Section } from '../../components/Section';
import Performance from './Performance/Performance';
import ExpectedOrders from './ExpectedOrders/ExpectedOrders';
import PaymentRate from './PaymentRate/PaymentRate';
import useMyProgression, { Progression } from './gql/myProgression';
import useMyExpectedOrders, { ExpectedOrdersConversion } from './gql/myExpectedOrders';
import useMyPaymentRate, { OrderPaymentRate } from './gql/myPaymentRate';
import useErrorHandler from '../../utils/userErrorHandler';
import getCurrentBusinessQuarter from '../../utils/getCurrentBusinessQuarter';

const MyPerformancePage: React.FC = () => {
    const [comparisonType, setComparisonType] = useState('month');
    const [expectedOrdersComparisonType, setExpectedOrdersComparisonType] = useState('month');
    const [orderPaymentComparisonType, setOrderPaymentComparisonType] = useState('month');
    const [firstComparisonBlockData, setFirstComparisonBlockData] = useState({ monthly: { target: 0, progress: 0 }, quarterly: { target: 0, progress: 0 } });
    const [secondComparisonBlockData, setSecondComparisonBlockData] = useState({ monthly: { target: 0, progress: 0 }, quarterly: { target: 0, progress: 0 } });
    const [expectedOrdersFirstComparisonBlockData, setExpectedOrdersFirstComparisonBlockData] = useState({ monthly: { expectedOrders: 0, orders: 0 }, quarterly: { expectedOrders: 0, orders: 0 } });
    const [expectedOrdersSecondComparisonBlockData, setExpectedOrdersSecondComparisonBlockData] = useState({ monthly: { expectedOrders: 0, orders: 0 }, quarterly: { expectedOrders: 0, orders: 0 } });
    const [paymentRateFirstComparisonBlockData, setPaymentRateFirstComparisonBlockData] = useState({ monthly: { totalPrice: 0, totalPayed: 0 }, quarterly: { totalPrice: 0, totalPayed: 0 } });
    const [paymentRateSecondComparisonBlockData, setPaymentRateSecondComparisonBlockData] = useState({ monthly: { totalPrice: 0, totalPayed: 0 }, quarterly: { totalPrice: 0, totalPayed: 0 } });
    const myProgression = useMyProgression({ year: new Date().getFullYear(), month: new Date().getMonth() + 1, quarter: getCurrentBusinessQuarter() });
    const myExpectedOrders = useMyExpectedOrders({ year: new Date().getFullYear(), month: new Date().getMonth() + 1, quarter: getCurrentBusinessQuarter() });
    const myPaymentRate = useMyPaymentRate({ year: new Date().getFullYear(), month: new Date().getMonth() + 1, quarter: getCurrentBusinessQuarter() });
    useErrorHandler(myProgression.error, myExpectedOrders.error, myPaymentRate.error);

    const getProgressionPercentage = (progress: number, target: number): number => {
        return Math.floor((progress / target) * 100);
    };

    const upliftFirstComparisonBlockData = (data: Progression) => {
        setFirstComparisonBlockData(data);
    };

    const upliftSecondComparisonBlockData = (data: Progression) => {
        setSecondComparisonBlockData(data);
    };

    const upliftExpectedOrdersFirstComparisonBlockData = (data: ExpectedOrdersConversion) => {
        setExpectedOrdersFirstComparisonBlockData(data);
    };

    const upliftExpectedOrdersSecondComparisonBlockData = (data: ExpectedOrdersConversion) => {
        setExpectedOrdersSecondComparisonBlockData(data);
    };

    const upliftPaymentRateFirstComparisonBlockData = (data: OrderPaymentRate) => {
        setPaymentRateFirstComparisonBlockData(data);
    };

    const upliftPaymentRateSecondComparisonBlockData = (data: OrderPaymentRate) => {
        setPaymentRateSecondComparisonBlockData(data);
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title="Jelenlegi teljesítmény">
                <div css={sectionStyles}>
                    <Typography>
                        Havi cél: {myProgression.data?.monthly.progress} / {myProgression.data?.monthly.target} Ft{' '}
                        <span style={{ fontWeight: 700, color: getProgressionPercentage(myProgression.data?.monthly.progress as number, myProgression.data?.monthly.target as number) >= 100 ? '#2e7d32' : '#d32f2f' }}>
                            ({getProgressionPercentage(myProgression.data?.monthly.progress as number, myProgression.data?.monthly.target as number)}%)
                        </span>
                    </Typography>
                    <Typography>
                        Negyedéves cél: {myProgression.data?.quarterly.progress} / {myProgression.data?.quarterly.target} Ft{' '}
                        <span style={{ fontWeight: 700, color: getProgressionPercentage(myProgression.data?.quarterly.progress as number, myProgression.data?.quarterly.target as number) >= 100 ? '#2e7d32' : '#d32f2f' }}>
                            ({getProgressionPercentage(myProgression.data?.quarterly.progress as number, myProgression.data?.quarterly.target as number)}%)
                        </span>
                    </Typography>
                </div>
            </Section>

            <Section title="Teljesítmény összehasonlítás">
                <div css={sectionStyles}>
                    <FormControl style={{ maxWidth: 300 }}>
                        <RadioGroup aria-labelledby="radio-buttons-group" name="controlled-radio-buttons-group" value={comparisonType} onChange={(e) => setComparisonType(e.target.value)}>
                            <FormControlLabel value="month" control={<Radio />} label="Hónap összehasonlítás" />
                            <FormControlLabel value="quarter" control={<Radio />} label="Negyedév összehasonlítás" />
                        </RadioGroup>
                    </FormControl>

                    <div css={comparisonConatinerStyles}>
                        <Paper css={paperStyles} elevation={3}>
                            <Performance quarterly={comparisonType === 'quarter'} upliftData={upliftFirstComparisonBlockData} comparisonBlockData={secondComparisonBlockData} />
                        </Paper>
                        <Paper css={paperStyles} elevation={3}>
                            <Performance quarterly={comparisonType === 'quarter'} upliftData={upliftSecondComparisonBlockData} comparisonBlockData={firstComparisonBlockData} />
                        </Paper>
                    </div>
                </div>
            </Section>

            <Section title="Várható rendelés konverziós arány">
                <div css={sectionStyles}>
                    <Typography>
                        Havi konverziós arány: {myExpectedOrders.data?.monthly.orders} / {myExpectedOrders.data?.monthly.expectedOrders}{' '}
                        <span style={{ fontWeight: 700, color: getProgressionPercentage(myExpectedOrders.data?.monthly.orders as number, myExpectedOrders.data?.monthly.expectedOrders as number) >= 100 ? '#2e7d32' : '#d32f2f' }}>
                            ({getProgressionPercentage(myExpectedOrders.data?.monthly.orders as number, myExpectedOrders.data?.monthly.expectedOrders as number)}%)
                        </span>
                    </Typography>
                    <Typography>
                        Negyedéves konverziós arány: {myExpectedOrders.data?.quarterly.orders} / {myExpectedOrders.data?.quarterly.expectedOrders}{' '}
                        <span style={{ fontWeight: 700, color: getProgressionPercentage(myExpectedOrders.data?.quarterly.orders as number, myExpectedOrders.data?.quarterly.expectedOrders as number) >= 100 ? '#2e7d32' : '#d32f2f' }}>
                            ({getProgressionPercentage(myExpectedOrders.data?.quarterly.orders as number, myExpectedOrders.data?.quarterly.expectedOrders as number)}%)
                        </span>
                    </Typography>
                </div>
            </Section>

            <Section title="Várható rendelés konverzió összehasonlítás">
                <div css={sectionStyles}>
                    <FormControl style={{ maxWidth: 300 }}>
                        <RadioGroup aria-labelledby="radio-buttons-group" name="controlled-radio-buttons-group" value={expectedOrdersComparisonType} onChange={(e) => setExpectedOrdersComparisonType(e.target.value)}>
                            <FormControlLabel value="month" control={<Radio />} label="Hónap összehasonlítás" />
                            <FormControlLabel value="quarter" control={<Radio />} label="Negyedév összehasonlítás" />
                        </RadioGroup>
                    </FormControl>

                    <div css={comparisonConatinerStyles}>
                        <Paper css={paperStyles} elevation={3}>
                            <ExpectedOrders quarterly={expectedOrdersComparisonType === 'quarter'} upliftData={upliftExpectedOrdersFirstComparisonBlockData} comparisonBlockData={expectedOrdersSecondComparisonBlockData} />
                        </Paper>
                        <Paper css={paperStyles} elevation={3}>
                            <ExpectedOrders quarterly={expectedOrdersComparisonType === 'quarter'} upliftData={upliftExpectedOrdersSecondComparisonBlockData} comparisonBlockData={expectedOrdersFirstComparisonBlockData} />
                        </Paper>
                    </div>
                </div>
            </Section>

            <Section title="Rendelés kifizetés">
                <div css={sectionStyles}>
                    <Typography>
                        Havi fizetési arány: {myPaymentRate.data?.monthly.totalPayed} / {myPaymentRate.data?.monthly.totalPrice} Ft{' '}
                        <span style={{ fontWeight: 700, color: getProgressionPercentage(myPaymentRate.data?.monthly.totalPayed as number, myPaymentRate.data?.monthly.totalPrice as number) >= 100 ? '#2e7d32' : '#d32f2f' }}>
                            ({getProgressionPercentage(myPaymentRate.data?.monthly.totalPayed as number, myPaymentRate.data?.monthly.totalPrice as number)}%)
                        </span>
                    </Typography>
                    <Typography>
                        Negyedéves fizetési arány: {myPaymentRate.data?.quarterly.totalPayed} / {myPaymentRate.data?.quarterly.totalPrice} Ft{' '}
                        <span style={{ fontWeight: 700, color: getProgressionPercentage(myPaymentRate.data?.quarterly.totalPayed as number, myPaymentRate.data?.quarterly.totalPrice as number) >= 100 ? '#2e7d32' : '#d32f2f' }}>
                            ({getProgressionPercentage(myPaymentRate.data?.quarterly.totalPayed as number, myPaymentRate.data?.quarterly.totalPrice as number)}%)
                        </span>
                    </Typography>
                </div>
            </Section>

            <Section title="Rendelés kifizetés összehasonlítás">
                <div css={sectionStyles}>
                    <FormControl style={{ maxWidth: 300 }}>
                        <RadioGroup aria-labelledby="radio-buttons-group" name="controlled-radio-buttons-group" value={orderPaymentComparisonType} onChange={(e) => setOrderPaymentComparisonType(e.target.value)}>
                            <FormControlLabel value="month" control={<Radio />} label="Hónap összehasonlítás" />
                            <FormControlLabel value="quarter" control={<Radio />} label="Negyedév összehasonlítás" />
                        </RadioGroup>
                    </FormControl>

                    <div css={comparisonConatinerStyles}>
                        <Paper css={paperStyles} elevation={3}>
                            <PaymentRate quarterly={orderPaymentComparisonType === 'quarter'} upliftData={upliftPaymentRateFirstComparisonBlockData} comparisonBlockData={paymentRateSecondComparisonBlockData} />
                        </Paper>
                        <Paper css={paperStyles} elevation={3}>
                            <PaymentRate quarterly={orderPaymentComparisonType === 'quarter'} upliftData={upliftPaymentRateSecondComparisonBlockData} comparisonBlockData={paymentRateFirstComparisonBlockData} />
                        </Paper>
                    </div>
                </div>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;

    & > * + * {
        margin-top: 20px !important;
    }
`;

const comparisonConatinerStyles = css`
    width: 100%;
    display: flex;

    & > * + * {
        margin-left: 20px !important;
    }

    @media (max-width: 767px) {
        flex-direction: column;

        & > * + * {
            margin-left: 0 !important;
            margin-top: 20px !important;
        }
    }
`;

const paperStyles = css`
    width: 100%;
    display: flex;
    padding: 20px;
    box-sizing: border-box;

    & > * + * {
        margin-left: 20px !important;
    }
`;

export default MyPerformancePage;
