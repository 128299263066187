/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Paper, Button, Dialog, DialogTitle, TextField, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import DataGridLocalization from '../../utils/DataGridLocalization';
import { Section } from '../../components/Section';
import useCustomerCharacteristicOptions from '../ListContactPage/CustomerCharacteristics/gql/customerCharacteristicOptions';
import useUpdateCustomerCharacteristicOption from './gql/updateCustomerCharacteristicOption';
import useDeleteCustomerCharacteristicOption from './gql/deleteCustomerCharacteristicOption';
import useErrorHandler from '../../utils/userErrorHandler';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const CharacteristicsPage: React.FC = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const customerCharacteristicOptions = useCustomerCharacteristicOptions();
    const [updateCustomerCharacteristicOption, updatedCustomerCharacteristicOption] = useUpdateCustomerCharacteristicOption();
    const [deleteCustomerCharacteristicOption, deletedCustomerCharacteristicOption] = useDeleteCustomerCharacteristicOption();
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [clickedCharacteristic, setClickedCharacteristic] = useState({ id: 0, name: '' });
    const [name, setName] = useState('');
    useErrorHandler(customerCharacteristicOptions.error, updatedCustomerCharacteristicOption.error, deletedCustomerCharacteristicOption.error);

    useEffect(() => {
        if (updatedCustomerCharacteristicOption.data || deletedCustomerCharacteristicOption.data) {
            setIsEditDialogOpen(false);
            setIsDeleteDialogOpen(false);
            setName('');
            enqueueSnackbar('A jellemző sikeresen módosítva!', { variant: 'success' });
            customerCharacteristicOptions.refetch();
        }
    }, [updatedCustomerCharacteristicOption.data, deletedCustomerCharacteristicOption.data]);

    const handleActionClick = (action: 'edit' | 'delete', id: number, name: string) => {
        setClickedCharacteristic({ id: id, name: name });

        if (action === 'edit') {
            setIsEditDialogOpen(true);
            setName(name);
        }
        if (action === 'delete') setIsDeleteDialogOpen(true);
    };

    const deleteCharacteristic = () => {
        if (clickedCharacteristic.id !== 0) deleteCustomerCharacteristicOption({ id: clickedCharacteristic.id });
    };

    const editCharacteristic = () => {
        if (clickedCharacteristic.id !== 0) updateCustomerCharacteristicOption({ id: clickedCharacteristic.id, data: { name: name } });
    };

    const makeRows = (characteristics: { id: number; name: string }[]) => {
        const rows: GridRowsProp = characteristics.map((characteristic) => ({
            id: characteristic.id,
            characteristicID: characteristic.id,
            name: characteristic.name,
        }));

        return rows;
    };

    const makeCols = () => {
        const columns: GridColDef[] = [
            { field: 'characteristicID', headerName: 'ID', width: 100 },
            { field: 'name', headerName: 'Név', width: 1240 },
            {
                field: 'actions',
                headerName: 'Műveletek',
                width: 150,
                renderCell: function renderActions(cellValues) {
                    return (
                        <div css={buttonsContainerStyles}>
                            <Button variant="contained" size="small" color="error" onClick={() => handleActionClick('delete', cellValues.row.id, cellValues.row.name)} style={{ minWidth: 32 }}>
                                <DeleteForeverIcon />
                            </Button>
                            <Button variant="contained" size="small" color="primary" onClick={() => handleActionClick('edit', cellValues.row.id, cellValues.row.name)} style={{ minWidth: 32 }}>
                                <EditIcon />
                            </Button>
                        </div>
                    );
                },
            },
        ];

        return columns;
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title="Jellemzők">
                <div style={{ height: 600, width: '100%' }}>
                    <DataGrid
                        componentsProps={{
                            pagination: {
                                labelRowsPerPage: 'Oldalanként',
                                labelDisplayedRows: ({ from, to, count }: { from: number; to: number; count: number }) => `${from}–${to} / ${count !== -1 ? count : `more than ${to}`}`,
                            },
                        }}
                        localeText={DataGridLocalization}
                        rows={makeRows(customerCharacteristicOptions.data || [])}
                        columns={makeCols()}
                        style={{ borderRadius: 0, border: 'none' }}
                    />
                </div>
                <div style={{ marginLeft: 20, marginBottom: 20 }}>
                    <Button variant="contained" color="success" onClick={() => navigate('/telesales/characteristics/create')}>
                        Új jellemző létrehozása
                    </Button>
                </div>

                <Dialog onClose={() => setIsDeleteDialogOpen(false)} open={isDeleteDialogOpen}>
                    <DialogTitle style={{ backgroundColor: '#0288d1', color: 'white', minWidth: 370 }}>Jellemző törlése</DialogTitle>
                    <div css={dialogContentContainerStyles}>
                        <Typography variant="body1" component="div">
                            Biztosan törlöd a jellemzőt?
                        </Typography>
                        <Typography variant="h6" component="div" align="center">
                            #{clickedCharacteristic.id} - {clickedCharacteristic.name}
                        </Typography>
                        <div css={dialogButtonsContainerStyle}>
                            <Button variant="contained" color="primary" size="large" onClick={() => setIsDeleteDialogOpen(false)}>
                                Mégse
                            </Button>
                            <Button variant="contained" color="error" size="large" onClick={deleteCharacteristic}>
                                Törlés
                            </Button>
                        </div>
                    </div>
                </Dialog>

                <Dialog onClose={() => setIsEditDialogOpen(false)} open={isEditDialogOpen}>
                    <DialogTitle style={{ backgroundColor: '#0288d1', color: 'white', minWidth: 370 }}>Jellemző szerkesztése</DialogTitle>
                    <div css={dialogContentContainerStyles}>
                        <TextField fullWidth label="Név" variant="outlined" value={name} onChange={(e) => setName(e.target.value)} />
                        <div css={dialogButtonsContainerStyle}>
                            <Button variant="contained" color="error" size="large" onClick={() => setIsEditDialogOpen(false)}>
                                Mégse
                            </Button>
                            <Button variant="contained" color="success" size="large" onClick={editCharacteristic}>
                                Mentés
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const buttonsContainerStyles = css`
    & > * + * {
        margin-left: 10px !important;
    }
`;

const dialogContentContainerStyles = css`
    padding: 24px;

    & > * + * {
        margin-top: 25px !important;
    }
`;

const dialogButtonsContainerStyle = css`
    display: flex;
    justify-content: center;
    margin-top: 30px;

    & > * + * {
        margin-left: 20px !important;
    }
`;

export default CharacteristicsPage;
