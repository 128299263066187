/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';

import huLocale from 'date-fns/locale/hu';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DateTimePicker } from '@mui/lab';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { SubSection } from '../../../components/SubSection';
import useUsersQuery, { User } from '../../AwaitingOrdersPage/gql/users';
import useErrorHandler from '../../../utils/userErrorHandler';
import useMeQuery from '../gql/me';
import { NewOrderArguments } from '../NewOrderPage';

type OrderDetailsProps = {
    sendData: (data: NewOrderArguments) => void;
};

const OrderDetails: React.FC<OrderDetailsProps> = (props) => {
    const usersResp = useUsersQuery();
    const meResp = useMeQuery();

    useErrorHandler(usersResp.error);

    const [value, setValue] = useState<User | null>(null);
    const [searchInput, setSearchInput] = useState<string>('');
    const [date, setDate] = useState<Date | null>(new Date());

    useEffect(() => {
        if (meResp.data && usersResp.data) {
            const isSalesMan = usersResp.data.filter((user) => user.permission.name.includes('telesales')).find((user) => user.id === meResp.data?.id);
            isSalesMan ? setValue(isSalesMan) : setValue(null);
        }
    }, [meResp.data, usersResp.data]);

    useEffect(() => {
        props.sendData({ type: 'orderDetails', seller: value ? value.id : null, created_at: date ? date : null });
    }, [value, date]);

    return (
        <SubSection title="Rendelési adatok" css={subSectionStyles}>
            <div css={containerStyles}>
                {usersResp.data && meResp.data && (
                    <Autocomplete
                        id="autocomplete"
                        sx={{ width: 300 }}
                        getOptionDisabled={(option) => !option.active}
                        getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.lastname} ${option.firstname}${option.middlename ? ' ' : ''}${option.middlename ? option.middlename : ''}`)}
                        filterOptions={(options) =>
                            options.filter(
                                (option) =>
                                    `${option.lastname} ${option.firstname}${option.middlename ? ' ' : ''}${option.middlename ? option.middlename : ''}` !==
                                    `${value?.lastname} ${value?.firstname}${value?.middlename ? ' ' : ''}${value?.middlename ? value?.middlename : ''}`,
                            )
                        }
                        options={usersResp.data.filter((user) => user.permission.name.includes('telesales'))}
                        autoComplete
                        includeInputInList
                        filterSelectedOptions
                        value={value}
                        onChange={(_event, newValue) => {
                            setValue(newValue);
                        }}
                        onInputChange={(_event, newInputValue) => {
                            setSearchInput(newInputValue);
                        }}
                        renderInput={(params) => <TextField {...params} label="Értékesítő neve" fullWidth required />}
                        renderOption={(props, option) => {
                            return (
                                <li {...props}>
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <Typography variant="body2" color="text.secondary">
                                                {option.id} - {`${option.lastname} ${option.firstname}${option.middlename ? ' ' : ''}${option.middlename ? option.middlename : ''}`}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </li>
                            );
                        }}
                        disabled={!meResp.data.permission.name.includes('admin')}
                    />
                )}

                <LocalizationProvider dateAdapter={AdapterDateFns} locale={huLocale}>
                    <DateTimePicker
                        label="Rendelés dátuma *"
                        views={['year', 'month', 'day', 'hours', 'minutes']}
                        inputFormat="yyyy.MM.dd. HH:mm"
                        mask={'____.__.__. __:__'}
                        ampm={false}
                        value={date}
                        onChange={(newDate) => setDate(newDate)}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </div>
        </SubSection>
    );
};

const subSectionStyles = css`
    width: 100%;
`;

const containerStyles = css`
    display: flex;
    gap: 20px;
`;

export default OrderDetails;
