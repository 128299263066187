import { gql } from '@apollo/client';
import { mutationFactory } from '../../../../utils/gqlFactories';

const updateStuckOrderListContact = gql`
    mutation updateStuckOrderListContact($id: Int!, $data: StuckOrderListContactInputUpdate!) {
        updateStuckOrderListContact(id: $id, data: $data) {
            id
        }
    }
`;

const useUpdateStuckOrderListContact = mutationFactory<{ id: number }>(updateStuckOrderListContact, 'updateStuckOrderListContact');

export default useUpdateStuckOrderListContact;
