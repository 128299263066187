/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import { Section } from '../../components/Section';
import { Button } from '@mui/material';
import useErrorHandler from '../../utils/userErrorHandler';
import useUsersQuery from './gql/users';

const AccountsPage: React.FC = () => {
    const users = useUsersQuery();
    useErrorHandler(users.error);

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title="Felhasználói fiókok">
                <TableContainer>
                    <Table aria-label="table" style={{ minWidth: 1310 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">ID</TableCell>
                                <TableCell align="center">Felhasználónév</TableCell>
                                <TableCell align="center">Név</TableCell>
                                <TableCell align="center">E-mail</TableCell>
                                <TableCell align="center">Jogosultság</TableCell>
                                <TableCell align="center">Fiók állapota</TableCell>
                                <TableCell align="center">Műveletek</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.data &&
                                users.data.map((user) => (
                                    <TableRow css={trStyle} key={user.id}>
                                        <TableCell align="center">{user.id}</TableCell>
                                        <TableCell align="center">{user.username}</TableCell>
                                        <TableCell align="center">{user.lastname + ' ' + user.firstname + ' ' + (user.middlename === null ? '' : user.middlename)}</TableCell>
                                        <TableCell align="center">{user.email}</TableCell>
                                        <TableCell align="center">{user.permission.name}</TableCell>
                                        <TableCell align="center">{user.active ? 'Aktív' : 'Inaktív'}</TableCell>
                                        <TableCell align="center" css={actionsColStyle}>
                                            <Link to={`/settings/accounts/${user.id}/edit`} style={{ textDecoration: 'none' }}>
                                                <Button variant="contained" color="primary">
                                                    Szerkesztés
                                                </Button>
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Link to="/settings/accounts/create" css={buttonContainerStyle}>
                    <Button variant="contained" color="success">
                        Új hozzáadása
                    </Button>
                </Link>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const trStyle = css`
    &: nth-of-type(odd) {
        background-color: #e3f2fd;
    }

    &:last-child td,
    &:last-child th {
        border: 0;
    }
`;

const actionsColStyle = css`
    & > * + * {
        margin-left: 20px !important;
    }
`;

const buttonContainerStyle = css`
    text-decoration: none;
    margin-left: auto;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
`;

export default AccountsPage;
