import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const campaignsQuery = gql`
    query campaigns($from: LocaleDate, $to: LocaleDate) {
        campaigns(from: $from, to: $to) {
            id
            name
            start
            end
        }
    }
`;

interface Campaign {
    id: number;
    name: string;
}

const useCampaignsQuery = queryFactory<Campaign[]>(campaignsQuery, 'campaigns');

export type { Campaign };
export default useCampaignsQuery;
