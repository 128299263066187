import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const emailTemplatesQuery = gql`
    query {
        emailTemplates {
            id
            name
            subject
            sender {
                displayed_name
            }
            reply_to {
                displayed_name
            }
            last_modified
        }
    }
`;

interface EmailTemplate {
    id: number;
    name: string;
    subject: string;
    sender: {
        displayed_name: string;
    };
    reply_to: {
        displayed_name: string;
    };
    last_modified: string;
}

const useEmailTemplatesQuery = queryFactory<EmailTemplate[]>(emailTemplatesQuery, 'emailTemplates');

export default useEmailTemplatesQuery;
