/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Section } from '../../components/Section';
import { Paper, TextField, Button, Table, TableBody, TableRow, TableCell, TableContainer, TableHead, Typography, FormControlLabel, Checkbox } from '@mui/material';
import useErrorHandler from '../../utils/userErrorHandler';
import NumberFormat from 'react-number-format';
import { SubSubSection } from '../../components/SubSubSection';
import { CustomDateRangePicker } from '../../components/CustomDateRangePicker';
import { v4 } from 'uuid';
import useFreebieQuery from './gql/freebie';
import useFreebieSubscribersByEmailQuery from './gql/freebieSubscribersByEmail';

const FreeContentDetailsPage: React.FC = () => {
    const params = useParams();
    const id = params.id as string;

    const freeContentResp = useFreebieQuery({ id: parseInt(id) });
    const [subscribers, subscribersResp] = useFreebieSubscribersByEmailQuery();

    useErrorHandler(freeContentResp.error, subscribersResp.error);

    const [email, setEmail] = useState('');
    const [checked, setChecked] = useState(true);
    const [date, setDate] = useState<
        {
            startDate: Date;
            endDate: Date;
            key: string;
        }[]
    >([
        {
            startDate: new Date(new Date().getFullYear(), 0, 1, 2, 0, 0, 0),
            endDate: new Date(new Date().getFullYear(), 11, 31, 2, 0, 0, 0),
            key: 'selection',
        },
    ]);

    const handleDateChange = (item: { selection: { startDate: Date; endDate: Date; key: string } }) => {
        setDate([item.selection]);
    };

    const handleDataFetch = async () => {
        if (checked) {
            await subscribers({ freebie: parseInt(id), email, from: date[0].startDate, to: date[0].endDate });
        } else {
            await subscribers({ freebie: parseInt(id), email, from: undefined, to: undefined });
        }
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`#${id}: Ingyenes anyag részletei`}>
                <div css={sectionStyles}>
                    <SubSubSection title="Részletek" style={{ padding: '0' }}>
                        {freeContentResp.data && (
                            <Table aria-label="table">
                                <TableBody>
                                    <TableRow css={trStyle}>
                                        <TableCell>ID</TableCell>
                                        <TableCell>#{freeContentResp.data.id}</TableCell>
                                    </TableRow>
                                    <TableRow css={trStyle}>
                                        <TableCell>Név</TableCell>
                                        <TableCell>{freeContentResp.data.name}</TableCell>
                                    </TableRow>
                                    <TableRow css={trStyle}>
                                        <TableCell>Leírás</TableCell>
                                        <TableCell>{freeContentResp.data.description ? freeContentResp.data.description : ''}</TableCell>
                                    </TableRow>
                                    <TableRow css={trStyle}>
                                        <TableCell>Webinár azonosító</TableCell>
                                        <TableCell>{freeContentResp.data.webinar_id ? freeContentResp.data.webinar_id : '-'}</TableCell>
                                    </TableRow>
                                    <TableRow css={trStyle}>
                                        <TableCell>Feliratkozók száma</TableCell>
                                        <TableCell>
                                            <NumberFormat value={freeContentResp.data.subscribers} thousandSeparator={' '} displayType="text" />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow css={trStyle}>
                                        <TableCell>Létrehozás dátuma</TableCell>
                                        <TableCell>{freeContentResp.data.created_at}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        )}
                    </SubSubSection>

                    <SubSubSection title={`${freeContentResp.data?.name} feliratkozók`}>
                        <div css={subSectionStyles}>
                            <CustomDateRangePicker ranges={date} onChange={handleDateChange} />
                            <div css={someInputContainerStyles}>
                                <div css={{ minWidth: 226 }} />
                                <TextField label="Szűrés email címre" placeholder="Szűrés email címre" variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} sx={{ minWidth: 352 }} />
                                <div css={buttonConatinerStyle}>
                                    <FormControlLabel control={<Checkbox checked={checked} onChange={() => setChecked(!checked)} />} label={'Dátum szűrővel'} />
                                    <Button size="large" variant="contained" onClick={handleDataFetch}>
                                        Lekérés
                                    </Button>
                                </div>
                            </div>

                            {subscribersResp.data?.length !== undefined && subscribersResp.data?.length !== 0 ? (
                                <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">ID</TableCell>
                                                <TableCell align="center">Név</TableCell>
                                                <TableCell align="center">Email</TableCell>
                                                <TableCell align="center">Telefon</TableCell>
                                                <TableCell align="center">Feliratkozás dátuma</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {subscribersResp.data.map(
                                                (row) =>
                                                    row && (
                                                        <TableRow key={v4()} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell align="center">{row.id}</TableCell>
                                                            <TableCell align="center">{row.contact.lastname + ' ' + row.contact.firstname}</TableCell>
                                                            <TableCell align="center">{row.contact.customer.email}</TableCell>
                                                            <TableCell align="center">{row.contact.phone ? row.contact.phone : ''}</TableCell>
                                                            <TableCell align="center">{row.created_at}</TableCell>
                                                        </TableRow>
                                                    ),
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : subscribersResp.data?.length === 0 ? (
                                <Typography variant="body1" color="primary" align="center">
                                    Nincs megjeleníthető adat a megadott feltételek alapján!
                                </Typography>
                            ) : null}
                        </div>
                    </SubSubSection>
                </div>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    padding: 20px;

    & > * + * {
        margin-top: 20px !important;
    }
`;

const subSectionStyles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 20px;
`;

const someInputContainerStyles = css`
    width: 931px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1000px) {
        flex-direction: column;
        & > * + * {
            margin-top: 20px !important;
        }
    }
`;

const buttonConatinerStyle = css`
    min-width: 352px;
    display: flex;
    justify-content: center;
`;

const trStyle = css`
    &: nth-of-type(odd) {
        background-color: #e3f2fd;
    }

    &:last-child td,
    &:last-child th {
        border: 0;
    }
`;

export default FreeContentDetailsPage;
