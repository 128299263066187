import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const customer = gql`
    query customer($id: Int!) {
        customer(id: $id) {
            id
            email
            details {
                firstname
                lastname
                phone
                billing_name
                billing_address
                tax_number
                created_at
            }
            characteristics {
                id
                characteristic_option {
                    id
                    name
                }
                assigner {
                    firstname
                    lastname
                }
                created_at
            }
            blocked {
                id
                comment
                reason {
                    id
                    name
                }
                blocker {
                    firstname
                    lastname
                }
                created_at
            }
        }
    }
`;

export interface Customer {
    id: number;
    email: string;
    details: {
        firstname: string;
        lastname: string;
        phone: string;
        billing_name: string;
        billing_address: string;
        tax_number: string;
        created_at: string;
    }[];
    characteristics: {
        id: number;
        characteristic_option: {
            id: number;
            name: string;
        };
        assigner: {
            firstname: string;
            lastname: string;
        };
        created_at: string;
    }[];
    blocked: {
        id: number;
        comment: string;
        reason: {
            id: number;
            name: string;
        };
        blocker: {
            firstname: string;
            lastname: string;
        };
        created_at: string;
    };
}

const useCustomer = queryFactory<Customer>(customer, 'customer');

export default useCustomer;
