/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Paper, Button } from '@mui/material';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import DataGridLocalization from '../../utils/DataGridLocalization';
import { Section } from '../../components/Section';
import useDeliveries, { Delivery } from './gql/deliveries';
import useErrorHandler from '../../utils/userErrorHandler';
import { useNavigate } from 'react-router-dom';

const ShippingListPage: React.FC = () => {
    const navigate = useNavigate();
    const deliveries = useDeliveries();
    useErrorHandler(deliveries.error);

    const makeRows = (deliveries: Delivery[]) => {
        const rows: GridRowsProp = deliveries.map((delivery) => ({
            id: delivery.id,
            deliveryID: delivery.id,
            customerName: `${delivery.ordered_products[0].order.customer.lastname} ${delivery.ordered_products[0].order.customer.firstname}`,
            products: delivery.ordered_products.map((product) => product.product.name).join(', '),
            name: delivery.name,
            phone: delivery.phone,
            address: `${delivery.zip}, ${delivery.city} ${delivery.address}`,
            deliveryFee: `${delivery.installment === null ? 0 : delivery.installment.gross} Ft`,
            date: delivery.date,
            comment: delivery.comment,
        }));

        return rows;
    };

    const makeCols = () => {
        const columns: GridColDef[] = [
            { field: 'deliveryID', headerName: 'ID', width: 100 },
            { field: 'customerName', headerName: 'Megrendelő neve', width: 150 },
            { field: 'products', headerName: 'Termékek', width: 300 },
            { field: 'name', headerName: 'Címzett neve', width: 150 },
            { field: 'phone', headerName: 'Címzett telefon', width: 150 },
            { field: 'address', headerName: 'Szállítási cím', width: 250 },
            { field: 'deliveryFee', headerName: 'Szállítási költség', width: 150 },
            { field: 'date', headerName: 'Tervezett szállítás', width: 150 },
            { field: 'comment', headerName: 'Futárnak megjegyzés', width: 200 },
        ];

        return columns;
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title="Szállítások">
                <div style={{ height: 600, width: '100%' }}>
                    <DataGrid
                        componentsProps={{
                            pagination: {
                                labelRowsPerPage: 'Oldalanként',
                                labelDisplayedRows: ({ from, to, count }: { from: number; to: number; count: number }) => `${from}–${to} / ${count !== -1 ? count : `more than ${to}`}`,
                            },
                        }}
                        localeText={DataGridLocalization}
                        rows={makeRows(deliveries.data || [])}
                        columns={makeCols()}
                        style={{ borderRadius: 0, border: 'none' }}
                    />
                </div>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

export default ShippingListPage;
