/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect } from 'react';

import { Paper, Button } from '@mui/material';

import { NewOrderProvider } from '../../contexts/NewOrderContext';
import { Section } from '../../components/Section';
import { OrderDetails } from './OrderDetails';
import { CustomerData } from './CustomerData';
import { Products } from './Products';
import { Addresses } from './Addresses';
import { ListedProduct } from './Products/Products';
import useCreateOrderMutation from './gql/createOrder';
import useErrorHandler from '../../utils/userErrorHandler';
import { useSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';

type NewOrderArguments =
    | { type: 'orderDetails'; seller: number | null; created_at: Date | null }
    | {
          type: 'customerData';
          id?: number;
          email?: string;
          details: {
              firstname: string;
              lastname: string;
              phone: string;
          };
      }
    | {
          type: 'addresses';
          billingDatas?: {
              billing_country: string;
              billing_zip: string;
              billing_city: string;
              billing_address: string;
              billing_name: string;
              taxNumber?: string;
          };
          shippingDatas?: {
              type: number;
              date: Date | null;
              name: string;
              phone: string;
              country: string;
              city: string;
              zip: string;
              address: string;
              comment: string;
          };
      }
    | { type: 'products'; products: ListedProduct[] };

type Installment = {
    gross: number;
    due_date: string | null;
    payment_method: number;
    currency?: number; // currency id
    currency_rate?: number; // átváltás mértéke float
    currency_gross?: number; // átváltva bruttóban
};

type Product = {
    id: number;
    priceId?: number;
    newPrice?: { gross: number; net: number };
    installments: Installment[];
};

type Customer = {
    id?: number; // csak  a rendszerben már létező vásárlónak van
    email?: string; // új vásárló esetén email kell
    details: {
        firstname: string;
        lastname: string;
        phone: string;
        billing_country: string;
        billing_zip: string;
        billing_city: string;
        billing_address: string;
        billing_name: string;
        taxNumber?: string;
    };
};

type Delivery = {
    type: number; // ez jelöli a cím típusát (saját, iroda, szomszéd, a yachtom, stb), erre lesz query
    method: number; // majd egyszer ha implementálva lesz több mint gls addig 1
    date: Date | null;
    name: string;
    phone: string;
    country: string;
    city: string;
    zip: string;
    address: string;
    comment: string;
};

type NewOrder = {
    list?: number;
    seller: number | null;
    created_at: Date | null;
    products: Product[];
    customer: Customer;
    delivery: Delivery;
};

type InstallmentInput = {
    id: string;
    gross: string;
    due_date: string | null;
    payment_method: {
        id: number;
        name: string;
    };
    currency?: number; // currency id
    currency_rate?: number; // átváltás mértéke float
    currency_gross?: number; // átváltva bruttóban
};

type ProductInput = {
    renderId?: string;
    id: number;
    name: string;
    priceType: string;
    priceId?: number;
    net?: number;
    gross: number;
    installments: InstallmentInput[];
};

const NewOrderPage: React.FC = () => {
    const [searchParams, _setSearchParams] = useSearchParams();
    const listId = searchParams.get('list');
    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const [createOrder, createOrderResp] = useCreateOrderMutation();

    useErrorHandler(createOrderResp.error);

    const newOrderDatas: NewOrder = {
        seller: 0,
        created_at: null,
        products: [],
        customer: {
            id: 7, // ha nem létezik, akkor el kell hagyni
            //email: "lajos@gmail.com" // ha nem létezik, akkor ezt kell megadni, ami elhagyandó
            details: {
                firstname: '',
                lastname: '',
                phone: '',
                billing_country: '',
                billing_zip: '',
                billing_city: '',
                billing_address: '',
                billing_name: '',
                taxNumber: '',
            },
        },
        delivery: {
            type: 1, // ez jelöli a cím típusát (saját, iroda, szomszéd, a yachtom, stb), erre lesz query (majd máskor)
            method: 1,
            date: null,
            name: '',
            phone: '',
            country: '',
            city: '',
            zip: '',
            address: '',
            comment: '',
        },
    };

    if (listId) {
        newOrderDatas.list = parseInt(listId);
    }

    useEffect(() => {
        if (createOrderResp.data) {
            navigate(`/orders/${createOrderResp.data.id}`);
        }
    }, [createOrderResp.data]);

    const getDataFromSections = (data: NewOrderArguments) => {
        switch (data.type) {
            case 'orderDetails':
                newOrderDatas.seller = data.seller;
                newOrderDatas.created_at = data.created_at;

                break;

            case 'customerData':
                newOrderDatas.customer.details.firstname = data.details.firstname;
                newOrderDatas.customer.details.lastname = data.details.lastname;
                newOrderDatas.customer.details.phone = data.details.phone;
                if (data.id) {
                    newOrderDatas.customer.id = data.id;
                    delete newOrderDatas.customer.email;
                }
                if (data.email) {
                    newOrderDatas.customer.email = data.email;
                    delete newOrderDatas.customer.id;
                }

                break;

            case 'addresses':
                if (data.billingDatas) {
                    newOrderDatas.customer.details.billing_address = data.billingDatas.billing_address;
                    newOrderDatas.customer.details.billing_city = data.billingDatas.billing_city;
                    newOrderDatas.customer.details.billing_country = data.billingDatas.billing_country;
                    newOrderDatas.customer.details.billing_name = data.billingDatas.billing_name;
                    newOrderDatas.customer.details.billing_zip = data.billingDatas.billing_zip;

                    if (data.billingDatas.taxNumber) {
                        newOrderDatas.customer.details.taxNumber = data.billingDatas.taxNumber;
                    } else {
                        delete newOrderDatas.customer.details.taxNumber;
                    }
                }
                if (data.shippingDatas) {
                    newOrderDatas.delivery.address = data.shippingDatas.address;
                    newOrderDatas.delivery.city = data.shippingDatas.city;
                    newOrderDatas.delivery.comment = data.shippingDatas.comment;
                    newOrderDatas.delivery.country = data.shippingDatas.country;
                    newOrderDatas.delivery.name = data.shippingDatas.name;
                    newOrderDatas.delivery.phone = data.shippingDatas.phone;
                    newOrderDatas.delivery.type = data.shippingDatas.type;
                    newOrderDatas.delivery.zip = data.shippingDatas.zip;
                    newOrderDatas.delivery.date = data.shippingDatas.date;
                }

                break;

            case 'products':
                const products: ProductInput[] = JSON.parse(JSON.stringify(data.products));
                const convertedProducts: Product[] = [];

                for (const product of products) {
                    const tempProduct: Product = { id: 0, installments: [] };

                    tempProduct.id = product.id;

                    if (product.net) {
                        tempProduct.newPrice = { gross: product.gross, net: product.net };
                    } else {
                        tempProduct.priceId = product.id;
                    }

                    const tempInstallments: Installment[] = [];

                    for (const installment of product.installments) {
                        const tempInstallment: Installment = { gross: 0, due_date: null, payment_method: 0 };
                        tempInstallment.due_date = installment.due_date;
                        tempInstallment.payment_method = installment.payment_method.id;
                        tempInstallment.currency = installment.currency;
                        if (installment.gross) tempInstallment.gross = parseInt(installment.gross);
                        if (installment.currency_gross && installment.currency_rate) {
                            tempInstallment.currency_gross = installment.currency_gross;
                            tempInstallment.currency_rate = installment.currency_rate;
                        }
                        tempInstallments.push(tempInstallment);
                    }

                    tempProduct.installments = tempInstallments;

                    convertedProducts.push(tempProduct);
                }

                newOrderDatas.products = convertedProducts;

                break;
        }
    };

    const areProductInstallmentsValid = (): [boolean, string] => {
        const invalidDate = newOrderDatas.products.find((product) => {
            return product.installments.find((installment) => {
                return installment.due_date === null;
            });
        });
        const invalidGross = newOrderDatas.products.find((product) => {
            return product.installments.find((installment) => {
                return installment.gross === 0;
            });
        });

        if (invalidDate === undefined && invalidGross === undefined) {
            return [true, ''];
        } else if (invalidDate !== undefined && invalidGross === undefined) {
            return [false, 'Fizetési részletek, fizetési határidejének kitöltése kötelező!'];
        } else if (invalidDate === undefined && invalidGross !== undefined) {
            return [false, 'Fizetési részletek, bruttó árának kitöltése kötelező és nem lehet 0!'];
        } else {
            return [false, 'Fizetési részletek, fizetési határidejének és bruttó árának kitöltése kötelező és az ár nem lehet 0!'];
        }
    };

    const areProductsWithInstallmentValid = (): boolean => {
        const invalidProduct = newOrderDatas.products.find((product) => {
            return product.installments.length === 0;
        });

        if (invalidProduct === undefined) {
            return true;
        } else {
            return false;
        }
    };

    const handleOrderCreate = async () => {
        if (newOrderDatas.created_at && newOrderDatas.seller) {
            if ((newOrderDatas.customer.email || newOrderDatas.customer.id) && newOrderDatas.customer.details.lastname && newOrderDatas.customer.details.firstname && newOrderDatas.customer.details.phone) {
                if (
                    newOrderDatas.delivery.address &&
                    newOrderDatas.delivery.city &&
                    newOrderDatas.delivery.country &&
                    newOrderDatas.delivery.name &&
                    newOrderDatas.delivery.phone &&
                    newOrderDatas.delivery.type &&
                    newOrderDatas.delivery.zip &&
                    newOrderDatas.delivery.date
                ) {
                    if (
                        newOrderDatas.customer.details.billing_address &&
                        newOrderDatas.customer.details.billing_city &&
                        newOrderDatas.customer.details.billing_country &&
                        newOrderDatas.customer.details.billing_name &&
                        newOrderDatas.customer.details.billing_zip
                    ) {
                        if (newOrderDatas.products.length > 0) {
                            if (areProductsWithInstallmentValid()) {
                                const isValid = areProductInstallmentsValid();
                                if (isValid[0]) {
                                    console.log('creating order with these datas: ');
                                    console.log(newOrderDatas);
                                    createOrder({ data: newOrderDatas });
                                } else {
                                    enqueueSnackbar(isValid[1], { variant: 'error' });
                                }
                            } else {
                                enqueueSnackbar('Minden termékre legalább egy fizetési részlet megadása  kötelező!', { variant: 'error' });
                            }
                        } else {
                            enqueueSnackbar('Termékek megadása  kötelező!', { variant: 'error' });
                        }
                    } else {
                        enqueueSnackbar('Számlázási adatok kitöltése kötelező!', { variant: 'error' });
                    }
                } else {
                    enqueueSnackbar('Szállítási adatok kitöltése kötelező!', { variant: 'error' });
                }
            } else {
                enqueueSnackbar('Vásárlói adatok kitöltése kötelező!', { variant: 'error' });
            }
        } else {
            enqueueSnackbar('Rendelési adatok kitöltése kötelező!', { variant: 'error' });
        }
    };

    return (
        <Paper css={container} elevation={2}>
            <Section title="Új rendelés felvétele">
                <div css={sectionStyles}>
                    <NewOrderProvider>
                        <OrderDetails sendData={getDataFromSections} />
                        <CustomerData sendData={getDataFromSections} />
                        <Addresses sendData={getDataFromSections} />
                        <Products sendData={getDataFromSections} />
                    </NewOrderProvider>

                    <Button variant="contained" color="success" onClick={() => handleOrderCreate()}>
                        Rendelés létrehozása
                    </Button>
                </div>
            </Section>
        </Paper>
    );
};

const container = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
`;

export type { NewOrderArguments, Product };
export default NewOrderPage;
