import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const addTagToProductMutation = gql`
    mutation addTagToProduct($product: Int!, $tag: Int!) {
        addTagToProduct(product: $product, tag: $tag) {
            id
        }
    }
`;

interface Tag {
    id: number;
}

const useAddTagToProductMutation = mutationFactory<Tag>(addTagToProductMutation, 'addTagToProduct');

export default useAddTagToProductMutation;
