import { gql } from '@apollo/client';
import { mutationFactory } from '../../../../utils/gqlFactories';

const updateLatePaymentListContact = gql`
    mutation updateLatePaymentListContact($id: Int!, $data: LatePaymentListContactInputUpdate!) {
        updateLatePaymentListContact(id: $id, data: $data) {
            id
        }
    }
`;

const useUpdateLatePaymentListContact = mutationFactory<{ id: number }>(updateLatePaymentListContact, 'updateLatePaymentListContact');

export default useUpdateLatePaymentListContact;
