import { gql } from '@apollo/client';
import { mutationFactory } from '../../../../utils/gqlFactories';

const updateListContact = gql`
    mutation updateListContact($id: Int!, $data: ListContactInputUpdate!) {
        updateListContact(id: $id, data: $data) {
            id
        }
    }
`;

const useUpdateListContact = mutationFactory<{ id: number }>(updateListContact, 'updateListContact');

export default useUpdateListContact;
