const months = [
    {
        number: 1,
        name: 'Január',
    },
    {
        number: 2,
        name: 'Február',
    },
    {
        number: 3,
        name: 'Március',
    },
    {
        number: 4,
        name: 'Április',
    },
    {
        number: 5,
        name: 'Május',
    },
    {
        number: 6,
        name: 'Június',
    },
    {
        number: 7,
        name: 'Július',
    },
    {
        number: 8,
        name: 'Augusztus',
    },
    {
        number: 9,
        name: 'Szeptember',
    },
    {
        number: 10,
        name: 'Október',
    },
    {
        number: 11,
        name: 'November',
    },
    {
        number: 12,
        name: 'December',
    },
];

export default months;
