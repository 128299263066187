import { gql } from '@apollo/client';
import { lazyQueryFactory } from '../../../utils/gqlFactories';

const customersQuery = gql`
    query customers($email: String) {
        customers(email: $email) {
            details {
                id
                customer {
                    id
                    email
                }
                firstname
                lastname
                phone
                created_at
            }
        }
    }
`;

interface Customer {
    details: CustomerDetails[];
}
interface CustomerDetails {
    id: number;
    customer: { id: number; email: string };
    firstname: string;
    lastname: string;
    phone: string;
    created_at: string;
}

const useCustomersLazyQuery = lazyQueryFactory<Customer[]>(customersQuery, 'customers');

export type { Customer, CustomerDetails };
export default useCustomersLazyQuery;
