import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const talkStates = gql`
    query talkStates {
        talkStates {
            id
            name
            description
        }
    }
`;

interface TalkState {
    id: number;
    name: string;
    description: string;
}

const useTalkStates = queryFactory<TalkState[]>(talkStates, 'talkStates');

export default useTalkStates;
