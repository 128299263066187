import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const updateProductCategoryMutation = gql`
    mutation updateProductCategory($id: Int!, $data: ProductCategoryInputUpdate!) {
        updateProductCategory(id: $id, data: $data) {
            id
        }
    }
`;

interface ProductCategory {
    id: number;
}

const useUpdateProductCategoryMutation = mutationFactory<ProductCategory>(updateProductCategoryMutation, 'updateProductCategory');

export default useUpdateProductCategoryMutation;
