import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const deleteExpectedOrderMutation = gql`
    mutation deleteExpectedOrder($id: Int!) {
        deleteExpectedOrder(id: $id)
    }
`;

const useDeleteExpectedOrderMutation = mutationFactory(deleteExpectedOrderMutation, 'deleteExpectedOrder');

export default useDeleteExpectedOrderMutation;
