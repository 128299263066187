/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Paper, Button, Dialog, DialogTitle, TextField, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import DataGridLocalization from '../../utils/DataGridLocalization';
import { Section } from '../../components/Section';
import { useSnackbar } from 'notistack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import hu from 'date-fns/locale/hu';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import useDayClosings, { DayClosing } from './gql/dayClosings';
import useErrorHandler from '../../utils/userErrorHandler';
import useUpdateDayClosing from './gql/updateDayClosing';
import useCreateDayClosing from './gql/createDayClosing';

const CharacteristicsPage: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const dayClosings = useDayClosings();
    const [createDayClosing, createdDayClosing] = useCreateDayClosing();
    const [updateDayClosing, updatedDayClosing] = useUpdateDayClosing();
    const [clickedClosing, setClickedClosing] = useState<number>(0);
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [time, setTime] = useState<Date>(new Date());
    useErrorHandler(dayClosings.error, createdDayClosing.error, updatedDayClosing.error);

    useEffect(() => {
        if (updatedDayClosing.data) {
            setIsEditDialogOpen(false);
            setIsCreateDialogOpen(false);
            enqueueSnackbar('A lezárás sikeresen frissítve!', { variant: 'success' });
            dayClosings.refetch();
        }
    }, [updatedDayClosing.data]);

    const handleActionClick = (action: 'create' | 'edit', id?: number, day?: string, time?: string) => {
        if (id) setClickedClosing(id);
        if (time && day) setTime(new Date(`${day} ${time}`));

        if (action === 'create') setIsCreateDialogOpen(true);
        if (action === 'edit') setIsEditDialogOpen(true);
    };

    const editClosing = () => {
        if (clickedClosing !== 0) updateDayClosing({ id: clickedClosing, data: { time: time.toISOString().split('T')[1] } });
    };

    const createClosing = () => {
        createDayClosing({ data: { time: time.toISOString().split('T')[1] } });
    };

    const handleTimeChange = (newDate: Date | null) => {
        if (newDate) setTime(newDate);
    };

    const makeRows = (closings: DayClosing[]) => {
        const rows: GridRowsProp = closings.map((closing) => ({
            id: closing.id,
            closingID: closing.id,
            day: closing.day,
            time: closing.time,
            closed_by: `${closing.closed_by.lastname} ${closing.closed_by.firstname}`,
        }));

        return rows;
    };

    const makeCols = () => {
        const columns: GridColDef[] = [
            { field: 'closingID', headerName: 'ID', width: 100 },
            { field: 'day', headerName: 'Nap', width: 400 },
            { field: 'time', headerName: 'Idő', width: 400 },
            { field: 'closed_by', headerName: 'Lezárta', width: 400 },
            {
                field: 'actions',
                headerName: 'Műveletek',
                width: 150,
                renderCell: function renderActions(cellValues) {
                    return (
                        <div css={buttonsContainerStyles}>
                            <Button variant="contained" size="small" color="primary" onClick={() => handleActionClick('edit', cellValues.row.id, cellValues.row.day, cellValues.row.time)} style={{ minWidth: 32 }}>
                                <EditIcon />
                            </Button>
                        </div>
                    );
                },
            },
        ];

        return columns;
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title="Lezárások">
                <div style={{ height: 600, width: '100%' }}>
                    <DataGrid
                        componentsProps={{
                            pagination: {
                                labelRowsPerPage: 'Oldalanként',
                                labelDisplayedRows: ({ from, to, count }: { from: number; to: number; count: number }) => `${from}–${to} / ${count !== -1 ? count : `more than ${to}`}`,
                            },
                        }}
                        localeText={DataGridLocalization}
                        rows={makeRows(dayClosings.data || [])}
                        columns={makeCols()}
                        style={{ borderRadius: 0, border: 'none' }}
                    />
                </div>
                <div style={{ marginLeft: 20, marginBottom: 20 }}>
                    <Button variant="contained" color="success" onClick={() => handleActionClick('create')}>
                        Új lezárás létrehozása
                    </Button>
                </div>

                <Dialog onClose={() => setIsEditDialogOpen(false)} open={isEditDialogOpen}>
                    <DialogTitle style={{ backgroundColor: '#0288d1', color: 'white' }}>Lezárás szerkesztése</DialogTitle>
                    <div css={dialogContentContainerStyles}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={hu}>
                            <TimePicker label="Idő" value={time} onChange={handleTimeChange} renderInput={(params) => <TextField {...params} />} />
                        </LocalizationProvider>
                        <div css={dialogButtonsContainerStyle}>
                            <Button variant="contained" color="error" size="large" onClick={() => setIsEditDialogOpen(false)}>
                                Mégse
                            </Button>
                            <Button variant="contained" color="success" size="large" onClick={editClosing}>
                                Mentés
                            </Button>
                        </div>
                    </div>
                </Dialog>

                <Dialog onClose={() => setIsCreateDialogOpen(false)} open={isCreateDialogOpen}>
                    <DialogTitle style={{ backgroundColor: '#0288d1', color: 'white' }}>Lezárás létrehozása</DialogTitle>
                    <div css={dialogContentContainerStyles}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={hu}>
                            <TimePicker label="Idő" value={time} onChange={handleTimeChange} renderInput={(params) => <TextField {...params} />} />
                        </LocalizationProvider>
                        <div css={dialogButtonsContainerStyle}>
                            <Button variant="contained" color="error" size="large" onClick={() => setIsCreateDialogOpen(false)}>
                                Mégse
                            </Button>
                            <Button variant="contained" color="success" size="large" onClick={createClosing}>
                                Mentés
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const buttonsContainerStyles = css`
    & > * + * {
        margin-left: 10px !important;
    }
`;

const dialogContentContainerStyles = css`
    padding: 24px;

    & > * + * {
        margin-top: 25px !important;
    }
`;

const dialogButtonsContainerStyle = css`
    display: flex;
    justify-content: center;
    margin-top: 30px;

    & > * + * {
        margin-left: 20px !important;
    }
`;

export default CharacteristicsPage;
