import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const CreateEmailTemplateMutation = gql`
    mutation CreateEmailTemplate($placeholders: [Int]!, $data: EmailTemplateInputCreate!) {
        createEmailTemplate(placeholders: $placeholders, data: $data) {
            id
        }
    }
`;

interface UpdatedTempalte {
    id: number;
}

const useCreateEmailTemplateMutation = mutationFactory<UpdatedTempalte>(CreateEmailTemplateMutation, 'createEmailTemplate');

export default useCreateEmailTemplateMutation;
