import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const productPricesQuery = gql`
    query productPrices($product: Int!) {
        productPrices(product: $product) {
            id
            net
            gross
            type {
                id
                name
            }
        }
    }
`;

interface ProductPrice {
    id: number;
    net: number;
    gross: number;
    type: {
        id: number;
        name: string;
    };
}

const useProductPricesQuery = queryFactory<ProductPrice[]>(productPricesQuery, 'productPrices');

export type { ProductPrice };
export default useProductPricesQuery;
