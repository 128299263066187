import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const removeEmailTemplateFromEvent = gql`
    mutation removeEmailTemplateFromEvent($template: Int!, $event: Int!) {
        removeEmailTemplateFromEvent(template: $template, event: $event) {
            id
        }
    }
`;

interface EmailAccount {
    id: number;
}

const useRemoveEmailTemplateFromEvent = mutationFactory<EmailAccount>(removeEmailTemplateFromEvent, 'removeEmailTemplateFromEvent');

export default useRemoveEmailTemplateFromEvent;
