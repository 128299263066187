import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const createEmailAccount = gql`
    mutation createEmailAccount($usersWithPermission: [Int], $data: EmailAccountInputCreate!) {
        createEmailAccount(usersWithPermission: $usersWithPermission, data: $data) {
            id
        }
    }
`;

interface EmailAccount {
    id: number;
}

const useCreateEmailAccount = mutationFactory<EmailAccount>(createEmailAccount, 'createEmailAccount');

export default useCreateEmailAccount;
