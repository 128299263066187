/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Section } from '../../components/Section';
import { Paper, TextField, Button } from '@mui/material';
import useErrorHandler from '../../utils/userErrorHandler';
import useCreateFreebieMutation from './gql/createFreebie';

const FreeContentCreatePage: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [createFreeContent, createdFreeContent] = useCreateFreebieMutation();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [webinar_id, setWebinar_id] = useState('');

    useErrorHandler(createdFreeContent.error);

    useEffect(() => {
        if (createdFreeContent.data) {
            navigate('/marketing/free-content');
        }
    }, [createdFreeContent.data]);

    const handleNumberChange = (e: string) => {
        let input = e;

        if (e.match(/\D/)) input = input.slice(0, -1);
        if (input.length < 21) setWebinar_id(input);
    };

    const handleCreate = () => {
        if (name !== '') {
            createFreeContent({ data: { name, description, webinar_id: webinar_id ? parseInt(webinar_id) : undefined } });
        } else {
            enqueueSnackbar('A csillaggal jelölt mezők kitöltése kötelező!', { variant: 'error' });
        }
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`Új ingyenes anyag létrehozása`}>
                <div css={sectionStyles}>
                    <TextField label="Név" placeholder="Az ingyenes anyag neve" variant="outlined" value={name} onChange={(e) => setName(e.target.value)} required />
                    <TextField label="Leírás" placeholder="Az ingyenes anyag leírása" variant="outlined" value={description} onChange={(e) => setDescription(e.target.value)} multiline rows={5} />
                    <TextField label="Webinár azonosító" placeholder="Az ingyenes anyag Webinár azonosítója" variant="outlined" value={webinar_id} onChange={(e) => handleNumberChange(e.target.value)} />

                    <div style={{ marginLeft: 'auto' }}>
                        <Button variant="contained" color="success" size="large" onClick={handleCreate} style={{ marginLeft: 'auto' }}>
                            Létrehozás
                        </Button>
                    </div>
                </div>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;

    & > * + * {
        margin-top: 20px !important;
    }
`;

export default FreeContentCreatePage;
