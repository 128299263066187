import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const emailAccount = gql`
    query emailAccount($id: Int!) {
        emailAccount(id: $id) {
            id
            host
            port
            secure
            username
            password
            displayed_name
            permissions {
                id
            }
        }
    }
`;

type User = {
    id: number;
};

interface EmailAccount {
    id: number;
    host: string;
    port: number;
    secure: boolean;
    username: string;
    password: string;
    displayed_name: string;
    permissions: User[];
}

const useEmailAccounts = queryFactory<EmailAccount>(emailAccount, 'emailAccount');

export default useEmailAccounts;
