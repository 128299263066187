/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Paper, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { v4 } from 'uuid';
import { Section } from '../../components/Section';
import huLocale from 'date-fns/locale/hu';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useUsers from '../WorkingTimeListPage/gql/users';
import useErrorHandler from '../../utils/userErrorHandler';
import useMeQuery from '../AbsencesPage/gql/me';
import { useSnackbar } from 'notistack';
import useCreateWorkingHourMutation from './gql/createWorkingHour';
import { useNavigate } from 'react-router-dom';
import { addDays } from 'date-fns';
import usePublicHolidaysQuery from '../HolidaysPage/gql/publicHolidays';
import useWorkingDayModsQuery from '../AlteredWorkSchedulePage/gql/workingDayMods';
import useOffDaysQuery from './gql/offDays';

const createSelectItems = (): { hour: string; value: number }[] => {
    const times = [];
    for (let i = 8; i >= 3; i = i - 0.5) {
        times.push({ hour: `${i} óra`, value: i });
    }
    return times;
};

const getCurrentYearDate = (month: number, day: number): string => {
    return new Date().getFullYear() + '-' + (month > 9 ? month.toString() : '0' + month) + '-' + (day > 9 ? day.toString() : '0' + day);
};

const WorkingTimeRecordPage: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const me = useMeQuery();
    const users = useUsers();
    const [createWorkingHour, workingHourResp] = useCreateWorkingHourMutation();
    const holidays = usePublicHolidaysQuery();
    const [workingDayMods, workingDayModsResp] = useWorkingDayModsQuery();
    const [getOffDays, offDaysResp] = useOffDaysQuery();
    useErrorHandler(users.error, me.error, workingHourResp.error, holidays.error, workingDayModsResp.error, offDaysResp.error);

    const holidayDates = holidays.data?.map((holiday) => new Date(getCurrentYearDate(holiday.month, holiday.day)).toDateString()); //disabledDays - ünnepek
    const workingDayModDates = workingDayModsResp.data?.map((mod) => {
        return { date: new Date(mod.date), type: mod.type.id };
    });
    const disabledModDates = workingDayModDates?.filter((mod) => mod.type === 1).map((mod) => mod.date.toDateString());
    const enabledModDates = workingDayModDates?.filter((mod) => mod.type === 2).map((mod) => mod.date.toDateString()); //disabledDays - modok
    const absenceRanges = offDaysResp.data?.map((range) => {
        return { start: new Date(range.start), steps: Math.round((new Date(range.end).getTime() - new Date(range.start).getTime()) / 86400000) };
    }); //disabledDays - hiányzások

    const [worker, setWorker] = useState<string>('');
    const [date, setDate] = useState<Date | null>(new Date());
    const [hours, setHours] = useState<string | ''>('8');
    const [month, setMonth] = useState<number>(new Date().getMonth());
    const [year, setYear] = useState<number>(new Date().getFullYear());

    useEffect(() => {
        if (!isAdmin() && me.data) {
            setWorker(me.data.id.toString());
        }
    }, [me.data]);
    // ### calendar disabledDays ###
    useEffect(() => {
        workingDayMods({ year });
    }, [year]);

    useEffect(() => {
        if (worker !== '') getOffDays({ user: parseInt(worker), from: new Date(year, month, 1), to: new Date(year, month + 1, 0) });
    }, [worker, month]);
    // ###
    const isAdmin = () => {
        return me.data?.permission.name.includes('admin');
    };

    const handleCreate = async () => {
        if (worker && date && hours) {
            await createWorkingHour({ data: { user: worker, date, hours: parseFloat(hours) } });
            navigate('/working-hours/list');
        } else {
            enqueueSnackbar('Az összes mező kitöltése kötelező!', { variant: 'error' });
        }
    };
    // ### calendar disabledDays ###
    const handleMonthChange = (date: Date) => {
        setMonth(date.getMonth());
        if (date.getFullYear() !== year) setYear(date.getFullYear());
    };

    const createDatesFromRanges = () => {
        const absenceDates: string[] = [];
        if (absenceRanges) {
            for (const range of absenceRanges) {
                for (let i = 0; i <= range.steps; i++) {
                    const currentDateString = addDays(range.start, i).toDateString();
                    if (!absenceDates.find((dateString) => dateString === currentDateString)) absenceDates.push(currentDateString);
                }
            }
        }
        return absenceDates;
    };

    const joinDateArrays = () => {
        if (holidayDates && disabledModDates && absenceDates) {
            return [...holidayDates, ...disabledModDates, ...absenceDates];
        } else {
            return [];
        }
    };

    const disabledDays = (date: Date) => {
        if (disabledDates.includes(date.toDateString())) return disabledDates.includes(date.toDateString()); // disabledDates(ünnepek + münkaszüneti nap + szabadság)
        if (enabledModDates?.includes(date.toDateString())) return !enabledModDates.includes(date.toDateString()); // áthelyezett munkanap
        return date.getDay() === 0 || date.getDay() === 6; // hétvégék
    };

    const absenceDates = createDatesFromRanges();
    const disabledDates = joinDateArrays();

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`Munkaidő regisztrálása`}>
                <div css={sectionStyles}>
                    <div css={inputsContainerStyles}>
                        <FormControl sx={{ minWidth: 300 }} disabled={!isAdmin()} required>
                            <InputLabel id="worker">Név</InputLabel>
                            <Select labelId="worker" id="worker-select" label="Név" value={worker} onChange={(e) => setWorker(e.target.value)}>
                                {users.data?.map((worker) => {
                                    return (
                                        <MenuItem key={v4()} value={worker.id}>
                                            {worker.lastname + ' ' + worker.firstname + ' ' + (worker.middlename ? worker.middlename : '') + ` - (${worker.username})`}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={huLocale}>
                            <DatePicker
                                label="Munka napja"
                                views={['year', 'month', 'day']}
                                inputFormat="yyyy.MM.dd."
                                mask={'____.__.__.'}
                                minDate={!isAdmin() ? addDays(new Date(), -3) : undefined}
                                shouldDisableDate={disabledDays}
                                value={date}
                                onChange={(newDate: Date | null) => setDate(newDate)}
                                onMonthChange={(date) => handleMonthChange(date)}
                                renderInput={(params) => <TextField {...params} css={inputsStyles} required />}
                                PopperProps={{ disablePortal: true }}
                            />
                        </LocalizationProvider>
                        <FormControl sx={{ minWidth: 300 }} disabled={!isAdmin()} required>
                            <InputLabel id="workHours">Munkaidő</InputLabel>
                            <Select labelId="workHours" id="workHours-select" label="Munkaidő" value={hours} onChange={(e) => setHours(e.target.value)}>
                                {createSelectItems().map((hour) => {
                                    return (
                                        <MenuItem key={v4()} value={hour.value}>
                                            {hour.hour}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <div css={buttonInputsContainerStyles}>
                            <Button size="large" variant="contained" color="success" css={buttonStyle} onClick={handleCreate}>
                                Rögzít
                            </Button>
                        </div>
                    </div>
                </div>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;
    & > * + * {
        margin-top: 20px !important;
    }
`;

const inputsContainerStyles = css`
    display: flex;
    gap: 20px;

    @media (max-width: 1200px) {
        flex-direction: column;
    }
`;

const inputsStyles = css`
    width: 300px;
    @media (max-width: 1200px) {
        width: 100%;
    }
`;

const buttonInputsContainerStyles = css`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    gap: 20px;

    @media (max-width: 1200px) {
        justify-content: center;
        align-items: center;
    }
`;

const buttonStyle = css`
    padding: 0 3rem;
`;

export default WorkingTimeRecordPage;
