/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Paper, Button, Typography } from '@mui/material';

import { Section } from '../../components/Section';
import { CustomerData } from './CustomerData';
import { OrderedProduct } from './OrderedProduct';
import UpsellTask from './UpsellTask/UpsellTask';

import useErrorHandler from '../../utils/userErrorHandler';
import useOrderQuery, { Order } from './gql/order';

const OrderDetailsPage: React.FC = () => {
    const params = useParams();
    const orderId = params.id as string;
    const orderResp = useOrderQuery({ id: parseInt(orderId) });

    useErrorHandler(orderResp.error);

    const [order, setOrder] = useState<Order>();

    useEffect(() => {
        orderResp.data && setOrder(orderResp.data);
    }, [orderResp.data]);

    const triggerReferch = async () => {
        await orderResp.refetch();
    };

    const handleDelete = async () => {
        console.log('deleted', orderId);
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <div css={headerStyles}>
                <Typography variant="h5" component="div" color="primary">
                    Rendelés ID: #{order?.id}
                </Typography>
                <Button variant="contained" color="error" css={buttonContainerStyles} onClick={handleDelete}>
                    Rendelés törlése
                </Button>
            </div>
            {order?.accepted === false && (
                <Section title="Upsell feladat">
                    <div css={sectionStyles}>{order && <UpsellTask />}</div>
                </Section>
            )}
            {order && <CustomerData data={order.customer}></CustomerData>}
            <Section title="Rendelési adatok" collapsible>
                <div css={sectionStyles}>{order && <OrderedProduct products={order.ordered_products} refetch={triggerReferch} customerId={order.customer.id} orderId={parseInt(orderId)} />}</div>
            </Section>
        </Paper>
    );
};

const headerStyles = css`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
`;

const buttonContainerStyles = css`
    text-decoration: none;
    margin-left: auto;
`;

export default OrderDetailsPage;
