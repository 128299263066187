import React, { useState } from 'react';
import { Popover, Typography } from '@mui/material';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import DataGridLocalization from '../../../utils/DataGridLocalization';
import { Section } from '../../../components/Section';
import { ID } from '../../../components/ID';
import useLatePaymentListContact, { Installment } from './gql/latePaymentListContactInstallments';
import { useParams } from 'react-router-dom';
import useErrorHandler from '../../../utils/userErrorHandler';

const Orders: React.FC = () => {
    const params = useParams();
    const latePaymentListContactInstallments = useLatePaymentListContact({ id: Number(params.id) });
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const isNotePopoverOpen = Boolean(anchorEl);
    useErrorHandler(latePaymentListContactInstallments.error);

    const getInstallmentsList = () => {
        const list: Installment[] = [];

        if (latePaymentListContactInstallments.data) {
            latePaymentListContactInstallments.data.order.ordered_products.map((product) => {
                product.installments.map((installment) => list.push(installment));
            });
        }

        return list;
    };

    const makeRows = (installments: Installment[]) => {
        const rows: GridRowsProp = installments.map((installment) => ({
            id: `${installment.ordered_product.order.id}${installment.ordered_product.product.id}`,
            orderID: installment.ordered_product.order.id,
            product: installment.ordered_product.product.name,
            price: `${installment.ordered_product.price.gross} Ft`,
            due_date: installment.due_date,
            paid: installment.payment_date || 'Nem',
        }));

        return rows;
    };

    const makeCols = () => {
        const columns: GridColDef[] = [
            {
                field: 'orderID',
                headerName: 'Rendelés ID',
                width: 150,
                renderCell: function renderID(cellValues) {
                    return <ID key={cellValues.row.orderID} id={cellValues.row.orderID.toString()} url={`/orders/${cellValues.row.orderID}`} openable />;
                },
            },
            { field: 'product', headerName: 'Termék', width: 300 },
            { field: 'price', headerName: 'Ár', width: 150 },
            { field: 'due_date', headerName: 'Határidő', width: 150 },
            { field: 'paid', headerName: 'Fizetve', width: 150 },
        ];

        return columns;
    };

    return (
        <Section title="Fizetési részletek">
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                    componentsProps={{
                        pagination: {
                            labelRowsPerPage: 'Oldalanként',
                            labelDisplayedRows: ({ from, to, count }: { from: number; to: number; count: number }) => `${from}–${to} / ${count !== -1 ? count : `more than ${to}`}`,
                        },
                    }}
                    localeText={DataGridLocalization}
                    rows={makeRows(getInstallmentsList())}
                    columns={makeCols()}
                    style={{ borderRadius: 0, border: 'none' }}
                />
            </div>

            <Popover
                id="popover"
                open={isNotePopoverOpen}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                style={{ maxWidth: 1200 }}
            >
                <Typography sx={{ p: 2, pb: 0 }}>Az ebben az oszlopban látható öt szám azt jelöli, hogy melyik fizetési állapotban hány részlet van. Ezek jelentései sorrendben, színekkel jelölve a következő:</Typography>
                <Typography sx={{ p: 2, pb: 0 }}>
                    <span style={{ fontWeight: 700, color: '#388e3c' }}>zöld:</span> fizetve
                </Typography>
                <Typography sx={{ p: 2, pb: 0, pt: 0 }}>
                    <span style={{ fontWeight: 700, color: '#9e9e9e' }}>szürke:</span> még nem esedékes
                </Typography>
                <Typography sx={{ p: 2, pb: 0, pt: 0 }}>
                    <span style={{ fontWeight: 700, color: '#d32f2f' }}>piros:</span> késett
                </Typography>
                <Typography sx={{ p: 2, pb: 0, pt: 0 }}>
                    <span style={{ fontWeight: 700, color: '#f57c00' }}>sárga:</span> másik rendelésbe be lett számítva
                </Typography>
                <Typography sx={{ p: 2, pb: 0, pt: 0 }}>
                    <span style={{ fontWeight: 700, color: '#0288d1' }}>kék:</span> másik rendelésből be lett számítva ide
                </Typography>
                <Typography sx={{ p: 2 }}>Bővebb részletekért nyisd meg a rendelés adatlapját.</Typography>
            </Popover>
        </Section>
    );
};

export default Orders;
