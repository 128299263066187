import { gql } from '@apollo/client';
import { lazyQueryFactory } from '../../../utils/gqlFactories';

const offDays = gql`
    query offDays($reason: Int, $user: Int, $from: LocaleDate, $to: LocaleDate) {
        offDays(reason: $reason, user: $user, from: $from, to: $to) {
            id
            start
            end
        }
    }
`;

interface OffDay {
    id: number;
    start: string;
    end: string;
}

const useOffDaysQuery = lazyQueryFactory<OffDay[]>(offDays, 'offDays');

export default useOffDaysQuery;
