import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const deleteWorkingDayModMutation = gql`
    mutation deleteWorkingDayMod($id: Int!) {
        deleteWorkingDayMod(id: $id)
    }
`;

const useDeleteWorkingDayModMutation = mutationFactory(deleteWorkingDayModMutation, 'deleteWorkingDayMod');

export default useDeleteWorkingDayModMutation;
