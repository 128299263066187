/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import { Section } from '../../components/Section';
import { Button } from '@mui/material';
import useEmailTemplatesQuery from './gql/emailTemplates';
import useErrorHandler from '../../utils/userErrorHandler';

const EmailTemplatesPage: React.FC = () => {
    const emailTemplates = useEmailTemplatesQuery();
    useErrorHandler(emailTemplates.error);

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title="E-mail sablonok">
                <TableContainer>
                    <Table aria-label="table" style={{ minWidth: 1310 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">ID</TableCell>
                                <TableCell align="center">Név</TableCell>
                                <TableCell align="center">Tárgy</TableCell>
                                <TableCell align="center">Küldő címe</TableCell>
                                <TableCell align="center">Küldő neve</TableCell>
                                <TableCell align="center">Utoljára módosítva</TableCell>
                                <TableCell align="center">Műveletek</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {emailTemplates.data &&
                                emailTemplates.data.map((template) => (
                                    <TableRow css={trStyle} key={template.id}>
                                        <TableCell align="center">{template.id}</TableCell>
                                        <TableCell align="center">{template.name}</TableCell>
                                        <TableCell align="center">{template.subject}</TableCell>
                                        <TableCell align="center">{template.reply_to.displayed_name}</TableCell>
                                        <TableCell align="center">{template.sender.displayed_name}</TableCell>
                                        <TableCell align="center">{template.last_modified}</TableCell>
                                        <TableCell align="center" css={actionsColStyle}>
                                            <Link to={`/emails/templates/${template.id}/edit`} style={{ textDecoration: 'none' }}>
                                                <Button variant="contained" color="primary">
                                                    Szerkesztés
                                                </Button>
                                            </Link>
                                            <Link to={`/emails/templates/${template.id}/send`} style={{ textDecoration: 'none' }}>
                                                <Button variant="contained" color="success">
                                                    Küldés
                                                </Button>
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Link to="/emails/templates/create" css={buttonContainerStyle}>
                    <Button variant="contained" color="success">
                        Új hozzáadása
                    </Button>
                </Link>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const trStyle = css`
    &: nth-of-type(odd) {
        background-color: #e3f2fd;
    }

    &:last-child td,
    &:last-child th {
        border: 0;
    }
`;

const actionsColStyle = css`
    & > * + * {
        margin-left: 20px !important;
    }
`;

const buttonContainerStyle = css`
    text-decoration: none;
    margin-left: auto;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
`;

export default EmailTemplatesPage;
