import { gql } from '@apollo/client';
import { queryFactory } from '../../../../utils/gqlFactories';

const blockTemplates = gql`
    query blockTemplates {
        blockTemplates {
            id
            block {
                id
                operators {
                    id
                    name
                }
                filters {
                    id
                    type {
                        id
                    }
                    list {
                        id
                    }
                    email {
                        id
                    }
                    sms {
                        id
                    }
                    tag {
                        id
                    }
                    product {
                        id
                    }
                    negation
                    from
                    to
                }
            }
            name
        }
    }
`;

type BlockTemplate = {
    id: number;
    block: {
        id: number;
        operators: { id: number; name: string }[];
        filters: {
            id: number;
            type: { id: number };
            list: { id: number };
            email: { id: number };
            sms: { id: number };
            tag: { id: number };
            product: { id: number };
            negation: boolean;
            from: Date;
            to: Date;
        }[];
    };
    name: string;
};

const useBlockTemplates = queryFactory<BlockTemplate[]>(blockTemplates, 'blockTemplates');

export default useBlockTemplates;
