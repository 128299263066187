/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, OutlinedInput, Box, Chip, SelectChangeEvent } from '@mui/material';
import { v4 } from 'uuid';
import { ProductCategory } from '../../../CategoriesPage/gql/productCategories';
import useRemoveCategoryFromProductMutation from '../../gql/removeCategoryFromProduct';
import useAddCategoryToProductMutation from '../../gql/addCategoryToProduct';
import useErrorHandler from '../../../../utils/userErrorHandler';

type ProductCategoriesProps = {
    id: string;
    selectedCategories: number[];
    categories: ProductCategory[];
};

const ProductCategories: React.FC<ProductCategoriesProps> = (props) => {
    const [removeCat, removedCatResp] = useRemoveCategoryFromProductMutation();
    const [addCat, addedCatResp] = useAddCategoryToProductMutation();
    useErrorHandler(removedCatResp.error, addedCatResp.error);

    const [category, setCategory] = useState<number[]>(props.selectedCategories);

    const handleChange = async (event: SelectChangeEvent<typeof category>) => {
        const { value } = event.target;
        const input = value as number[];
        const difference: number[] = input.filter((x) => !category.includes(x)).concat(category.filter((x) => !input.includes(x)));

        if (input.length > category.length) {
            await addCat({ product: parseInt(props.id), category: difference[0] });
        } else {
            await removeCat({ product: parseInt(props.id), category: difference[0] });
        }
        setCategory(input);
    };

    return (
        <FormControl css={chipSelectStyles}>
            <InputLabel id="category-chip-label">Kategóriák</InputLabel>
            <Select
                multiple
                value={category}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Kategóriák" />}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((catId: number) => (
                            <Chip key={v4()} label={props.categories.find((e) => e.id === catId)?.name} variant="filled" color="info" />
                        ))}
                    </Box>
                )}
            >
                {props.categories.map((cat: ProductCategory) => (
                    <MenuItem key={cat.id} value={cat.id}>
                        {cat.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const chipSelectStyles = css`
    width: 100%;
`;

export default ProductCategories;
