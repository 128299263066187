import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { SnackbarProvider } from 'notistack';

const client = new ApolloClient({
    uri: 'https://api.maganpenzugyiakademia.hu',
    cache: new InMemoryCache(),
    credentials: 'include',
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <ApolloProvider client={client}>
        <SnackbarProvider maxSnack={3}>
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </SnackbarProvider>
    </ApolloProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
