import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const deleteBlockedContact = gql`
    mutation deleteBlockedContact($id: Int!) {
        deleteBlockedContact(id: $id)
    }
`;

const useDeleteBlockedContact = mutationFactory<number>(deleteBlockedContact, 'deleteBlockedContact');

export default useDeleteBlockedContact;
