/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { Alert, Chip, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import huLocale from 'date-fns/locale/hu';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Action, NewInstallment } from '../Products';
import { Currency } from '../../gql/currencies';
import useCurrencyRateQuery, { Currencyrate } from '../../gql/currencyRate';
import useErrorHandler from '../../../../utils/userErrorHandler';

interface NewInstallmentRowProps {
    paymentTypes: {
        id: number;
        name: string;
    }[];
    installment: NewInstallment;
    currencies: Currency[];
    productRenderId: string | undefined;
    dispatch: React.Dispatch<Action>;
}

const NewInstallmentRow: React.FC<NewInstallmentRowProps> = (props) => {
    const [getCurrentRate, currentRateResp] = useCurrencyRateQuery();

    useErrorHandler(currentRateResp.error);

    const [currency, setCurrency] = useState<string>(props.installment.currency ? props.installment.currency.toString() : props.currencies[0].id.toString());
    const [rate, setRate] = useState<Currencyrate | null>(null);

    useEffect(() => {
        if (parseInt(currency) !== 1) {
            currentRateResp.data && setRate(currentRateResp.data);
            getCurrentRate({ currency: parseInt(currency) });
        } else if (parseInt(currency) === 1) {
            rate && setRate(null);
        }
    }, [currency]);

    useEffect(() => {
        if (currentRateResp.data) {
            setRate(currentRateResp.data);
        }
    }, [currentRateResp.data]);

    useEffect(() => {
        if (parseInt(currency) !== 1 && rate?.rate) {
            props.installment.id &&
                props.productRenderId &&
                props.dispatch({
                    type: 'changeInstallmentCurrency',
                    currency_gross: parseInt(props.installment.gross) ? Math.round((parseInt(props.installment.gross) / rate.rate + Number.EPSILON) * 100) / 100 : undefined,
                    currency_rate: rate.rate,
                    currency: parseInt(currency),
                    productRenderId: props.productRenderId,
                    installmentId: props.installment.id,
                });
        } else if (parseInt(currency) === 1) {
            props.installment.id &&
                props.productRenderId &&
                props.dispatch({
                    type: 'changeInstallmentCurrency',
                    currency_gross: undefined,
                    currency_rate: undefined,
                    currency: 1,
                    productRenderId: props.productRenderId,
                    installmentId: props.installment.id,
                });
        }
    }, [rate, props.installment.gross]);

    const handlePriceChange = (e: string) => {
        let input = e;

        if (e.match(/\D/)) input = input.slice(0, -1);
        if (input.length < 21 && parseInt(input) && props.productRenderId) {
            props.dispatch({ type: 'changeInstallmentGross', gross: input, installmentId: props.installment.id, productRenderId: props.productRenderId });
        } else if (input === '' && props.productRenderId) {
            props.dispatch({ type: 'changeInstallmentGross', gross: input, installmentId: props.installment.id, productRenderId: props.productRenderId });
        }
    };

    const handlePaymentMethodChange = (e: string) => {
        const selectedPaymentMethod = props.paymentTypes.find((x) => x.id === parseInt(e));

        if (props.productRenderId && selectedPaymentMethod) {
            props.dispatch({ type: 'changeInstallmentPaymentMethod', payment_method: selectedPaymentMethod, installmentId: props.installment.id, productRenderId: props.productRenderId });
        }
    };

    const handleDueDateChange = (e: Date | null) => {
        props.installment.id && props.productRenderId && props.dispatch({ type: 'changeInstallmentDueDate', due_date: e ? e : null, productRenderId: props.productRenderId, installmentId: props.installment.id });
    };

    const handleDelete = () => {
        props.installment.id && props.productRenderId && props.dispatch({ type: 'deleteInstallment', productRenderId: props.productRenderId, installmentId: props.installment.id });
    };

    return (
        <>
            <div css={rowContainerStyles}>
                <Tooltip title={'Kattints a részlet törléséhez.'} placement="top" arrow>
                    <Chip label={`-`} color="error" onClick={handleDelete} css={chipStyles} />
                </Tooltip>

                <FormControl css={inputStyles}>
                    <InputLabel id="workHours">Fizetési mód</InputLabel>
                    <Select labelId="workHours" id="workHours-select" label="Fizetési mód" value={props.installment.payment_method.id.toString()} onChange={(e) => handlePaymentMethodChange(e.target.value)}>
                        {props.paymentTypes.map((type) => {
                            return (
                                <MenuItem key={v4()} value={type.id}>
                                    {type.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>

                <LocalizationProvider dateAdapter={AdapterDateFns} locale={huLocale}>
                    <DatePicker
                        label="Fizetési határidő"
                        views={['year', 'month', 'day']}
                        inputFormat="yyyy.MM.dd."
                        mask={'____.__.__.'}
                        value={props.installment.due_date ? props.installment.due_date : null}
                        onChange={(newDate) => handleDueDateChange(newDate)}
                        renderInput={(params) => <TextField {...params} css={inputStyles} />}
                    />
                </LocalizationProvider>

                <TextField id="grossPrice" label="Bruttó ár (Ft)" placeholder="A termék bruttó ára" value={props.installment.gross} onChange={(e) => handlePriceChange(e.target.value)} css={inputStyles} />

                {props.currencies.length > 0 && (
                    <FormControl css={inputStyles}>
                        <InputLabel id="currencies">Pénznemek</InputLabel>
                        <Select labelId="currencies" id="currencies" value={currency} label="Pénznemek" onChange={(e) => setCurrency(e.target.value)}>
                            {props.currencies.map((currency) => (
                                <MenuItem key={currency.id} value={currency.id}>
                                    {currency.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </div>
            {rate?.rate && currentRateResp.data && (
                <div css={rowContainerStyles}>
                    <Alert variant="outlined" severity="info" style={{ width: '100%' }}>
                        {`${parseInt(props.installment.gross) ? Math.round((parseInt(props.installment.gross) / rate.rate + Number.EPSILON) * 100) / 100 : '0'} ${
                            props.currencies.find((data) => data.id === parseInt(currency))?.name
                        }, árfolyam: ${rate.rate} (${rate.source})`}
                    </Alert>
                </div>
            )}
        </>
    );
};

const rowContainerStyles = css`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    @media (max-width: 1100px) {
        flex-direction: column;
    }
`;

const inputStyles = css`
    width: 300px;

    @media (max-width: 1100px) {
        width: 280px;
    }
`;

const chipStyles = css`
    width: 150px;

    @media (max-width: 1100px) {
        width: 140px;
    }
`;

export type { NewInstallmentRowProps };
export default NewInstallmentRow;
