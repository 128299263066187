/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';

import { SubSubSection } from '../../../components/SubSubSection';
import { TableContainer, TableHead } from '@mui/material';
import NumberFormat from 'react-number-format';
import { Delivery } from '../gql/order';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

type CustomerData = {
    name: string;
    phone: string;
    country: string;
    zip: string;
    city: string;
    address: string;
};

type TransportDataProps = {
    data: Delivery[];
    customerId: number;
    orderedProductId: number;
    orderId: number;
};

const TransportData: React.FC<TransportDataProps> = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const renderData = props.data
        .map((shipping) => {
            if (shipping.state_changes.length !== 0) {
                return shipping.state_changes.map((state, index) => {
                    if (index === 0) {
                        return {
                            id: shipping.id,
                            customer_data: {
                                name: shipping.name,
                                phone: shipping.phone,
                                country: shipping.country,
                                zip: shipping.zip,
                                city: shipping.city,
                                address: shipping.address,
                            },
                            method: shipping.method.name,
                            date: shipping.date,
                            price: shipping.installment ? shipping.installment.gross : null,
                            shipping_id: 'valamiId-123321',
                            state_change: { created_at: state.created_at, state: state.state.name },
                            comment: shipping.comment ? shipping.comment : '-',
                        };
                    } else {
                        return { state_change: { created_at: state.created_at, state: state.state.name } };
                    }
                });
            } else {
                return {
                    id: shipping.id,
                    customer_data: {
                        name: shipping.name,
                        phone: shipping.phone,
                        country: shipping.country,
                        zip: shipping.zip,
                        city: shipping.city,
                        address: shipping.address,
                    },
                    method: shipping.method.name,
                    date: shipping.date,
                    price: shipping.installment ? shipping.installment.gross : null,
                    shipping_id: 'valamiId-123321',
                    state_change: { created_at: null, state: null },
                    comment: shipping.comment ? shipping.comment : '-',
                };
            }
        })
        .flat();

    const findEditAbleShipping = (): number | null => {
        const editable = props.data.find((shipping) => shipping.state_changes.length === 0);
        if (editable) {
            return editable.id;
        } else {
            return null;
        }
    };

    const renderName = (data: CustomerData) => {
        const name = data.name ? data.name : '';
        const phone = data.phone ? data.phone : '';

        if (name && phone) {
            return `${name}, ${phone}`;
        } else if (name) {
            return `${name}`;
        } else if (phone) {
            return `${phone}`;
        } else {
            return '';
        }
    };

    const renderAddressLineOne = (data: CustomerData) => {
        const country = data.country ? data.country : '';
        const zip = data.zip ? data.zip : '';

        if (country && zip) {
            return `${country}, ${zip}`;
        } else if (country) {
            return `${country}`;
        } else if (zip) {
            return `${zip}`;
        } else {
            return '';
        }
    };

    const renderAddressLineTwo = (data: CustomerData) => {
        const city = data.city ? data.city : '';
        const address = data.address ? data.address : '';

        if (city && address) {
            return `${city}, ${address}`;
        } else if (city) {
            return `${city}`;
        } else if (address) {
            return `${address}`;
        } else {
            return '';
        }
    };

    const handleEdit = () => {
        const editId = findEditAbleShipping();
        if (editId) {
            navigate(`/orders/delivery/${editId}/edit`);
        } else {
            enqueueSnackbar('Nincs aktuális szállítás, folyamatban lévő vagy befejezett szállítás nem szerkeszthető!', { variant: 'error' });
        }
    };

    const renderTableRows = (): React.ReactNode => {
        if (renderData) {
            return (
                <>
                    {renderData.map((row, index) => {
                        if (row.id) {
                            return (
                                <TableRow key={index}>
                                    <TableCell align="center">{row.id}</TableCell>
                                    <TableCell align="center">
                                        {renderName(row.customer_data)}
                                        <br />
                                        {renderAddressLineOne(row.customer_data)}
                                        <br />
                                        {renderAddressLineTwo(row.customer_data)}
                                    </TableCell>
                                    <TableCell align="center">{row.method}</TableCell>
                                    <TableCell align="center">{row.date}</TableCell>
                                    <TableCell align="center">{row.price ? <NumberFormat value={row.price} thousandSeparator={' '} suffix={` Ft`} displayType="text" /> : '-'}</TableCell>
                                    <TableCell align="center">{row.shipping_id}</TableCell>
                                    <TableCell align="center">{row.comment}</TableCell>
                                    <TableCell align="center">{row.state_change.state ? row.state_change.state : '-'}</TableCell>
                                    <TableCell align="center">{row.state_change.created_at ? row.state_change.created_at : '-'}</TableCell>
                                </TableRow>
                            );
                        } else {
                            return (
                                <TableRow key={index}>
                                    <TableCell align="center" css={emptyCellStyles} />
                                    <TableCell align="center" css={emptyCellStyles} />
                                    <TableCell align="center" css={emptyCellStyles} />
                                    <TableCell align="center" css={emptyCellStyles} />
                                    <TableCell align="center" css={emptyCellStyles} />
                                    <TableCell align="center" css={emptyCellStyles} />
                                    <TableCell align="center" css={emptyCellStyles} />
                                    <TableCell align="center">{row.state_change.state}</TableCell>
                                    <TableCell align="center">{row.state_change.created_at}</TableCell>
                                </TableRow>
                            );
                        }
                    })}
                </>
            );
        } else {
            return null;
        }
    };

    return (
        <SubSubSection title="Szállítási adatok">
            <TableContainer>
                <Table aria-label="table" style={{ minWidth: 1310 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">ID</TableCell>
                            <TableCell align="center">Szállítási adatok</TableCell>
                            <TableCell align="center">Partner</TableCell>
                            <TableCell align="center">Időpont</TableCell>
                            <TableCell align="center">Szállítási díj</TableCell>
                            <TableCell align="center">Szállítási azonosító</TableCell>
                            <TableCell align="center">Megjegyzés</TableCell>
                            <TableCell align="center">Állapot</TableCell>
                            <TableCell align="center">Állapotváltozás dátuma</TableCell>
                        </TableRow>
                    </TableHead>
                    {props.data && <TableBody>{renderTableRows()}</TableBody>}
                </Table>
            </TableContainer>
            <div css={buttonContainer}>
                <Button css={timeButtonStyle} variant="contained" onClick={() => handleEdit()}>
                    Aktuális szállítás szerkesztése
                </Button>
                <Button css={timeButtonStyle} variant="contained" color="success" onClick={() => navigate(`/orders/${props.orderId}/delivery/${props.customerId}/${props.orderedProductId}/create`)}>
                    Új szállítás
                </Button>
            </div>
        </SubSubSection>
    );
};

const emptyCellStyles = css`
    background-color: #e3f2fd;
`;

const buttonContainer = css`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const timeButtonStyle = css`
    width: 45%;
`;

export default TransportData;
