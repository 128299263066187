import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const publicHolidaysQuery = gql`
    query {
        publicHolidays {
            id
            name
            month
            day
        }
    }
`;

interface PublicHoliday {
    id: number;
    name: string;
    month: number;
    day: number;
}

const usePublicHolidaysQuery = queryFactory<PublicHoliday[]>(publicHolidaysQuery, 'publicHolidays');

export default usePublicHolidaysQuery;
