/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Section } from '../../components/Section';
import useUsers from './gql/users';
import useEmailAccount from './gql/emailAccount';
import useUpdateEmailAccount from './gql/updateEmailAccount';
import useAddEmailAccountPermissionForUser from './gql/addEmailAccountPermissionForUser';
import useRemoveEmailAccountPermissionFromUser from './gql/removeEmailAccountPermissionFromUser';
import { Paper, TextField, Button, FormControl, Select, MenuItem, InputLabel, Box, Tab, FormControlLabel, Checkbox } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import useErrorHandler from '../../utils/userErrorHandler';
import { useSnackbar } from 'notistack';

const EmailAccountEditPage: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();
    const users = useUsers();
    const emailAccount = useEmailAccount({ id: Number(params.id) });
    const [updateEmailAccount, updatedEmailAccount] = useUpdateEmailAccount();
    const [addEmailAccountPermissionForUser, addedEmailAccountPermissionForUser] = useAddEmailAccountPermissionForUser();
    const [removeEmailAccountPermissionFromUser, removedEmailAccountPermissionFromUser] = useRemoveEmailAccountPermissionFromUser();
    const [displayedTab, setDisplayedTab] = useState<'data' | 'permissions'>('data');
    const [host, setHost] = useState('');
    const [port, setPort] = useState(0);
    const [username, setUsername] = useState('');
    const [displayedName, setDisplayedName] = useState('');
    const [password, setPassword] = useState('');
    const [secure, setSecure] = useState(1);
    const [usersWithPermission, setUsersWithPermission] = useState<{ id: number; username: string; alreadyHasPermission: boolean }[]>();
    useErrorHandler(users.error, emailAccount.error, updatedEmailAccount.error, addedEmailAccountPermissionForUser.error, removedEmailAccountPermissionFromUser.error);

    useEffect(() => {
        if (emailAccount.data && users.data) {
            setHost(emailAccount.data.host);
            setPort(emailAccount.data.port);
            setUsername(emailAccount.data.username);
            setDisplayedName(emailAccount.data.displayed_name);
            setPassword(emailAccount.data.password);
            setSecure(emailAccount.data.secure ? 1 : 2);

            const usersWithPerm = users.data.map((user) => {
                const hasPermission = emailAccount.data?.permissions.find((userWithPermisison) => (userWithPermisison.id === user.id ? true : false));
                return { ...user, alreadyHasPermission: hasPermission ? true : false };
            });
            setUsersWithPermission(usersWithPerm);
            console.log('perm', usersWithPerm);
        }
    }, [emailAccount.data, users.data]);

    useEffect(() => {
        if (updatedEmailAccount.data) {
            navigate('/emails/accounts');
        }
    }, [updatedEmailAccount]);

    const handleTabChange = (_event: React.SyntheticEvent, newValue: 'data' | 'permissions') => {
        setDisplayedTab(newValue);
    };

    const handleSave = () => {
        if (isValid()) {
            updateEmailAccount({ id: Number(params.id), data: { host, port, username, displayed_name: displayedName, password, secure: secure === 1 ? true : false } });
        } else {
            enqueueSnackbar('A csillaggal jelölt mezők kitöltése kötelező!', { variant: 'error' });
        }
    };

    const handlePermissionChange = async (userID: number, checked: boolean) => {
        if (checked) {
            await addEmailAccountPermissionForUser({ account: Number(params.id), user: userID });
            console.log('refetch');
            await emailAccount.refetch();
            console.log('refetched');
        } else {
            await removeEmailAccountPermissionFromUser({ account: Number(params.id), user: userID });
            emailAccount.refetch();
        }
    };

    const isValid = () => {
        return username !== '' && displayedName !== '' && password !== '' && host !== '' && port !== 0;
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`E-mail fiók: #${params.id} szerkesztése`}>
                <TabContext value={displayedTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                            <Tab label="Adatok" value="data" />
                            <Tab label="Jogosultságok" value="permissions" />
                        </TabList>
                    </Box>
                    <TabPanel value="data" style={{ padding: 0 }}>
                        <div css={sectionStyles}>
                            <TextField label="Felhasználónév*" variant="outlined" value={username} onChange={(e) => setUsername(e.target.value)} />
                            <TextField label="Név*" variant="outlined" value={displayedName} onChange={(e) => setDisplayedName(e.target.value)} />
                            <TextField label="Jelszó*" variant="outlined" value={password} onChange={(e) => setPassword(e.target.value)} />
                            <TextField label="Host*" variant="outlined" value={host} onChange={(e) => setHost(e.target.value)} />
                            <TextField label="Port*" type="number" InputLabelProps={{ shrink: true }} value={port} onChange={(e) => setPort(Number(e.target.value))} />
                            <FormControl fullWidth>
                                <InputLabel id="secure-select">TLS kapcsolat</InputLabel>
                                <Select label="TLS kapcsolat" id="secure-select" value={secure} onChange={(e) => setSecure(Number(e.target.value))}>
                                    <MenuItem value={1}>Igen</MenuItem>
                                    <MenuItem value={2}>Nem</MenuItem>
                                </Select>
                            </FormControl>
                            <div style={{ marginLeft: 'auto' }}>
                                <Button variant="contained" color="success" size="large" onClick={handleSave} style={{ marginLeft: 'auto' }}>
                                    Mentés
                                </Button>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value="permissions" style={{ padding: 0 }}>
                        <div css={sectionStyles}>
                            {usersWithPermission &&
                                usersWithPermission.map((user) => (
                                    <FormControlLabel control={<Checkbox checked={user.alreadyHasPermission} onChange={(e) => handlePermissionChange(user.id, e.target.checked)} />} label={`${user.id} - ${user.username}`} key={user.id} />
                                ))}
                        </div>
                    </TabPanel>
                </TabContext>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;

    & > * + * {
        margin-top: 20px !important;
    }
`;

export default EmailAccountEditPage;
