import { gql } from '@apollo/client';
import { lazyQueryFactory } from '../../../utils/gqlFactories';

const customerAllDeliveriesQuery = gql`
    query customerAllDeliveries($customer: Int!) {
        customerAllDeliveries(customer: $customer) {
            id
            method {
                name
            }
            name
            country
            city
            zip
            address
            date
            phone
            comment
        }
    }
`;

interface ShippingDetails {
    id: number;
    method: { name: string };
    name: string;
    country: string;
    city: string;
    zip: string;
    address: string;
    date: string;
    phone: string;
    comment: string;
}

const useCustomerAllDeliveriesQuery = lazyQueryFactory<ShippingDetails[]>(customerAllDeliveriesQuery, 'customerAllDeliveries');

export type { ShippingDetails };
export default useCustomerAllDeliveriesQuery;
