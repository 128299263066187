import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const createOrderMutation = gql`
    mutation createOrder($data: OrderInputCreate!) {
        createOrder(data: $data) {
            id
        }
    }
`;

interface CreatedOrder {
    id: number;
}

const useCreateOrderMutation = mutationFactory<CreatedOrder>(createOrderMutation, 'createOrder');

export default useCreateOrderMutation;
