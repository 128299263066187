/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Paper, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { Section } from '../../components/Section';
import { v4 } from 'uuid';
import { CustomDateRangePicker } from '../../components/CustomDateRangePicker';
import useErrorHandler from '../../utils/userErrorHandler';
import useUsers from '../WorkingTimeListPage/gql/users';
import useMeQuery from '../AbsencesPage/gql/me';
import { addDays } from 'date-fns';
import useCreateOffDayMutation from './gql/createOffDay';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import useOffDayReasonsQuery from '../AbsenceEditPage/gql/offDayReasons';

const AbsenceCreatePage: React.FC = () => {
    const navigate = useNavigate();
    const users = useUsers();
    const me = useMeQuery();
    const offDayReasons = useOffDayReasonsQuery();
    const [createOffDay, offDayResp] = useCreateOffDayMutation();
    useErrorHandler(users.error, me.error, offDayResp.error, offDayReasons.error);
    const { enqueueSnackbar } = useSnackbar();

    const [reason, setReason] = useState('');
    const [worker, setWorker] = useState('');
    const [date, setDate] = useState<
        {
            startDate: Date;
            endDate: Date;
            key: string;
        }[]
    >([
        {
            startDate: new Date(new Date()),
            endDate: addDays(new Date(), 6),
            key: 'selection',
        },
    ]);

    useEffect(() => {
        if (me.data) {
            setWorker(me.data.id.toString());
        }
    }, [me.data]);

    const handleDateChange = (item: { selection: { startDate: Date; endDate: Date; key: string } }) => {
        setDate([item.selection]);
    };

    const isAdmin = () => {
        return me.data?.permission.name.includes('admin');
    };

    const handeSave = async () => {
        if (reason && worker && date[0].startDate && date[0].endDate) {
            await createOffDay({ data: { user: parseInt(worker), reason: parseInt(reason), start: date[0].startDate, end: date[0].endDate } });
            navigate('/working-hours/absence');
        } else {
            enqueueSnackbar('Az összes mező kitöltése kötelező!', { variant: 'error' });
        }
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`Távollét rögzítése`}>
                <div css={sectionStyles}>
                    <CustomDateRangePicker ranges={date} onChange={handleDateChange} />
                    <div css={someInputContainerStyles}>
                        <div css={{ minWidth: 226 }} />
                        <FormControl sx={{ minWidth: 352 }} disabled={!isAdmin()} css={selectStyles} required>
                            <InputLabel id="worker">Név</InputLabel>
                            <Select labelId="worker" id="worker-select" label="Név" value={worker} onChange={(e) => setWorker(e.target.value)}>
                                {users.data?.map((worker) => {
                                    return (
                                        <MenuItem key={v4()} value={worker.id}>
                                            {worker.lastname + ' ' + worker.firstname + ' ' + (worker.middlename ? worker.middlename : '') + ` - (${worker.username})`}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ minWidth: 352 }} required>
                            <InputLabel id="reason">Indok</InputLabel>
                            <Select labelId="reason" id="reason-select" label="Indok" value={reason} onChange={(e) => setReason(e.target.value)}>
                                {offDayReasons.data?.map((reason) => {
                                    return (
                                        <MenuItem key={v4()} value={reason.id}>
                                            {reason.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div css={buttonContainerStyle}>
                    <Button variant="contained" color="success" onClick={handeSave}>
                        Hozzáadás
                    </Button>
                </div>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    & > * + * {
        margin-top: 20px !important;
    }
`;

const someInputContainerStyles = css`
    width: 931px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1000px) {
        flex-direction: column;
        & > * + * {
            margin-top: 20px !important;
        }
    }
`;

const selectStyles = css`
    margin-right: 15px;
    @media (max-width: 1000px) {
        margin-right: 0px;
    }
`;

const buttonContainerStyle = css`
    text-decoration: none;
    margin-left: auto;
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
`;

export default AbsenceCreatePage;
