import { gql } from '@apollo/client';
import { queryFactory } from '../../../../utils/gqlFactories';

const latePaymentListContact = gql`
    query latePaymentListContact($id: Int!) {
        latePaymentListContact(id: $id) {
            order {
                ordered_products {
                    installments {
                        payment_date
                        due_date
                        ordered_product {
                            order {
                                id
                            }
                            product {
                                id
                                name
                            }
                            price {
                                gross
                            }
                        }
                    }
                }
            }
        }
    }
`;

export interface Installment {
    due_date: string;
    payment_date: string;
    ordered_product: {
        order: {
            id: number;
        };
        product: {
            id: number;
            name: string;
        };
        price: {
            gross: number;
        };
    };
}

export interface LatePaymentListContact {
    order: {
        ordered_products: {
            installments: Installment[];
        }[];
    };
}

const useLatePaymentListContact = queryFactory<LatePaymentListContact>(latePaymentListContact, 'latePaymentListContact');

export default useLatePaymentListContact;
