/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useContext } from 'react';
import { ListContext, ListContextType, OperatorType } from '../../../contexts/ListContext';
import { Button } from '@mui/material';

type OperatorProps = {
    operator: OperatorType;
    filterOperator?: boolean;
    blockID?: number;
};

const Operator: React.FC<OperatorProps> = ({ operator, filterOperator, blockID }: OperatorProps) => {
    const { dispatch, toggleOperator } = useContext(ListContext) as ListContextType;

    const handleToggle = () => {
        if (filterOperator && blockID) dispatch({ type: 'TOGGLE_OPERATOR', payload: { blockID: blockID, operatorID: operator.id } });
        if (!filterOperator) toggleOperator(operator.id);
    };

    return (
        <div css={containerStyles}>
            <Button variant="contained" color={operator.name === 'ÉS' ? 'success' : 'error'} size="large" onClick={handleToggle}>
                {operator.name}
            </Button>
        </div>
    );
};

const containerStyles = css`
    display: flex;
    justify-content: center;
`;

export default Operator;
