/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Paper, Button, Typography, Tooltip, TableContainer, Table, TableHead, TableCell, TableRow, TableBody, Modal, Box } from '@mui/material';
import useErrorHandler from '../../../utils/userErrorHandler';
import useProductPricesQuery from '../gql/productPrices';
import useDeletePriceMutation from '../gql/deletePrice';

type PricesTableProps = {
    productId: string;
};

const PricesTable: React.FC<PricesTableProps> = (props) => {
    const productPricesResp = useProductPricesQuery({ product: parseInt(props.productId) });
    const [deletePrice, deletedPriceResp] = useDeletePriceMutation();

    useErrorHandler(productPricesResp.error, deletedPriceResp.error);

    const [selected, setSelected] = useState<number>();
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        if (selected) productPricesResp.refetch();
    }, [deletedPriceResp.data]);

    const handleModal = (id: number) => {
        setModalOpen(true);
        setSelected(id);
    };

    const handleDelete = async () => {
        await deletePrice({ id: selected });
        setModalOpen(false);
    };

    return (
        <>
            {productPricesResp.data && productPricesResp.data?.length !== undefined && productPricesResp.data?.length !== 0 ? (
                <Paper>
                    <TableContainer>
                        <Table aria-label="table" style={{ minWidth: 1310 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">ID</TableCell>
                                    <TableCell align="center">Típus</TableCell>
                                    <TableCell align="center">Nettó (Ft)</TableCell>
                                    <TableCell align="center">Bruttó (Ft)</TableCell>
                                    <Tooltip title={'Kampány árhoz tartozó kampány.'} placement="top" arrow>
                                        <TableCell align="center">Kampány?</TableCell>
                                    </Tooltip>
                                    <Tooltip title={'Kampány lejárása után automatikusan értendő ár.'} placement="top" arrow>
                                        <TableCell align="center">Visszatérési ár</TableCell>
                                    </Tooltip>
                                    <TableCell align="center">Műveletek</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {productPricesResp.data.map((price) => (
                                    <TableRow css={trStyles} key={price.id}>
                                        <TableCell align="center">{price.id}</TableCell>
                                        <Tooltip title={price.type.description} placement="top" arrow>
                                            <TableCell align="center">{price.type.name}</TableCell>
                                        </Tooltip>
                                        <TableCell align="center">{price.net}</TableCell>
                                        <TableCell align="center">{price.gross}</TableCell>
                                        {price.campaign ? (
                                            <Tooltip title={`#${price.campaign.id}: ${price.campaign.name}`} placement="top" arrow>
                                                <TableCell align="center">{`${price.campaign.name}: ${price.campaign.start} - ${price.campaign.end}`}</TableCell>
                                            </Tooltip>
                                        ) : (
                                            <TableCell align="center" />
                                        )}
                                        {price.fallback ? (
                                            <Tooltip title={`nettó: ${price.fallback.net}Ft, bruttó:${price.fallback.gross}Ft`} placement="top" arrow>
                                                <TableCell align="center">{`#${price.fallback.id}: ${price.fallback.type.name}`}</TableCell>
                                            </Tooltip>
                                        ) : (
                                            <TableCell align="center" />
                                        )}
                                        <TableCell align="center" css={actionsColStyle}>
                                            <Button variant="contained" color="error" onClick={() => handleModal(price.id)}>
                                                Törlés
                                            </Button>
                                            <Link to={`/products/${props.productId}/price/${price.id}/edit`} style={{ textDecoration: 'none' }}>
                                                <Button variant="contained" color="primary">
                                                    Szerkesztés
                                                </Button>
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            ) : productPricesResp.data?.length === 0 ? (
                <div css={sectionStyles}>
                    <Typography variant="body1" color="primary" align="center">
                        Nincs még egy ár se megadva!
                    </Typography>
                </div>
            ) : null}
            <Modal open={modalOpen} onClose={() => setModalOpen(false)} aria-labelledby="delete-modal-title" aria-describedby="delete-modal-description">
                <Box css={boxStyles}>
                    <Typography id="delete-modal-title" variant="h6" component="h2" style={{ marginBottom: '1rem' }}>
                        Biztosan törölni szeretnéd az árat?
                    </Typography>
                    <div css={buttonContainerStyles}>
                        <Button size="large" variant="contained" color="error" onClick={handleDelete}>
                            Igen
                        </Button>
                        <Button size="large" variant="contained" onClick={() => setModalOpen(false)}>
                            Nem
                        </Button>
                    </div>
                </Box>
            </Modal>
        </>
    );
};

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;
    & > * + * {
        margin-top: 20px !important;
    }
`;

const trStyles = css`
    &: nth-of-type(odd) {
        background-color: #e3f2fd;
    }

    &:last-child td,
    &:last-child th {
        border: 0;
    }
`;

const actionsColStyle = css`
    & > * + * {
        margin-left: 20px !important;
    }
`;

const boxStyles = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 2px solid #0288d1;
    boxshadow: 24;
    padding: 1rem;
`;

const buttonContainerStyles = css`
    display: flex;
    justify-content: space-evenly;
    margin-top: 1rem;
`;

export default PricesTable;
