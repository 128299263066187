import { gql } from '@apollo/client';
import { queryFactory } from '../../../../utils/gqlFactories';

const myStuckOrderListContacts = gql`
    query myStuckOrderListContacts($stuckOrderList: Int!) {
        myStuckOrderListContacts(stuckOrderList: $stuckOrderList) {
            id
            customer {
                firstname
                lastname
                phone
                customer {
                    email
                }
            }
            assignedContact {
                id
                call_state {
                    name
                }
                talk_state {
                    name
                }
            }
            comment
            deleted
            created_at
        }
    }
`;

export interface StuckOrderListContact {
    id: number;
    customer: {
        firstname: string;
        lastname: string;
        phone: string;
        customer: {
            email: string;
        };
    };
    assignedContact: {
        id: number;
        call_state: {
            name: string;
        };
        talk_state: {
            name: string;
        };
    };
    comment: string;
    deleted: boolean;
    created_at: string;
}

const useMyStuckOrderListContacts = queryFactory<StuckOrderListContact[]>(myStuckOrderListContacts, 'myStuckOrderListContacts');

export default useMyStuckOrderListContacts;
