import Customer from '../types/Customer';
import History from '../types/History';
import Product from '../types/Product';

export type Order = {
    id: number;
    customer: Customer;
    products: Product[];
    history: History[];
};

export const orders: Order[] = [
    {
        id: 15916143,
        customer: {
            id: '148',
            name: 'Kiss Noémi',
            phone: '+36204785169',
            email: 'kissnoemi@gmail.com',
            billing: {
                name: 'Kiss Kft',
                tax_number: '145ABC45',
                address: {
                    country: 'Magyarország',
                    city: 'Debrecen',
                    zip: '4027',
                    street: 'Dózsa György utca',
                    number: '17',
                },
            },
            history: [
                {
                    contact: 'Mező Krisztián',
                    date: '2022-03-08 08:00',
                    typeOfContact: 'telefonhívás',
                    conclusion: 'Azt mondta hogy ne keressük X témával kapcsoaltban.',
                },
            ],
        },
        products: [
            {
                name: "Értékalapú Befektetés a' la Warren Buffett szeminárium",
                gross: 42000,
                net: 33071,
                VAT: 27,
                currency: 'Ft',
                ID: '23',
            },
        ],
        history: [
            {
                contact: 'ERP',
                date: '2022-03-08 08:00',
                typeOfContact: 'email',
                conclusion: 'Fizetési felszólítás kiküldve.',
            },
            {
                contact: 'Mező Krisztián',
                date: '2022-03-11 12:47',
                typeOfContact: 'telefonhívás',
                conclusion: 'Azt mondta jövő hétfőn fizetni fog.',
            },
            {
                contact: 'ERP',
                date: '2022-03-30 10:00',
                typeOfContact: 'SMS',
                conclusion: 'Fizetési felszólítás kiküldve.',
            },
        ],
    },
    {
        id: 95123816,
        customer: {
            id: '473',
            name: 'Teszt Béla',
            phone: '+36208281952',
            email: 'tesztbela@gmail.com',
            billing: {
                name: 'Teszt Kft',
                tax_number: '19QWE129',
                address: {
                    country: 'Magyarország',
                    city: 'Budapest',
                    zip: '1085',
                    street: 'Valamilyen utca',
                    number: '56',
                },
            },
            history: [],
        },
        products: [
            {
                name: 'Teszt termék',
                gross: 21000,
                net: 17531,
                VAT: 27,
                currency: 'Ft',
                ID: '23',
            },
        ],
        history: [],
    },
    {
        id: 45675312,
        customer: {
            id: '971',
            name: 'John Doe',
            phone: '+36207531919',
            email: 'johndoe@gmail.com',
            billing: {
                name: 'John Kft',
                tax_number: '12345678',
                address: {
                    country: 'Magyarország',
                    city: 'Budapest',
                    zip: '1074',
                    street: 'Valamilyen utca',
                    number: '6',
                },
            },
            history: [],
        },
        products: [
            {
                name: 'Valamilyen termék ami nagyon jó',
                gross: 18990,
                net: 15735,
                VAT: 27,
                currency: 'Ft',
                ID: '23',
            },
        ],
        history: [],
    },
];
