import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const blockedContacts = gql`
    query {
        blockedContacts {
            id
            customer {
                id
                email
                details {
                    firstname
                    lastname
                }
            }
            reason {
                id
                name
            }
            blocker {
                firstname
                lastname
            }
            comment
            created_at
        }
    }
`;

export interface BlockedContact {
    id: number;
    customer: {
        id: number;
        email: string;
        details: {
            firstname: string;
            lastname: string;
        }[];
    };
    reason: {
        id: number;
        name: string;
    };
    blocker: {
        firstname: string;
        lastname: string;
    };
    comment: string;
    created_at: string;
}

const useBlockedContacts = queryFactory<BlockedContact[]>(blockedContacts, 'blockedContacts');

export default useBlockedContacts;
