import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const updateEmailAccount = gql`
    mutation updateEmailAccount($id: Int!, $data: EmailAccountInputUpdate!) {
        updateEmailAccount(id: $id, data: $data) {
            id
        }
    }
`;

interface EmailAccount {
    id: number;
    username: string;
    displayed_name: string;
}

const useUpdateEmailAccount = mutationFactory<EmailAccount>(updateEmailAccount, 'updateEmailAccount');

export default useUpdateEmailAccount;
