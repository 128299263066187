import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const deliveries = gql`
    query deliveries {
        deliveries {
            id
            name
            phone
            zip
            city
            address
            date
            comment
            installment {
                gross
            }
            comment
            ordered_products {
                product {
                    name
                }
                order {
                    customer {
                        firstname
                        lastname
                    }
                }
            }
            installment {
                gross
            }
        }
    }
`;

export interface Delivery {
    id: number;
    name: string;
    phone: string;
    zip: string;
    city: string;
    address: string;
    date: string;
    comment: string;
    ordered_products: {
        product: {
            name: string;
        };
        order: {
            customer: {
                firstname: string;
                lastname: string;
            };
        };
    }[];
    installment: {
        gross: number;
    };
}

const useDeliveries = queryFactory<Delivery[]>(deliveries, 'deliveries');

export default useDeliveries;
