/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import NavDropdownProps from './NavDropdownProps';
import NavDropdownState from './NavDropdownState';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { v4 } from 'uuid';
import IRoute from '../../utils/IRoute';

import { Link } from 'react-router-dom';

class NavDropdown extends React.Component<NavDropdownProps, NavDropdownState> {
    constructor(props: NavDropdownProps) {
        super(props);

        this.state = {
            anchorEl: null,
        };

        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    render(): React.ReactNode {
        return (
            <Box sx={{ flexGrow: 0, alignSelf: 'center' }}>
                {this.props.insideDropdown && (
                    <MenuItem onClick={this.handleOpen}>
                        <Typography textAlign="center">{this.props.menu.name}</Typography>
                        <KeyboardArrowDownIcon />
                    </MenuItem>
                )}
                {!this.props.insideDropdown && (
                    <Button onClick={this.handleOpen} sx={{ color: 'white' }} endIcon={<KeyboardArrowDownIcon />}>
                        {this.props.menu.name}
                    </Button>
                )}
                <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                >
                    {this.props.menu.sub.map((subMenu: IRoute) => (
                        <Link css={linkStyles} to={subMenu.to} key={v4()}>
                            <MenuItem onClick={this.handleClose}>{subMenu.name}</MenuItem>
                        </Link>
                    ))}
                </Menu>
            </Box>
        );
    }

    handleOpen(event: React.MouseEvent<HTMLElement>): void {
        this.setState({ anchorEl: event.currentTarget });
    }

    handleClose(): void {
        this.setState({ anchorEl: null });
        if (this.props.handleCloseNavMenu) this.props.handleCloseNavMenu();
    }
}

const linkStyles = css`
    color: black;
    text-decoration: none;
`;

export default NavDropdown;
