import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const productPricesQuery = gql`
    query productPrices($product: Int!) {
        productPrices(product: $product) {
            id
            net
            gross
            type {
                id
                name
                description
            }
            campaign {
                id
                name
            }
        }
    }
`;

interface PriceType {
    id: number;
    name: string;
    description: string;
}

interface Campaign {
    id: number;
    name: string;
}

interface ProductPrice {
    id: number;
    net: number;
    gross: number;
    type: PriceType;
    campaign: Campaign;
}

const useProductPricesQuery = queryFactory<ProductPrice[]>(productPricesQuery, 'productPrices');

export type { ProductPrice };
export default useProductPricesQuery;
