import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const freebie = gql`
    query freebie($id: Int!) {
        freebie(id: $id) {
            id
            name
            description
            webinar_id
            subscribers
            created_at
        }
    }
`;

type Freebie = {
    id: number;
    name: string;
    description: string | null;
    webinar_id: number | null;
    subscribers: number;
    created_at: string;
};

const useFreebieQuery = queryFactory<Freebie>(freebie, 'freebie');

export type { Freebie as Freeby };
export default useFreebieQuery;
