import { gql } from '@apollo/client';
import { mutationFactory } from '../../../../utils/gqlFactories';

const updateSalesPopupListContact = gql`
    mutation updateSalesPopupListContact($id: Int!, $data: SalesPopupListContactInputUpdate!) {
        updateSalesPopupListContact(id: $id, data: $data) {
            id
        }
    }
`;

const useUpdateSalesPopupListContact = mutationFactory<{ id: number }>(updateSalesPopupListContact, 'updateSalesPopupListContact');

export default useUpdateSalesPopupListContact;
