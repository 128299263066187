/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Paper, Typography } from '@mui/material';
import { Section } from '../../components/Section';
import { useParams } from 'react-router-dom';
import useSentEmailQuery from './gql/sentEmail';

const SentEmailPage: React.FC = () => {
    const params = useParams();
    const id = parseInt(params.id as string);
    const SentEmail = useSentEmailQuery({ id: id });

    const emailTemplatePLaceholderMarkup = (): { __html: string } => {
        let html = SentEmail.data?.template.content;

        if (SentEmail.data) {
            for (const ph of SentEmail.data?.substituted_placeholders) {
                html = html?.replace(`{{${ph.name}}}`, ph.value);
            }
        }

        return SentEmail.data && html ? { __html: html } : { __html: 'Nem sikerült betölteni az előnézetet.' };
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`Elküldött üzenet: #${id}`}>
                <div css={sectionStyles}>
                    <Typography variant="h5" component="div">
                        <span css={textStyles}>Tárgy: </span>
                        {SentEmail.data && `${SentEmail.data.template.subject}`}
                    </Typography>
                    <div css={dataTextStyles}>
                        <Typography variant="h6" component="div">
                            <span css={textStyles}>Feladó: </span>
                            {SentEmail.data && `${SentEmail.data.template.sender_name} <${SentEmail.data.template.sender_email}>`}
                        </Typography>
                        <Typography variant="h6" component="div">
                            <span css={textStyles}>Címzett: </span>
                            {SentEmail.data && `${'valami majd a szerverről?'}`}
                        </Typography>
                    </div>
                    <div dangerouslySetInnerHTML={emailTemplatePLaceholderMarkup()} css={templatePreviewStyles} />
                </div>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;

    & > * + * {
        margin-top: 20px !important;
    }
`;

const templatePreviewStyles = css`
    overflow-x: scroll;
    background-color: white;
    filter: drop-shadow(0 0.1rem 0.25rem #999);
`;

const textStyles = css`
    color: #0288d1;
`;

const dataTextStyles = css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 960px) {
        flex-direction: column;
    }
`;

export default SentEmailPage;
