import IRoute from './IRoute';

const routes: IRoute[] = [
    {
        to: '/orders',
        name: 'Rendelések',
        sub: [
            {
                to: '/orders/create-order',
                name: 'Rendelés létrehozása',
                sub: [],
            },
            {
                to: '/orders/list',
                name: 'Rendelések',
                sub: [],
            },
            {
                to: '/orders/expected-orders/create',
                name: 'Várható rendelés létrehozása',
                sub: [],
            },
            {
                to: '/orders/expected-orders/list',
                name: 'Várható rendelések',
                sub: [],
            },
            {
                to: '/orders/awaiting-approval',
                name: 'Jóváhagyásra vár',
                sub: [],
            },
        ],
    },
    {
        to: '/finances',
        name: 'Pénzügy',
        sub: [
            {
                to: '/finances/expected-transactions',
                name: 'Várható utalások',
                sub: [],
            },
            {
                to: '/finances/late-transactions',
                name: 'Késett utalások',
                sub: [],
            },
        ],
    },
    {
        to: '/shipping',
        name: 'Szállítás',
        sub: [
            {
                to: '/shipping/cod',
                name: 'Mai utánvét',
                sub: [],
            },
            {
                to: '/shipping/list',
                name: 'Szállítások',
                sub: [],
            },
        ],
    },
    {
        to: '/customers',
        name: 'Ügyfelek',
        sub: [],
    },
    {
        to: '/marketing',
        name: 'Marketing',
        sub: [
            {
                to: '/marketing/campaigns',
                name: 'Kampányok',
                sub: [],
            },
            {
                to: '/marketing/campaign/create',
                name: 'Kampány létrehozás',
                sub: [],
            },
            {
                to: '/marketing/free-content',
                name: 'Ingyenes anyagok',
                sub: [],
            },
            {
                to: '/marketing/tags',
                name: 'Tagek',
                sub: [],
            },
        ],
    },
    {
        to: '/products',
        name: 'Termékek',
        sub: [
            {
                to: '/products/list',
                name: 'Termék lista',
                sub: [],
            },
            {
                to: '/products/categories',
                name: 'Kategória lista',
                sub: [],
            },
        ],
    },
    {
        to: '/telesales',
        name: 'Telesales',
        sub: [
            {
                to: '/telesales/my-lists',
                name: 'Saját listák',
                sub: [],
            },
            {
                to: '/telesales/lists',
                name: 'Listák',
                sub: [],
            },
            {
                to: '/telesales/lists/create',
                name: 'Új lista',
                sub: [],
            },
            {
                to: '/telesales/states',
                name: 'Állapotok',
                sub: [],
            },
            {
                to: '/telesales/characteristics',
                name: 'Jellemzők',
                sub: [],
            },
            {
                to: '/telesales/blocked-contacts',
                name: 'Blokkolt ügyfelek',
                sub: [],
            },
            {
                to: '/telesales/my-performance',
                name: 'Teljesítményem',
                sub: [],
            },
        ],
    },
    {
        to: '/working-hours',
        name: 'Munkaidő',
        sub: [
            {
                to: '/working-hours/list',
                name: 'Nyilvántartás',
                sub: [],
            },
            {
                to: '/working-hours/record',
                name: 'Munkaidő regisztrálása',
                sub: [],
            },
            {
                to: '/working-hours/holidays',
                name: 'Ünnepnapok',
                sub: [],
            },
            {
                to: '/working-hours/altered-work-schedule',
                name: 'Módosított munkarend',
                sub: [],
            },
            {
                to: '/working-hours/absence',
                name: 'Távollét',
                sub: [],
            },
        ],
    },
    {
        to: '/emails',
        name: 'Levelek',
        sub: [
            {
                to: '/emails/templates',
                name: 'E-mail sablonok',
                sub: [],
            },
            {
                to: '/emails/automatic',
                name: 'Automatikus e-mail',
                sub: [],
            },
            {
                to: '/emails/sent',
                name: 'Elküldött e-mail-ek',
                sub: [],
            },
            {
                to: '/emails/accounts',
                name: 'Fiókok',
                sub: [],
            },
        ],
    },
    {
        to: '/settings',
        name: 'Beállítások',
        sub: [
            {
                to: '/settings/accounts',
                name: 'Fiók kezelés',
                sub: [],
            },
            {
                to: '/settings/day-closings',
                name: 'Nap lezárások',
                sub: [],
            },
        ],
    },
];

export default routes;
