/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import SectionProps from './SectionProps';
import SectionState from './SectionState';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

class Section extends React.Component<SectionProps, SectionState> {
    constructor(props: SectionProps) {
        super(props);

        this.state = {
            collapsed: false,
        };
    }

    render(): React.ReactNode {
        return (
            <Paper elevation={2} className={this.props.className}>
                <div css={headStyles} onClick={this.toggleCollapse}>
                    <Typography variant="h5">{this.props.title}</Typography>
                    {this.renderArrow()}
                </div>
                {this.renderBody()}
            </Paper>
        );
    }

    renderArrow(): React.ReactNode {
        if (this.props.collapsible) {
            if (this.state.collapsed) {
                return <KeyboardArrowDownIcon />;
            } else {
                return <KeyboardArrowUpIcon />;
            }
        } else {
            return null;
        }
    }

    renderBody(): React.ReactNode {
        if (!this.props.collapsible) {
            return <div css={bodyStyles}>{this.props.children}</div>;
        } else {
            if (!this.state.collapsed) {
                return <div css={bodyStyles}>{this.props.children}</div>;
            } else {
                return null;
            }
        }
    }

    // HANDLERS
    toggleCollapse = (): void => {
        this.setState(
            (prevState: SectionState): SectionState => {
                return {
                    collapsed: !prevState.collapsed,
                };
            },
        );
    };
}

const headStyles = css`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #ffffff;
    background-color: #0288d1;
    padding: 10px;
    box-sizing: border-box;
`;

const bodyStyles = css`
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`;

export default Section;
