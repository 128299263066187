import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const createUserMutation = gql`
    mutation createUser($data: UserInputCreate!) {
        createUser(data: $data) {
            id
        }
    }
`;

interface User {
    id: number;
}

const useCreateUserMutation = mutationFactory<User>(createUserMutation, 'createUser');

export default useCreateUserMutation;
