import React from 'react';
import Product, { ProductProps } from '../Product/Product';

const MemorizedProduct = (props: ProductProps) => {
    const installmentHash = props.product.installments.map((i) => `${i.id}|${i.gross}|${i.payment_method.id}|${i.payment_method.name}|${i.due_date}`).join('@');
    return React.useMemo(
        () => (
            <Product
                product={props.product}
                setSelectedProductId={props.setSelectedProductId}
                isModalOpen={props.isModalOpen}
                setIsModalOpen={props.setIsModalOpen}
                currencies={props.currencies}
                paymentMethods={props.paymentMethods}
                dispatch={props.dispatch}
            />
        ),

        [props.product.gross, props.product.net, props.product.priceId, props.product.priceType, installmentHash],
    );
};

export default MemorizedProduct;
