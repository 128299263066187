/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';

import { SubSection } from '../../../components/SubSection';
import CustomerFilter from './CustomerFilter/CustomerFilter';
import { CustomerDetails } from '../gql/customerDetails';
import useErrorHandler from '../../../utils/userErrorHandler';
import { NewOrderContext } from '../../../contexts/NewOrderContext';
import { NewOrderArguments } from '../NewOrderPage';
import useCustomersLazyQuery from '../gql/customers';
import { useSnackbar } from 'notistack';
import useCustomerDetailLazyQuery from '../gql/customerDetail';

type CustomerDataProps = {
    sendData: (data: NewOrderArguments) => void;
};

const CustomerData: React.FC<CustomerDataProps> = (props) => {
    const params = useParams();
    const customerId = params.customerId as string;

    const { enqueueSnackbar } = useSnackbar();

    const [customers, customersResp] = useCustomersLazyQuery();
    const [paramCustomer, paramCustomerResp] = useCustomerDetailLazyQuery();

    const { handleContextValueChange } = useContext(NewOrderContext);

    useErrorHandler(customersResp.error, paramCustomerResp.error);

    const [isNewCustomer, setIsNewCustomer] = useState<boolean>(false);
    const [searchEmail, setSearchEmail] = useState<string>('');
    const [open, setOpen] = useState(false);
    const [customerDetails, setCustomerDetails] = useState<CustomerDetails[]>([]);

    const [selected, setSelected] = useState<CustomerDetails[]>([]);
    const [first_name, setFirst_name] = useState<string>('');
    const [last_name, setLast_name] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');

    useEffect(() => {
        if (customerId) {
            paramCustomer({ id: parseInt(customerId) });
        }
    }, [customerId]);

    useEffect(() => {
        if (paramCustomerResp.data) {
            setSelected([paramCustomerResp.data]);
        }
    }, [paramCustomerResp.data]);

    useEffect(() => {
        if (!customerId) {
            setCustomerDetails([]);
            setSelected([]);
            if (searchEmail !== '') {
                const timer = setTimeout(() => {
                    customers({ email: searchEmail });
                }, 500);

                return () => clearTimeout(timer);
            } else if (searchEmail === '') {
                setCustomerDetails([]);
                setSelected([]);
            }
        }
    }, [searchEmail]);

    useEffect(() => {
        if (customersResp.data) {
            setCustomerDetails(customersResp.data.map((customer) => customer.details).flat());
        }
        customersResp.data?.length === 0 && searchEmail.length > 0 && enqueueSnackbar('Nincs egy találat sem!', { variant: 'warning' });
    }, [customersResp.data]);

    useEffect(() => {
        if (customerDetails.length > 0) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [customerDetails]);

    useEffect(() => {
        if (!isNewCustomer && selected.length > 0) {
            props.sendData({ type: 'customerData', id: selected[0].customer.id, details: { firstname: first_name, lastname: last_name, phone: phone } });
        } else {
            props.sendData({ type: 'customerData', email: email, details: { firstname: first_name, lastname: last_name, phone: phone } });
        }
    }, [first_name, last_name, email, phone, selected, isNewCustomer]);

    useEffect(() => {
        if (!isNewCustomer && selected.length > 0) {
            setFirst_name(selected[0].firstname);
            setLast_name(selected[0].lastname);
            setEmail(selected[0].customer.email);
            setPhone(selected[0].phone ? selected[0].phone : '');
            handleContextValueChange('existingCustomerId', selected[0].id);
        } else {
            setFirst_name('');
            setLast_name('');
            setEmail('');
            setPhone('');
            handleContextValueChange('existingCustomerId', null);
        }
    }, [selected, isNewCustomer]);

    useEffect(() => {
        handleContextValueChange('name', first_name || last_name ? `${last_name} ${first_name}` : '');
        handleContextValueChange('phone', phone ? phone : '');
    }, [first_name, last_name, phone]);

    const handleInputTypeChange = (_event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
        if (newAlignment === 'new') {
            setIsNewCustomer(true);
            setFirst_name('');
            setLast_name('');
            setEmail('');
            setPhone('');
        }
        if (newAlignment === 'existing') {
            setIsNewCustomer(false);
            if (selected.length > 0) {
                setFirst_name(selected[0].firstname);
                setLast_name(selected[0].lastname);
                setEmail(selected[0].customer.email);
                setPhone(selected[0].phone ? selected[0].phone : '');
            }
        }
    };

    const swapNames = () => {
        const fname = first_name;
        const lname = last_name;
        setFirst_name(lname);
        setLast_name(fname);
    };

    return (
        <SubSection title="Vásárlói adatok">
            <div css={inputsOuterContainerStyles}>
                <div css={inputsContainerStyles}>
                    <ToggleButtonGroup color="primary" value={isNewCustomer ? 'new' : 'existing'} exclusive onChange={handleInputTypeChange} size="small" disabled={customerId ? true : false}>
                        <ToggleButton value="existing">Visszatérő vásárló</ToggleButton>
                        <ToggleButton value="new">Új vásárló</ToggleButton>
                    </ToggleButtonGroup>
                    {!customerId && !isNewCustomer && <CustomerFilter selected={selected} setSelected={setSelected} options={customerDetails} search={searchEmail} setSearch={setSearchEmail} open={open} setOpen={setOpen} />}

                    <TextField id="last_name" label="Vezetéknév *" value={last_name} onChange={(e) => setLast_name(e.target.value)} />
                    <Button onClick={swapNames}>
                        <AutorenewIcon />
                    </Button>
                    <TextField id="first_name" label="Keresztnév *" value={first_name} onChange={(e) => setFirst_name(e.target.value)} />
                </div>
                <div css={inputsContainerStyles}>
                    <TextField id="email" label="Email *" value={email} onChange={(e) => setEmail(e.target.value)} disabled={!isNewCustomer} />
                    <TextField id="phone" label="Telefonszám *" value={phone} onChange={(e) => setPhone(e.target.value)} />
                </div>
            </div>
        </SubSection>
    );
};

const inputsOuterContainerStyles = css`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const inputsContainerStyles = css`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export default CustomerData;
