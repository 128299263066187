import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const login = gql`
    mutation login($data: LoginInput!) {
        login(data: $data)
    }
`;

const useLogin = mutationFactory<string>(login, 'login');

export default useLogin;
