/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Section } from '../../../components/Section';

const Filters: React.FC = () => {
    return (
        <Section title="Szabályok">
            <div css={sectionStyles}>
                <p>Zoli stuff : )</p>
            </div>
        </Section>
    );
};

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;

    & > * + * {
        margin-top: 20px !important;
    }
`;

export default Filters;
