import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const paymentMethodsQuery = gql`
    query {
        paymentMethods {
            id
            name
        }
    }
`;

interface PaymentMethod {
    id: number;
    name: string;
}

const usePaymentMethodsQuery = queryFactory<PaymentMethod[]>(paymentMethodsQuery, 'paymentMethods');

export type { PaymentMethod };
export default usePaymentMethodsQuery;
