/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import useErrorHandler from '../../../utils/userErrorHandler';
import useProductPricesQuery, { ProductPrice } from '../gql/productPrices';

type FallbackSelectionProps = {
    id: string;
    selections: number | null;
    setSelections: React.Dispatch<React.SetStateAction<number | null>>;
};

const FallbackSelection: React.FC<FallbackSelectionProps> = (props) => {
    const pricesResp = useProductPricesQuery({ product: parseInt(props.id) });

    useErrorHandler(pricesResp.error);

    const [value, setValue] = useState<string>('Nincs megadva');

    useEffect(() => {
        if (pricesResp.data) handleValue();
    }, [pricesResp.data]);

    const handleChange = (selectedValue: string | null): void => {
        if (selectedValue) {
            const value = selectedValue.split(',', 1)[0];
            const selected = pricesResp.data?.find((price) => price.type.name === value);
            selected ? props.setSelections(selected.id) : props.setSelections(null);
            if (selected) {
                setValue(`${selected.type.name}, nettó: ${selected.net} Ft / bruttó: ${selected.gross} Ft (#id:${selected.id})`);
            } else {
                setValue('Nincs megadva');
            }
        }
    };

    const handleValue = () => {
        const selected = pricesResp.data?.find((price) => price.id === props.selections);
        if (selected) {
            setValue(`${selected.type.name}, nettó: ${selected.net} Ft / bruttó: ${selected.gross} Ft (#id:${selected.id})`);
        } else {
            setValue('Nincs megadva');
        }
    };

    return (
        <Autocomplete
            options={
                pricesResp.data
                    ? [
                          'Nincs megadva',
                          ...pricesResp.data.map((data: ProductPrice) => {
                              return `${data.type.name}, nettó: ${data.net} Ft / bruttó: ${data.gross} Ft (#id:${data.id})`;
                          }),
                      ]
                    : []
            }
            value={value}
            renderInput={(params) => <TextField {...params} label="Visszatérési ár *" placeholder="Visszatérési ár" />}
            onChange={(_event, value) => {
                handleChange(value);
            }}
            css={inputsStyles}
        />
    );
};

const inputsStyles = css`
    width: 100%;
`;

export default FallbackSelection;
