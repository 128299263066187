import { gql } from '@apollo/client';
import { mutationFactory } from '../../../../utils/gqlFactories';

const moveAssignedContacts = gql`
    mutation moveAssignedContacts($tsUser: Int!, $assignedContacts: [Int]!) {
        moveAssignedContacts(tsUser: $tsUser, assignedContacts: $assignedContacts)
    }
`;

const useMoveAssignedContacts = mutationFactory<number>(moveAssignedContacts, 'moveAssignedContacts');

export default useMoveAssignedContacts;
