import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const emailAccounts = gql`
    query {
        emailAccounts {
            id
            host
            port
            secure
            username
            displayed_name
        }
    }
`;

interface EmailAccount {
    id: number;
    host: string;
    port: string;
    secure: boolean;
    username: string;
    displayed_name: string;
}

const useEmailAccounts = queryFactory<EmailAccount[]>(emailAccounts, 'emailAccounts');

export type { EmailAccount };
export default useEmailAccounts;
