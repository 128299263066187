/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Section } from '../../components/Section';
import { Paper, TextField, Button } from '@mui/material';
import useErrorHandler from '../../utils/userErrorHandler';
import { useSnackbar } from 'notistack';
import useFreebieQuery from '../FreeContentDetailsPage/gql/freebie';
import useUpdateFreebieMutation from './gql/updateFreebie';

const FreeContentEditPage: React.FC = () => {
    const params = useParams();
    const id = params.id as string;
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const freeContentResp = useFreebieQuery({ id: parseInt(id) });
    const [updateFreeContent, updatedFreeContentResp] = useUpdateFreebieMutation();

    useErrorHandler(freeContentResp.error, updatedFreeContentResp.error);

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [webinar_id, setWebinar_id] = useState('');

    useEffect(() => {
        if (updatedFreeContentResp.data) {
            navigate('/marketing/free-content');
        }
    }, [updatedFreeContentResp.data]);

    useEffect(() => {
        if (freeContentResp.data) {
            setName(freeContentResp.data.name);
            setDescription(freeContentResp.data.description ? freeContentResp.data.description : '');
            setWebinar_id(freeContentResp.data.webinar_id ? freeContentResp.data.webinar_id.toString() : '');
        }
    }, [freeContentResp.data]);

    const handleNumberChange = (e: string) => {
        let input = e;

        if (e.match(/\D/)) input = input.slice(0, -1);
        if (input.length < 21) setWebinar_id(input);
    };

    const handleEdit = () => {
        if (name !== '') {
            updateFreeContent({ id: parseInt(id), data: { name, description, webinar_id: webinar_id ? parseInt(webinar_id) : null } });
        } else {
            enqueueSnackbar('A csillaggal jelölt mezők kitöltése kötelező!', { variant: 'error' });
        }
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`#${id}: Ingyenes anyag szerkesztése`}>
                <div css={sectionStyles}>
                    <TextField label="Név" placeholder="Az ingyenes anyag neve" variant="outlined" value={name} onChange={(e) => setName(e.target.value)} required />
                    <TextField label="Leírás" placeholder="Az ingyenes anyag leírása" variant="outlined" value={description} onChange={(e) => setDescription(e.target.value)} multiline rows={5} />
                    <TextField label="Webinár azonisító" placeholder="Az ingyenes anyag Webinár azonisítója" variant="outlined" value={webinar_id} onChange={(e) => handleNumberChange(e.target.value)} />

                    <div style={{ marginLeft: 'auto' }}>
                        <Button variant="contained" color="success" size="large" onClick={handleEdit} style={{ marginLeft: 'auto' }}>
                            Módosít
                        </Button>
                    </div>
                </div>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;

    & > * + * {
        margin-top: 20px !important;
    }
`;

export default FreeContentEditPage;
