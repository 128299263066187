import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const myStuckOrderLists = gql`
    query myStuckOrderLists($lastNDays: Int!) {
        myStuckOrderLists(lastNDays: $lastNDays) {
            id
            created_at
        }
    }
`;

const useMyStuckOrderLists = queryFactory<{ id: number; created_at: string }[]>(myStuckOrderLists, 'myStuckOrderLists');

export default useMyStuckOrderLists;
