import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const addProductToPackageMutation = gql`
    mutation addProductToPackage($package: Int!, $subproduct: Int!) {
        addProductToPackage(package: $package, subproduct: $subproduct) {
            id
        }
    }
`;

interface Product {
    id: number;
}

const useAddProductToPackageMutation = mutationFactory<Product>(addProductToPackageMutation, 'addProductToPackage');

export default useAddProductToPackageMutation;
