import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const updatePriceMutation = gql`
    mutation updatePrice($id: Int!, $data: PriceInputUpdate!) {
        updatePrice(id: $id, data: $data) {
            id
        }
    }
`;

interface UpdatedPrice {
    id: number;
}

const useUpdatePriceMutation = mutationFactory<UpdatedPrice>(updatePriceMutation, 'updatePrice');

export type { UpdatedPrice };
export default useUpdatePriceMutation;
