import { gql, OperationVariables } from '@apollo/client';
import { useQuery } from '@apollo/client';

const myExpectedOrders = gql`
    query myExpectedOrders($year: Int!, $month: Int!, $quarter: Int!) {
        monthly: myMonthlyExpectedOrderConversionRate(year: $year, month: $month) {
            expectedOrders
            orders
        }

        quarterly: myQuarterlyExpectedOrderConversionRate(year: $year, quarter: $quarter) {
            expectedOrders
            orders
        }
    }
`;

export interface ExpectedOrdersConversion {
    monthly: {
        expectedOrders: number;
        orders: number;
    };
    quarterly: {
        expectedOrders: number;
        orders: number;
    };
}

const useMyExpectedOrders = (args?: unknown) => {
    const res = useQuery<ExpectedOrdersConversion>(myExpectedOrders, { variables: args as OperationVariables | undefined, fetchPolicy: 'network-only', errorPolicy: 'all' });
    return res;
};

export default useMyExpectedOrders;
