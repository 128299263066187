/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import NumberFormat from 'react-number-format';

import ProductDataProps from './ProductDataProps';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { SubSubSection } from '../../../components/SubSubSection';

class ProductData extends React.Component<ProductDataProps> {
    render(): React.ReactNode {
        return (
            <SubSubSection title="Termék adatok">
                <Table aria-label="table">
                    <TableBody>
                        <TableRow css={trStyle}>
                            <TableCell css={tdStyle}>Termék ID</TableCell>
                            <TableCell>#{this.props.data.product.id}</TableCell>
                        </TableRow>
                        <TableRow css={trStyle}>
                            <TableCell>Bruttó ár</TableCell>
                            <TableCell>
                                <NumberFormat value={this.props.data.price.gross} thousandSeparator={' '} suffix={` Ft`} displayType="text" />
                            </TableCell>
                        </TableRow>
                        <TableRow css={trStyle}>
                            <TableCell>Nettó ár</TableCell>
                            <TableCell>
                                <NumberFormat value={this.props.data.price.net} thousandSeparator={' '} suffix={` Ft`} displayType="text" />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </SubSubSection>
        );
    }
}

const trStyle = css`
    &: nth-of-type(odd) {
        background-color: #e3f2fd;
    }

    &:last-child td,
    &:last-child th {
        border: 0;
    }
`;

const tdStyle = css`
    width: 50%;
`;

export default ProductData;
