import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const deleteOffDayMutation = gql`
    mutation deleteOffDay($id: Int!) {
        deleteOffDay(id: $id)
    }
`;

const useDeleteOffDayMutation = mutationFactory(deleteOffDayMutation, 'deleteOffDay');

export default useDeleteOffDayMutation;
