import { gql } from '@apollo/client';
import { queryFactory } from '../../../../utils/gqlFactories';

const listFilterValues = gql`
    query listFilterValues($filterType: Int!) {
        listFilterValues(filterType: $filterType) {
            id
            name
        }
    }
`;

const useListFilterValues = queryFactory<{ id: string; name: string }[]>(listFilterValues, 'listFilterValues');

export default useListFilterValues;
