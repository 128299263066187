/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { Requireable } from 'react';
import PropTypes from 'prop-types';

import SubSubSectionProps from './SubSubSectionProps';
import SubSubSectionState from './SubSubSectionState';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

class SubSubSection extends React.Component<SubSubSectionProps, SubSubSectionState> {
    static propTypes: {
        children: Requireable<unknown>;
        title: Requireable<string>;
    };

    constructor(props: SubSubSectionProps) {
        super(props);

        this.state = {
            collapsed: false,
        };
    }

    render(): React.ReactNode {
        return (
            <Paper css={containerStyles} style={{ ...this.props.style }} elevation={2}>
                <div css={headStyles} onClick={this.toggleCollapse}>
                    <Typography variant="subtitle2">{this.props.title}</Typography>
                    {this.renderArrow()}
                </div>
                {this.renderBody()}
            </Paper>
        );
    }

    renderArrow(): React.ReactNode {
        if (this.props.collapsible) {
            if (this.state.collapsed) {
                return <KeyboardArrowDownIcon />;
            } else {
                return <KeyboardArrowUpIcon />;
            }
        } else {
            return null;
        }
    }

    renderBody(): React.ReactNode {
        if (!this.state.collapsed) {
            return <div css={bodyStyles}>{this.props.children}</div>;
        } else {
            return null;
        }
    }

    // HANDLERS
    toggleCollapse = (): void => {
        this.setState(
            (prevState: SubSubSectionState): SubSubSectionState => {
                return {
                    collapsed: !prevState.collapsed,
                };
            },
        );
    };
}

const containerStyles = css`
    width: 100%;
`;

const headStyles = css`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #ffffff;
    background-color: #4fc3f7;
    padding: 10px;
    box-sizing: border-box;
`;

const bodyStyles = css`
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`;

SubSubSection.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
};

export default SubSubSection;
