import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const users = gql`
    query {
        users {
            id
            username
            firstname
            lastname
            middlename
            active
            permission {
                name
            }
        }
    }
`;

type User = {
    id: number;
    username: string;
    firstname: string;
    lastname: string;
    middlename: string;
    active: boolean;
    permisison: { id: number; name: string };
};

const useUsers = queryFactory<User[]>(users, 'users');

export default useUsers;
