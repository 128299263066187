import { gql } from '@apollo/client';
import { lazyQueryFactory } from '../../../utils/gqlFactories';

const customerDetailsQuery = gql`
    query customerDetails($customer: Int) {
        customerDetails(customer: $customer, hasBillingData: true) {
            id
            billing_country
            billing_zip
            billing_city
            billing_address
            billing_name
            phone
            tax_number
        }
    }
`;

interface BillingDetails {
    id: number;
    billing_country: string;
    billing_zip: string;
    billing_city: string;
    billing_address: string;
    billing_name: string;
    phone: string;
    tax_number: string;
}

const useSelectedCustomerDetailsQuery = lazyQueryFactory<BillingDetails[]>(customerDetailsQuery, 'customerDetails');

export type { BillingDetails };
export default useSelectedCustomerDetailsQuery;
