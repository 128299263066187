function formatDatetime(date: string): string {
    return new Date(parseInt(date)).toLocaleString('HU-hu').replace(/(\d{4})\.\s(\d{2})\.\s(\d{2})\.\s(\d\d?:\d\d:\d\d)/, (_, g1, g2, g3, g4) => `${g1}-${g2}-${g3} ${g4}`);
}

function formatDate(date: string): string {
    return new Date(parseInt(date)).toLocaleDateString('HU-hu').replace(/\.\s?/g, (m) => (m === '.' ? '' : '-'));
}

export { formatDate };
export default formatDatetime;
