/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Section } from '../../components/Section';
import { Paper, TextField, Button } from '@mui/material';
import useErrorHandler from '../../utils/userErrorHandler';
import useCreateAcTagMutation from './gql/createAcTag';

const TagCreatePage: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [createAcTag, createdAcTagResp] = useCreateAcTagMutation();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    useErrorHandler(createdAcTagResp.error);

    useEffect(() => {
        if (createdAcTagResp.data) {
            navigate('/marketing/tags');
        }
    }, [createdAcTagResp.data]);

    const handleCreate = () => {
        if (name !== '' && description !== '') {
            createAcTag({ data: { name, description } });
        } else {
            enqueueSnackbar('A csillaggal jelölt mezők kitöltése kötelező!', { variant: 'error' });
        }
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`Új tag létrehozása`}>
                <div css={sectionStyles}>
                    <TextField label="Név" placeholder="A tag neve" variant="outlined" value={name} onChange={(e) => setName(e.target.value)} required />
                    <TextField label="Leírás" placeholder="A tag leírása" variant="outlined" value={description} onChange={(e) => setDescription(e.target.value)} multiline rows={5} required />

                    <div style={{ marginLeft: 'auto' }}>
                        <Button variant="contained" color="success" size="large" onClick={handleCreate} style={{ marginLeft: 'auto' }}>
                            Létrehozás
                        </Button>
                    </div>
                </div>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;

    & > * + * {
        margin-top: 20px !important;
    }
`;

export default TagCreatePage;
