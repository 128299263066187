import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const createDayClosing = gql`
    mutation createDayClosing($data: DayClosingInput!) {
        createDayClosing(data: $data) {
            id
        }
    }
`;

const useCreateDayClosing = mutationFactory<{ id: number }>(createDayClosing, 'createDayClosing');

export default useCreateDayClosing;
