import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const list = gql`
    query list($id: Int!) {
        list(id: $id) {
            id
            name
            description
            contacts
            dynamic
            frequency
            ttl
            created_at
            updated_at
            creator {
                firstname
                lastname
            }
        }
    }
`;

export interface List {
    id: number;
    name: string;
    description: string;
    contacts: number;
    dynamic: boolean;
    frequency: number;
    ttl: number;
    created_at: string;
    updated_at: string;
    creator: {
        firstname: string;
        lastname: string;
    };
}

const useList = queryFactory<List>(list, 'list');

export default useList;
