import { gql } from '@apollo/client';
import { mutationFactory } from '../../../../utils/gqlFactories';

const updateCustomerNote = gql`
    mutation updateCustomerNote($id: Int!, $data: CustomerNoteInputUpdate!) {
        updateCustomerNote(id: $id, data: $data) {
            id
        }
    }
`;

const useUpdateCustomerNote = mutationFactory<{ id: number }>(updateCustomerNote, 'updateCustomerNote');

export default useUpdateCustomerNote;
