/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useContext, useState, useEffect } from 'react';
import { BlockType, ListContext, ListContextType, OperatorType } from '../../contexts/ListContext';
import { Paper, Button, TextField, FormControlLabel, Checkbox } from '@mui/material';
import FilterBlock from './FilterBlock/FilterBlock';
import { Section } from '../../components/Section';
import Operator from './Operator/Operator';
import FilterBlockLoadDialog from './FilterBlockLoadDialog/FilterBlockLoadDialog';
import useCreateList from './gql/createList';
import useErrorHandler from '../../utils/userErrorHandler';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

type ConvertedBlock = {
    operators: number[];
    filters: {
        type: number;
        list?: number;
        email?: number;
        sms?: number;
        tag?: number;
        product?: number;
        negation: boolean;
        from: Date;
        to: Date;
    }[];
};

const ListCreatePage: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [createList, createdList] = useCreateList();
    const { content, blocks, operators, typeMap, addNewBlock } = useContext(ListContext) as ListContextType;
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [dynamic, setDynamic] = useState(false);
    const [frequency, setFrequency] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    useErrorHandler(createdList.error);

    useEffect(() => {
        if (createdList.data) {
            navigate('/telesales/lists');
            enqueueSnackbar('A lista sikeresen létrehozva!', { variant: 'success' });
        }
    }, [createdList.data]);

    const saveList = () => {
        const convertedBlockOperators = operators.map((operator) => (operator.name === 'ÉS' ? 1 : 2));
        const convertedBlocks = blocks.map((block) => {
            const convertedOperators = block.operators.map((operator) => (operator.name === 'ÉS' ? 1 : 2));
            const convertedFilters = block.filters.map((filter) => {
                const [type, id] = filter.valueID.split('-');
                const filterIDs = { type: Number(type) };
                filterIDs[typeMap[type as keyof typeof typeMap] as keyof typeof filterIDs] = Number(id);

                return {
                    ...filterIDs,
                    negation: filter.negation,
                    from: filter.from,
                    to: filter.to,
                };
            });

            const convertedBlock: ConvertedBlock = {
                operators: block.operators.length > 0 ? convertedOperators : [],
                filters: convertedFilters,
            };

            return convertedBlock;
        });

        const listToSave = {
            name: name,
            description: description,
            dynamic: dynamic,
            frequency: frequency === 0 ? undefined : frequency,
            ttl: duration === 0 ? undefined : duration,
            operators: operators.length > 0 ? convertedBlockOperators : [],
            blocks: convertedBlocks,
        };

        createList({ data: listToSave });
    };

    const isValid = () => {
        const filtered = blocks.filter((block) => {
            if (block.filters.length === 0) return true;
            const filtered = block.filters.filter((filter) => filter.typeID === 0 || filter.valueID === '');
            return filtered.length !== 0;
        });

        return name !== '' && description !== '' && blocks.length !== 0 && filtered.length === 0;
    };

    const toggleDynamic = () => {
        if (dynamic) {
            setFrequency(0);
            setDuration(0);
        }
        setDynamic(!dynamic);
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title="Lista adatok">
                <div css={sectionStyles}>
                    <TextField label="Lista neve*" variant="outlined" value={name} onChange={(e) => setName(e.target.value)} />
                    <TextField multiline label="Lista leírása*" variant="outlined" value={description} onChange={(e) => setDescription(e.target.value)} />
                    <div>
                        <FormControlLabel control={<Checkbox checked={dynamic} onChange={toggleDynamic} />} label="Folyamatos frissítés" />
                    </div>
                    <div css={numberInputsContainerStyles}>
                        <TextField
                            fullWidth
                            label="Gyakoriság (perc)"
                            type="number"
                            inputProps={{ min: 0, max: 60 }}
                            InputLabelProps={{ shrink: true }}
                            disabled={!dynamic}
                            value={frequency}
                            onChange={(e) => setFrequency(Number(e.target.value) > 60 ? 60 : Number(e.target.value))}
                        />
                        <TextField
                            fullWidth
                            label="Élettartam (óra)"
                            type="number"
                            inputProps={{ min: 0, max: 12 }}
                            InputLabelProps={{ shrink: true }}
                            disabled={!dynamic}
                            value={duration}
                            onChange={(e) => setDuration(Number(e.target.value) > 12 ? 12 : Number(e.target.value))}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button variant="contained" color="success" onClick={saveList} disabled={!isValid()}>
                            Mentés
                        </Button>
                    </div>
                </div>
            </Section>
            <Section title="Szűrők">
                <div css={sectionStyles}>
                    {content.map((content, idx) => {
                        if (content.type === 'operator') {
                            return <Operator key={idx} operator={content.content as OperatorType} />;
                        } else {
                            return <FilterBlock key={content.content.id} block={content.content as BlockType} />;
                        }
                    })}
                    <div css={buttonsContainerStyles}>
                        <Button variant="contained" color="info" onClick={() => setIsDialogOpen(true)}>
                            Blokk betöltése
                        </Button>
                        <Button variant="contained" color="success" onClick={addNewBlock}>
                            Új blokk
                        </Button>
                    </div>
                </div>
            </Section>

            <FilterBlockLoadDialog isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} />
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;

    & > * + * {
        margin-top: 20px !important;
    }
`;

const numberInputsContainerStyles = css`
    display: flex;

    & > * + * {
        margin-left: 20px !important;
    }
`;

const buttonsContainerStyles = css`
    display: flex;
    justify-content: center;

    & > * + * {
        margin-left: 20px !important;
    }
`;

export default ListCreatePage;
