/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import huLocale from 'date-fns/locale/hu';
import { Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Button, Typography, TextField } from '@mui/material';
import { Section } from '../../components/Section';
import { v4 } from 'uuid';
// import useErrorHandler from '../../utils/userErrorHandler';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

const dummyOrdersWithCOD = {
    data: [
        {
            id: 1,
            product: {
                id: 1,
                name: 'remek termék',
            },
            customer: {
                id: 1,
                name: 'Virág Sándor',
                address: '1234 Karó falva, Szőlő sor 12.',
            },
            date: '2022-02-02 02:02:02',
            shippingDate: '2022-02-08',
            seller: {
                id: 1,
                name: 'Hajni',
            },
        },
        {
            id: 2,
            product: {
                id: 1,
                name: 'remek termék',
            },
            customer: {
                id: 4,
                name: 'Virág Géza',
                address: '4321 Mezőváros , Fa utca 21.',
            },
            date: '2022-02-03 02:02:02',
            shippingDate: '2022-02-08',
            seller: {
                id: 1,
                name: 'Hajni',
            },
        },
    ],
};

const CodPage: React.FC = () => {
    // const [codOrders, codOrdersResp] = useOffDayQuery();
    // useErrorHandler( codOrdersResp.error, );

    const [date, setDate] = useState<Date | null>(new Date());

    useEffect(() => {
        if (date) {
            console.log('date changed... refetching data');
        }
    }, [date]);

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`Utánvétek ${date?.toLocaleDateString()} napon`}>
                <div css={sectionStyles}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={huLocale}>
                        <DatePicker
                            label="Utánvét napja"
                            views={['year', 'month', 'day']}
                            inputFormat="yyyy.MM.dd."
                            mask={'____.__.__.'}
                            value={date}
                            onChange={(newDate: Date | null) => setDate(newDate)}
                            renderInput={(params) => <TextField {...params} css={inputsStyles} required />}
                        />
                    </LocalizationProvider>
                    <div css={buttonContainerStyle}>
                        <Button variant="contained" color="success">
                            Keres
                        </Button>
                    </div>
                    {dummyOrdersWithCOD.data?.length !== undefined && dummyOrdersWithCOD.data?.length !== 0 ? (
                        <TableContainer aria-label="table" style={{ minWidth: 1310 }} component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">ID</TableCell>
                                        <TableCell align="center">Rendelés dátuma</TableCell>
                                        <TableCell align="center">Szállítás dátuma</TableCell>
                                        <TableCell align="center">Értékesítő</TableCell>
                                        <TableCell align="center">Vevő neve</TableCell>
                                        <TableCell align="center">Címe</TableCell>
                                        <TableCell align="center">Termékek</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dummyOrdersWithCOD.data?.map((row) => (
                                        <TableRow key={v4()} css={trStyles}>
                                            <TableCell align="center">{row.id}</TableCell>
                                            <TableCell align="center">{row.date}</TableCell>
                                            <TableCell align="center">{row.shippingDate}</TableCell>
                                            <TableCell align="center">{row.seller.name}</TableCell>
                                            <TableCell align="center">{row.customer.name}</TableCell>
                                            <TableCell align="center">{row.customer.address}</TableCell>
                                            <TableCell align="center">{row.product.name}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : dummyOrdersWithCOD.data?.length === 0 ? (
                        <Typography variant="body1" color="primary" align="center">
                            Nincs megjeleníthető adat a megadott feltételek alapján!
                        </Typography>
                    ) : null}
                </div>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;

    & > * + * {
        margin-top: 20px !important;
    }
`;

const trStyles = css`
    &: nth-of-type(odd) {
        background-color: #e3f2fd;
    }

    &:last-child td,
    &:last-child th {
        border: 0;
    }
`;

const buttonContainerStyle = css`
    text-decoration: none;
    margin-left: auto;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const inputsStyles = css`
    width: 100%;
`;

export default CodPage;
