export const countries: string[] = [
    'Albánia',
    'Andorra',
    'Ausztria',
    'Belgium',
    'Bosznia-Hercegovina',
    'Bulgária',
    'Csehország',
    'Dánia',
    'Egyesült Királyság',
    'Észtország',
    'Fehéroroszország',
    'Finnország',
    'Franciaország',
    'Görögország',
    'Hollandia',
    'Horvátország',
    'Izland',
    'Írország',
    'Lengyelország',
    'Lettország',
    'Liechtenstein',
    'Litvánia',
    'Luxemburg',
    'Észak-Macedónia',
    'Magyarország',
    'Málta',
    'Moldova',
    'Monaco',
    'Montenegró',
    'Németország',
    'Norvégia',
    'Olaszország',
    'Oroszország',
    'Portugália',
    'Románia',
    'San Marino',
    'Spanyolország',
    'Svájc',
    'Svédország',
    'Szerbia',
    'Szlovákia',
    'Szlovénia',
    'Törökország',
    'Ukrajna',
    'Vatikán',
];
