import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const removeProductFromPackageMutation = gql`
    mutation removeProductFromPackage($package: Int!, $subproduct: Int!) {
        removeProductFromPackage(package: $package, subproduct: $subproduct) {
            id
        }
    }
`;

interface Product {
    id: number;
}

const useRemoveProductFromPackageMutation = mutationFactory<Product>(removeProductFromPackageMutation, 'removeProductFromPackage');

export default useRemoveProductFromPackageMutation;
