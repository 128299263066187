/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import useCampaignsQuery, { Campaign } from '../gql/campaigns';
import useErrorHandler from '../../../utils/userErrorHandler';

type CampaignSelectionProps = {
    selections: number | null;
    setSelections: React.Dispatch<React.SetStateAction<number | null>>;
};

const CampaignSelection: React.FC<CampaignSelectionProps> = (props) => {
    const campaignsResp = useCampaignsQuery();
    useErrorHandler(campaignsResp.error);

    const [value, setValue] = useState<string>('Nincs megadva');

    useEffect(() => {
        if (campaignsResp.data) handleValue();
    }, [campaignsResp.data]);

    const handleChange = (selectedValue: string | null): void => {
        if (selectedValue) {
            const value = selectedValue.split(',', 1)[0];
            const selected = campaignsResp.data?.find((campaign) => campaign.name === value);
            selected ? props.setSelections(selected.id) : props.setSelections(null);
            if (selected) {
                setValue(selected.name);
            } else {
                setValue('Nincs megadva');
            }
        }
    };

    const handleValue = () => {
        const selected = campaignsResp.data?.find((campaign) => campaign.id === props.selections);
        if (selected) {
            setValue(selected.name);
        } else {
            setValue('Nincs megadva');
        }
    };

    return (
        <Autocomplete
            options={
                campaignsResp.data
                    ? [
                          'Nincs megadva',
                          ...campaignsResp.data.map((data: Campaign) => {
                              return data.name;
                          }),
                      ]
                    : []
            }
            value={value}
            renderInput={(params) => <TextField {...params} label="Kampány *" placeholder="Kampány" />}
            onChange={(_event, value) => {
                handleChange(value);
            }}
            css={inputsStyles}
        />
    );
};

const inputsStyles = css`
    width: 100%;
`;

export default CampaignSelection;
