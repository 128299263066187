import { gql } from '@apollo/client';
import { mutationFactory } from '../../../../utils/gqlFactories';

const deleteCustomerNote = gql`
    mutation deleteCustomerNote($id: Int!) {
        deleteCustomerNote(id: $id)
    }
`;

const useDeleteCustomerNote = mutationFactory<number>(deleteCustomerNote, 'deleteCustomerNote');

export default useDeleteCustomerNote;
