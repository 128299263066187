import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const myUpsellLists = gql`
    query myUpsellLists($lastNDays: Int!) {
        myUpsellLists(lastNDays: $lastNDays) {
            id
            created_at
        }
    }
`;

const useMyUpsellLists = queryFactory<{ id: number; created_at: string }[]>(myUpsellLists, 'myUpsellLists');

export default useMyUpsellLists;
