import { gql } from '@apollo/client';
import { lazyQueryFactory } from '../../../utils/gqlFactories';

const customerPendingDeliveriesQuery = gql`
    query customerPendingDeliveries($customer: Int!) {
        customerPendingDeliveries(customer: $customer) {
            id
            method {
                name
            }
            name
            country
            city
            zip
            address
            date
            phone
        }
    }
`;

interface PendingShippingDetail {
    id: number;
    method: { name: string };
    name: string;
    country: string;
    city: string;
    zip: string;
    address: string;
    date: string;
    phone: string;
}

const useCustomerPendingDeliveriesQuery = lazyQueryFactory<PendingShippingDetail[]>(customerPendingDeliveriesQuery, 'customerPendingDeliveries');

export type { PendingShippingDetail };
export default useCustomerPendingDeliveriesQuery;
