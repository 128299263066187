import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const createProductCategoryMutation = gql`
    mutation createProductCategory($data: ProductCategoryInputCreate!) {
        createProductCategory(data: $data) {
            id
        }
    }
`;

interface ProductCategory {
    id: number;
}

const useCreateProductCategory = mutationFactory<ProductCategory>(createProductCategoryMutation, 'createProductCategory');

export default useCreateProductCategory;
