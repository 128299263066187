import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const createOffDayMutation = gql`
    mutation createOffDay($data: OffDayInputCreate!) {
        createOffDay(data: $data) {
            id
        }
    }
`;

interface CreatedOffDay {
    id: number;
}

const useCreateOffDayMutation = mutationFactory<CreatedOffDay>(createOffDayMutation, 'createOffDay');

export default useCreateOffDayMutation;
