/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Paper } from '@mui/material';
import { Section } from '../../components/Section';
import { useParams } from 'react-router-dom';
import useErrorHandler from '../../utils/userErrorHandler';
import useProductQuery from '../ProductPage/gql/product';
import { SubSection } from '../../components/SubSection';
import ProductInfo from './ProductInfo/ProductInfo';
import ProductClassification from './ProductClassification/ProductClassification';
import PackageProducts from './PackageProducts/PackageProducts';

const ProductEditPage: React.FC = () => {
    const params = useParams();
    const id = params.id as string;
    const productResp = useProductQuery({ id: parseInt(id) });

    useErrorHandler(productResp.error);

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`#${id}: Termék${productResp.data?.package ? 'csomag' : ''} szerkesztése`}>
                <div css={sectionStyles}>
                    <SubSection title="Általános információk">{productResp.data && <ProductInfo name={productResp.data.name} description={productResp.data.description} stripeId={productResp.data.stripe_id} id={id} />}</SubSection>
                    <SubSection title={`Termék${productResp.data?.package ? 'csomag' : ''} besorolása`}>
                        {productResp.data && <ProductClassification selectedCategories={productResp.data.categories.map((cat) => cat.id)} selectedTags={productResp.data.ac_tags} id={id} />}
                    </SubSection>
                    {productResp.data?.package && <SubSection title="Termékcsomag tartalma:">{productResp.data && <PackageProducts selectedProducts={productResp.data.subproducts} id={id} />}</SubSection>}
                </div>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;
    & > * + * {
        margin-top: 20px !important;
    }
`;

export default ProductEditPage;
