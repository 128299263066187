import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const products = gql`
    query products($package: Boolean) {
        products(package: $package) {
            id
            name
            ac_tags {
                id
                name
                description
            }
        }
    }
`;

interface Tag {
    id: number;
    name: string;
    description: string;
}

type Product = {
    id: number;
    name: string;
    ac_tags: Tag[];
};

const useProductsQuery = queryFactory<Product[]>(products, 'products');

export type { Product };
export default useProductsQuery;
