import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const updateFreebieMutation = gql`
    mutation updateFreebie($id: Int!, $data: FreebieInputUpdate!) {
        updateFreebie(id: $id, data: $data) {
            id
        }
    }
`;

interface Freebie {
    id: number;
}

const useUpdateFreebieMutation = mutationFactory<Freebie>(updateFreebieMutation, 'updateFreebie');

export default useUpdateFreebieMutation;
