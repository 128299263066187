/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Paper, FormControl, InputLabel, Select, MenuItem, Button, TextField } from '@mui/material';
import { Section } from '../../components/Section';
import useErrorHandler from '../../utils/userErrorHandler';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import useProductQuery from '../ProductPage/gql/product';
import TypeSelection from '../ProductPricingPage/TypeSelection/TypeSelection';
import CampaignSelection from '../ProductPricingPage/CampaignSelection/CampaignSelection';
import FallbackSelection from '../ProductPricingPage/FallbackSelection/FallbackSelection';
import useCampaignsQuery from '../ProductPricingPage/gql/campaigns';
import usePriceTypesQuery, { PriceType } from '../ProductPricingPage/gql/priceTypes';
import usePriceQuery from './gql/price';
import useUpdatePriceMutation from './gql/updatePrice';

const ProductPriceEditPage: React.FC = () => {
    const params = useParams();
    const id = params.id as string;
    const pid = params.priceid as string;
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const productResp = useProductQuery({ id: parseInt(id) });
    const campaignsResp = useCampaignsQuery();
    const typesResp = usePriceTypesQuery();
    const priceResp = usePriceQuery({ id: parseInt(pid) });
    const [updatePrice, updatedPriceResp] = useUpdatePriceMutation();

    useErrorHandler(productResp.error, campaignsResp.error, typesResp.error, priceResp.error, updatedPriceResp.error);

    const [netPrice, setNetPrice] = useState<string>('');
    const [grossPrice, setGrossPrice] = useState<string>('');
    const [VAT, setVAT] = useState(0.27);
    const [selCampaigns, setSelCampaigns] = useState<number | null>(null);
    const [selFbPrice, setSelFbPrice] = useState<number | null>(null);
    const [priceTypes, setPriceTypes] = useState<PriceType[]>([]);
    const [selPriceType, setSelPriceType] = useState<PriceType>({ id: 0, name: '', description: '' });
    const [lastUsed, setLastUsed] = useState<string>('');

    useEffect(() => {
        if (priceResp.data) {
            setNetPrice(priceResp.data.net.toString());
            setGrossPrice(priceResp.data.gross.toString());
            setSelPriceType(priceResp.data.type);
            priceResp.data.fallback && setSelFbPrice(priceResp.data.fallback.id);
            priceResp.data.campaign && setSelCampaigns(priceResp.data.campaign.id);
        }
    }, [priceResp.data]);

    useEffect(() => {
        if (typesResp.data) {
            setPriceTypes(typesResp.data);
        }
    }, [typesResp.data]);

    useEffect(() => {
        if (!productResp.data?.package && lastUsed === 'net') {
            netPrice ? setGrossPrice(Math.round(parseInt(netPrice) * (1 + VAT)).toString()) : setGrossPrice('');
        }
    }, [netPrice]);

    useEffect(() => {
        if (!productResp.data?.package && lastUsed === 'gross') {
            grossPrice ? setNetPrice(Math.round(parseInt(grossPrice) * (1 - VAT)).toString()) : setNetPrice('');
        }
    }, [grossPrice]);

    useEffect(() => {
        if (!productResp.data?.package && lastUsed === 'net') {
            netPrice ? setGrossPrice(Math.round(parseInt(netPrice) * (1 + VAT)).toString()) : setGrossPrice('');
        } else if (!productResp.data?.package && lastUsed === 'gross') {
            grossPrice ? setNetPrice(Math.round(parseInt(grossPrice) * (1 - VAT)).toString()) : setNetPrice('');
        }
    }, [VAT]);

    const handlePriceChange = (id: 'net' | 'gross', e: string) => {
        let input = e;
        if (id === 'net') {
            if (e.match(/\D/)) input = input.slice(0, -1);
            if (input.length < 21) setNetPrice(input);
        } else {
            if (e.match(/\D/)) input = input.slice(0, -1);
            if (input.length < 21) setGrossPrice(input);
        }
        setLastUsed(id);
    };

    const handlePriceTypesWithNew = async (inputType: PriceType) => {
        if (typesResp.data && typesResp.data.find((type) => type.id === inputType.id)) {
            setSelPriceType(inputType);
        } else {
            await typesResp.refetch();
            setSelPriceType(inputType);
        }
    };

    const handleSave = async () => {
        if (netPrice && grossPrice && selPriceType.id !== 0) {
            if (selPriceType.name.includes('kampány')) {
                if (selCampaigns && selFbPrice) {
                    await updatePrice({ id: parseInt(pid), data: { net: parseInt(netPrice), gross: parseInt(grossPrice), type: selPriceType.id, campaign: selCampaigns, fallback: selFbPrice } });
                    navigate(`/products/${id}/details`);
                } else {
                    enqueueSnackbar('A csillaggal jelölt mezők kitöltése kötelező!', { variant: 'error' });
                }
            } else {
                await updatePrice({ id: parseInt(pid), data: { net: parseInt(netPrice), gross: parseInt(grossPrice), type: selPriceType.id, campaign: null, fallback: null } });
                navigate(`/products/${id}/details`);
            }
        } else {
            enqueueSnackbar('A csillaggal jelölt mezők kitöltése kötelező!', { variant: 'error' });
        }
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`#${pid}: ár szerkesztése - #${id}: ${productResp.data?.name} termék${productResp.data?.package ? 'csomag' : ''} ára`}>
                <div css={sectionStyles}>
                    <div css={inputsContainerStyles}>
                        <TextField id="netPrice" label="Nettó ár (Ft)" placeholder="A termék nettó ára" value={netPrice} onChange={(e) => handlePriceChange('net', e.target.value)} css={inputsStyles} required />
                        {!productResp.data?.package && (
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="VAT">Áfa</InputLabel>
                                <Select labelId="VAT" id="VAT-select" label="Áfa" value={VAT} onChange={(e) => setVAT(e.target.value as number)} disabled={productResp.data?.package}>
                                    <MenuItem value={0.05}>5%</MenuItem>
                                    <MenuItem value={0.27}>27%</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                        <TextField id="grossPrice" label="Bruttó ár (Ft)" placeholder="A termék bruttó ára" value={grossPrice} onChange={(e) => handlePriceChange('gross', e.target.value)} css={inputsStyles} required />
                        <TypeSelection options={priceTypes} selections={selPriceType} setSelections={handlePriceTypesWithNew} />
                        {selPriceType.name.includes('kampány') && <CampaignSelection selections={selCampaigns} setSelections={setSelCampaigns} />}
                        {selPriceType.name.includes('kampány') && <FallbackSelection selections={selFbPrice} setSelections={setSelFbPrice} id={id} />}
                    </div>
                    <div css={buttonInputsContainerStyles}>
                        <Button size="large" variant="contained" color="success" onClick={handleSave}>
                            {`Ár módosítása`}
                        </Button>
                    </div>
                </div>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    position: relative;
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;

    & > * + * {
        margin-top: 25px !important;
    }
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;

    & > * + * {
        margin-top: 20px !important;
    }
`;

const inputsContainerStyles = css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    & > * + * {
        margin-top: 20px !important;
    }
`;

const inputsStyles = css`
    width: 100%;
`;

const buttonInputsContainerStyles = css`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;

    @media (max-width: 1200px) {
        justify-content: center;
        align-items: center;
    }
`;

export default ProductPriceEditPage;
