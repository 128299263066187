/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import { Paper, Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Section } from '../../components/Section';
import SentEmails from './SentEmails/SentEmails';
import useSentEmails from './gql/sentEmails';
import useErrorHandler from '../../utils/userErrorHandler';

const SentEmailsPage: React.FC = () => {
    const automaticallySentEmails = useSentEmails({ automatic: true });
    const manuallySentEmails = useSentEmails({ automatic: false });
    const [displayedEmails, setDisplayedEmails] = useState<'automatic' | 'manual'>('automatic');
    useErrorHandler(automaticallySentEmails.error, manuallySentEmails.error);

    const handleTabChange = (_event: React.SyntheticEvent, newValue: 'automatic' | 'manual') => {
        setDisplayedEmails(newValue);
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title="Elküldött üzenetek">
                <TabContext value={displayedEmails}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                            <Tab label="Automatikus üzenetek" value="automatic" />
                            <Tab label="Manuális üzenetek" value="manual" />
                        </TabList>
                    </Box>
                    <TabPanel value="automatic" style={{ padding: 0 }}>
                        {automaticallySentEmails.data && <SentEmails emails={automaticallySentEmails.data} />}
                    </TabPanel>
                    <TabPanel value="manual" style={{ padding: 0 }}>
                        {manuallySentEmails.data && <SentEmails emails={manuallySentEmails.data} />}
                    </TabPanel>
                </TabContext>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

export default SentEmailsPage;
