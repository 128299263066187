import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const acTag = gql`
    query acTag($id: Int!) {
        acTag(id: $id) {
            id
            name
            description
        }
    }
`;

type AcTag = {
    id: number;
    name: string;
    description: string | null;
};

const useAcTagQuery = queryFactory<AcTag>(acTag, 'acTag');

export type { AcTag };
export default useAcTagQuery;
