import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const createWorkingDayModMutation = gql`
    mutation createWorkingDayMod($data: WorkingDayModInputCreate!) {
        createWorkingDayMod(data: $data) {
            id
            type {
                name
            }
            date
        }
    }
`;

interface WorkingDay {
    id: number;
    type: { name: string };
    date: string;
}

const useCreateWorkingDayModMutation = mutationFactory<WorkingDay>(createWorkingDayModMutation, 'createWorkingDayMod');

export default useCreateWorkingDayModMutation;
