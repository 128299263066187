/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Paper, Button, TextField } from '@mui/material';
import { Section } from '../../components/Section';
import { useNavigate, useParams } from 'react-router-dom';
import useProductCategoryQuery from './gql/productCategory';
import useErrorHandler from '../../utils/userErrorHandler';
import useUpdateProductCategoryMutation from './gql/updateProductCategory';
import { useSnackbar } from 'notistack';

const CategoryEditPage: React.FC = () => {
    const params = useParams();
    const id = params.id as string;
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const getCategory = useProductCategoryQuery({ id: parseInt(id) });
    const [updateCategory, updatedCategoryResp] = useUpdateProductCategoryMutation();
    useErrorHandler(getCategory.error, updatedCategoryResp.error);

    const [name, setName] = useState<string>('');

    useEffect(() => {
        if (getCategory.data) setName(getCategory.data.name);
    }, [getCategory.data]);

    const handleSave = async () => {
        if (name) {
            await updateCategory({ id: parseInt(id), data: { name } });
            navigate('/products/categories');
        } else {
            enqueueSnackbar('A csillaggal jelölt mezők kitöltése kötelező!', { variant: 'error' });
        }
    };

    return (
        <Paper css={containerStyles} elevation={0}>
            <Section title={`#${id}: kategória szerkesztése`}>
                <div css={sectionStyles}>
                    <div css={inputsContainerStyles}>
                        <TextField fullWidth id="description" label="Név" placeholder="A kategória neve" value={name} onChange={(e) => setName(e.target.value)} css={inputsStyles} required />
                        <div css={buttonInputsContainerStyles}>
                            <Button size="large" variant="contained" color="success" css={buttonStyle} onClick={handleSave}>
                                Módosít
                            </Button>
                        </div>
                    </div>
                </div>
            </Section>
        </Paper>
    );
};

const containerStyles = css`
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    gap: 25px;
`;

const sectionStyles = css`
    display: flex;
    flex-direction: column;
    padding: 20px;
    & > * + * {
        margin-top: 20px !important;
    }
`;

const inputsContainerStyles = css`
    display: flex;
    gap: 20px;
    @media (max-width: 1200px) {
        flex-direction: column;
    }
`;

const inputsStyles = css`
    width: 300px;
    @media (max-width: 1200px) {
        width: 100%;
    }
`;

const buttonInputsContainerStyles = css`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    gap: 20px;
    @media (max-width: 1200px) {
        justify-content: center;
        align-items: center;
    }
`;

const buttonStyle = css`
    padding: 0 3rem;
`;

export default CategoryEditPage;
