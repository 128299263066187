import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const blockReasons = gql`
    query {
        blockReasons {
            id
            name
        }
    }
`;

const useBlockReasons = queryFactory<{ id: number; name: string }[]>(blockReasons, 'blockReasons');

export default useBlockReasons;
