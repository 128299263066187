import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const customerDetailQuery = gql`
    query customerDetail($id: Int!) {
        customerDetail(id: $id) {
            id
            customer {
                email
            }
            firstname
            lastname
            phone
            created_at
        }
    }
`;

interface CustomerDetail {
    id: number;
    customer: { email: string };
    firstname: string;
    lastname: string;
    phone: string;
    created_at: string;
}

const useCustomerDetailQuery = queryFactory<CustomerDetail>(customerDetailQuery, 'customerDetail');

export type { CustomerDetail };
export default useCustomerDetailQuery;
