import { gql } from '@apollo/client';
import { queryFactory } from '../../../utils/gqlFactories';

const productCategoryQuery = gql`
    query productCategory($id: Int!) {
        productCategory(id: $id) {
            id
            name
        }
    }
`;

interface ProductCategory {
    id: number;
    name: string;
}

const useProductCategoryQuery = queryFactory<ProductCategory>(productCategoryQuery, 'productCategory');

export default useProductCategoryQuery;
