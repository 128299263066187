/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useContext } from 'react';
import { ListContext, ListContextType, FilterType } from '../../../contexts/ListContext';
import { Button, Select, MenuItem } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { SubSection } from '../../../components/SubSection';
import { CustomDateRangePicker } from '../../../components/CustomDateRangePicker';
import useListFilterTypes from './gql/listFilterType';
import useListFilterValues from './gql/listFilterValues';

type FilterProps = {
    filter: FilterType;
    blockID: number;
};

const Filter: React.FC<FilterProps> = ({ filter, blockID }: FilterProps) => {
    const listFilterType = useListFilterTypes();
    const listFilterValues = useListFilterValues({ filterType: filter.typeID });
    const { dispatch } = useContext(ListContext) as ListContextType;

    const handleFilterChange = (selectedFilterID: number) => {
        dispatch({ type: 'UPDATE_FILTER', payload: { blockID: blockID, filterID: filter.id, updatedFilter: { ...filter, typeID: selectedFilterID, valueID: '' } } });
    };

    const handleSubjectChange = (selectedSubjectID: string) => {
        dispatch({ type: 'UPDATE_FILTER', payload: { blockID: blockID, filterID: filter.id, updatedFilter: { ...filter, valueID: selectedSubjectID } } });
    };

    const handleNegateChange = () => {
        dispatch({ type: 'UPDATE_FILTER', payload: { blockID: blockID, filterID: filter.id, updatedFilter: { ...filter, negation: !filter.negation } } });
    };

    const handleDateChange = (from: Date, to: Date) => {
        dispatch({ type: 'SET_DATE', payload: { blockID: blockID, filterID: filter.id, from: from, to: to } });
    };

    return (
        <div css={containerStyles}>
            <div css={inputsContainerStyles}>
                <Button variant="contained" color={filter.negation ? 'error' : 'success'} onClick={handleNegateChange} style={{ minWidth: 56 }}>
                    {filter.negation && <RemoveIcon fontSize="large" />}
                    {!filter.negation && <AddIcon fontSize="large" />}
                </Button>
                <Select style={{ backgroundColor: 'white' }} fullWidth value={filter.typeID === 0 ? '' : filter.typeID} onChange={(e) => handleFilterChange(Number(e.target.value))}>
                    {listFilterType.data?.map((filter) => (
                        <MenuItem value={filter.id} key={filter.id}>
                            {filter.name}
                        </MenuItem>
                    ))}
                </Select>
                <Select style={{ backgroundColor: 'white' }} fullWidth value={filter.valueID} onChange={(e) => handleSubjectChange(e.target.value)}>
                    {listFilterValues.data?.map((filterValue) => (
                        <MenuItem value={filterValue.id} key={filterValue.id}>
                            {filterValue.name}
                        </MenuItem>
                    ))}
                </Select>
                <Button variant="contained" color="error" onClick={() => dispatch({ type: 'REMOVE_FILTER', payload: { blockID: blockID, filterID: filter.id } })} style={{ minWidth: 56 }}>
                    <DeleteForeverIcon fontSize="large" />
                </Button>
            </div>
            <div css={dateContainerStyles}>
                <SubSection title={`Dátum: ${filter.from.toLocaleDateString()} - ${filter.to.toLocaleDateString()}`} collapsible closedDefault fitContent midTitle>
                    <CustomDateRangePicker
                        ranges={[
                            {
                                startDate: filter.from,
                                endDate: filter.to,
                                key: 'selection',
                            },
                        ]}
                        onChange={(item) => handleDateChange(item.selection.startDate, item.selection.endDate)}
                    />
                </SubSection>
            </div>
        </div>
    );
};

const containerStyles = css`
    display: flex;
    flex-direction: column;

    & > * + * {
        margin-top: 30px !important;
    }
`;

const inputsContainerStyles = css`
    display: flex;
    justify-content: space-between;

    & > * + * {
        margin-left: 30px !important;
    }
`;

const dateContainerStyles = css`
    display: flex;
    justify-content: center;
`;

export default Filter;
