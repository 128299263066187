import { gql } from '@apollo/client';
import { mutationFactory } from '../../../utils/gqlFactories';

const createCustomerCharacteristicOption = gql`
    mutation createCustomerCharacteristicOption($data: CustomerCharacteristicOptionInputCreate!) {
        createCustomerCharacteristicOption(data: $data) {
            id
        }
    }
`;

const useCreateCustomerCharacteristicOption = mutationFactory<{ id: number }>(createCustomerCharacteristicOption, 'createCustomerCharacteristicOption');

export default useCreateCustomerCharacteristicOption;
